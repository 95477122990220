import React from "react"
import styled from "styled-components"
import image from "galaxies/elements/sun.svg"

const Image = styled.img`
  position: fixed;
  left: 50%;
  margin-left: -425px;
  width: 850px;
  height: 769px;
`

const Sun = () => <Image src={image} />

export default Sun
