import React from "react"
import DailyMissionFinishedModal from "components/modals/daily-mission-finished-modal"

const DailyMissionSuccessModal = () => (
  <DailyMissionFinishedModal>
    <h2>Missão Concluída!</h2>
    <p className="medium">Você respondeu todas as questões do dia.</p>
  </DailyMissionFinishedModal>
)

export default DailyMissionSuccessModal
