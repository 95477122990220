import styled from "styled-components"
import PropTypes from "prop-types"
import { withPlanet } from "planet-context"

const AvatarHeader = withPlanet(styled.div`
  background: url(${props => props.planet.editProfileBackground}) no-repeat bottom center;
  background-size: 535px auto;
  margin-top: 70px;

  .avatar {
    height: 250px;
    margin: 0 auto;
    display: block;
  }
`)

AvatarHeader.propTypes = {
  planet: PropTypes.shape({
    editProfileBackground: PropTypes.string.isRequired
  })
}

const AvatarHeaderTitle = styled.h2`
  margin-bottom: 50px;
  font-size: 39px;
  font-weight: 500;
  text-align: center;
`

export { AvatarHeader, AvatarHeaderTitle }
