import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import { connect } from "react-redux"
import BadgesList from "backpack/badges/BadgesList"
import {
  EarnedImageContainer,
  EarnedText,
  EarnedTitle,
  EarnedTitleLight,
  EarnedParagraph
} from "backpack/badges/styled"
import { fetchBadges } from "backpack/actions"

class EarnedBadge extends React.Component {
  componentDidMount () {
    this.props.dispatch(fetchBadges())
  }

  render () {
    const { badges, badgeSlug, opened, onClose } = this.props
    const badge = badges.find(x => x.slug == badgeSlug)

    if (!badge) return null
    const Component = BadgesList[badge.slug].component

    return (
      <CloseableModal opened={opened} color="honey-flower" onClose={onClose} fullscreen>
        <EarnedImageContainer>
          <Component />
        </EarnedImageContainer>
        <EarnedTitleLight>Você ganhou a badge</EarnedTitleLight>
        <EarnedTitle>{badge.id}</EarnedTitle>
        <EarnedText>{badge.trigger_earned_text}</EarnedText>
        <EarnedParagraph>{badge.description}</EarnedParagraph>
      </CloseableModal>
    )
  }
}

const mapStateToProps = state => {
  return {
    badges: state.backpackReducer.badges
  }
}

export default connect(mapStateToProps)(EarnedBadge)
