import React from "react"
import {
  OddsCircleRed,
  OddsCircleGreen,
  ChancesContainer,
  TableTitle,
  Table,
  Th,
  BottomText,
  OddContainer
} from "MySisu/styled"
import { connect } from "react-redux"

const Odds = ({ odds, approved }) => (
  <OddContainer>
    {approved ? <OddsCircleGreen /> : <OddsCircleRed />}
    {approved ? ">" : "<"}
    {parseFloat(odds) * 100} %
  </OddContainer>
)

const Chances = ({ chances, chancesTableTitle }) => (
  <div>
    <ChancesContainer>
      <TableTitle>
        <strong>{chancesTableTitle}</strong>
      </TableTitle>
      <Table>
        <thead>
          <tr>
            <Th>Universidade</Th>
            <Th>Curso</Th>
            <Th>Nota ponderada</Th>
            <Th>Nota de corte</Th>
            <Th>Chances</Th>
          </tr>
        </thead>
        <tbody>
          {chances.map((course, index) => {
            return (
              <tr key={index}>
                <td>{course.college}</td>
                <td>{course.course}</td>
                <td>{course.calculated_grade}</td>
                <td>{course.minimum_grade}</td>
                <td>
                  <Odds odds={course.odds} approved={course.approved} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </ChancesContainer>
    <BottomText>
      *Atenção: suas chances de aprovação foram estimadas com base nas notas de corte de ampla concorrência (sem cotas).
      Essa estimativa não possui nenhum efeito válido para a seleção do SISU.
    </BottomText>
  </div>
)

const mapStateToProps = state => {
  return {
    chances: state.mySisuReducer.chances,
    chancesTableTitle: state.mySisuReducer.chancesTableTitle
  }
}

export default connect(mapStateToProps)(Chances)
