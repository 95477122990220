import { push } from "connected-react-router"
import { MixpanelSignupEvent } from "tags/mixpanel"
import API from "api"
import NextStepRouter from "onboarding/next-step-router"

export function confirmCheckout (currentUser, dispatch) {
  API.post("/checkout/confirmation", {}, response => {
    const { next_step: nextStep } = response.headers

    if (nextStep == "enjoying") {
      MixpanelSignupEvent(currentUser, currentUser.relative)
    }

    const route = NextStepRouter(nextStep)
    dispatch(push(route))
  })
}
