import React from "react"
import styled from "styled-components"
import image from "galaxies/elements/neighboring-planets.svg"

const Image = styled.img`
  width: 750px;
  height: 628px;

  @media (max-width: 767px) {
    width: 350px;
    height: 293px;
  }
`

const NeighboringPlanets = () => <Image src={image} />

export default NeighboringPlanets
