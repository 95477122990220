import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { withPlanet } from "planet-context"
import { Container, OverlayContainer, Title, Name, Answered } from "daily-mission/knowledge-area"
import { Ruby, Padlock } from "Icons"

const Blocked = styled.div`
  width: 117px;
  height: 151px;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  font-family: "Montserrat";
  font-weight: 700;
  position: absolute;
  top: 30px;
  left: 144px;
`

const BlockedTop = styled.div`
  background-color: #ff8532;
  height: 99px;
  border-radius: 20px 20px 0 0;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${Padlock} {
    width: 17px;
    height: 22px;
    margin-bottom: 10px;
    background-color: #fff;
  }

  &:hover {
    background-color: #e97322;
  }
`

const BlockedBottom = styled.div`
  background-color: #e97322;
  height: 52px;
  font-size: 20px;
  border-radius: 0 0 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${Ruby} {
    margin-left: 5px;
  }
`

const RetrievedQuestion = ({ started, answered, planet }) => {
  const Wrapper = started && !answered ? Container : OverlayContainer

  return (
    <Wrapper color={planet.knowledgeAreaSlide.prev}>
      {answered && <Answered color={planet.mainColor}>Respondida</Answered>}

      {!started && (
        <Blocked>
          <BlockedTop>
            <Padlock /> DESBLOQUEAR
          </BlockedTop>

          <BlockedBottom>
            01 <Ruby />
          </BlockedBottom>
        </Blocked>
      )}

      <Title>
        <div>Missão diária</div>
        <Name>{started ? "Questão resgatada" : "Resgatar questão"}</Name>
      </Title>
    </Wrapper>
  )
}

RetrievedQuestion.propTypes = {
  answered: PropTypes.bool,
  planet: PropTypes.shape({
    mainColor: PropTypes.string.isRequired,
  }),
}

export default withPlanet(RetrievedQuestion)
