import React from "react"
import { Link } from "react-router-dom"

const BtnLink = ({ to, value, ...props }) => (
  <Link to={to} className="btn btn-link" {...props}>
    {value}
  </Link>
)

export default BtnLink
