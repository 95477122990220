import styled from "styled-components"

const StyledHorizon = styled.div`
  width: 100%;
  position: ${(props) => (props.fixed ? "fixed" : "relative")};
  z-index: ${(props) => (props.fixed ? 1 : 0)};
  &::before {
    content: "";
    position: absolute;
    display: block;
    z-index: 2;
  }
`

const StyledHorizonContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  height: ${(props) => (props.short ? 245 : 330)}px;
  max-width: 1920px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 1020px;
    z-index: 1;
  }
`

const StyledHorizonTitle = styled.div`
  font-family: "Montserrat";
  font-size: 39px;
  line-height: 1em;
  font-weight: 700;
  color: #ffffff;
  z-index: 3;

  @media (max-width: 768px) {
    font-size: 25px;
    margin-top: 20px;
  }
`

export { StyledHorizon, StyledHorizonContainer, StyledHorizonTitle }
