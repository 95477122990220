import React from "react"
import { getAllGrades } from "GamesStation/gradeDetails"
import { getGamesByType, getOlympicGames } from "GamesStation/gameNames"
import Layout from "GamesStation/Layout"
import ServiceDown from "GamesStation/ServiceDown"
import {
  GridContainer,
  GradeItem,
  GradeLabel,
  GradesGrid,
  LabelOlympic,
  OlympicGames,
  ModalGameList,
  ModalContainer,
  MadeImage
} from "GamesStation/styled"

import GameList from "GamesStation/GameList"
import styled from "styled-components"

const ModalHeader = styled.div`
  position: relative;
  padding: 15px 0;
`

const CloseButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 0;
  width: 25px;
  height: 25px;
  display: flex;
  border-radius: 100%;
  padding: 0;
  align-items: center;
  justify-content: center;
  border: 1px solid #ececec;
`

class GradeList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      games: [],
      maintenance: false,
      showModalGameList: false,
      selectedGrade: "",
      olympicGames: getOlympicGames() || []
    }
  }

  toggleShowModal(grade) {
    this.setState({
      games: getGamesByType(grade?.gradeType, grade?.gradeYear),
      selectedGrade: grade ? `${grade.title} ${grade.subtitle}` : "",
      showModalGameList: !this.state.showModalGameList
    })
  }

  render() {
    const { maintenance, showModalGameList } = this.state
    const ALL_GRADES_LIST = getAllGrades()

    if (maintenance) {
      return <ServiceDown />
    }

    return (
      <Layout backUrl="/galaxia">
        <GridContainer>
          <GradesGrid>
            {ALL_GRADES_LIST.map(grade => {
              return (
                <GradeItem
                  hide={grade.hide}
                  key={grade.id}>
                  <MadeImage
                    bg={grade.bg}
                    bg_contrast={grade.bg_contrast}
                    hide={grade.hide}
                    onClick={() => this.toggleShowModal(grade)}>
                    <div>{grade.title}</div>
                    <span>{grade.subtitle}</span>
                  </MadeImage>
                </GradeItem>
              )
            })}
          </GradesGrid>

          <div>
            <LabelOlympic>Jogos Olímpicos</LabelOlympic>
            <OlympicGames>
              <GameList props={this.props} games={this.state.olympicGames}></GameList>
            </OlympicGames>
          </div>
        </GridContainer>

        { showModalGameList && (
          <ModalContainer onClick={() => this.toggleShowModal()}>
            <ModalGameList>
              <ModalHeader>
                <CloseButton>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" /></svg>
                </CloseButton>
                <GradeLabel>{this.state.selectedGrade}</GradeLabel>
              </ModalHeader>
              <GameList props={this.props} games={this.state.games}></GameList>
            </ModalGameList>
          </ModalContainer>
        )}
      </Layout>
    )
  }
}

export default GradeList
