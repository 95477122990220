import floor from "planets/onis/onis-checkpoint.svg"
import smallMountain from "planets/onis/onis-mountain.svg"

export default {
  mainColor: "#ff534d",
  secondaryColor: "#ff7d00",
  floor: {
    image: floor,
    height: 130
  },
  stone: {
    image: smallMountain,
    width: 230,
    height: 180,
    left: 30
  }
}
