import React from "react"
import { Link } from "react-router-dom"

const BtnPrimary = ({ to, value, arrow, check, disabled, disabledValue, color = "", small, ...rest }) => (
  <Link
    to={to}
    disabled={disabled}
    tabIndex={disabled ? "-1" : null}
    className={`btn btn-primary ${color} ${small ? "small" : ""}`}
    {...rest}>
    {disabled && disabledValue ? disabledValue : value}

    {arrow && <i className="btn-icon arrow-icon arrow-icon-right" />}
    {check && <i className="btn-icon check-icon" />}
  </Link>
)

export default BtnPrimary
