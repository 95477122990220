export default [
  {
    title: "Regras básicas",
    description: "",
    items: [
      {
        text: "Escolher o nível de dificuldade. E a quantidade de partidas que deseja jogar.",
      },
      {
        text: "O Gamão deve ser praticado por dois jogadores. Joga-se com um tabuleiro que tem vinte e quatro triângulos chamados casas. Os triângulos têm cores alternadas e estão agrupados em quatro quadrantes com seis triângulos em cada um. Referimo-nos aos quadrantes como quadrante interior e quadrante exterior. Os quadrantes interiores e exteriores são separados por um sulco no centro do tabuleiro, chamado Barra.",
      },
      {
        text: "Cada jogador tem quinze peças da sua cor. ",
      }
    ]
  },
  {
    title: "Movendo as peças",
    description: "",
    items: [
      {
        text: "Para iniciar a partida, cada jogador lança apenas um dado. O que obtiver o maior valor começa a partida e realiza seu movimento usando os dois valores obtidos nesse lançamento inicial. Se sair valores iguais, os jogadores lançarão os dados novamente. Após essa primeira jogada, cada jogador lança os dois dados em turnos alternados.",
      },
      {
        text: "O resultado do lançamento dos dados indica o número de casas que o jogador deve mover suas peças. As regras são as seguintes:",
        items: [
          {
            text: "Uma peça pode ser movida apenas para uma casa aberta, ou seja, uma que não esteja ocupada por duas ou mais peças do oponente.",
          },
          {
            text: "Os valores dos dois dados constituem movimentos separados. Por exemplo, se o jogador lançar 5 e 3, poderá mover uma peça por cinco espaços para uma casa aberta e outra peça por três espaços para uma casa aberta, ou a mesma peça por oito espaços em direção a uma casa aberta, mas apenas se uma das casas intermediárias (três ou cinco espaços, a partir da casa inicial) também estiver aberta.",
          },
          {
            text: "O jogador que tirar o mesmo valor nos dois dados (duplo) deve mover essa quantidade de casas duas vezes. Um lançamento de 6 e 6 indica que o jogador deve mover quatro vezes seis casas, podendo usar qualquer combinação de peças que julgar adequada para completar este resultado.",
          },
          {
            text: "O jogador é obrigado a usar os dois valores do lançamento sempre que possível (ou os quatro valores de um duplo). Quando apenas um valor puder ser usado, o jogador deverá movimentar sua peça de acordo com ele. Se qualquer um dos valores puder ser usado, mas não ambos, o jogador deverá usar o valor mais alto. Se nenhum dos valores puder ser usado, o jogador perde a vez. Em um lançamento duplo, quando não for possível efetuar os quatro movimentos, o jogador deve fazer o maior número de movimentos possível.",
          }
        ]
      }
    ]
    },
  {
    title: "Ataque e reentrada",
    description: "",
    items: [
      {
        text: "Uma casa ocupada por apenas uma peça de qualquer cor é chamada “blot” (patrulheiro solitário). Se uma peça chegar a uma casa ocupada por apenas uma peça do oponente (“blot”), essa peça do oponente é colocada na Barra.",
      },
      {
        text: "Toda vez que um jogador tiver uma ou mais peças na Barra, seu primeiro dever é reinserir essa(s) peça(s) no tabuleiro, no quadrante interior do seu oponente. A peça deve ser inserida em uma casa aberta, correspondente a um dos valores tirados no lançamento dos dados.",
      },
      {
        text: "Se nenhuma das casas estiver aberta, o jogador perde a vez. Se o jogador não puder reinserir todas as suas peças, deve colocar o maior número possível e depois passar a vez ao oponente.",
      },
      {
        text: "Assim que a última peça do jogador for reinserida, os valores ainda não utilizados no dado devem ser jogados, movendo a peça que foi reinserida ou uma peça diferente.",
      }
    ]
  },
  {
    title: "Retirada das peças",
    description: "",
    items: [
      {
        text: "Somente quando o jogador colocar todas as suas 15 peças em seu quadrante interior pode começar a retirá-las do tabuleiro. Retira-se uma peça do tabuleiro ao lançar os dados e conseguir o valor que corresponda à casa na qual essa peça esteja. Desse modo, tirar um 6 no lançamento dos dados permitirá que o jogador retire uma peça que esteja na casa 6.",
      },
      {
        text: "Se não houver nenhuma peça na casa indicada pelo lançamento dos dados, o jogador deve mover uma ou mais peças de acordo com o valor obtido nos dados. Se não for possível mover peças, o jogador retira peças localizadas na casa de valor mais alto. O jogador não precisa retirar peças do tabuleiro se puder fazer qualquer outro movimento.",
      },
      {
        text: "O jogador deve ter todas as suas peças dentro do seu quadrante interior para poder retirá-las do tabuleiro. Se alguma de suas peças for para a Barra durante o processo de retirada, o jogador deve reinseri-la e trazê-la para o seu quadrante interior antes de dar continuidade à retirada.",
      },
      {
        text: "O primeiro jogador a retirar todas as suas peças do tabuleiro ganha o jogo.",
      }
    ]
  }
]