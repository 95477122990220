import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import trail from "gamification-manual-trail.png"

const TermsAndConditionsModal = ({ opened, onClose }) => (
  <CloseableModal opened={opened} onClose={onClose} overlay>
    <h2 className="modal-title">Manual de Gamificação</h2>
    <div className="scrollable-container document-modal">
      <p>
        O presente Manual aplica-se aos Usuários que utilizam a plataforma
        mindzup.com.br da empresa MINDLAB DO BRASIL COMÉRCIO DE LIVROS LTDA,
        empresa com sede na Rua Humberto I, 220, 2º Andar, São Paulo, Estado de
        São Paulo, CEP 04.018-030, adiante denominada Mind Lab, através da
        Plataforma.
      </p>
      <p>
        O presente Manual destina-se a disponibilizar orientações e informações
        das funcionalidades da Plataforma.
      </p>
      <p>
        Ao navegar na Plataforma, o visitante declara ter lido, compreendido e
        aceito o presente instrumento, manifestando conhecimento de que este
        documento é parte integrante dos Termos e Condições de Uso e da Política
        de Privacidade da Mind Lab, disponível em app.mindzup.com.br. Caso haja
        conflito entre as disposições dos documentos, deve prevalecer o
        estabelecido no mais específico.
      </p>

      <h3>1. DEFINIÇÕES</h3>
      <p>
        Os termos abaixo descritos, quando empregados no presente instrumento,
        terão os seguintes significados:
      </p>
      <p>
        <strong>Avatar</strong>: Personagem escolhido pelo Usuário para uso no
        ambiente virtual da Plataforma.
      </p>
      <p>
        <strong>Badge</strong>: Um badge é um símbolo ou um indicador de uma
        realização, habilidade, qualidade ou interesse. Um &quot;badge
        digital&quot; é um registro online de uma dessas conquistas, monitorado
        por uma comunidade em que o beneficiário tenha interagido e obtido o
        emblema, bem como o trabalho feito para obtê-lo.
      </p>
      <p>
        <strong>Boost</strong>: O Boost é o nosso multiplicador de pontos ou
        como nomeamos em nossa Plataforma, multiplicador de XPs.{" "}
      </p>
      <p>
        <strong>Conta de Acesso</strong>: Credencial digital formada pela
        combinação de login e senha, única e intransferível para cada Usuário da
        Plataforma, que dará permissão ao Usuário ingressar e realizar compras.
      </p>
      <p>
        <strong>Feature</strong>: Funcionalidade.
      </p>
      <p>
        <strong>Plataforma</strong>: área restrita do site, onde os Usuários
        inserem suas informações cadastrais e utilizam dos benefícios oferecidos
        pela Mind Lab.
      </p>
      <p>
        <strong>Rubi</strong>: O rubi é um item raro virtual que é utilizado na
        Plataforma como meio de recompensa e, com a qual, o usuário poderá
        resgatar benefícios, oportunamente.
      </p>
      <p>
        <strong>Usuário(s)</strong>: Aluno(s) vinculado(s) à uma Instituição de
        Ensino que utiliza a plataforma mindzup.com.br, cadastrado na Plataforma e
        que recebe uma conta de acesso individual e exclusiva.
      </p>
      <p>
        <strong>XP</strong>: XP é usado no mundo dos jogos online para
        quantificar experiência, onde o jogador acumula XP durante sua
        experiência na plataforma e tem vários benefícios dependendo de seu
        engajamento e desempenho.
      </p>

      <h3>2. TIPOS DE RECOMPENSAS</h3>
      <p>
        O Usuário será contemplado com três tipos de recompensas pela conclusão
        de ações dentro da Plataforma, são elas:
      </p>
      <ul>
        <li>
          <p>XP (experience points)</p>
        </li>
        <li>
          <p>Rubis </p>
        </li>
        <li>
          <p>Badges</p>
        </li>
      </ul>

      <p>
        <strong>XP</strong>
      </p>
      <p>
        São pontos que o usuário recebe ao fim de determinada ação dentro da
        plataforma, esses pontos são utilizados para que o usuário consiga
        avançar com seu avatar na trilha de cada planeta.
      </p>
      <p>
        Os Experience Points (XP) são entidades que tem como função única e
        exclusiva, demarcar o avanço do usuário dentro da plataforma. Os XPs são
        a representação única da evolução do usuário, não existindo qualquer
        outro tipo de mecânica para avanço.{" "}
      </p>
      <p>Um usuário não perde XPs que já adquiriu.</p>
      <p>XPs não são considerados moedas de troca dentro da plataforma.</p>
      <p>
        O número de XP que o usuário possui é chamado de XP Acumulado (XPa).
        Representa, portanto, o número total de XP que o usuário adquire durante
        o ano.
      </p>
      <p>
        Na transição de um ano letivo para o outro, o usuário terá o número de
        XPs zerado, pois ele iniciará uma nova jornada, em seu novo ano escolar
      </p>

      <p>
        <strong>Rubi</strong>
      </p>
      <p>
        São artefatos especiais, adquiridos com menos frequência que os XPs, que
        possuem como principal característica ser moeda de troca dentro da
        Plataforma. Os Rubis não são utilizados para o avanço do usuário nas
        trilhas e fazem referência a &quot;Itens Especiais, premium&quot; que
        são adquiridos pelo Usuário, segundo critérios dinâmicos.
      </p>
      <p>
        Os Rubis são um tipo de recompensa variável, pois podem sofrer
        diminuição em seu número total de acordo com o uso por parte do Usuário.
        Por ser uma moeda de troca, ao adquirir determinado item ou
        funcionalidade dentro da Plataforma, o Usuário estará
        &quot;pagando&quot; com seus Rubis, gerando assim essa redução em sua
        base.{" "}
      </p>

      <p>
        <strong>Badges</strong>
      </p>
      <p>
        Fazendo com que o usuário se engaje a cada nova interação, uma das
        funcionalidades de recompensa definidas é a das Badges. Essas badges são
        &quot;medalhas&quot; que representam determinadas conquistas dentro da
        plataforma. A medida que o usuário acumula ações dentro da plataforma,
        ele vai contribuindo para a liberação e conquista dessas medalhas.
      </p>

      <h3>3. MAPA GERAL - AQUISIÇÃO DE XP E RUBIS</h3>
      <p>
        Para podermos recompensar os usuários pelas ações dentro da Plataforma,
        foi estruturada uma tabela de Valores por Ação, em que foram definidos
        valores para cada tipo de ação possível dentro da Plataforma. Esses
        valores estão diretamente relacionados às recompensas, sendo os valores
        que cada usuário receberá ao realizar determinada ação.
      </p>
      <p>
        Os valores não serão fixos, podendo ser alterados a qualquer tempo no
        intuito de maximizar o aprendizado dos Usuários. Qualquer alteração
        constitui liberalidade da Mind Lab.{" "}
      </p>
      <p>
        Para a funcionalidade &quot;Boost&quot;, faremos apenas a multiplicação
        desses valores para o usuário, não alterando a base fixa de cada ação.
      </p>
      <p>
        Nas campanhas de engajamento e ativação, com aumento nos valores de XPs
        e/ou Rubis ganhos pelo Usuário, trataremos como um &quot;Boost
        manual&quot;. Não alteraremos os valores bases, apenas o valor
        multiplicador.
      </p>

      <h3>4. EVOLUÇÃO E AVANÇO DO AVATAR NA PLATAFORMA</h3>
      <p>
        Para representarmos visualmente a evolução do Usuário dentro da
        Plataforma, é utilizado o conceito de Trilhas de Avanço. Cada planeta
        possui sua própria trilha, e cada trilha possui um conjunto de casas,
        conforme imagem abaixo:
      </p>
      <img src={trail} className="document-trail-image" />

      <p>
        Cada casa possui uma meta em XP, sendo que o usuário precisa atingir o
        valor mínimo para conseguir chegar até a respectiva casa. Portanto, o
        avanço do usuário fica restrito ao atingimento da meta de XP da próxima
        casa.
      </p>
      <p>
        <strong>Avanço na trilha</strong>
      </p>
      <p>
        Para que o avatar se movimente até a próxima casa, o usuário precisa
        atingir a meta de XP definida para aquela próxima casa. Essa meta é
        estruturada em cima da seguinte fórmula:
      </p>
      <p className="formula">V = T + VA</p>
      <p>
        VALOR DA CASA = GATILHO DAQUELA FAIXA ESCOLAR + VALOR DA ÚLTIMA CASA
      </p>
      <p>
        O valor da meta de atingimento de uma casa será sempre o valor do
        gatilho estipulado para o ano escolar ao qual o aluno está atrelado,
        somado ao valor da casa anterior.{" "}
      </p>
      <p>
        Para compreendermos melhor esse cálculo, vamos definir as variáveis da
        fórmula acima:
      </p>
      <p>
        <strong>Lógica de cálculo – Gatilho do ano escolar</strong>
      </p>
      <p>
        Cada casa de uma trilha terá sua meta de XP, ou seja, o valor em XP que
        o Usuário precisa atingir para se movimentar até aquela determinada
        casa. Para atingir esse valor, o Usuário deverá adquirir XP realizando
        as ações dentro da plataforma, aumentando consequentemente seu número de
        XP acumulados.{" "}
      </p>
      <p>
        Essas ações, por sua vez, possuem algumas variações dependendo do ano
        escolar ao qual o Usuário está atrelado, ou dependendo do modelo que a
        escola adquiriu para aquele determinado ano escolar.
      </p>
      <p>
        <strong>Variações de Ano e Escola</strong>
      </p>
      <p>
        A Missão Diária é constituída basicamente por questões diárias, uma de
        cada área do conhecimento, onde o usuário possui um número fixo de
        questões para serem respondidas a cada dia. Esse número fixo possui
        variações de acordo com o ano escolar do Usuário, pois algumas faixas
        etárias possuem menos áreas do conhecimento em sua grade curricular.
      </p>
      <p>
        Diante desse cenário, temos a seguinte estrutura de áreas do
        conhecimento por ano escolar, ou seja, número máximo de questões por ano
        escolar (uma vez que temos apenas uma questão por área do conhecimento).
        Por liberalidade da Mind Lab, os números máximos podem ou não ser
        apresentados aos Usuários.
      </p>
      <table className="blue-table">
        <tr>
          <th>Ano escolar</th>
          <th>Nº máximo de áreas do conhecimento/dia</th>
          <th>Nº máximo de questões/dia</th>
        </tr>
        <tr>
          <td>2º a 4º</td>
          <td>3</td>
          <td>3</td>
        </tr>
        <tr>
          <td>5º a 9º</td>
          <td>5</td>
          <td>5</td>
        </tr>
        <tr>
          <td>1ª a 3ª (EM)</td>
          <td>4</td>
          <td>4</td>
        </tr>
      </table>
      <p>
        Com a variação no número de questões diárias entre os anos escolares,
        estruturamos gatilhos de avanço diferentes para cada ano. Como alguns
        anos possuem menos ações diárias para ganhar XP (nesse caso, ações de
        interação com questões), temos a seguinte tabela para os Triggers de
        Avanço por Ano Escolar:
      </p>
      <p>
        De acordo com a tabela acima, um usuário precisa acumular o mínimo de XP
        para poder avançar para a próxima casa. Todos esses gatilhos foram
        definidos em cima de algoritmos que impedem que, diante todas as ações
        diárias de ganhos de XP, o usuário fique limitado a 1 avanço de casa
        diário, salvo estipulação em contrário, pois existem exceções em que um
        usuário pode avançar mais de uma casa por dia.
      </p>
      <p>
        <strong>Variações de Modelo</strong>
      </p>
      <p>
        A Missão Diária é constituída basicamente por questões diárias, uma de
        cada área do conhecimento, onde cada faixa de ano escolar possui um
        número fixo de questões para serem respondidas por dia, o que nos
        apresenta 3 cenários diferentes (2ºano - 4º ano / 5ºano - 8ºano / 1ºano
        - 3ºano EM).
      </p>
      <p>
        Ao adquirir o produto digital, sem as aulas presenciais do Programa
        MenteInovadora, não apresentamos a área de MenteInovadora dentro da
        Missão Diária, o que diminui o número de questões diárias que o usuário
        pode responder para adquirir XP e assim avançar na trilha.{" "}
      </p>
      <p>
        Não existindo a aula do Programa MenteInovadora nos pacotes de produto
        dessa Instituição de Ensino, não inserimos, dentro da Missão Diária do
        Usuário, o agrupamento de questões da área MenteInovadora. Portando,
        para esse modelo o cenário fica o seguinte:
      </p>
      <table className="blue-table">
        <tr>
          <th>Ano escolar</th>
          <th>Nº de áreas do conhecimento/dia</th>
          <th>Nº máximo de questões/dia</th>
        </tr>
        <tr>
          <td>2º a 4º</td>
          <td>2</td>
          <td>2</td>
        </tr>
        <tr>
          <td>5º a 9º</td>
          <td>4</td>
          <td>4</td>
        </tr>
        <tr>
          <td>1ª a 3ª (EM)</td>
          <td>4</td>
          <td>4</td>
        </tr>
      </table>
      <p>
        Os usuários que não possuem a aula de Programa MenteInovadora presencial
        terão Gatilhos de Avanço por Ano Escolar diferenciados.
      </p>
      <p>
        De acordo com a tabela acima, o Usuário precisa acumular o mínimo de XP
        para poder avançar para a próxima casa. Todos esses gatilhos foram
        definidos em cima de algoritmos que impedem que, diante todas as ações
        diárias de ganhos de XP, o usuário fique limitado a 1 avanço de casa
        diário, salvo estipulação em contrário, pois existem exceções em que um
        usuário pode avançar mais de uma casa por dia.
      </p>
      <p>
        <strong>Cenários de avanço</strong>
      </p>
      <p>
        Para o avanço do avatar na trilha, criamos alguns cenários que ilustram
        diferentes situações que podem ocorrer. Assim como temos variações de
        Gatilho de Avanço de acordo com a condição de &quot;Existir ou não a
        área de Menteinovadora na Missão Diária&quot;, trabalharemos com duas
        segmentações: Cenários Com Área MI e Cenários Sem Área MI.
      </p>
      <p>
        <strong>Cenários Com Área MI</strong>
      </p>
      <p>
        Trataremos apenas cenários de usuários que possuem a área de
        Menteinovadora dentro da Missão Diária, portanto, os gatilhos de usuário
        serão retirados da tabela destinada a este modelo. A tabela pode ser
        encontrada mais acima neste documento, dentro do tópico{" "}
        <strong>&quot;Variações de Ano Escolar&quot;.</strong>
      </p>
      <p>
        <strong>Cenários Sem Área MI</strong>
      </p>
      <p>
        Trataremos apenas cenários de usuários que não possuem a área de
        Menteinovadora dentro da Missão Diária, portanto, os gatilhos de usuário
        serão retirados da tabela destinada a este modelo. A tabela pode ser
        encontrada mais acima neste documento, dentro do tópico{" "}
        <strong>&quot;Variações de Modelo&quot;.</strong>
      </p>

      <h3>5. BADGES – GATILHOSDE LIBERAÇÃO</h3>
      <p>
        <strong>Gatilhos de Liberação </strong>
      </p>
      <p>
        Cada badge terá seu próprio gatilho de liberação, que pode ou não estar
        associado ao número de XPs pelo usuário.
      </p>
      <p>Os tipos de badges existentes são:</p>
      <ul>
        <li>
          <p>
            Badges de Desempenho: São medalhas diretamente conectadas ao
            desempenho do usuário em determinas áreas do conhecimento. Esse
            desempenho será registrado de acordo com o acerto e erro nas
            questões, seja de Missão Diária ou Avaliações.
          </p>
        </li>
        <li>
          <p>
            Badges de Engajamento: São medalhas diretamente conectadas ao
            engajamento do usuário na plataforma. Frequência de uso e variedade
            de interações com as diversas funcionalidades são ações que levam o
            usuário a liberar este tipo de badge.
          </p>
        </li>
        <li>
          <p>
            Badges do Programa MenteInovadora: São medalhas diretamente
            conectadas ao Programa MenteInovadora. Essas badges serão liberadas
            ao fim de cada semestre.
          </p>
        </li>
      </ul>
      <p>
        Gatilho de liberação de badge é meta que o usuário precisa atingir para
        receber determinada badge
      </p>

      <h3>6. MULTIPLICADOR DE PONTOS </h3>
      <p>
        O Boost é o nosso multiplicador de pontos, ou como nomeamos em nossa
        Plataforma, multiplicador de XPs.{" "}
      </p>
      <p>
        O Boost funciona como uma espécie de bônus, disponibilizado para o
        Usuário após a realização de uma sequência de tarefas bem sucedidas.{" "}
      </p>
      <p>
        Ao atingir o trigger para a ativação, o Usuário aciona o Boost,
        multiplicando todos os XPs adquiridos durante o período de ativação.
      </p>
      <p>
        O dia de aplicação do boost conta como o 1º dia da nova sequência de
        questões diárias.
      </p>
      <p>
        Ao responder todas as questões de Missão diária disponíveis no período
        de 5 dias seguidos, o usuário acionará o trigger do boost, ativando o
        multiplicador de XP. O multiplicador fica ativo durante as próximas 24h,
        multiplicando todos os XPs adquiridos pelo usuário nesse período.
      </p>

      <h3>7. QUESTÃO DESAFIO</h3>
      <p>
        A Questão Desafio é utilizada para alavancar o engajamento do Usuário,
        estimulando a recorrência e o empenho nas Missões Diárias.
      </p>
      <p>
        A Questão Desafio constitui uma oportunidade de recompensar Usuários com
        uma nova questão de maior complexidade. Temos como regra para essa
        funcionalidade:
      </p>
      <table className="orange-table">
        <tr>
          <th>Trigger</th>
          <th>Recompensa</th>
        </tr>
        <tr>
          <td>Acertou todas as questões da Missão Diária daquele dia</td>
          <td>
            É apresentado ao usuário uma questão da base de questões de Missão
            Diária do ano seguinte ao dele
          </td>
        </tr>
      </table>
      <p>
        Toda vez que um Usuário acertar todas as questões diárias naquele dia,
        ao fim desse processo será apresentada para ele uma opção de realizar ou
        não a Questão Desafio.
      </p>

      <h3>8. RESGATE DE QUESTÃO</h3>
      <p>
        O Resgate de Questão é o meio pelo qual o Usuário poderá, mediante
        &quot;pagamento&quot; com os Rubis, adquirir questões de Missões Diárias
        perdidas, dias em que o Usuário não acessou a Plataforma.
      </p>
      <p>
        Cada usuário pode Resgatar apenas 1 Missão Diária anterior/dia, mesmo
        que esse usuário tenha Rubis em quantidades suficientes para realizar
        essa aquisição.
      </p>

      <h3>9. EVENTOS BÔNUS</h3>
      <p>
        Os eventos bônus são disponibilizados por um curto período e são
        específicos com recompensas alteradas por multiplicadores para
        reativação e valorização dos Usuários.
      </p>

      <h3>10. DISPOSIÇÕES GERAIS</h3>
      <p>
        O presente Manual, as recompensas e os prêmios que porventura venham ser
        disponibilizadas pela Mind Lab, estão sujeitos a constante melhoria e
        aprimoramento.
      </p>
      <p>
        Assim, a Mind Lab se reserva o direito de modificar a qualquer momento,
        de forma unilateral, o presente instrumento, recompensas e/ou prêmios.{" "}
      </p>
      <p>
        O novo manual, recompensas e/ou prêmios, entrarão em vigor
        automaticamente da sua publicação na Plataforma.
      </p>
      <p>
        A tolerância do eventual descumprimento de quaisquer das condições do
        presente instrumento não constituirá novação das obrigações aqui
        estipuladas e tampouco impedirá ou inibirá a exigibilidade das mesmas a
        qualquer tempo.
      </p>
      <p>
        O Usuário autoriza expressamente a Mind Lab a comunicar-se com este
        através de todos os canais disponíveis, incluindo correio eletrônico
        (e-mail), celular, SMS, entre
      </p>

      <h3>11. ATENDIMENTO AO USUÁRIO</h3>
      <p>
        A Mind Lab envidará seus melhores esforços no sentido de manter as
        informações que disponibilizar em seu Portal precisas, atualizadas e
        completas, o quanto possível. No entanto, não se responsabiliza por
        imprecisão, erro, fraude, inexatidão, insuficiência ou divergência nas
        informações, dados, imagens, vídeos ou outros conteúdos relacionados às
        comunicações.
      </p>
      <p>
        Para a Mind Lab seus comentários são sempre bem-vindos, e neles, você
        pode expressar sua opinião sobre um item adquirido utilizando o nosso
        site. Tal opinião não deve ser tomada como manifestação oficial da Mind
        Lab, mas como uma declaração concentrada na experiência dos próprios
        Usuários, relacionadas a um determinado produto oferecido. Feita de
        forma espontânea e colaborativa, sua opinião torna-se muito útil para
        ajudar outros Usuários ou Internautas a tomar decisões sobre a compra
        deste produto.
      </p>
      <p>
        É importante que o espaço destinado a seus comentários somente seja
        utilizado para o fim que se destina: Avaliar os produtos oferecidos.
        Comentários ou publicações em desacordo com as normas da Mind Lab
        poderão ser removidos.
      </p>
      <p>
        Lembre-se que o conteúdo de cada comentário não é de qualquer forma
        revisado, monitorado, alterado ou modificado previamente pela Mind Lab,
        sendo de única e exclusiva responsabilidade do Usuário que o publicou.
      </p>
      <p>
        Caso tenha qualquer dúvida em relação ao presente documento, favor
        entrar em contato:
      </p>
      <p>
        a) <strong>via Chat</strong>, acessando o Portal no endereço eletrônico
        (mindzup.com.br), clicando em Atendimento, Canais de Atendimento e
        selecionando a opção Atendimento por Chat. Horário de Atendimento: de
        segunda a sexta, das 7h às 19h.
      </p>
      <p>
        b) <strong>pelo telefone</strong> (011) 3474-1770. Horário de
        Atendimento: de segunda a sexta, das 7h às 19h.
      </p>
      <p>
        c) <strong>via Site</strong>, acessando o Portal no endereço eletrônico
        (mindzup.com.br), clicando em Atendimento, Canais de Atendimento e
        selecionando a opção Atendimento ao Consumidor, preenchendo os campos
        solicitados no site e clicando em enviar.
      </p>

      <h3>12. LEGISLAÇÃO E FORO</h3>
      <p>
        O presente Manual é regido pela legislação da República Federativa do
        Brasil. Seu texto deverá ser interpretado no idioma português e os
        Usuários da Plataforma se submetem ao Foro da Comarca de São Paulo,
        Estado de São Paulo, com renúncia a qualquer outro por mais privilegiado
        que seja.
      </p>
      <p>
        <strong>Atualização</strong>: 20 de dezembro de 2017.
      </p>
    </div>
  </CloseableModal>
)

export default TermsAndConditionsModal
