import floor from "planets/axion/axion-checkpoint.svg"
import bottle from "planets/axion/axion-bottle.svg"

export default {
  mainColor: "#38003f",
  secondaryColor: "#6800bf",
  floor: {
    image: floor,
    height: 165
  },
  stone: {
    image: bottle,
    width: 58,
    height: 119,
    left: 40
  }
}
