import React from "react"
import FormGroup from "components/form/form-group"
import FormLabel from "components/form/form-label"
import ComponentHelper from "helpers/component-helper"

const FormSelect = ({ id, name, required, label, children, errors = [], ...rest }) => (
  <FormGroup hasError={ComponentHelper.hasError(errors, name)}>
    <div className="form-select">
      <select id={id} className="form-field" name={name} {...rest} required={required}>
        {children}
      </select>
      <FormLabel htmlFor={id}>{ComponentHelper.label(errors, name, label)}</FormLabel>
      <i className="arrow-icon-down" />
    </div>
  </FormGroup>
)

export default FormSelect
