import scenario from "planets/kai/kai-scenario.svg"
import flag from "planets/kai/kai-high-flag.svg"
import { stone0, stone1, stone2, stone3, stone4, stone5, stone6 } from "planets/kai/stone"

export default {
  stones: [stone0, stone1, stone2, stone3, stone4, stone5, stone6],
  scenario: scenario,
  flag: flag,
  gradient:
    "linear-gradient(90deg, #005576 0%, #007694 0%, #6ed3b6 43%, #91e4b4 49%, #34b8b8 88%, #0093af 100%,  #005576 100%);",
  paddingTop: "20px"
}
