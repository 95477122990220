import React from "react"
import styled from "styled-components"
import Planet from "Galaxy/wrappers/Planet"
import PlanetImage from "Galaxy/wrappers/PlanetImage"
import groupem from "galaxies/blue-galaxy/groupem.svg"

const Groupem = props => (
  <Planet {...props}>
    <PlanetImage src={groupem} blocked={props.blocked} />
  </Planet>
)

export default styled(Groupem)``
