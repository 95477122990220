import background from "galaxies/blue-galaxy/blue-background.svg"
import satellite from "galaxies/elements/blue-satellite.svg"
import gameControl from "galaxies/elements/blue-game-control.svg"
import milkyWay from "galaxies/elements/blue-milky-way.svg"

export default {
  background: background,
  satellite: satellite,
  gameControl: gameControl,
  milkyWay: milkyWay
}
