import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { Notification } from "Icons"
import API from "api"

const NotificationIcon = styled(Notification)`
  position: fixed;
  bottom: 40px;
  left: 40px;
  z-index: 2;
`

class Bag extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      notification: false,
      fetched: false
    }
  }

  componentDidMount () {
    API.get("/renew", response => {
      const { renew_available: renewAvailable, unpaid_order: unpaidOrder } = response.data

      this.setState({
        notification: renewAvailable && unpaidOrder,
        fetched: true
      })
    })
  }

  render () {
    const { notification, fetched } = this.state
    const to = notification && fetched ? "/mochila/pagamento" : "/mochila"

    return (
      <Link to={to} className="bag">
        <i className="bag-icon" />
        {notification && fetched && <NotificationIcon>1</NotificationIcon>}
      </Link>
    )
  }
}

export default Bag
