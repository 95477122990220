import React from "react"
import Methods from "payment_methods.png"
import Moip from "moip.png"

const PaymentMethods = () => (
  <div className="payment-methods">
    <img src={Methods} />
    <img src={Moip} />
  </div>
)

export default PaymentMethods
