import React from "react"
import HoursUtilized from "components/exams/hours-utilized"

const ExamSimpleCalibratedFinalizedCard = ({ miniexamName, timeSpent, duration, experience, completed }) => (
  <div className="exam-card">
    <div className="exam-card-title">{miniexamName}</div>

    <div className="expiration-start-container">
      <HoursUtilized timeSpent={timeSpent} duration={duration} />

      {completed ? (
        <div className="exam-card-metadata">
          <i className="checkbox-icon" />
          <div>
            <span>Entregue</span>
            <div className="exam-card-metadata-subitem">XP: {experience}</div>
          </div>
        </div>
      ) : (
        <div className="exam-card-metadata">Expirado</div>
      )}
    </div>
  </div>
)

export default ExamSimpleCalibratedFinalizedCard
