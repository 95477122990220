import scenario from "planets/union/union-scenario.svg"
import flag from "planets/union/union-high-flag.svg"
import { stone0, stone1, stone2, stone3, stone4, stone5, stone6 } from "planets/union/stone"

export default {
  stones: [stone0, stone1, stone2, stone3, stone4, stone5, stone6],
  scenario: scenario,
  flag: flag,
  gradient: "linear-gradient(180deg,#C0CACC 0%,#C0CACC 100%)",
  paddingTop: "20px"
}
