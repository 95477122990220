import styled from "styled-components"
import PropTypes from "prop-types"
import Rounded from "Icons/Rounded"

const directions = {
  left: "rotate(135deg) translateX(-1px) translateY(-2px)",
  right: "rotate(315deg) translateX(-7px) translateY(5px)",
  down: "rotate(45deg)"
}

const Arrow = styled(Rounded)`
  background-color: #ffffff;

  &::before {
    content: "";
    border-style: solid;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    transform: ${props => directions[props.direction]};
  }
`

Arrow.propTypes = {
  direction: PropTypes.string.isRequired
}

export default Arrow
