import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import { Header } from "Header"
import { GamificationBar } from "GamificationBar"
import { BtnPrimary } from "components/button"
import finalized from "finalized.svg"

const EssayFinalizedModal = ({ opened }) => (
  <CloseableModal opened={opened} closeButton={false} fullscreen color="honey-flower">
    <Header color="colored">
      <GamificationBar />
    </Header>

    <h2>Redação enviada!</h2>

    <div className="exam-finalized-content">
      <img src={finalized} />

      <p>
        Sua redação foi enviada para nossos corretores :)<br />
        Sua correção e nota estarão disponíveis em até 10 dias úteis.
      </p>
    </div>

    <BtnPrimary to="/simulados" value="Ir para a Estação" arrow />
  </CloseableModal>
)

export default EssayFinalizedModal
