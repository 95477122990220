export default [
  {
    title: "Regras básicas",
    description: "",
    items: [
      {
        text: "A cada rodada, o jogador pode escolher entre:",
        items: [
          {
            text: "Colocar uma peça fechada (peça-base e suas três molduras em qualquer casa, vazia, com exceção das casas dos mineiros).",
          },
          {
            text: "Abrir uma peça: abrir as molduras de uma peça, de acordo com o desenho impresso na peça-base.",
          }
        ]
      },
      {
        text: "Cada moldura deve estar conectada à base ou à outra moldura pela lateral.",
      },
      {
        text: "Duas molduras não podem ser colocadas na mesma casa.",
      },
      {
        text: "As casas com mineiros podem conter molduras."
      },
      {
        text: "Na versão básica, as peças são abertas somente na posição em que foram colocadas em jogo.",
      },
      {
        text: "O jogador coloca um diamante em cada moldura que se conectar com um dos mineiros impressos do tabuleiro (por laterais, como um túnel).",
        items: [
          {
            text: "Durante o jogo, pode ocorrer uma situação em que uma moldura que não recebeu diamante no momento que foi aberta conecta-se a um “túnel” que leva a um mineiro.",
          },
          {
            text: "O jogador colocará diamantes em todas as molduras que estiverem conectadas (diretamente ou sequencialmente) a um ou mais mineiros.",
          }
        ]
      },
      {
        text: "O jogo acaba quando nenhum jogador puder realizar uma jogada.",
      }
    ]
  }
]