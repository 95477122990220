import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import UserAvatar from "components/user-avatar"

const DuplicatedSessionModal = ({ opened, onClose }) => (
  <CloseableModal overlay opened={opened} onClose={onClose} small>
    <h2 className="modal-title">Ops! Janela duplicada</h2>
    <p>Identificamos que esse respondedor foi aberto em outra janela e, por isso, precisaremos fechar essa sessão.</p>
    <p className="small"><i>Atenção: para a segurança de suas respostas, não permitimos que o respondedor de uma avaliação seja aberto em mais de uma janela ao mesmo tempo.</i></p>
    <UserAvatar kind="sad" />
  </CloseableModal>
)

export default DuplicatedSessionModal
