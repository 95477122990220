import styled from "styled-components"
import { BtnPrimary } from "Buttons"
import { Link } from "react-router-dom"

const PaymentContainer = styled.div`
  max-width: 900px;
  margin: 40px auto 20px;
  text-align: center;

  @media (max-width: 768px) {
    margin: 40px 10px 20px;
  }
`

const Title = styled.h2`
  color: #591279;
  font-weight: 500;
`

const Button = styled(BtnPrimary)`
  margin-top: 50px;
`

const LargeTextButton = styled(Button)`
  max-width: 390px;
`

const TextLink = styled.a`
  display: block;
  margin-top: 20px;
  color: #8e3fac;
  font-size: 13px;
`

const Contact = styled.div`
  color: #9b9b9b;
  font-size: 13px;
  margin-top: 60px;
`

const PurpleText = styled.p`
  color: #8e3fac;
`

const SquareText = styled.div`
  color: #9b9b9b;
  font-size: 15px;
  border: 1px solid #9c9c9c;
  padding: 30px;
  width: 300px;
  margin: 50px auto;
`

const PinkLink = styled(Link)`
  color: #ff7a93;
`

const ContactChatText = styled.div`
  margin-top: 60px;
  color: #9b9b9b;
  font-size: 12px;
`

const PurpleSquareText = styled(SquareText)`
  color: #8e3fac;
  font-weight: 600;
  font-size: 20px;
`

const PaymentDoneViaBoletoText = styled.div`
  margin-top: 50px;
  color: #9b9b9b;
  font-size: 14px;
`

export {
  PaymentContainer,
  Title,
  Button,
  TextLink,
  Contact,
  PurpleText,
  SquareText,
  PinkLink,
  LargeTextButton,
  ContactChatText,
  PurpleSquareText,
  PaymentDoneViaBoletoText
}
