const AUTH_TOKEN_KEY = "@apollo-auth-token-key/v1"
const WEBSOCKET_TOKEN_KEY = "@apollo-websocket-token-key/v1"

export const getAuthToken = () => localStorage.getItem(AUTH_TOKEN_KEY)
export const storeAuthToken = token => localStorage.setItem(AUTH_TOKEN_KEY, token)
export const removeAuthToken = () => localStorage.removeItem(AUTH_TOKEN_KEY)
export const isAuthenticated = () => getAuthToken() !== null

export const getWebsocketToken = () => localStorage.getItem(WEBSOCKET_TOKEN_KEY)
export const storeWebsocketToken = token => localStorage.setItem(WEBSOCKET_TOKEN_KEY, token)
export const removeWebsocketToken = () => localStorage.removeItem(WEBSOCKET_TOKEN_KEY)
