import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import Tooltip from "components/tooltip"

const Planet = ({ blocked, visited, to, tooltip, onClick, children, className }) => (
  <div className={className}>
    {blocked && (
      <Tooltip>
        <i className="padlock-icon" />
      </Tooltip>
    )}

    {visited && (
      <Tooltip>
        <i className="flag-icon" />
      </Tooltip>
    )}

    {tooltip && <Tooltip>{tooltip}</Tooltip>}

    {to ? (
      <Link to={to} onClick={onClick}>
        {children}
      </Link>
    ) : (
      children
    )}
  </div>
)

const StyledPlanet = styled(Planet)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Tooltip} {
    margin-bottom: 10px;

    .light {
      font-weight: 500;
    }
  }
`

export default StyledPlanet
