import React from "react"
import styled from "styled-components"
import image from "galaxies/elements/blue-planet.svg"

const Image = styled.img`
  width: 500px;
  height: 500px;

  @media (max-width: 767px) {
    width: 80px;
    height: 80px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 300px;
    height: 300px;
  }
`

const BluePlanet = () => <Image src={image} />

export default BluePlanet
