import React from "react"
import { Layout } from "Session"
import Modal from "components/modal"
import { Vigo } from "components/avatar"

class AccountPasswordEmailSent extends React.Component {
  render () {
    return (
      <Layout>
        <Modal backUrl="/entrar">
          <div className="modal-title">
            <h2>E-mail enviado</h2>
            <p>Pronto, agora é só seguir as instruções enviadas para o e-mail que você nos mandou!</p>
            <p>Depois é só voltar aqui.</p>
          </div>

          <Vigo />

          <p className="mute">Se estiver demorando, olhe no Spam ;)</p>
        </Modal>
      </Layout>
    )
  }
}

export default AccountPasswordEmailSent
