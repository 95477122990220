import React from "react"
import { push } from "connected-react-router"
import { connect } from "react-redux"
import { MixpanelTrack } from "tags/mixpanel"
import { PixelTrack } from "tags/pixel"
import OnboardingOrderSuccessNextYear from "onboarding/order/SuccessNextYear"
import OnboardingOrderSuccessCurrentYear from "onboarding/order/SuccessCurrentYear"
import API from "api"

class OnboardingOrderSuccess extends React.Component {
  constructor (props) {
    super(props)
    this.state = { waiting: true, isNextYear: false }
  }

  componentDidMount () {
    MixpanelTrack("Pagamento realizado com sucesso")
    PixelTrack("Sucesso - Pagamento Concluído")
    this.checkRenewal()
  }

  checkRenewal () {
    const { dispatch } = this.props

    API.get("/checkout/order", response => {
      const { renew } = response.data

      if (renew) {
        dispatch(push("/mochila/pagamento"))
      } else {
        API.get("/checkout/confirmation", response => {
          const { data: order } = response
          const isNextYear = order.year == new Date().getFullYear() + 1
          this.setState({ waiting: false, isNextYear })
        })
      }
    })
  }

  render () {
    const { waiting, isNextYear } = this.state

    if (waiting) {
      return null
    }

    if (isNextYear) {
      return <OnboardingOrderSuccessNextYear />
    } else {
      return <OnboardingOrderSuccessCurrentYear />
    }
  }
}

export default connect()(OnboardingOrderSuccess)
