import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import TimeIsUpModal from "components/modals/time-is-up-modal"
import { connectToChannel } from "socket-connect"

class EssayTimerChannel extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      timeIsUpModal: false
    }
  }

  componentDidMount () {
    this.subscribeChannel()
  }

  componentWillUnmount () {
    this.unsubscribeChannel()
  }

  redirectToStation () {
    this.props.dispatch(push("/simulados"))
  }

  subscribeChannel () {
    const { examTimerId } = this.props

    this.timerChannel = connectToChannel(`unpausable_timer:${examTimerId}`)

    if (!this.timerChannel) return

    this.timerChannel.join().receive("error", response => {
      console.error("[exam/essay-timer] Unable to join channel: ", response)
      this.redirectToStation()
    })

    this.timerChannel.on("expired", () => {
      this.unsubscribeChannel()
      this.setState({ timeIsUpModal: true })
    })
  }

  unsubscribeChannel () {
    if (this.timerChannel && this.timerChannel.state === "joined") {
      this.timerChannel.leave()
    }
  }

  render () {
    const { timeIsUpModal } = this.state

    if (timeIsUpModal) {
      return (
        <div className="fullscreen-modal-wrapper">
          <TimeIsUpModal opened={timeIsUpModal} onClose={() => this.redirectToStation()} />
        </div>
      )
    }

    return null
  }
}

export default connect()(EssayTimerChannel)
