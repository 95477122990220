import React from "react"
import styled from "styled-components"
import { Header } from "Header"
import { GamificationBar } from "GamificationBar"
import { Horizon } from "Horizon"

const GameStationContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const GameStationLayout = ({ children, backUrl, backLabel, title = undefined }) => {
  const gameStationTitle = title || "Estação de Jogos"

  return (
    <GameStationContainer id="game-station">
      <Header backUrl={backUrl} backLabel={backLabel}>
        <GamificationBar />
      </Header>


      <Horizon title={gameStationTitle} short fixed />

      {children}
    </GameStationContainer>
  )
}

export default GameStationLayout
