export default [
  {
    title: "Como jogar Perudo?",
    items: [
      { text: "Perudo é um jogo para 2 a 4 jogadores (recomendamos a versão para 4 jogadores)." },
      { text: "Cada jogador começa com 5 dados, que não estarão visíveis para os demais jogadores. Os coringas são representados por um símbolo, chamado Paco." },
      { text: "O jogo acontece em sentido horário. Na sua vez, cada jogador aposta na quantidade de dados que acredita ter na mesa. A rodada termina quando um jogador acreditar que outro jogador apostou alto demais ou exatamente a quantidade de dados que há no jogo." },
      { text: "O primeiro jogador anuncia sua aposta, ou seja, dá um palpite sobre quantos dados de um determinado valor há sob os copos de todos os jogadores juntos (“x” quantidade de dados “n”, por exemplo: “dois quatros”)." },
      { text: "O próximo jogador tem três opções: aumentar a aposta feita pelo oponente, duvidar da aposta chamando “Dudo” (“duvido”) ou dizer “Calza” (“na mosca”)." },
      {
        text: "Um jogador pode aumentar a aposta de três maneiras:",
        items: [
          { text: "Aumentar a quantidade de dados (x). Continuando a partir do mesmo exemplo, o próximo jogador pode dizer “três quatros”." },
          { text: "Aumentar o valor dos dados (n). No exemplo, o próximo jogador pode dizer “dois cincos”." },
          { text: "Ou as duas coisas. No exemplo, “três cincos”." },
          { text: "O jogador também pode reduzir a aposta para apostar somente em \"Pacos\" (\"Coringas\"). Para isso o número de dados deve ser pelo menos metade do número de dados arredondados. Por exemplo, se a aposta anterior era de 7 dados com o valor 6, o jogador que for apostar somente em \"Pacos\" (\"Coringas\") deve dizer, 4 dados com o valor de 6." },
        ]
      },
      {
        text: "Quando um jogador chama o “Dudo”:",
        items: [
          { text: "Os dados de todos os jogadores são apresentados. Se tiver a quantidade apostada pelo jogador anterior, o jogador que chamou o Dudo, ou seja, quem duvidou do palpite dado pelo oponente, perde um dado. Se a quantidade de dados não estiver correta, ou seja, com dados a menos ou a mais do que a aposta, o jogador anterior (quem fez a aposta) perde um dado." }
        ]
      },
      {
        text: "Quando um jogador chama “Calza”:",
        items: [
          { text: "Qualquer jogador, exceto o jogador que fez o lance atual, pode chamar “Calza”." },
          { text: "Assim que “Calza” é chamado, todos os jogadores revelam seus dados." },
          { text: "Todos os dados que correspondem ao lance são contados, incluindo \"Pacos\" (\"Coringas\")." },
          { text: "Se o número de dados for exatamente igual ao lance, o jogador que chamou “Calza” recupera um dado perdido." },
          { text: "Se o jogador tiver apenas um dado, na sua próxima vez que tiver apenas um dado não ativará outra rodada do Palifico." },
          { text: "Se o número de dados não for igual ao lance, o jogador que chamou “Calza” perde um dado." },
          { text: "Se o jogador que chamou “Calza” tiver pelo menos um dado, ele começa a nova rodada." },
        ]
      },
    ]
  },
  {
    title: "Rodada Palifico",
    items: [
      { text: "No momento em que um jogador tiver apenas um dado restante, esse jogador alcançou \"Palifico\". Nessa rodada, os \"Pacos\" (\"Coringas\") não contam mais como nenhum valor de dado." },
      { text: "Um lance de abertura pode ser qualquer valor de dado, incluindo \"Pacos\" (\"Coringas\"), que equivalem ao número 1." },
      { text: "O valor do dado não pode ser alterado nas apostas." },
      { text: "Após esta rodada, o jogo recomeça normalmente, independentemente de o jogador do Palifico ainda ter somente um dado restante." },
    ]
  },
  {
    title: "Fim de Jogo",
    items: [
      { text: "Um jogador sai do jogo quando perder todos os seus dados. Este jogador ainda pode continuar acompanhando a partida, no entanto sem a possibilidade de fazer apostas, duvidar ou chamar “Calza”." },
      { text: "O jogo termina quando restar apenas um jogador com dados no jogo." }
    ]
  }
]
