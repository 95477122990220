import React from "react"
import { connect } from "react-redux"
import { push, goBack } from "connected-react-router"
import { Header } from "Header"
import { GamificationBar } from "GamificationBar"
import EssayRulesContent from "components/exams/essay-rules-content"
import { BtnPrimary } from "components/button"
import { setCurrentEssayId } from "exam/essay-actions"
import API from "api"
import { ScrollUp } from "helpers/scroll"

class EssayRules extends React.Component {
  constructor (props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  UNSAFE_componentWillMount () {
    if (!this.props.essayId) {
      this.props.dispatch(goBack())
    }
  }

  componentDidMount () {
    ScrollUp()
  }

  handleClick (e) {
    const { dispatch, requestInProgress } = this.props

    if (requestInProgress) return

    API.post("/essay_sessions", { essayId: this.props.essayId }, response => {
      const { essay_session_id } = response.data

      dispatch(setCurrentEssayId({ essaySessionId: essay_session_id }))
      dispatch(push("/simulados/redacao/tema"))
    })

    e.preventDefault()
  }

  render () {
    const { essayTitle, requestInProgress } = this.props

    return (
      <div>
        <Header backUrl="/simulados" color="colored" backLabel="Estação de Avaliações">
          <GamificationBar />
        </Header>

        <div className="exam-rules-container">
          <EssayRulesContent essayTitle={essayTitle} />

          <hr />

          <BtnPrimary to="#" value="Iniciar redação" disabled={requestInProgress} onClick={this.handleClick} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    essayId: state.currentEssayReducer.essayId,
    essayTitle: state.currentEssayReducer.essayTitle,
    requestInProgress: state.http.requestInProgress
  }
}

export default connect(mapStateToProps)(EssayRules)
