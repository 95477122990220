import React from "react"
import FormGroup from "components/form/form-group"
import FormLabel from "components/form/form-label"
import ComponentHelper from "helpers/component-helper"

const FormInput = (props) => {
  const { type, id, name, required, label, errors = [], children, className = "", ...rest } = props

  return (
    <FormGroup hasError={ComponentHelper.hasError(errors, name)}>
      {children}
      <input type={type} id={id} name={name} className={`form-field ${className}`} {...rest} required={required} />
      <FormLabel htmlFor={id}>{ComponentHelper.label(errors, name, label)}</FormLabel>
    </FormGroup>
  )
}

export default FormInput
