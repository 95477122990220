import styled from "styled-components"
import mountain from "planets/pensatus/pensatus-mountain.svg"
import sky from "planets/pensatus/pensatus-sky.svg"
import { StyledHorizon, StyledHorizonContainer, StyledHorizonTitle } from "Horizon"

const PlanetHorizon = styled(StyledHorizon)`
  background: linear-gradient(180deg, #741cb5 0%, #76b8ff 100%);

  &::before {
    background: url(${mountain}) no-repeat;

    bottom: -32px;
    width: 578px;
    height: 65px;
    left: 50%;
    margin-left: -289px;

    @media (max-width: 768px) {
      left: 171px;
      width: 475px;
      height: 58px;
    }
  }
`

const ShortPlanetHorizon = PlanetHorizon

const PlanetHorizonContainer = styled(StyledHorizonContainer)`
  &::before {
    background: url(${sky}) no-repeat;
    height: 200px;
    bottom: 0px;
    transform: scaleX(-1);

    @media (max-width: 768px) {
      left: -250px;
      width: 600px;
    }
  }
`

const ShortPlanetHorizonContainer = styled(PlanetHorizonContainer)`
  &::before {
    background: url(${sky}) no-repeat;
    height: 150px;
    bottom: 50px;
    transform: scaleX(-1);

    @media (max-width: 768px) {
      bottom: 75px;
      height: 100px;
    }
  }
`

const PlanetHorizonTitle = StyledHorizonTitle

export { PlanetHorizon, ShortPlanetHorizon, PlanetHorizonContainer, ShortPlanetHorizonContainer, PlanetHorizonTitle }
