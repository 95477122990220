import stone0Icon from "planets/kai/kai-stone-0.svg"
import stone1Icon from "planets/kai/kai-stone-1.svg"
import stone2Icon from "planets/kai/kai-stone-2.svg"
import stone3Icon from "planets/kai/kai-stone-3.svg"
import stone4Icon from "planets/kai/kai-stone-4.svg"
import stone5Icon from "planets/kai/kai-stone-5.svg"
import stone6Icon from "planets/kai/kai-stone-6.svg"

export const stone0 = {
  icon: stone0Icon,
  width: "60px",
  height: "50px",
  top: "-25px",
  left: "300px"
}

export const stone1 = {
  icon: stone1Icon,
  width: "60px",
  height: "57px",
  top: "110px",
  left: "132px"
}

export const stone2 = {
  icon: stone2Icon,
  width: "60px",
  height: "52px",
  top: "268px",
  left: "49px"
}

export const stone3 = {
  icon: stone3Icon,
  width: "65px",
  height: "60px",
  top: "352px",
  left: "255px"
}

export const stone4 = {
  icon: stone4Icon,
  width: "60px",
  height: "56px",
  top: "481px",
  left: "31px"
}

export const stone5 = {
  icon: stone5Icon,
  width: "60px",
  height: "64px",
  top: "589px",
  left: "299px"
}

export const stone6 = {
  icon: stone6Icon,
  width: "58px",
  height: "56px",
  top: "646px",
  left: "43px"
}
