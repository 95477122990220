import axios from "axios"
import { logout } from "actions"
import { startRequest, stopRequest } from "actions/http"
import { push } from "connected-react-router"
import { apiEndpoint } from "config"
import { getAuthToken } from "auth"

class API {
  constructor (baseURL) {
    this.axios = axios.create({ baseURL: baseURL })
  }

  configDefaults (appStore) {
    this.axios.interceptors.request.use(
      config => {
        appStore.dispatch(startRequest())
        return config
      },
      error => {
        return Promise.reject(error)
      }
    )

    this.axios.interceptors.response.use(
      response => {
        appStore.dispatch(stopRequest())
        return response
      },
      error => {
        appStore.dispatch(stopRequest())
        if (error.response.status === 401) {
          appStore.dispatch(logout())
        }
        if (error.response.status === 403) {
          appStore.dispatch(push("/"))
        }
        return Promise.reject(error)
      }
    )
  }

  spread (callback) {
    return axios.spread(() => callback())
  }

  defaultHeaders () {
    const authToken = getAuthToken()
    return { authorization: `Bearer: ${authToken}` }
  }

  get (path, successCallback, failureCallback) {
    return this.axios
      .get(path, { headers: this.defaultHeaders() })
      .then(response => {
        successCallback && successCallback(response)
      })
      .catch(e => {
        failureCallback && failureCallback(e)
      })
  }

  post (path, body, successCallback, failureCallback) {
    return this.axios
      .post(path, body, { headers: this.defaultHeaders() })
      .then(response => {
        successCallback && successCallback(response)
      })
      .catch(e => {
        failureCallback && failureCallback(e)
      })
  }

  put (path, body, successCallback, failureCallback) {
    return this.axios
      .put(path, body, { headers: this.defaultHeaders() })
      .then(response => {
        successCallback && successCallback(response)
      })
      .catch(e => {
        failureCallback && failureCallback(e)
      })
  }

  putWithOptions (path, body, options, successCallback, failureCallback) {
    return this.axios
      .put(path, body, { ...options, headers: { ...this.defaultHeaders(), ...options.headers } })
      .then(response => {
        successCallback && successCallback(response)
      })
      .catch(e => {
        failureCallback && failureCallback(e)
      })
  }

  patch (path, body, successCallback, failureCallback) {
    return this.axios
      .patch(path, body, { headers: this.defaultHeaders() })
      .then(response => {
        successCallback && successCallback(response)
      })
      .catch(e => {
        failureCallback && failureCallback(e)
      })
  }

  delete (path, successCallback, failureCallback) {
    return this.axios
      .delete(path, { headers: this.defaultHeaders() })
      .then(response => {
        successCallback && successCallback(response)
      })
      .catch(e => {
        failureCallback && failureCallback(e)
      })
  }
}

const ApolloAPI = new API(`${apiEndpoint}/app`)

export default ApolloAPI
