import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import { FormInputMasked } from "components/form/form-input-masked"
import Form from "components/form/form"
import { Submit } from "components/button"
import FormInput from "components/form/form-input"
import FormGroup from "components/form/form-group"
import FormSelect from "components/form/form-select"
import API from "api"
import NextStepRouter from "onboarding/next-step-router"
import { Validator, CreditCard } from "onboarding/order/moip"
import { moipPublicKey } from "config"
import { MixpanelTrack, MixpanelPeopleSet } from "tags/mixpanel"

class CreditcardForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      submitDisabled: false,
      fullname: "",
      birthdate_day: "",
      birthdate_month: "",
      birthdate_year: "",
      tax_document: "",
      number: "",
      cvc: "",
      expiration_month: "",
      expiration_year: "",
      installments: "",
      installmentsOptions: [],
      errors: []
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  validate () {
    const creditcard = {
      number: this.state.number,
      cvc: this.state.cvc,
      expMonth: this.state.expiration_month,
      expYear: this.state.expiration_year
    }

    if (!Validator.isValidNumber(creditcard.number)) {
      this.setState({ errors: [{ number: "não é válido" }] })
      return
    }

    if (!Validator.isExpiryDateValid(creditcard.expMonth, creditcard.expYear)) {
      this.setState({
        errors: [
          {
            expiration_month: "incorreto",
            expiration_year: "incorreto"
          }
        ]
      })
      return
    }

    if (!Validator.isSecurityCodeValid(creditcard.number, creditcard.cvc)) {
      this.setState({ errors: [{ cvc: "não é válido" }] })
      return
    }

    return creditcard
  }

  handleInputChange (event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSubmit (event) {
    if (this.state.submitDisabled) return

    this.setState({ submitDisabled: true })

    const creditcard = this.validate()

    if (!creditcard) {
      this.setState({ submitDisabled: false })
      return
    }

    let hash = CreditCard.setPubKey(moipPublicKey.split(/\r\n|\r|\n/g).join("\n"))
      .setCreditCard(creditcard)
      .hash()

    API.post(
      "/checkout/payment",
      {
        payment_method: "creditcard",
        creditcard: {
          hash: hash,
          fullname: this.state.fullname,
          birthdate: {
            year: this.state.birthdate_year,
            month: this.state.birthdate_month,
            day: this.state.birthdate_day
          },
          tax_document: this.state.tax_document,
          installments: this.state.installments
        }
      },
      response => {
        MixpanelPeopleSet({
          "quantidade parcelas": this.state.installments,
          "forma de pagamento": "creditcard",
          "valor pago": this.props.totalValue
        })

        MixpanelTrack("Inseriu os dados de pagamento com cartao de credito", {
          "NUMERO DE PARCELAS": this.state.installments,
          "NOME DO CARTAO": this.state.fullname
        })

        const { dispatch } = this.props
        const redirectTarget = NextStepRouter(response.headers.next_step)
        dispatch(push(redirectTarget))
      },
      error => {
        const errors = error.response.data["errors"]
        this.setState({ errors, submitDisabled: false })
      }
    )

    event.preventDefault()
  }

  render () {
    const { errors, submitDisabled } = this.state
    const { installmentsOptions } = this.props

    return (
      <Form onSubmit={this.handleSubmit}>
        <h3>Dados do cartão</h3>

        <FormInput
          type="text"
          id="fullname"
          name="fullname"
          label="Nome que está no cartão"
          value={this.state.fullname}
          onChange={this.handleInputChange}
          errors={errors}
          required
        />

        <FormInputMasked
          type="tel"
          mask={[/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
          id="tax_document"
          name="tax_document"
          label="CPF"
          value={this.state.tax_document}
          onChange={this.handleInputChange}
          errors={errors}
          required
        />

        <FormGroup grid>
          <FormSelect
            id="birthdate_day"
            name="birthdate_day"
            label="Dia de Nascimento"
            value={this.state.birthdate_day}
            onChange={this.handleInputChange}
            errors={errors}
            required
          >
            <option value="" />
            {Array(31)
              .fill()
              .map((_, i) => (
                <option key={`d${i}`} value={i + 1}>
                  {i + 1}
                </option>
              ))}
          </FormSelect>
          <FormSelect
            id="birthdate_month"
            name="birthdate_month"
            label="Mês de Nascimento"
            value={this.state.birthdate_month}
            onChange={this.handleInputChange}
            errors={errors}
            required
          >
            <option value="" />
            {Array(12)
              .fill()
              .map((_, i) => (
                <option key={`m${i}`} value={i + 1}>
                  {i + 1}
                </option>
              ))}
          </FormSelect>
          <FormSelect
            id="birthdate_year"
            name="birthdate_year"
            label="Ano de Nascimento"
            value={this.state.birthdate_year}
            onChange={this.handleInputChange}
            errors={errors}
            required
          >
            <option value="" />
            {Array(90)
              .fill()
              .map((_, i) => (
                <option key={`y${i}`} value={new Date().getFullYear() - 18 - i}>
                  {new Date().getFullYear() - 18 - i}
                </option>
              ))}
          </FormSelect>
        </FormGroup>

        <FormInputMasked
          type="tel"
          mask={[
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/
          ]}
          id="number"
          name="number"
          label="Número do cartão"
          value={this.state.number}
          onChange={this.handleInputChange}
          errors={errors}
          guide={false}
          required
        />

        <FormGroup grid>
          <FormSelect
            id="expiration_month"
            name="expiration_month"
            label="Válido até o mês"
            value={this.state.expiration_month}
            onChange={this.handleInputChange}
            errors={errors}
            required
          >
            <option value="" />
            <option value="01">01</option>
            <option value="02">02</option>
            <option value="03">03</option>
            <option value="04">04</option>
            <option value="05">05</option>
            <option value="06">06</option>
            <option value="07">07</option>
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </FormSelect>

          <FormInputMasked
            type="tel"
            mask={[/(2)/, /\d/, /\d/, /\d/]}
            id="expiration_year"
            name="expiration_year"
            label="Válido até o ano"
            value={this.state.expiration_year}
            onChange={this.handleInputChange}
            errors={errors}
            required
          />
        </FormGroup>

        <FormInput
          type="tel"
          id="cvc"
          name="cvc"
          label="Código de Segurança"
          value={this.state.cvc}
          onChange={this.handleInputChange}
          errors={errors}
          required
        />

        <FormSelect
          id="installments"
          name="installments"
          label="Parcelas"
          value={this.state.installments}
          onChange={this.handleInputChange}
          errors={errors}
          required
        >
          <option value="" />
          {installmentsOptions.map((installment, index) => (
            <option value={installment.value} key={`i${index}`}>
              {installment.label}
            </option>
          ))}
        </FormSelect>

        <Submit value="Enviar" disabled={submitDisabled} arrow />
      </Form>
    )
  }
}

export default connect()(CreditcardForm)
