import React from "react"

const EssayRulesContent = ({ essayTitle }) => (
  <div>
    <h2 className="light">{essayTitle}</h2>

    <div className="exam-rules">
      <p>
        <strong>1.</strong> Antes de iniciar, garanta que tenha em mãos sua
        folha de redação. Caso tenha impressora, recomendamos que imprima nosso
        modelo. Caso contrário, basta usar uma folha de caderno comum (17cm de
        largura) e escrever no máximo 30 linhas.
      </p>
      <p>
        <strong>2.</strong> Leia atentamente o tema e a coletânea de apoio de
        sua {essayTitle}! Concentração nesta etapa é muito importante para
        evitar erros de compreensão do que foi proposto : )
      </p>
      <p>
        <strong>3.</strong> Redija sua {essayTitle} com atenção! Ela deve ter no
        máximo 30 linhas e ser escrita a caneta (preta), com letra legível e sem
        rasuras. Atente-se ao tema proposto e defina uma tese e linha de
        pensamento coerentes.
      </p>
      <p>
        <strong>4.</strong> Após finalizada, tire uma foto de sua redação ou
        digitalize-a, salvando o arquivo no seu computador. Na tela ENVIANDO SUA
        REDAÇÃO você deverá carregar o arquivo para concluir o envio.
        Lembre-se!! Uma vez enviada sua Redação, você não poderá mais alterá-la.
        Portanto, sempre revise seu texto com atenção.
      </p>
      <p>
        <strong>5.</strong> Sua redação será corrigida em até 10 dias úteis. Ao
        receber sua correção, você terá uma nota geral de redação e uma nota
        para cada uma das demais competências.
      </p>
    </div>
  </div>
)

export default EssayRulesContent
