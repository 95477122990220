import React from "react"
import * as Sentry from "@sentry/browser"
import { Layout } from "Session"
import Modal from "components/modal"
import { VigoSad } from "components/avatar"
import { env, sentryDsnPublic } from "config"

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidMount () {
    if (env === "production") {
      Sentry.init({
        dsn: sentryDsnPublic,
        beforeSend(event, hint) {
          const error = hint.originalException
          if (error && error.message && error.message.includes("s is not a function")) {
            return null
          }
          return event
        }
      })
    }
  }

  componentDidCatch (error) {
    this.setState({ hasError: true })
    if (env === "production") {
      if (error.message) {
        try {
          const errors = localStorage.getItem("err") || []
          if (Array.isArray(errors)) {
            if (!errors.includes(error.message)) {
              errors.push(error.message)
              localStorage.setItem("err", JSON.stringify(errors))
              Sentry.captureException(error)
            }
          }
        } catch (e) {
          console.log("Error...", e)
        }
      } else {
        Sentry.captureException(error)
      }
    }
  }

  render () {
    if (this.state.hasError) {
      return (
        <Layout>
          <Modal color="purple">
            <h2>Ops! Um erro inesperado ocorreu.</h2>

            <p>A comunicação com nosso centro de controle pode estar com problemas.</p>
            <VigoSad />
            <p>Para nos ajudar a resolver, conte-nos o que ocorreu!</p>

            <a
              href="https://ajuda.mindlab.com.br/hc/pt-br/requests/new"
              className="btn btn-primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              Reportar erro
              <i className="btn-icon arrow-icon arrow-icon-right" />
            </a>
          </Modal>
        </Layout>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
