import { Route } from "react-router"
import PublicRoute from "helpers/public-route"
import AuthenticatedRoute from "helpers/authenticated-route"
import Home from "account/home"
import AccountSessionNew from "account/session/new"
import AccountPasswordNew from "account/password/new"
import AccountPasswordEmailSent from "account/password/email-sent"
import AccountPasswordEdit from "account/password/edit"
import AccountPasswordShow from "account/password/show"
import ValidateAccount from "account/session/ValidateAccount"

const Routes = [
  {
    path: "/",
    component: Home,
    router: PublicRoute
  },
  {
    path: "/entrar",
    component: AccountSessionNew,
    router: PublicRoute
  },
  {
    path: "/esqueci-minha-senha",
    component: AccountPasswordNew,
    router: PublicRoute
  },
  {
    path: "/esqueci-minha-senha/email-enviado",
    component: AccountPasswordEmailSent,
    router: Route
  },
  {
    path: "/esqueci-minha-senha/:token/editar",
    component: AccountPasswordEdit,
    router: Route
  },
  {
    path: "/esqueci-minha-senha/completo",
    component: AccountPasswordShow,
    router: Route
  },
  {
    path: "/validar-conta",
    component: ValidateAccount,
    router: AuthenticatedRoute
  }
]

export default Routes
