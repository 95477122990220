import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import PropTypes from "prop-types"
import background from "knowledge-area-background.svg"
import { withPlanet } from "planet-context"

const Container = styled.div`
  background: url(${background}) no-repeat center right;
  background-color: ${(props) => props.color};
  background-size: cover;
  width: 420px;
  max-width: 95%;
  min-height: 240px;
  max-height: 240px;
  position: relative;
  border-radius: 20px;
  margin: 0 auto;
`

Container.propTypes = {
  color: PropTypes.string.isRequired,
}

const OverlayContainer = styled(Container)`
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 20px;
  }
`

const Answered = styled.div`
  position: absolute;
  top: 55px;
  left: 0;
  background-color: #ffffff;
  border-radius: 0 60px 60px 0;
  text-transform: uppercase;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 18px;
  line-height: 1em;
  color: ${(props) => props.color};
  padding: 15px 25px;
`

Answered.propTypes = {
  color: PropTypes.string.isRequired,
}

const Index = styled.div`
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 96px;
  line-height: 1em;
  color: #ffffff;
  position: absolute;
  top: 30px;
  right: 30px;
`

const Title = styled.div`
  color: #ffffff;
  position: absolute;
  left: 25px;
  bottom: 25px;
`

const Name = styled.div`
  font-size: 28px;
  line-height: 1.2em;
  font-weight: 700;
  margin-top: 10px;

  @media (max-width: 320px) {
    font-size: 14px;
  }

  @media (min-width: 321px) and (max-width: 768px) {
    font-size: 18px;
  }
`

let KnowledgeArea = ({ name, index, answered, planet }) => {
  const Wrapper = answered ? OverlayContainer : Container

  return (
    <Wrapper color={planet.knowledgeAreaSlide.prev}>
      {answered && <Answered color={planet.mainColor}>Respondida</Answered>}
      <Index>{index}</Index>

      <Title>
        <div>Missão diária</div>
        <Name>{name}</Name>
      </Title>
    </Wrapper>
  )
}

KnowledgeArea.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  answered: PropTypes.bool,
  planet: PropTypes.shape({
    mainColor: PropTypes.string.isRequired,
  }),
}

KnowledgeArea = withPlanet(KnowledgeArea)

const KnowledgeAreaSlide = withPlanet(styled.div`
  margin-top: 40px;
  width: 100%;

  .slick-center {
    ${Container} {
      background-color: ${(props) => props.planet.mainColor};
    }
  }

  .slick-center + .slick-slide {
    ${Container} {
      background-color: ${(props) => props.planet.knowledgeAreaSlide.next};
    }
  }
`)

const KnowledgeAreaSlideItemLink = styled(Link)`
  display: block;
  text-decoration: none;
`

export {
  KnowledgeArea,
  Container,
  OverlayContainer,
  Index,
  Title,
  Name,
  KnowledgeAreaSlide,
  KnowledgeAreaSlideItemLink,
  Answered,
}
