import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import ClockCountdown from "components/clock-countdown"
import UserAvatar from "components/user-avatar"

const BlockedPlanetModal = ({ opened, onClose, unblockTime }) => (
  <CloseableModal opened={opened} onClose={onClose}>
    <h2 className="modal-title">Planeta Bloqueado</h2>
    <p className="medium">Ooops! Esse planeta ainda está bloqueado, ele só será liberado em:</p>
    <ClockCountdown until={unblockTime} />
    <UserAvatar kind="sad" />
  </CloseableModal>
)

export default BlockedPlanetModal
