import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import { BtnPrimary } from "components/button"
import { StyledRuby } from "daily-mission/RetrieveQuestionStyle"

class RetrieveQuestionConfirmationModal extends React.Component {
  constructor (props) {
    super(props)
    this.handleConfirmation = this.handleConfirmation.bind(this)
  }

  handleConfirmation (event) {
    const { onConfirmation } = this.props
    onConfirmation()
    event.preventDefault()
  }

  render () {
    const { opened, onClose } = this.props

    return (
      <CloseableModal opened={opened} onClose={onClose} overlay small>
        <StyledRuby />
        <h2>Desbloquear desafio?</h2>
        <p>Você quer trocar um rubi para resgatar uma questão que errou anteriormente?</p>

        <div className="buttons-container">
          <BtnPrimary to="#" value="Continuar" onClick={this.handleConfirmation} />
        </div>
      </CloseableModal>
    )
  }
}

export default RetrieveQuestionConfirmationModal
