import Space from "Galaxy/Space"
import Layout from "Galaxy/Layout"
import PlanetImage from "Galaxy/wrappers/PlanetImage"
import LastPlanetEndedNotification from "Galaxy/modals/LastPlanetEndedNotification"
import NeighboringPlanets from "Galaxy/elements/NeighboringPlanets"
import BluePlanet from "Galaxy/elements/BluePlanet"
import Sun from "Galaxy/elements/Sun"
import { GameControl } from "Galaxy/elements/GameControl"
import { Satellite } from "Galaxy/elements/Satellite"
import MilkyWay from "Galaxy/elements/MilkyWay"
import Axion from "Galaxy/planets/Axion"
import Groupem from "Galaxy/planets/Groupem"
import Imagite from "Galaxy/planets/Imagite"
import Logic from "Galaxy/planets/Logic"
import Natus from "Galaxy/planets/Natus"
import Onis from "Galaxy/planets/Onis"
import Pensatus from "Galaxy/planets/Pensatus"
import Racion from "Galaxy/planets/Racion"
import Union from "Galaxy/planets/Union"
import Kai from "Galaxy/planets/Kai"
import Roya from "Galaxy/planets/Roya"
import Cryo from "Galaxy/planets/Cryo"

export const PLANETS = {
  Áxion: Axion,
  Groupem: Groupem,
  Imagite: Imagite,
  Lógic: Logic,
  Natus: Natus,
  Onis: Onis,
  Pensatus: Pensatus,
  Ración: Racion,
  Unión: Union,
  Kai: Kai,
  Roya: Roya,
  Cryo: Cryo
}

export {
  Space,
  Layout,
  PlanetImage,
  LastPlanetEndedNotification,
  NeighboringPlanets,
  BluePlanet,
  Sun,
  GameControl,
  Satellite,
  MilkyWay,
  Axion,
  Groupem,
  Imagite,
  Logic,
  Natus,
  Onis,
  Pensatus,
  Racion,
  Union,
  Kai,
  Roya,
  Cryo
}
