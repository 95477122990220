import React from "react"
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView"
import FormRadio from "components/form/form-radio"
import PropTypes from "prop-types"

class QuestionAlternative extends React.Component {
  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (event) {
    const { questionAnswered, onChange } = this.props

    if (questionAnswered) {
      event.preventDefault()
    } else {
      onChange && onChange()
    }
  }

  render () {
    const { id, alternativeName, state, correct, wrong, correctlyAnswered } = this.props

    const showCorrectAnswer =
      (correctlyAnswered === undefined || !correctlyAnswered) && this.props.correctAnswer === alternativeName

    return (
      <div className={`question-alternative ${state ? state : ""} ${correct ? "correct" : ""} ${wrong ? "wrong" : ""}`}>
        {showCorrectAnswer && <div className="correct-answer">Resposta correta</div>}

        <FormRadio
          id={`${id}-${alternativeName}`}
          name={id}
          value={alternativeName}
          defaultChecked={this.props.checked}
          onChange={this.handleChange}
          disabled={this.props.disabled}
        >
          <FroalaEditorView model={this.props.content} />
        </FormRadio>

        <hr />
      </div>
    )
  }
}

QuestionAlternative.propTypes = {
  questionAnswered: PropTypes.bool,
  onChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  alternativeName: PropTypes.string.isRequired,
  state: PropTypes.string,
  correct: PropTypes.bool,
  wrong: PropTypes.bool,
  correctlyAnswered: PropTypes.bool
}

export default QuestionAlternative
