class ComponentHelper {
  static label (errors, paramKey, defaultLabel) {
    const error = errors.find(x => x[paramKey])

    return error ? `${defaultLabel} ${error[paramKey]}` : defaultLabel
  }

  static hasError (errors, paramKey) {
    return errors.find(x => x[paramKey])
  }
}

export default ComponentHelper
