import React from "react"
import FormGroup from "components/form/form-group"
import FormLabel from "components/form/form-label"
import ComponentHelper from "helpers/component-helper"

class FormPassword extends React.Component {
  constructor (props) {
    super(props)
    this.state = { type: "password" }
    this.toggleType = this.toggleType.bind(this)
  }

  toggleType () {
    this.setState({ type: this.state.type === "password" ? "text" : "password" })
  }

  render () {
    const { id, name, required, label, errors = [], children, className = "", ...rest } = this.props
    const { type } = this.state

    return (
      <FormGroup hasError={ComponentHelper.hasError(errors, name)}>
        {children}
        <input type={type} id={id} name={name} className={`form-field ${className}`} {...rest} required={required} />
        <FormLabel htmlFor={id}>{ComponentHelper.label(errors, name, label)}</FormLabel>
        <i className={`eye-icon ${type}`} onClick={this.toggleType} />
      </FormGroup>
    )
  }
}

export default FormPassword
