import styled from "styled-components"
import PropTypes from "prop-types"
import { withPlanet } from "planet-context"

const Stone = styled.div`
  position: absolute;
  background-repeat: no-repeat;
  animation: fadeIn 500ms ease-in-out;
  background-image: url(${props => props.planet.trail.stones[props.position].icon});
  width: ${props => props.planet.trail.stones[props.position].width};
  height: ${props => props.planet.trail.stones[props.position].height};
  top: ${props => props.planet.trail.stones[props.position].top};
  left: ${props => props.planet.trail.stones[props.position].left};
`

Stone.propTypes = {
  position: PropTypes.number.isRequired,
  planet: PropTypes.shape({
    trail: PropTypes.shape({
      stones: PropTypes.array.isRequired
    })
  })
}

export default withPlanet(Stone)
