import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import { UsersList } from "trail/users"

const TrailUsersDetailsModal = ({ opened, onClose, users }) => (
  <CloseableModal opened={opened} onClose={onClose}>
    <h2>Amigos que estão nessa posição da trilha</h2>
    <UsersList users={users} />
  </CloseableModal>
)

export default TrailUsersDetailsModal
