import React from "react"
import { connect } from "react-redux"
import { Layout } from "Session"
import { Submit } from "components/button"
import Modal from "components/modal"
import Form from "components/form/form"
import { FormInputMasked } from "components/form/form-input-masked"
import API from "api"
import { MixpanelTrack, MixpanelPeopleSet, MixpanelReset } from "tags/mixpanel"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { logout } from "actions"
import { push } from "connected-react-router"

const ModalTitle = styled.div`
  margin-bottom: 50px;
`

const Contact = styled.div`
  color: #9b9b9b;
  font-size: 13px;
  margin-top: 60px;
  line-height: 20px;
`

const PurpleLink = styled(Link)`
  display: block;
  margin-top: 10px;
  color: #8e3fac;
`

class ValidateAccount extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activationCode: "",
      errors: []
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSignOut = this.handleSignOut.bind(this)
  }

  handleChange (event) {
    this.setState({ activationCode: event.target.value })
  }

  handleSubmit (event) {
    const { dispatch, requestInProgress } = this.props
    if (requestInProgress) return

    const activationCode = this.state.activationCode.toUpperCase()
    if (activationCode === "") return

    API.post(
      "/accounts/validate",
      { activation_code: activationCode },
      () => {
        MixpanelPeopleSet({
          "codigo de ativacao": activationCode
        })

        MixpanelTrack("Confirmou Código de Ativação")

        dispatch(push("/galaxia"))
      },
      error => {
        const errors = error.response.data["errors"]
        this.setState({ errors })
      }
    )

    event.preventDefault()
  }

  handleSignOut (event) {
    event.preventDefault()
    const { dispatch } = this.props

    API.delete("/sessions", () => {
      MixpanelTrack("Efetuou logout")
      MixpanelReset()

      dispatch(logout())
      dispatch(push("/"))
    })
  }

  render () {
    const { requestInProgress } = this.props
    const { activationCode, errors } = this.state
    const currentYear = new Date().getFullYear()

    return (
      <Layout>
        <Modal backUrl="/">
          <ModalTitle>
            <h2>Preparado para {currentYear}?!</h2>
            <p>Seu acesso ao Mindzup ainda não foi liberado em {currentYear}.</p>
            <p>Para validar sua conta, basta inserir o Código de Ativação {currentYear} no campo abaixo :)</p>
          </ModalTitle>

          <Form onSubmit={this.handleSubmit}>
            <FormInputMasked
              mask={[
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /(E|e)/,
                /(F|f|M|m|I|i)/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/
              ]}
              type="text"
              id="activation_code"
              name="activation_code"
              label="Código de Ativação"
              autoComplete="off"
              className="text-uppercase"
              value={activationCode}
              onChange={this.handleChange}
              errors={errors}
              required
            />

            <Submit value="validar meu acesso" disabled={requestInProgress} arrow />
          </Form>

          <PurpleLink to="#" onClick={this.handleSignOut}>
            Validar outra hora!
          </PurpleLink>

          <Contact>
            <strong>Problemas?</strong> Entre em contato com nosso time de atendimento via chat ou telefone: (11)
            3474-1770
          </Contact>
        </Modal>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    requestInProgress: state.http.requestInProgress
  }
}

export default connect(mapStateToProps)(ValidateAccount)
