import styled from "styled-components"
import iconPadlock from "padlock.svg"

const Padlock = styled.i`
  mask: url(${iconPadlock}) no-repeat;
  background-color: #333;
  display: block;
`

export default Padlock
