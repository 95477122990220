import React from "react"
import { Header } from "Header"

const Modal = ({ backUrl, backLabel, closeButton, onClose, color = "", small, overlay, fullscreen, children }) => {
  const smallClass = small ? "small" : ""
  const fullscreenClass = fullscreen ? "fullscreen" : ""
  const overlayClass = overlay ? "overlay" : ""

  return (
    <div className={`modal ${fullscreenClass} ${overlayClass}`}>
      <Header backUrl={backUrl} backLabel={backLabel} />

      <div className={`modal-content ${color} ${smallClass} ${fullscreenClass}`}>
        {closeButton && (
          <i className="modal-close-button error-icon" onClick={onClose}>
            &times;
          </i>
        )}
        {children}
      </div>
    </div>
  )
}

export default Modal
