import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import API from "api"
import { connectToChannel } from "socket-connect"
import { MixpanelTrack } from "tags/mixpanel"
import Processing from "components/processing"
import AnswerForm from "daily-mission/AnswerForm"

class RetrievedQuestionAnswer extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      answerSessionClosed: false,
      questions: []
    }

    this.syncAnswer = this.syncAnswer.bind(this)
    this.fetchRetrievedQuestion = this.fetchRetrievedQuestion.bind(this)
  }

  componentDidMount () {
    this.fetchRetrievedQuestion()
    this.subscribeToAnswerSession()
  }

  fetchRetrievedQuestion () {
    const { dispatch } = this.props

    API.post(
      "/daily_mission_question_retrieve",
      {},
      response => {
        const question = response.data
        this.setState({ questions: [question] })
      },
      () => {
        dispatch(push("/missao-diaria"))
      }
    )
  }

  subscribeToAnswerSession () {
    const { dispatch } = this.props

    const channel = connectToChannel("daily_mission")

    if (!channel) return

    channel.join().receive("error", () => {
      dispatch(push("/"))
    })

    channel.on("close_answer_sessions", () => {
      this.setState({ answerSessionClosed: true })
    })
  }

  syncAnswer (knowledgeArea, alternative) {
    const { value } = alternative

    MixpanelTrack("Respondeu Questão Resgatada de Missão Diária", {
      "ÁREA DA QUESTÃO": knowledgeArea
    })

    API.put("/daily_mission_question_retrieve", { alternative_name: value }, () => {
      this.fetchRetrievedQuestion()
    })
  }

  handleFinalization () {
    MixpanelTrack("Concluiu Questão Resgatada de Missão Diária")
  }

  render () {
    const { answerSessionClosed, questions } = this.state
    const selected = this.props.location.state ? this.props.location.state.selected : 0

    if (typeof questions === "undefined" || questions.length == 0) {
      return <Processing />
    }

    return (
      <AnswerForm
        questions={questions}
        answerSessionClosed={answerSessionClosed}
        syncAnswer={this.syncAnswer}
        handleFinalization={this.handleFinalization}
        urlOnFinalization="/missao-diaria"
        selected={selected}
      />
    )
  }
}

export default connect()(RetrievedQuestionAnswer)
