import React from "react"
import { connect } from "react-redux"
import NewPlanetAvailableModal from "components/modals/new-planet-available-modal"
import NewGalaxyAvailableModal from "components/modals/new-galaxy-available-modal"
import VisitedPlanetModal from "components/modals/visited-planet-modal"
import BlockedPlanetModal from "components/modals/blocked-planet-modal"
import BlockedGalaxyModal from "components/modals/blocked-galaxy-modal"
import BlockedSatelliteModal from "components/modals/blocked-satellite-modal"
import EvaluatedEssayModal from "components/modals/evaluated-essay-modal"
import AccountValidatedModal from "components/modals/AccountValidatedModal"
import EarnedBadge from "backpack/badges/EarnedBadge"
import { PLANETS, Layout, Space, GameControl, Satellite, MilkyWay, LastPlanetEndedNotification } from "Galaxy"
import { ScrollDown } from "helpers/scroll"
import { setCurrentGalaxy } from "actions"
import { MixpanelTrack } from "tags/mixpanel"
import { syncCurrentPlanet } from "actions"
import API from "api"

class GalaxyPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showGalaxyBlockedModal: false,
      showPlanetBlockedModal: false,
      showPlanetVisitedModal: false,
      showPlanetAvailableModal: false,
      showAccountValidatedModal: false,
      accountValidatedYear: "",
      newGalaxyAvailable: false,
      showSatelliteBlockedModal: false,
      showEvaluatedEssayModal: false,
      showLastPlanetEndedNotification: false,
      essayEvaluatedRuby: "",
      essayEvaluatedSessionId: "",
      earnedBadgesSlugs: [],
      satelliteAvailable: false,
      unblockPlanetTime: null,
      newPlanetAvailableName: null,
      newPlanetAvailableMessages: [],
      currentGalaxy: null,
      nearGalaxy: null
    }

    this.handleScrollDown = this.handleScrollDown.bind(this)
    this.handleGalaxyClick = this.handleGalaxyClick.bind(this)
    this.handlePlanetClick = this.handlePlanetClick.bind(this)
    this.handleSatelliteClick = this.handleSatelliteClick.bind(this)
    this.handleEarnedBadgeNotification = this.handleEarnedBadgeNotification.bind(this)
    this.onEarnedBadgeModalClose = this.onEarnedBadgeModalClose.bind(this)
  }

  componentDidMount () {
    this.fetchGalaxies()
      .then(API.spread(() => this.fetchNotifications()))
      .then(API.spread(() => this.handleScrollDown()))
  }

  handleScrollDown () {
    const { showPlanetAvailableModal, newGalaxyAvailable, showEvaluatedEssayModal } = this.state

    if (!showPlanetAvailableModal && !newGalaxyAvailable && !showEvaluatedEssayModal) {
      ScrollDown()
    }
  }

  nearGalaxyTooltip () {
    const { nearGalaxy, newGalaxyAvailable } = this.state

    if (newGalaxyAvailable) {
      return <div>Galáxia liberada!</div>
    } else if (nearGalaxy && !nearGalaxy.blocked) {
      return (
        <div className="light">
          <div>
            <strong>Galáxia</strong>
          </div>{" "}
          {nearGalaxy.name}
        </div>
      )
    } else {
      return null
    }
  }

  fetchGalaxies () {
    const { dispatch } = this.props

    return API.get("/galaxies", response => {
      const { galaxies, satellite_available: satelliteAvailable } = response.data
      const currentGalaxy = galaxies.find(galaxy => galaxy.current) || galaxies.find(galaxy => !galaxy.blocked)
      const nearGalaxy = galaxies.find(g => g.id != currentGalaxy.id)

      this.setState({
        satelliteAvailable,
        currentGalaxy,
        nearGalaxy
      })

      dispatch(setCurrentGalaxy(currentGalaxy.name, currentGalaxy.slug))
    })
  }

  fetchNotifications () {
    return API.get("/notifications", response => {
      const notifications = response.data
      this.handlePlanetNotification(notifications.find(n => n.kind === "new-planet-available"))
      this.handleGalaxyNotification(notifications.find(n => n.kind === "new-galaxy-available"))
      this.handleLastPlanetEndedNotification(notifications.find(n => n.kind === "last-planet-ended"))
      this.handleEssayNotification(notifications.find(n => n.kind === "essay-evaluated"))
      this.handleEarnedBadgeNotification(notifications.filter(n => n.kind === "earned-badge"))
      this.handleAccountValidatedNotification(notifications.find(n => n.kind === "account-validated"))
    })
  }

  handleAccountValidatedNotification (notification) {
    this.setState({ showAccountValidatedModal: true, accountValidatedYear: notification.year })
    API.post("/notifications", { slugs: [notification.slug] })
  }

  handlePlanetNotification (notification) {
    const { currentGalaxy } = this.state

    if (!notification || currentGalaxy.name !== notification.galaxy) {
      return
    }

    this.setState({
      showPlanetAvailableModal: true,
      newPlanetAvailableName: notification.planet,
      newPlanetAvailableMessages: notification.messages
    })

    this.props.dispatch(syncCurrentPlanet())
    API.post("/notifications", { slugs: [notification.slug] })
  }

  handleGalaxyNotification (notification) {
    if (!notification) {
      return
    }

    this.setState({ newGalaxyAvailable: true })

    API.post("/notifications", { slugs: [notification.slug] })
  }

  handleLastPlanetEndedNotification (notification) {
    if (!notification) {
      return
    }

    this.setState({ showLastPlanetEndedNotification: true })

    API.post("/notifications", { slugs: [notification.slug] })
  }

  handleEssayNotification (notification) {
    if (!notification) {
      return
    }

    this.setState({
      showEvaluatedEssayModal: true,
      essayEvaluatedRuby: notification.ruby,
      essayEvaluatedSessionId: notification.essay_session_id
    })

    API.post("/notifications", { slugs: [notification.slug] })
  }

  handleEarnedBadgeNotification (notifications) {
    if (!notifications) {
      return
    }

    API.post("/notifications", { slugs: notifications.map(notification => notification.slug) })

    const earnedBadgesSlugs = notifications.map(notification => notification.earned_badge_slug)
    this.setState({ showEarnedBadgeModal: true, earnedBadgesSlugs: earnedBadgesSlugs })
  }

  onEarnedBadgeModalClose (badgeSlug) {
    const { earnedBadgesSlugs } = this.state
    this.setState({ earnedBadgesSlugs: earnedBadgesSlugs.filter(bs => bs !== badgeSlug) })
  }

  handleClick (e, kind) {
    this.setState({ [kind]: true })
    e.preventDefault()
  }

  handleGalaxyClick (e) {
    const { id, name, blocked } = this.state.nearGalaxy

    if (blocked) {
      MixpanelTrack("Tentou acessar Galáxia Bloqueada")

      this.handleClick(e, "showGalaxyBlockedModal")
    } else {
      API.put(`/galaxies/${id}`, {}, () => {
        MixpanelTrack(`Acessou Galáxia ${name}`)

        this.switchGalaxies()
      })
    }
  }

  switchGalaxies () {
    const { currentGalaxy: newNearGalaxy, nearGalaxy: newCurrentGalaxy } = this.state

    this.setState({
      currentGalaxy: newCurrentGalaxy,
      nearGalaxy: newNearGalaxy
    })

    this.props.dispatch(setCurrentGalaxy(newCurrentGalaxy.name, newCurrentGalaxy.slug))
    this.fetchNotifications()
  }

  handlePlanetClick (e, planet) {
    if (planet.blocked) {
      MixpanelTrack("Tentou acessar Planeta Bloqueado")

      this.setState({ unblockPlanetTime: planet.available_at })
      this.handleClick(e, "showPlanetBlockedModal")
    } else if (planet.visited) {
      this.handleClick(e, "showPlanetVisitedModal")
    } else {
      MixpanelTrack("Acessou Planeta Liberado")
    }
  }

  handleSatelliteClick (e) {
    const { satelliteAvailable } = this.state

    if (satelliteAvailable) {
      MixpanelTrack("Acessou Estação de Avaliações")
    } else {
      MixpanelTrack("Tentou acessar Estação de Avaliações bloqueada")

      this.handleClick(e, "showSatelliteBlockedModal")
    }
  }

  handlePlanetAvailableModalClose () {
    ScrollDown()
  }

  render () {
    const {
      showPlanetAvailableModal,
      newGalaxyAvailable,
      showPlanetVisitedModal,
      showPlanetBlockedModal,
      showGalaxyBlockedModal,
      showSatelliteBlockedModal,
      showEvaluatedEssayModal,
      showAccountValidatedModal,
      accountValidatedYear,
      showLastPlanetEndedNotification,
      newPlanetAvailableName,
      newPlanetAvailableMessages,
      currentGalaxy,
      nearGalaxy,
      unblockPlanetTime,
      essayEvaluatedRuby,
      essayEvaluatedSessionId,
      earnedBadgesSlugs
    } = this.state

    const nearGalaxyTooltip = this.nearGalaxyTooltip()

    return (
      <Layout>
        <EvaluatedEssayModal
          opened={showEvaluatedEssayModal}
          ruby={essayEvaluatedRuby}
          essaySessionId={essayEvaluatedSessionId}
          onClose={() => this.setState({ showEvaluatedEssayModal: false })}
        />

        {earnedBadgesSlugs.map(ebs => {
          return <EarnedBadge key={ebs} opened onClose={() => this.onEarnedBadgeModalClose(ebs)} badgeSlug={ebs} />
        })}

        <Space>
          <NewPlanetAvailableModal
            opened={showPlanetAvailableModal}
            messages={newPlanetAvailableMessages}
            onClose={this.handlePlanetAvailableModalClose}
          />

          <AccountValidatedModal
            opened={showAccountValidatedModal}
            onClose={() => this.setState({ showAccountValidatedModal: false })}
            year={accountValidatedYear}
          />

          <NewGalaxyAvailableModal
            opened={newGalaxyAvailable}
            onClose={() => this.setState({ newGalaxyAvailable: false })}
          />

          <VisitedPlanetModal
            opened={showPlanetVisitedModal}
            onClose={() => this.setState({ showPlanetVisitedModal: false })}
          />

          <BlockedPlanetModal
            opened={showPlanetBlockedModal}
            onClose={() => this.setState({ showPlanetBlockedModal: false })}
            unblockTime={unblockPlanetTime}
          />

          <BlockedSatelliteModal
            opened={showSatelliteBlockedModal}
            onClose={() => this.setState({ showSatelliteBlockedModal: false })}
          />

          <LastPlanetEndedNotification
            opened={showLastPlanetEndedNotification}
            onClose={() => this.setState({ showLastPlanetEndedNotification: false })}
          />

          {nearGalaxy && (
            <BlockedGalaxyModal
              opened={showGalaxyBlockedModal}
              unblockTime={nearGalaxy.available_at}
              onClose={() => this.setState({ showGalaxyBlockedModal: false })}
            />
          )}

          {nearGalaxy && (
            <MilkyWay
              to="#"
              blocked={nearGalaxy.blocked}
              onClick={this.handleGalaxyClick}
              tooltip={nearGalaxyTooltip}
            />
          )}

          <Satellite to="/simulados" onClick={this.handleSatelliteClick} />

          {this.props.hasGames && <GameControl to="/jogos/estacao" />}

          {currentGalaxy &&
            currentGalaxy.planets.map((planet, index) => {
              const { name, blocked, visited } = planet
              const PlanetComponent = PLANETS[name]
              const notification = newPlanetAvailableName === name && "Planeta liberado!"

              return (
                <PlanetComponent
                  key={index}
                  to="/trilha"
                  tooltip={notification}
                  blocked={blocked}
                  visited={visited}
                  onClick={e => this.handlePlanetClick(e, planet)}
                />
              )
            })}
        </Space>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    hasGames: state.auth.currentUser.has_games
  }
}

export default connect(mapStateToProps)(GalaxyPage)
