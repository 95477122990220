import "raf/polyfill"
import animateScrollTo from "animated-scroll-to"

const ScrollTo = (target) => {
  window.setTimeout(function () {
    animateScrollTo(target)
  }, 150)
}

const ScrollDown = () => {
  ScrollTo(document.body.scrollHeight)
}

const ScrollUp = () => {
  ScrollTo(0)
}

export { ScrollDown, ScrollUp, ScrollTo }
