import React from "react"
import { connect } from "react-redux"
import { Layout } from "Session"
import Modal from "components/modal"
import { BtnPrimary, BtnLight } from "components/button"
import { PixelTrack } from "tags/pixel"

class OnboardingActivationConfirmation extends React.Component {
  componentDidMount () {
    PixelTrack("Confirmação de Código")
  }

  render () {
    const { schoolName, schoolYear } = this.props

    return (
      <Layout>
        <Modal backUrl="/criar-conta">
          <div className="modal-title">
            <h2>Tenho código</h2>
            <p>Confirme os dados abaixo para continuar o cadastro:</p>
          </div>

          <p className="school-name">
            <strong>{schoolName}</strong>
          </p>
          <p className="school-year">{schoolYear.name}</p>

          <BtnPrimary to="/criar-conta/dados" value="Continuar" arrow />
          <BtnLight to="/criar-conta" value="Cancelar" />
        </Modal>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    activationCode: state.activation.activationCode,
    partnerEmail: state.activation.partnerEmail,
    schoolName: state.activation.schoolName,
    schoolYear: state.activation.schoolYear
  }
}

export default connect(mapStateToProps)(OnboardingActivationConfirmation)
