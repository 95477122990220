import {
  AdvanceThinkingSkills,
  Adventurous,
  AlienMaster,
  Apollo,
  BenefitsOfCollaboration,
  Conquer,
  ConqueredPlanet,
  //DataAnalysisAndManagement,
  DivingIntoMethods,
  Epic,
  GalacticMaster,
  HomoSapiensSapiensPlus,
  HumanityHero,
  Imagining,
  InformationAnalysis,
  InvestingResources,
  JoiningForces,
  //LogicThinkingSkills,
  MagicOfLetters,
  MakingQuestions,
  MasterOfNumbers,
  MemoryApplications,
  MetWeeklyGoal,
  Newbie,
  PlanningAndOrdering,
  PlanningTheFuture,
  ResourceManagement,
  RiskManagement,
  SolvingProblems,
  Superstar,
  TacticsAndStrategies,
  UniverseMaster
} from "components/badge"

// Badges marked as "TODO" are using placeholders.
const BadgesList = {
  "administracao-e-analise-de-dados": { component: MasterOfNumbers, column: 1 }, //TODO: DataAnalysisAndManagement
  "analisando-informacoes": { component: InformationAnalysis },
  "aplicacoes-da-memoria": { component: MemoryApplications },
  "apollo": { component: Apollo, column: 2 },
  "aventureiro": { component: Adventurous, column: 2 },
  "beneficios-da-colaboracao": {
    component: BenefitsOfCollaboration,
    slug: "beneficios-da-colaboracao"
  },
  "conquistador": { component: Conquer, column: 3 },
  "epico": { component: Epic, column: 2 },
  "fazendo-perguntas": { component: MakingQuestions },
  "gerenciamento-de-recursos": { component: ResourceManagement },
  "gerenciamento-de-risco": { component: RiskManagement },
  "habilidades-avancadas-de-pensamento": {
    component: AdvanceThinkingSkills,
    slug: "habilidades-avancadas-de-pensamento"
  },
  "habilidades-de-pensamento-logico": { component: AdvanceThinkingSkills }, //TODO: LogicThinkingSkills
  "herois-da-humanidade": { component: HumanityHero, column: 1 },
  "homo-sapiens-sapiens-plus": { component: HomoSapiensSapiensPlus, column: 1 },
  "imaginando": { component: Imagining },
  "investindo-recursos": { component: InvestingResources },
  "magico-das-letras": { component: MagicOfLetters, column: 1 },
  "mago-das-letras": { component: MagicOfLetters, column: 1 },
  "mergulhando-nos-metodos": { component: DivingIntoMethods },
  "mestre-do-universo": { component: UniverseMaster, column: 3 },
  "mestre-dos-numeros": { component: MasterOfNumbers, column: 1 },
  "mestre-extraterrestre": { component: AlienMaster, column: 3 },
  "mestre-galactico": { component: GalacticMaster, column: 3 },
  "newbie-bem-vindo": { component: Newbie, column: 1 },
  "planejando-e-ordenando": { component: PlanningAndOrdering },
  "planejando-o-futuro": { component: PlanningTheFuture },
  "plano-de-voo": { component: MetWeeklyGoal, column: 2 },
  "resolvendo-problemas": { component: SolvingProblems },
  "superstar": { component: Superstar, column: 2 },
  "taticas-e-estrategias": { component: TacticsAndStrategies },
  "tomada-de-decisoes": { component: BenefitsOfCollaboration },
  "unindo-forcas": { component: JoiningForces },
  "vai-planeta": { component: ConqueredPlanet, column: 3 }
}

export default BadgesList
