import React from "react"

const Form = ({ onSubmit, children }) => (
  <form onSubmit={onSubmit} className="form" noValidate>
    <fieldset>
      {children}
    </fieldset>
  </form>
)

export default Form
