import React from "react"
import { connect } from "react-redux"
import CloseableModal from "components/modals/closeable-modal"
import { Vigo } from "components/avatar"

const AccountValidatedModal = ({ opened, year, onClose, currentUser }) => (
  <CloseableModal small opened={opened} onClose={onClose}>
    <h2 className="light">Bem-vindo :)</h2>

    <p>Olá, {currentUser.name}!</p>
    <p>Sua conta foi validada para esse ano de {year}!!</p>

    <h2 className="modal-message light">Boa sorte!</h2>

    <Vigo />
  </CloseableModal>
)

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  }
}

export default connect(mapStateToProps)(AccountValidatedModal)
