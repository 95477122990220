import datePt from "date-fns/locale/pt"
import dateFormat from "date-fns/format"
import endOfYear from "date-fns/endOfYear"
import startOfYear from "date-fns/startOfYear"

export function SecondsToHourMins (seconds) {
  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  return m === 0 ? (h === 1 ? `${h} hora` : `${h} horas`) : `${h}h${m}min`
}

export function SecondsToHourMinsSecs (seconds) {
  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor((seconds % (3600 * 60)) / 60)
  return `${h}:${m}:${s}`
}

export function formattedEndOfYear (year) {
  const date = new Date(year, 0, 1)
  return dateFormat(endOfYear(date), "d 'de' MMMM 'de' yyyy", { locale: datePt })
}

export function formattedStartOfYear (year) {
  const date = new Date(year, 0, 1)
  return dateFormat(startOfYear(date), "d 'de' MMMM 'de' yyyy", { locale: datePt })
}

export function endOfCurrentYear () {
  return formattedEndOfYear(new Date().getFullYear())
}
