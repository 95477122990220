export default [
  {
    title: "",
    description: "",
    items: [
      {
        text: "Selecionar uma carta-desafio (de acordo com o nível de dificuldade).",
      },
      {
        text: "As barreiras devem ser colocadas de forma a levar o pirata pelo caminho mais curto possível, utilizando o número exato de passos. Sem a barreira, o pirata não pode desviar e tomar um caminho mais longo.",
      },
      {
        text: "Tipos de barreiras:",
        items: [
          {
            text: "Arbusto: bloqueia a passagem entre duas casas",
          },
          {
            text: "Parede de tijolos: bloqueia totalmente uma casa",
          },
          {
            text: "Chaves: devem ser recolhidas ao longo do caminho",
          }
        ]
      }
    ]
  }
]