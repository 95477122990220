import React from "react"
import { PerformanceName } from "backpack/badges/styled"
import BlockedBadgeBase from "backpack/badges/BlockedBadgeBase"

const BlockedBadge = ({ badge, progress }) => (
  <BlockedBadgeBase badge={badge} progress={progress}>
    <PerformanceName>{badge.id}</PerformanceName>
  </BlockedBadgeBase>
)

export default BlockedBadge
