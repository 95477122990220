import React from "react"
import { Redirect } from "react-router"
import { Vigo } from "components/avatar"
import { BtnPrimary } from "components/button"
import { Header } from "Header"

class Storytelling extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      fireRedirect: false,
      back: false,
      nextText: "Continuar",
      nextArrow: true,
      index: 0,
      title: null,
      message: null
    }

    this.handlePrev = this.handlePrev.bind(this)
    this.handleNext = this.handleNext.bind(this)
  }

  componentDidMount () {
    this.fetchMessage(0)
  }

  fetchMessage (index) {
    const { titles, messages } = this.props
    this.setState({ title: titles[index], message: messages[index] })
  }

  handlePrev (event) {
    this.handleStory(this.state.index - 1)
    event.preventDefault()
  }

  handleNext (event) {
    const { index } = this.state
    const last = this.props.messages.length - 1

    if (index === last) {
      this.setState({ fireRedirect: true })
    } else {
      this.handleStory(index + 1)
    }

    event.preventDefault()
  }

  handleStory (newIndex) {
    const last = this.props.messages.length - 1

    if (newIndex === last) {
      this.setState({ nextText: "Começar", nextArrow: false })
    } else {
      this.setState({ nextText: "Continuar", nextArrow: true })
    }

    this.setState({ index: newIndex, back: newIndex !== 0 })
    this.fetchMessage(newIndex)
  }

  render () {
    const { redirectTarget, messages } = this.props
    const { back, title, message, fireRedirect, index, nextText, nextArrow } = this.state

    return (
      <div className="modal storytelling">
        {back && (
          <Header backUrl="#" onBackClick={this.handlePrev} />
        )}

        <div className="storytelling-content">
          <div className="modal-content">
            <div className="avatar-circle">
              <Vigo />
            </div>
            {title && <h2>{title}</h2>}
            <p>{message}</p>
          </div>

          <div className="storytelling-bullets">
            <ul>
              {messages.map((_message, n) => (
                <li key={n} className={n === index ? "active" : ""} />
              ))}
            </ul>
          </div>

          <BtnPrimary color="blue" to="#" value={nextText} arrow={nextArrow} onClick={this.handleNext} />
        </div>

        {fireRedirect && <Redirect to={redirectTarget} />}
      </div>
    )
  }
}

export default Storytelling
