import floor from "planets/imagite/imagite-checkpoint.svg"
import areaStone from "planets/imagite/imagite-area-stone.svg"

export default {
  mainColor: "#9f72ae",
  secondaryColor: "#ffffff",
  floor: {
    image: floor,
    height: 230
  },
  stone: {
    image: areaStone,
    width: 90,
    height: 127,
    left: 40
  }
}
