import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { withPlanet } from "planet-context"

const StyledElements = styled.div`
  width: 405px;
  position: relative;
`

const StyledScenario = styled.div`
  background: url(${props => props.scenario}) no-repeat center top;
  background-size: auto 100%;
  width: 100%;
  height: 818px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`

const Scenario = ({ children, planet }) => (
  <StyledScenario scenario={planet.trail.scenario}>
    <StyledElements>{children}</StyledElements>
  </StyledScenario>
)

Scenario.propTypes = {
  children: PropTypes.array.isRequired,
  planet: PropTypes.object.isRequired
}

export default withPlanet(Scenario)
