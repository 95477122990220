import { Link } from "react-router-dom"
import styled from "styled-components"
import { withGalaxy } from "galaxy-context"

const StyledGameControl = styled(Link)`
  background: url(${props => props.galaxy.gameControl}) no-repeat;
  background-size: contain;
  width: 100px;
  height: 80px;
  transform: rotate(35deg);
`

const GameControl = withGalaxy(StyledGameControl)

export { GameControl, StyledGameControl }
