import React from "react"
import styled from "styled-components"
import Planet from "Galaxy/wrappers/Planet"
import PlanetImage from "Galaxy/wrappers/PlanetImage"
import { withGalaxy } from "galaxy-context"

const MilkyWay = ({ galaxy, ...props }) => (
  <Planet {...props}>
    <PlanetImage src={galaxy.milkyWay} />
  </Planet>
)

const withStyles = WrappedComponent => styled(WrappedComponent)``

export default withStyles(withGalaxy(MilkyWay))
