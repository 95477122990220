import AuthenticatedRoute from "helpers/authenticated-route"
import OnboardingAvatarStorytelling from "onboarding/avatar/storytelling"
import OnboardingAvatarChoose from "onboarding/avatar/choose"
import OnboardingAvatarConfirm from "onboarding/avatar/confirm"

const Routes = [
  {
    router: AuthenticatedRoute, 
    path: "/criar-conta/historia",
    component: OnboardingAvatarStorytelling
  },
  {
    router: AuthenticatedRoute, 
    path: "/criar-conta/escolher-avatar",
    component: OnboardingAvatarChoose
  },
  {
    router: AuthenticatedRoute, 
    path: "/criar-conta/avatar-escolhido",
    component: OnboardingAvatarConfirm
  },
]

export default Routes
