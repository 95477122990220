import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import { Layout } from "Avatar"
import { findAvatar } from "components/avatar"
import { BtnPrimary } from "components/button"
import { Header } from "Header"
import { BottomCurve } from "components/bottom-curve"
import API from "api"
import NextStepRouter from "onboarding/next-step-router"
import { updateCurrentUser } from "actions"
import { MixpanelTrack, MixpanelPeopleSet, MixpanelSignupEvent } from "tags/mixpanel"
import { PixelTrack } from "tags/pixel"
import { validateAvatarAccess } from "onboarding/avatar/validation"

class OnboardingAvatarConfirm extends React.Component {
  constructor (props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount () {
    validateAvatarAccess(this.props.dispatch)
    PixelTrack("Confirmar Avatar")
  }

  handleClick (event) {
    const { dispatch, selectedAvatar } = this.props

    API.put("/accounts", { avatar: selectedAvatar }, response => {
      const { next_step: nextStep } = response.headers
      const { user, relative } = response.data
      dispatch(updateCurrentUser(user, relative))

      MixpanelPeopleSet({
        avatar: selectedAvatar
      })

      MixpanelTrack("Escolheu avatar", {
        "AVATAR ESCOLHIDO": selectedAvatar
      })

      if (nextStep == "enjoying") {
        MixpanelSignupEvent(user, relative)
      }

      const redirectTarget = NextStepRouter(nextStep)
      dispatch(push(redirectTarget))
    })

    event.preventDefault()
  }

  render () {
    const { component: UserAvatar, name } = findAvatar(this.props.selectedAvatar)

    return (
      <Layout>
        <Header backUrl="/criar-conta/escolher-avatar" />

        <h2 className="big dotted">
          Escolha seu <strong>Avatar</strong>
        </h2>

        <BottomCurve>
          <div className="chosen-avatar">
            <UserAvatar style={{ height: "200px" }} />
            <h2 className="avatar-slide-name big">{name}</h2>
          </div>

          <BtnPrimary to="#" value="Comece a explorar" onClick={this.handleClick} />
        </BottomCurve>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    selectedAvatar: state.selectedAvatar,
    currentUser: state.auth.currentUser
  }
}

export default connect(mapStateToProps)(OnboardingAvatarConfirm)
