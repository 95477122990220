import React from "react"
import { connect } from "react-redux"
import { goBack } from "connected-react-router"
import { Header, HeaderLink } from "Header"
import EssayUploadInstructions from "components/exams/essay-upload-instructions"
import EssayUploadConfirmation from "components/exams/essay-upload-confirmation"
import InvalidFileModal from "components/modals/invalid-file-modal"
import EssayRulesModal from "components/modals/essay-rules-modal"
import EssayTimerChannel from "exam/EssayTimerChannel"
import { Upload } from "helpers/file-uploader"
import { getEssaySession, clearEssaySession } from "exam/essay-actions"

class EssayUpload extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      file: null,
      imagePreviewUrl: null,
      showErrorModal: false,
      showRulesModal: false
    }

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount () {
    const { essaySessionId } = this.props

    if (!essaySessionId) {
      return
    }

    this.props.dispatch(getEssaySession(essaySessionId))
  }

  componentWillUnmount () {
    this.props.dispatch(clearEssaySession())
  }

  handleChange (e) {
    if (e.target.files.length == 0) {
      return
    }

    const file = e.target.files[0]

    Upload(file, this.uploadResult).then(
      data => {
        this.setState({
          file: file,
          imagePreviewUrl: data,
          showErrorModal: false
        })
      },
      () => {
        this.setState({
          imagePreviewUrl: null,
          showErrorModal: true
        })
      }
    )

    e.preventDefault()
  }

  render () {
    const { essayTitle, essaySessionId, pausable, examTimerId } = this.props

    if (!essaySessionId) {
      this.props.dispatch(goBack())
      return null
    }

    const { file, showErrorModal, showRulesModal, imagePreviewUrl } = this.state

    return (
      <div className="essay-upload">
        {pausable === false && examTimerId && <EssayTimerChannel examTimerId={examTimerId} />}
        <InvalidFileModal opened={showErrorModal} onClose={() => this.setState({ showErrorModal: false })} />

        <EssayRulesModal
          essayTitle={essayTitle}
          opened={showRulesModal}
          onClose={() => this.setState({ showRulesModal: false })}
        />

        <Header backUrl="/simulados/redacao/tema" color="colored" backLabel="Tema">
          <HeaderLink to="#" onClick={() => this.setState({ showRulesModal: true })}>
            Regras
          </HeaderLink>
        </Header>

        <div className="essay-upload-container">
          {imagePreviewUrl === null ? (
            <EssayUploadInstructions essayTitle={essayTitle} onChange={this.handleChange} />
          ) : (
            <EssayUploadConfirmation
              essaySessionId={essaySessionId}
              file={file}
              imagePreviewUrl={imagePreviewUrl}
              changeImage={() => this.setState({ imagePreviewUrl: null, file: null })}
            />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    essaySessionId: state.currentEssayReducer.essaySessionId,
    essayTitle: state.currentEssayReducer.essayTitle,
    pausable: state.essaySessionReducer.pausable,
    examTimerId: state.essaySessionReducer.examTimerId
  }
}

export default connect(mapStateToProps)(EssayUpload)
