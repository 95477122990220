import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import { Header } from "Header"
import QuestionsSelector from "components/questions-selector"
import { BtnPrimary, BtnLink } from "components/button"
import ExamQuestion from "components/exams/question"
import InlineFixedClock from "components/inline-fixed-clock"
import API from "api"

const ExamTitle = ({ title, correctAnswers, wrongAnswers, timeSpent }) => (
  <div className="exam-answer-title">
    <h2 className="light">{title}</h2>
    <div className="answer-key-stats">
      <i className="check-icon" />
      <strong>{correctAnswers}</strong>
      <i className="error-icon">x</i>
      <strong>{wrongAnswers}</strong>
      <InlineFixedClock timeSpent={timeSpent} />
    </div>
  </div>
)

class AnswerKey extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      totalQuestions: 0,
      current: null,
      title: "",
      questions: [],
      timeSpent: 0,
      wrongAnswers: 0,
      correctAnswers: 0
    }

    this.handleQuestionSelector = this.handleQuestionSelector.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handlePrevious = this.handlePrevious.bind(this)
  }

  UNSAFE_componentWillMount () {
    const { state } = this.props.location

    if (state) {
      this.questionSetSessionId = state.questionSetSessionId
    } else {
      console.error("[exam/answer-key] No state, dead inside!")
      this.redirectToStation()
    }
  }

  componentDidMount () {
    if (!this.questionSetSessionId) {
      return
    }

    API.get(`/answer_key/${this.questionSetSessionId}`, response => {
      const {
        total_questions: totalQuestions,
        miniexam_name: title,
        time_spent: timeSpent,
        questions: questions
      } = response.data

      const correctAnswers = questions.filter(q => q.correctly_answered).length
      const wrongAnswers = totalQuestions - correctAnswers

      this.setState({ totalQuestions, title, questions, timeSpent, correctAnswers, wrongAnswers }, () =>
        this.goToTheNextOne()
      )
    })
  }

  redirectToStation () {
    this.props.dispatch(push("/simulados"))
  }

  getCurrentIndex () {
    const { questions, current } = this.state
    return questions.findIndex(q => q.id === current)
  }

  goToTheNextOne () {
    const { questions } = this.state

    const currentIndex = this.getCurrentIndex()
    let nextQuestionIndex = 0
    if (currentIndex + 1 !== questions.length) {
      nextQuestionIndex = currentIndex + 1
    }
    const nextQuestion = questions[nextQuestionIndex].id

    this.setState({ current: nextQuestion })
  }

  handleQuestionSelector (e) {
    this.setState({ current: e.target.dataset.question })
  }

  handlePrevious (e) {
    const { questions } = this.state

    const currentIndex = this.getCurrentIndex()
    let previousQuestionIndex = 0
    if (currentIndex !== 0) {
      previousQuestionIndex = currentIndex - 1
    } else {
      previousQuestionIndex = questions.length - 1
    }

    const previousQuestion = questions[previousQuestionIndex].id

    this.setState({ current: previousQuestion })
    e.preventDefault()
  }

  handleNext (e) {
    this.goToTheNextOne()
    e.preventDefault()
  }

  render () {
    const { questions, totalQuestions, title, current, timeSpent, correctAnswers, wrongAnswers } = this.state

    return (
      <div className="fullscreen-modal-wrapper">
        <Header backUrl="/simulados" backLabel="Estação de Avaliações" color="colored" />

        <ExamTitle title={title} correctAnswers={correctAnswers} wrongAnswers={wrongAnswers} timeSpent={timeSpent} />

        <QuestionsSelector questions={questions} current={current} onClick={this.handleQuestionSelector} answerKey />

        <hr />

        <div className="exam-question">
          <h3>
            Questão {this.getCurrentIndex() + 1} de {totalQuestions}
          </h3>

          {questions.map((question, index) => {
            const { id } = question

            return (
              <div key={index} style={{ display: current === id ? "block" : "none" }}>
                <ExamQuestion question={question} alternativesDisabled />

                <div className="buttons-container">
                  <BtnLink to="#" value="Questão anterior" onClick={this.handlePrevious} />
                  <BtnPrimary to="#" value="Proxima questão" onClick={this.handleNext} />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default connect()(AnswerKey)
