import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import {
  setDailyMissionQuestions,
  setDailyMissionCorrectAmount,
  setDailyMissionWrongAmount,
  setDailyMissionExperience,
  setDailyMissionRuby
} from "actions"
import API from "api"
import Service from "Service"
import { connectToChannel } from "socket-connect"
import { MixpanelTrack } from "tags/mixpanel"
import Processing from "components/processing"
import AnswerForm from "daily-mission/AnswerForm"

class QuestionAnswer extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      answerSessionClosed: false
    }

    this.syncAnswer = this.syncAnswer.bind(this)
  }

  componentDidMount () {
    this.fetchDailyMissionQuestions()
    this.subscribeToAnswerSession()
  }

  fetchDailyMissionQuestions () {
    const { dispatch } = this.props

    Service.getDailyMissionQuestions(response => {
      dispatch(setDailyMissionQuestions(response.data.question_answers))
      dispatch(setDailyMissionCorrectAmount(response.data.correct_count))
      dispatch(setDailyMissionWrongAmount(response.data.wrong_count))
      dispatch(setDailyMissionExperience(response.data.experience))
      dispatch(setDailyMissionRuby(response.data.ruby))
    })
  }

  subscribeToAnswerSession () {
    const { dispatch } = this.props

    const channel = connectToChannel("daily_mission")

    if (!channel) return

    channel.join().receive("error", () => {
      dispatch(push("/"))
    })

    channel.on("close_answer_sessions", () => {
      this.setState({ answerSessionClosed: true })
    })
  }

  syncAnswer (knowledgeArea, alternative) {
    const { name, value } = alternative

    MixpanelTrack("Respondeu Questão de Missão Diária", {
      "ÁREA DA QUESTÃO": knowledgeArea
    })

    API.put(`/daily_mission/question_answers/${name}`, { alternative_name: value }, () => {
      this.fetchDailyMissionQuestions()
    })
  }

  handleFinalization () {
    MixpanelTrack("Concluiu Missão Diária")
  }

  render () {
    const { answerSessionClosed } = this.state
    const { dailyMissionQuestions } = this.props
    const selected = this.props.location.state ? this.props.location.state.selected : 0

    if (typeof dailyMissionQuestions === "undefined" || dailyMissionQuestions.length == 0) {
      return <Processing />
    }

    return (
      <AnswerForm
        questions={dailyMissionQuestions}
        answerSessionClosed={answerSessionClosed}
        syncAnswer={this.syncAnswer}
        handleFinalization={this.handleFinalization}
        urlOnFinalization="/missao-diaria/finalizada"
        selected={selected}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    dailyMissionQuestions: state.dailyMissionQuestions.questions
  }
}

export default connect(mapStateToProps)(QuestionAnswer)
