import styled from "styled-components"

const Tooltip = styled.div`
  background-color: #ffffff;
  color: #8e3fac;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 18px;
  line-height: 1.2em;
  text-align: center;
  padding: 5px 15px;
  display: inline-block;
  border-radius: 20px;
  position: relative;

  &::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-color: #ffffff transparent transparent;
    border-width: 10px 10px 0;
    position: absolute;
    bottom: -9px;
    left: 50%;
    margin-left: -10px;
  }
`

export default Tooltip
