import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import dateFormat from "date-fns/format"
import parseDate from "date-fns/parseISO"
import { Layout } from "Session"
import Modal from "components/modal"
import Form from "components/form/form"
import { Submit } from "components/button"
import FormRadio from "components/form/form-radio"
import API from "api"
import NextStepRouter from "onboarding/next-step-router"
import PaymentMethods from "components/payment-methods"
import { MixpanelPeopleSet } from "tags/mixpanel"
import CreditcardForm from "onboarding/order/CreditcardForm"
import CreditCard from "creditcard.svg"
import Boleto from "boleto.svg"

class OnboardingPayment extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      submitDisabled: false,
      payment_method: "",
      discountValue: "",
      totalValue: "",
      changePaymenMethodDate: "...",
      installmentsOptions: [],
      errors: []
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  componentDidMount () {
    const { dispatch } = this.props

    API.get(
      "/checkout/payment",
      response => {
        const { data, headers } = response
        const allowedSteps = ["checkoutCreditcardError", "checkoutPayment"]

        if (allowedSteps.includes(headers.next_step)) {
          const {
            change_payment_method_date,
            discount_value: discountValue,
            total_value: totalValue,
            installments_options: installmentsOptions
          } = data

          const changePaymenMethodDate = dateFormat(parseDate(change_payment_method_date), "dd/MM/yyyy")
          this.setState({ discountValue, totalValue, installmentsOptions, changePaymenMethodDate })
        } else {
          const redirectTarget = NextStepRouter(headers.next_step)
          dispatch(push(redirectTarget))
        }
      },
      () => {
        dispatch(push("/pedido/compra"))
      }
    )
  }

  handleInputChange (event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSubmit (event) {
    const { dispatch } = this.props
    const { totalValue, submitDisabled } = this.state

    if (submitDisabled) return

    this.setState({ submitDisabled: true })

    API.post(
      "/checkout/payment",
      { payment_method: "boleto" },
      response => {
        MixpanelPeopleSet({ "forma de pagamento": "boleto", "valor pago": totalValue })

        const redirectTarget = NextStepRouter(response.headers.next_step)
        dispatch(push(redirectTarget))
      },
      error => {
        const errors = error.response.data["errors"]
        this.setState({ errors, submitDisabled: false })
      }
    )

    event.preventDefault()
  }

  render () {
    const {
      errors,
      payment_method,
      discountValue,
      totalValue,
      installmentsOptions,
      changePaymenMethodDate,
      submitDisabled
    } = this.state

    return (
      <Layout>
        <Modal backUrl="/pedido/compra">
          <h2>Pagamento</h2>

          <Form onSubmit={this.handleSubmit}>
            <h3>Resumo do pagamento</h3>

            <div className="invoice">
              {discountValue !== 0 && (
                <div className="invoice-row">
                  Cupom de desconto <span className="invoice-value green">- {discountValue}</span>
                </div>
              )}

              <div className="invoice-row">
                <strong>Total</strong> <span className="invoice-value purple">{totalValue}</span>
              </div>
            </div>

            <h3 className="payment-method-label">Forma de pagamento</h3>

            <div>
              <strong>Atenção:</strong> Não há diferença de valor entre os meios de pagamento.
            </div>

            <FormRadio
              containerClassName="form-group-payment"
              id="payment_method_creditcard"
              name="payment_method"
              value="creditcard"
              onChange={this.handleInputChange}
              errors={errors}
              required
            >
              <img src={CreditCard} />
              <div>
                <span>Cartão de crédito</span>
              </div>
            </FormRadio>

            <hr />

            <FormRadio
              containerClassName="form-group-payment"
              id="payment_method_boleto"
              name="payment_method"
              value="boleto"
              onChange={this.handleInputChange}
              errors={errors}
              required
            >
              <img src={Boleto} />
              <div>
                <span>Boleto</span>
                <br />
                <small>
                  Pagamento à vista. Você poderá alterar a forma de pagamento dia{" "}
                  <strong>{changePaymenMethodDate}</strong>.
                </small>
              </div>
            </FormRadio>

            {payment_method && payment_method !== "creditcard" && (
              <Submit value="Enviar" disabled={submitDisabled} arrow />
            )}
          </Form>

          {payment_method === "creditcard" && (
            <CreditcardForm installmentsOptions={installmentsOptions} totalValue={totalValue} />
          )}

          <PaymentMethods />
        </Modal>
      </Layout>
    )
  }
}

export default connect()(OnboardingPayment)
