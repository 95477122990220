import React from "react"
import Modal from "components/modal"
import { PoppyMoneyShip } from "components/avatar"

const Processing = ({ children }) => (
  <div className="session-container">
    <Modal color="purple">
      <PoppyMoneyShip />
      <h2 className="big">Processando...</h2>
      {children}
    </Modal>
  </div>
)

export default Processing
