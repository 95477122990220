import React from "react"
import styled from "styled-components"
import Planet from "Galaxy/wrappers/Planet"
import PlanetImage from "Galaxy/wrappers/PlanetImage"
import roya from "galaxies/purple-galaxy/roya.svg"

const Roya = props => (
  <Planet {...props}>
    <PlanetImage src={roya} blocked={props.blocked} />
  </Planet>
)

export default styled(Roya)``
