const NextStepRouter = step => {
  const StepLocationMatch = {
    signUpWaitingNextYear: "/criar-conta/sucesso",
    avatarRegistration: "/criar-conta/historia",
    checkout: "/pedido",
    checkoutPayment: "/pedido/pagamento",
    checkoutBoleto: "/pedido/boleto",
    checkoutCreditcardProcessing: "/pedido/cartao/processando",
    checkoutCreditcardError: "/pedido/cartao/erro",
    checkoutCreditcardWaiting: "/pedido/cartao/aguardando",
    checkoutCreditcardWaitingNextYear: "/pedido/cartao/aguardando",
    checkoutCompleted: "/pedido/concluido",
    checkoutCompletedNextYear: "/pedido/concluido",
    validateActivationCode: "/validar-conta",
    enjoying: "/galaxia"
  }

  return StepLocationMatch[step]
}

export default NextStepRouter
