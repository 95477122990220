import {
  Abalone,
  Octi,
  Damas,
  Quoridor,
  Hidden,
  Mancala,
  Moonwalk,
  CodeBreaker,
  Diamonds,
  Wizards,
  TreasureIsland,
  RedCard,
  BattleSubmarine,
  Domino,
  Gamao,
  MiniBridge,
  Perudo,
  RushHour,
  Yams,
  Mindsters,
  TicTacToe,
  WolfAndSheep
} from "GamesStation/games"

const MAKER_TYPES = {
  BGA: "BGA",
  MARKETJS: "MARKETJS"
}

const SHARED_MODE = {
  description:
    "Cada partida é jogada contra outro usuário online sorteado aleatoriamente. Não é possível escolher ou convidar um usuário para jogar.",
  playAction: "Jogar",
  findsPlayer: true
}

const SOLO_MODE = {
  description: "Jogo de tabuleiro sem a necessidade de aguardar por oponentes e/ou parceiro. Cada partida é jogada contra oponentes digitais.",
  playAction: "Jogar",
  findsPlayer: false
}

const DETAILS = [
  {
    id: "abalone",
    title: "Abalone",
    url: "abalone",
    component: Abalone,
    mode: SHARED_MODE,
    goal: "Empurrar 6 (seis) esferas do seu oponente para fora do tabuleiro (pela borda).",
    instructions: require("./instructions/abalone").default
  },
  {
    id: "checkers",
    title: "Damas Olímpicas",
    url: "checkers",
    component: Damas,
    mode: SHARED_MODE,
    goal: "Imobilizar as peças do oponente (normalmente a maioria das peças foi capturada).",
    instructions: require("./instructions/checkers").default
  },
  {
    id: "quoridor",
    title: "Quoridor",
    url: "quoridor",
    component: Quoridor,
    mode: SHARED_MODE,
    goal: "Ser o primeiro a chegar ao lado oposto do tabuleiro.",
    instructions: require("./instructions/quoridor").default
  },
  {
    id: "octi",
    title: "Octi",
    url: "octi",
    component: Octi,
    mode: SHARED_MODE,
    goal: "Ser o primeiro jogador a colocar uma de suas peças na base de seu oponente.",
    instructions: require("./instructions/octi").default
  },
  {
    id: "mancala",
    title: "Mancala",
    subtitle: "Jogo do 2º ano EFI",
    url: "mancala",
    component: Mancala,
    mode: SHARED_MODE,
    goal: "Acumular mais sementes que o oponente no seu depósito (kalaha).",
    instructions: require("./instructions/mancala").default
  },
  {
    id: "hidden",
    title: "Escondidos",
    subtitle: "Jogo do 3º ano EFI",
    url: "escondidos",
    component: Hidden,
    mode: SHARED_MODE,
    goal: "Ser o primeiro jogador a descobrir o esconderijo da peça do oponente.",
    setup:
      "Cada jogador escolhe em qual casa do tabuleiro colocará sua peça (somente as casas verdes). A peça não pode ser colocada sobre nenhuma das figuras impressas no tabuleiro.",
    instructions: require("./instructions/hidden").default
  },
  {
    id: "treasureisland",
    title: "Ilha do Tesouro",
    subtitle: "Jogo do 4º ano EFI",
    url: "ilha-do-tesouro",
    component: TreasureIsland,
    mode: SOLO_MODE,
    maker: MAKER_TYPES.BGA,
    goal: "Levar o pirata à arca do tesouro utilizando o número exato de passos.",
    instructions: require("./instructions/treasureisland").default
  },
  {
    id: "codebreaker",
    title: "Code Breaker",
    subtitle: "Jogo do 5º ano EFI",
    url: "code-breaker",
    component: CodeBreaker,
    mode: SHARED_MODE,
    goal: "Ser o primeiro jogador a decifrar o código de seu oponente, que é formado por quatro algarismos.",
    instructions: require("./instructions/codebreaker").default
  },
  {
    id: "redcard",
    title: "Cartão Vermelho",
    subtitle: "Jogo do 6º ano EFII",
    url: "cartao-vermelho",
    component: RedCard,
    mode: SHARED_MODE,
    goal: "Juntar 6 cartas com a mesma característica de uma das suas 3 cartas-objetivo.",
    instructions: require("./instructions/redcard").default
  },
  {
    id: "wizards",
    title: "Wizards",
    subtitle: "Jogo do 7º ano EFII",
    url: "wizards",
    component: Wizards,
    mode: SHARED_MODE,
    goal: "Ser o jogador a vencer o maior número de rodadas.",
    instructions: require("./instructions/wizards").default
  },
  {
    id: "moonwalk",
    title: "Moonwalk",
    subtitle: "Jogo do 8º ano EFII",
    url: "moonwalk",
    component: Moonwalk,
    mode: SHARED_MODE,
    goal: "Terminar o jogo com a maioria das peças do tabuleiro na sua cor.",
    instructions: require("./instructions/moonwalk").default
  },
  {
    id: "diamonds",
    title: "Diamonds",
    subtitle: "Jogo do 9º ano EFII",
    url: "diamonds",
    component: Diamonds,
    mode: SHARED_MODE,
    goal: "Ser o jogador com o maior número de diamantes colocados no tabuleiro.",
    instructions: require("./instructions/diamonds").default
  },
  {
    id: "dudo",
    title: "Perudo",
    subtitle: "",
    url: "dudo",
    component: Perudo,
    mode: SHARED_MODE,
    goal: "Ser o último jogador com um ou mais dados.",
    setup: "Cada jogador recebe um copo e 5 dados.",
    instructions: require("./instructions/dudo").default
  },
  {
    id: "yams",
    title: "Yams",
    subtitle: "",
    url: "yams",
    component: Yams,
    mode: SOLO_MODE,
    maker: MAKER_TYPES.MARKETJS,
    goal: "Marcar o maior número de pontos em diversas categorias, usando os dados.",
    setup: "Componentes do jogo: 1 copo e 5 dados",
    instructions: require("./instructions/yams").default
  },
  {
    id: "gamao",
    title: "Gamão",
    subtitle: "",
    url: "gamao",
    component: Gamao,
    mode: SOLO_MODE,
    maker: MAKER_TYPES.MARKETJS,
    goal: "Ser o primeiro jogador a tirar todas as suas peças do tabuleiro.",
    setup: "Componentes do jogo: 1 tabuleiro com 24 casas, 15 peças escuras e 15 peças claras",
    instructions: require("./instructions/gamao").default
  },
  {
    id: "domino",
    title: "Dominó",
    subtitle: "",
    url: "domino",
    component: Domino,
    mode: SOLO_MODE,
    maker: "MARKETJS",
    goal: "Fazer o maior número de pontos.",
    instructions: require("./instructions/domino").default
  },
  {
    id: "rushhour",
    title: "Hora do Rush",
    subtitle: "",
    url: "rush-hour",
    component: RushHour,
    mode: SOLO_MODE,
    maker: MAKER_TYPES.MARKETJS,
    goal: "Tirar o carro vermelho do estacionamento.",
    instructions: require("./instructions/rushhour").default
  },
  {
    id: "minibridge",
    title: "Mini Bridge",
    subtitle: "",
    url: "mini-bridge",
    component: MiniBridge,
    mode: SOLO_MODE,
    maker: MAKER_TYPES.MARKETJS,
    goal: "Ser o jogador a acumular mais pontos.",
    instructions: require("./instructions/minibridge").default
  },
  {
    id: "battlesubmarine",
    title: "Batalha dos Submarinos",
    subtitle: "",
    url: "battle-submarine",
    component: BattleSubmarine,
    mode: SOLO_MODE,
    maker: MAKER_TYPES.MARKETJS,
    goal: "Ser o primeiro a afundar toda a frota do oponente no tabuleiro.",
    instructions: require("./instructions/battlesubmarine").default
  },
  {
    id: "wolfandsheep",
    title: "Lobo e ovelhas",
    subtitle: "",
    url: "wolfandsheep",
    component: WolfAndSheep,
    mode: SHARED_MODE,
    maker: MAKER_TYPES.BGA,
    goal: `Lobo: chegar até a última linha do tabuleiro.
    \t Ovelhas: cercar e aprisionar o lobo, de maneira que ele não possa se mover.
    `,
    instructions: require("./instructions/wolfandsheep").default
  },
  {
    id: "mindsters",
    title: "Mindsters",
    subtitle: "",
    url: "mindsters",
    component: Mindsters,
    mode: SHARED_MODE,
    goal: "Ser o primeiro jogador a conquistar quatro cartas da mesma família de mindster.",
    instructions: require("./instructions/mindsters").default
  },
  {
    id: "tictactoe",
    title: "Tic Tac Toe",
    subtitle: "",
    url: "tictactoe",
    component: TicTacToe,
    mode: SHARED_MODE,
    goal: "Ser o primeiro jogador a criar uma sequência com suas peças na horizontal, vertical ou diagonal.",
    instructions: require("./instructions/tictactoe").default
  },
]

const VIDEOS = [
  {
    "id": "abalone",
    "videos": [
      {
        "url": "https://player.vimeo.com/video/134967216?app_id=122963",
        "title": "Forças Unidas (Abalone)",
        "skill": "rule"
      },
      {
        "url": "https://player.vimeo.com/video/134967215?app_id=122963",
        "title": "APLICANDO O MÉTODO AVES MIGRATÓRIAS",
        "skill": "strategy"
      }
    ]
  },
  {
    "id": "codebreaker",
    "videos": [
      {
        "url": "https://player.vimeo.com/video/141178722?app_id=122963",
        "title": "CODE BREAKER",
        "skill": "rule"
      },
      {
        "url": "https://player.vimeo.com/video/141407184?app_id=122963",
        "title": "TIRADA SISTEMÁTICA DE CONCLUSÕE",
        "skill": "strategy"
      },
      {
        "url": "https://player.vimeo.com/video/142273793?app_id=122963",
        "title": "CODE_BREAKER_CONTEXTUALIZANDO",
        "skill": "context"
      },
    ]
  },
  {
    "id": "checkers",
    "videos": [
      {
        "url": "https://player.vimeo.com/video/133441342?app_id=122963",
        "title": "DAMAS OLÍMPICAS- 2",
        "skill": "rule"
      },
      {
        "url": "https://player.vimeo.com/video/135055667?app_id=122963",
        "title": "APLICANDO O METODO ARVORE DO PENSAMENTO",
        "skill": "strategy"
      }
    ]
  },
  {
    "id": "hidden",
    "videos": [
      {
        "url": "https://player.vimeo.com/video/143864190?app_id=122963",
        "title": "Escondidos_3A1SLIA1VJ_vSimples",
        "skill": "rule"
      },
      {
        "url": "https://player.vimeo.com/video/143192192?app_id=122963",
        "title": "Escondidos_3A1SL3A2VE",
        "skill": "strategy"
      },
      {
        "url": "https://player.vimeo.com/video/145863851?app_id=122963",
        "title": "ESCONDIDOS",
        "skill": "context"
      },
    ]
  },
  {
    "id": "mancala",
    "videos": [
      {
        "url": "https://player.vimeo.com/video/138878750?app_id=122963",
        "title": "MANCALA_AMEACAS_IRREVOGAVEIS",
        "skill": "strategy"
      }
    ]
  },
  {
    "id": "moonwalk",
    "videos": [
      {
        "url": "https://player.vimeo.com/video/135998076?app_id=122963",
        "title": "MOONWALK",
        "skill": "rule"
      },
      {
        "url": "https://player.vimeo.com/video/141539782?app_id=122963",
        "title": "MOONWALK_(AMEACAS_TACITAS)",
        "skill": "strategy"
      }
    ]
  },
  {
    "id": "octi",
    "videos": [
      {
        "url": "https://player.vimeo.com/video/134622190?app_id=122963",
        "title": "OCTÓGONO FANTÁSTICO",
        "skill": "rule"
      },
      {
        "url": "https://player.vimeo.com/video/134622076?app_id=122963",
        "title": "SACRIFÍCIO A CURTO PRAZO",
        "skill": "strategy"
      }
    ]
  },
  {
    "id": "quoridor",
    "videos": [
      {
        "url": "https://player.vimeo.com/video/135274325?app_id=122963",
        "title": "BLOQUEIO",
        "skill": "rule"
      },
      {
        "url": "https://player.vimeo.com/video/135274322?app_id=122963",
        "title": "BLOQUEIO",
        "skill": "strategy"
      }
    ]
  },
  {
    "id": "redcard",
    "videos": [
      {
        "url": "https://player.vimeo.com/video/139741585?app_id=122963",
        "title": "CARTÃO VERMELHO",
        "skill": "rule"
      },
      {
        "url": "https://player.vimeo.com/video/139743830?app_id=122963",
        "title": "PERMANEÇA FOCADO",
        "skill": "strategy"
      }
    ]
  },
  {
    "id": "dudo",
    "videos": [
      {
        "url": "https://player.vimeo.com/video/141178721?app_id=122963",
        "title": "SEMÁFORO",
        "skill": "strategy"
      },
    ]
  },
  {
    "id": "treasureisland",
    "videos": [
      {
        "url": "https://player.vimeo.com/video/145667552?app_id=122963",
        "title": "ILHA DO TESOURO",
        "skill": "rule"
      },
      {
        "url": "https://player.vimeo.com/video/145667550?app_id=122963",
        "title": "APLICANDO O MÉTODO DO SEMÁFORO",
        "skill": "strategy"
      },
      {
        "url": "https://player.vimeo.com/video/146693343?app_id=122963",
        "title": "Ilha do Tesouro",
        "skill": "context"
      },
    ]
  },
  {
    "id": "wizards",
    "videos": [
      {
        "url": "https://player.vimeo.com/video/136858474?app_id=122963",
        "title": "WIZARDS",
        "skill": "rule"
      },
      {
        "url": "https://player.vimeo.com/video/136154455?app_id=122963",
        "title": "EVITANDO CONFLITOS Wizards",
        "skill": "strategy"
      }
    ]
  },
  {
    "id": "diamonds",
    "videos": [
      {
        "url": "https://player.vimeo.com/video/137882826?app_id=122963",
        "title": "Diamonds",
        "skill": "rule"
      },
      {
        "url": "https://player.vimeo.com/video/139266198?app_id=122963",
        "title": "Diamonds - Minimizando Riscos",
        "skill": "strategy"
      }
    ]
  },
  {
    "id": "wolfandsheep",
    "videos": [
      {
        "url": "https://player.vimeo.com/video/141819289?app_id=122963",
        "title": "LOBO_E_OVELHAS",
        "skill": "rule"
      },
      {
        "url": "https://player.vimeo.com/video/141951691?app_id=122963",
        "title": "CONTEXTUALIZANDO",
        "skill": "context"
      }
    ]
  },
  {
    "id": "mindsters",
    "videos": [
      {
        "url": "https://player.vimeo.com/video/145413795?app_id=122963",
        "title": "MINDSTERS",
        "skill": "rule"
      },
      {
        "url": "https://player.vimeo.com/video/146304492",
        "title": "CONTEXTUALIZANDO",
        "skill": "context"
      }
    ]
  },
  {
    "id": "tictactoe",
    "videos": [
      {
        "url": "https://player.vimeo.com/video/144014075",
        "title": "TIC TAC TOE",
        "skill": "rule"
      },
      {
        "url": "https://player.vimeo.com/video/144743580",
        "title": "CONTEXTUALIZANDO",
        "skill": "context"
      },
      {
        "url": "https://player.vimeo.com/video/146690007",
        "title": "CONTEXTUALIZANDO",
        "skill": "context"
      }
    ]
  },
  {
    "id": "yams",
    "videos": [
      {
        "url": "https://player.vimeo.com/video/139742332?app_id=122963",
        "title": "Método da Árvore do Pensamento",
        "skill": "strategy"
      }
    ]
  },
  {
    "id": "domino",
    "videos": [
      {
        "url": "https://player.vimeo.com/video/135397432?app_id=122963",
        "title": "AUMENTANDO AS SUAS CHANCES DOMINÓ",
        "skill": "strategy"
      }
    ]
  },
  {
    "id": "gamao",
    "videos": [
      {
        "url": "https://player.vimeo.com/video/145508467?app_id=122963",
        "title": "VERSÃO COMPLETA",
        "skill": "rule"
      },
      {
        "url": "https://player.vimeo.com/video/144870027?app_id=122963",
        "title": "BASES SEGURAS",
        "skill": "strategy"
      },
      {
        "url": "https://player.vimeo.com/video/144870028?app_id=122963",
        "title": "CONTROLE DAS CASAS-CHAVE",
        "skill": "strategy"
      }
    ]
  },
  {
    "id": "rushhour",
    "videos": [
      {
        "url": "https://player.vimeo.com/video/136337621?app_id=122963",
        "title": "Hora do Rush",
        "skill": "rule"
      },
      {
        "url": "https://player.vimeo.com/video/137627638?app_id=122963",
        "title": "Hora do Rush - Tentativa e erro",
        "skill": "context"
      },
      {
        "url": "https://player.vimeo.com/video/137515208?app_id=122963",
        "title": "CONTEXTUALIZANDO",
        "skill": "context"
      }
    ]
  },
  {
    "id": "minibridge",
    "videos": [
      {
        "url": "https://player.vimeo.com/video/139247429?app_id=122963",
        "title": "MINIBRIDGE ESTAGIO3",
        "skill": "rule"
      },
      {
        "url": "https://player.vimeo.com/video/139280151?app_id=122963",
        "title": "RETENÇAO DE MEMORIA ATRAVÉS DE AGRUPAMENTOS",
        "skill": "strategy"
      }
    ]
  },
  {
    "id": "battlesubmarine",
    "videos": [
      {
        "url": "https://player.vimeo.com/video/141178721?app_id=122963",
        "title": "MÉTODO DO SEMÁFORO",
        "skill": "strategy"
      }
    ]
  }
]

export const extraGames = [
  {
    game_id: "tictactoe",
    players_in_game: 0,
    players_waiting: 0
  }
]

export function findGameById(gameId) {
  return DETAILS.find(d => d.id === gameId)
}

export function findGameByUrl(url) {
  return DETAILS.find(d => d.url === url)
}

export function findVideosById(gameId) {
  return VIDEOS.find(d => d.id === gameId)
}

export { MAKER_TYPES }
