export default [
  {
    title: "Regras básicas",
    description: "",
    items: [
      {
        text: "O jogo é para 3 a 4 jogadores. ",
      },
      {
        text: "São possíveis de serem jogadas duas versões do jogo:",
        items: [
          {
            text: "Versão Espadas de Parceria: jogo para 4 jogadores, na qual se joga em parceria (2 versus 2).",
          },
          {
            text: "Versão Espadas Mortais: jogo para 3 jogadores.",
          }
        ]
      },
      {
        text: "São distribuídas 13 cartas a cada jogador na versão “Espadas Parceiras” e 17 na versão “Espadas Mortais”.",
      },
      {
        text: "Após olhar as cartas que tem na mão (e antes de dar início à primeira rodada), cada jogador escolhe um palpite de quantos truques conseguirá fazer naquela partida.",
      },
      {
        text: "Os palpites são anotados em uma tabela, para posterior contagem de pontos.",
      },
      {
        text: "O jogo acontece no sentido horário. Cada jogador, na sua vez, descarta uma de suas cartas, até que se complete uma rodada. Quatro/três cartas estarão na mesa, compondo um “truque”.",
      },
      {
        text: "O naipe da primeira carta descartada na rodada é chamado de “Naipe Principal”. Ele comanda a rodada: todo jogador deve colocar uma carta desse naipe. Só é permitido descartar uma carta de outro naipe no caso do jogador não ter nenhuma carta do Naipe Principal. É proibido blefar.",
      },
      {
        text: "No final de cada rodada, o jogador que descartou a carta do Naipe Principal (ou o trunfo) de maior valor ganha este “truque” e o recolhe, mantendo-o fechado perto de si. O vencedor de uma rodada dá início à rodada seguinte. Ao final das 13 ou 17 rodadas, cada jogador conta os “truques” obtidos e marca sua pontuação em uma tabela, segundo as regras de pontuação.",
      },
      {
        text: "Nessas versões do jogo as cartas do naipe de espadas são os trunfos.",
      }
    ]
  },
  {
    title: "Regras de pontuação",
    description: "",
    items: [
      {
        text: "No início do jogo, o jogador deve fazer uma aposta de quantas rodadas pretende ganhar. Caso opte por fazer uma aposta nula (zero), esta poderá ser feita de duas formas:",
        items: [
          {
            text: "Sem visualizar as suas cartas (“às cegas”): a qual se honrada lhe resultará em 200 pontos. Caso não cumpra, este receberá penalidade de igual valor. Optando em fazer uma aposta nula, é preciso clicar no botão “0 às cegas”, o palpite(zero) será registrado sem ser possível visualizar as cartas antes.",
          },
          {
            text: "Olhando as cartas e optando pelo zero: quando honrado esse palpite, o jogador receberá 100 pontos. Caso não cumpra com o combinado, receberá penalidade de igual valor. Caso o jogador opte em fazer uma aposta olhando suas cartas, este poderá fazê- la, clicando em “aposta” e escolhendo o número que desejar(0 a 13 na versão espadas parceiras e de 0 a 17 na versão espadas mortais).",
          }
        ]
      },
      {
        text: "As sacolas representam a quantidade de truques realizados a mais do que o combinado. A cada 10 sacolas acumuladas o jogador receberá uma penalidade de 100 pontos.",
      },
      {
        text: "A soma dos pontos ocorre da seguinte forma: (nº da aposta) x 10 + (nº de sacolas).",
      },
      {
        text: "Caso o jogador cumpra menos truques do que o combinado, o cálculo da pontuação deve ser feito da seguinte forma: (nº da aposta) x (-10).",
      }
    ]
  }
]