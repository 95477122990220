import React from "react"
import { Header } from "Header"
import { GamificationBar } from "GamificationBar"
import { ScrollUp } from "helpers/scroll"
import { withGalaxy } from "galaxy-context"

const PlayToWinXp = ({ galaxy }) => {
  ScrollUp()

  return (
    <div className="bag-manual-about-container">
      <Header backUrl="/mochila/mural" color="colored">
        <GamificationBar />
      </Header>
      <h1 className="purple">Partidas de jogos valem XP?</h1>
      <img src={galaxy.gameControl} />
      <p>Sim! Se você é um aluno que possui a Estação de Jogos, você pode ganhar ainda mais experiência jogando!</p>
      <p>
        Você pode jogar quantas partidas quiser, mas apenas as 3 primeiras de cada dia valem XP. Assim como nós, os
        avatares do Mindzup também adquirirem conhecimento um pouquinho por dia ;)
      </p>
    </div>
  )
}

export default withGalaxy(PlayToWinXp)
