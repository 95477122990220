import floor from "planets/union/union-area-floor.svg"
import stone from "planets/union/union-area-stone.svg"

export default {
  mainColor: "#c9fff5",
  secondaryColor: "#ffffff",
  floor: {
    image: floor,
    height: 152
  },
  stone: {
    image: stone,
    width: 72,
    height: 99,
    left: 40
  }
}
