/*
  Returns an array with `count` `undefined` values

  Example:
    |> Times(3)
    |> [undefined, undefined, undefined]

*/
export function Times (count) {
  return count > -1 ? Array(count).fill() : []
}

/*
  Returns an array with `slice` values. The values are integer and
  sorted descending.

  Example:
    |> ReverseArraySlice(10, 3)
    |> [9, 8, 7]

    |> ReverseArraySlice(6, 2)
    |> [5, 4]

*/
export function ReverseArraySlice (length, slice) {
  return Array.from(Array(length).keys()).reverse().slice(0, slice)
}
