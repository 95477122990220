import React from "react"
import { push } from "connected-react-router"
import { connect } from "react-redux"
import { Layout } from "Session"
import { BtnPrimary } from "components/button"
import Modal from "components/modal"
import { logout } from "actions"
import { MixpanelTrack, MixpanelReset } from "tags/mixpanel"
import startOfYear from "date-fns/startOfYear"
import dateFormat from "date-fns/format"
import API from "api"

class OnboardingOrderSuccessNextYear extends React.Component {
  constructor (props) {
    super(props)
    this.handleSignOut = this.handleSignOut.bind(this)
  }

  handleSignOut (event) {
    event.preventDefault()
    const { dispatch } = this.props

    API.delete("/sessions", () => {
      MixpanelTrack("Efetuou logout")
      MixpanelReset()

      dispatch(logout())
      dispatch(push("/"))
    })
  }
  render () {
    const nextYear = new Date().getFullYear() + 1
    const startDate = dateFormat(startOfYear(new Date(nextYear, 0, 1)), "dd/MM/yyyy")

    return (
      <Layout>
        <Modal color="purple">
          <h2>Pagamento realizado com sucesso!</h2>

          <p>Identificamos no nosso sistema que seu pagamento já foi concluído</p>
          <p>
            Como o ano letivo de {nextYear} ainda não começou, não podemos liberar seu acesso à plataforma. Para iniciar
            sua jornada no Mindzup, retorne no dia {startDate}
          </p>

          <p>Estaremos aguardando você :)</p>

          <div className="signup-notice-modal username-container">
            <p>
              <strong>
                Em {nextYear}, você deverá acessar a plataforma utilizando seu <span className="pink">username</span> e{" "}
                <span className="pink">senha</span>. Portanto, guarde essas informações com cuidado :)
              </strong>
            </p>
          </div>

          <BtnPrimary onClick={this.handleSignOut} to="#" value="Sair" arrow />

          <div className="modal-link">
            Dúvidas?{" "}
            <a href="https://ajuda.mindlab.com.br/hc/pt-br/requests/new" target="_blank" rel="noopener noreferrer">
              Fale com a gente no chat
            </a>
          </div>
        </Modal>
      </Layout>
    )
  }
}

export default connect()(OnboardingOrderSuccessNextYear)
