import React from "react"
import parseDate from "date-fns/parseISO"
import formatDate from "date-fns/format"
// import { connect } from "react-redux"
import PercentageBar from "components/PercentageBar"
// import API from "api"
import styled from "styled-components"

const ExamLink = styled.a`
	&[disabled]{
		cursor: disabled;
		pointer-events: none;
		opacity: .5;
	}
	&:hover {
		color: white;
	}
	display: block;
	text-decoration: none;
	color: white;
`

class SocioEmotionalExamStartableCard extends React.Component {
	constructor(props) {
		super(props)
		// this.handleStart = this.handleStart.bind(this)
		this.state = { showDetails: false }
		this.toggleDetails = this.toggleDetails.bind(this)
		this.nextQuestion = props.miniexams && props.miniexams.length && props.miniexams.filter(miniexam => !miniexam.completed)[0]
	}

	toggleDetails(e) {
		this.setState({ showDetails: this.state.showDetails })
		e.preventDefault()
	}

	// handleStart (e) {
	//   const { socioEmotionalExamId, requestInProgress } = this.props

	//   if (requestInProgress) return

	//   API.post("/socio_emotional_exam_sessions", { socioEmotionalExamId }, response => {
	//     window.location = response.data.provider_url
	//   })

	//   e.preventDefault()
	// }

	componentDidMount() {
		console.log(this.props)
	}

	render() {
		const {
			// exam,
			// totalQuestions,
			// questionsAnswered,
			// duration,
			// timeLeft,
			miniexams,
			name,
			started,
			expiresAt,
			completePercentage,
			link
		} = this.props


		return (
			<div className="exam-card">
				{console.log(this.nextQuestion)}
				<div className="exam-card-title">{name}</div>
				{
					miniexams && miniexams.length > 1 && miniexams.map((exam, idx) => (
						<ExamLink
							rel="noreferrer"
							href={exam.url}
							key={`exam-${idx}`}
							className="socio-stats-container"
							target="_self"
							disabled={!exam.enabled}
						>
							{exam.completed
								? <i className="checkbox-icon" />
								: <i className="arrow-icon arrow-icon-right to-do small" />}
							<span>{exam.name}</span>
						</ExamLink>
					))
				}
				<PercentageBar theme="white" complete={completePercentage} />
				<div className="progress-bar-text">seu progresso na prova: {completePercentage}%</div>

				<div className="expiration-start-container">
					<div>
						Expira em <strong>{formatDate(parseDate(expiresAt), "dd/MM/yyyy 'às' HH'h'mm")} </strong>
					</div>

					{completePercentage !== 100 && <a
						className="btn btn-light white small"
						href={link}
						target="_self"
						rel="noreferrer"
						a>{started ? "Continuar" : "Começar"} <i className="arrow-icon arrow-icon-right"></i></a>}
				</div>
			</div>
		)
	}
}

// const mapStateToProps = state => {
//   return {
//     requestInProgress: state.http.requestInProgress
//   }
// }

// export default connect(mapStateToProps)(SocioEmotionalExamStartableCard)
export default SocioEmotionalExamStartableCard
