import { Link } from "react-router-dom"
import styled from "styled-components"
import { withGalaxy } from "galaxy-context"

const StyledSatellite = styled(Link)`
  background: url(${props => props.galaxy.satellite}) no-repeat;
  background-size: contain;
  width: 85px;
  height: 110px;
`

const Satellite = withGalaxy(StyledSatellite)

export { Satellite, StyledSatellite }
