import React from "react"
import Countdown from "react-countdown-now"
import styled from "styled-components"
import { Clock } from "Icons"

const StyledCountdown = styled.div`
  background-color: ${(props) => props.color};
  color: #fff;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  height: 32px;
  line-height: 32px;
  border-radius: 20px;
  padding: 0 15px;
  display: inline-block;
  margin-left: 10px;

  @media (max-width: 768px) {
    margin: 0 auto;
  }
`

const StyledClockIcon = styled(Clock)`
  display: inline-block;
  vertical-align: middle;
  position: relative;
`

const colors = {
  purple: "#8e3fac",
  red: "#f13a30",
}

const InlineCountdown = ({ finishTime, color }) => {
  const hex = colors[color]

  return (
    <StyledCountdown color={hex}>
      <StyledClockIcon color={hex} />
      <Countdown date={finishTime} renderer={(props) => `${props.hours}:${props.minutes}:${props.seconds}`} />
    </StyledCountdown>
  )
}

export default InlineCountdown
