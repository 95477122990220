import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import Modal from "components/modal"

const DailyMissionFinishedModal = ({ wrongAmount, correctAmount, experience, ruby, children }) => (
  <Modal fullscreen backUrl="/trilha" backLabel="Trilha" color="purple">
    <div>
      {children}

      <div className="gamification-rounded daily-mission-performance">
        Acertos: {correctAmount} | Erros: {wrongAmount}
      </div>

      <p>Hoje você conseguiu</p>
    </div>

    <div className="earnings-container">
      <i className="earnings-brain" />

      <div className="xp-points gamification-rounded">
        <strong>{experience} xp</strong>
      </div>

      {ruby > 0 && (
        <div className="ruby-container">
          <div>
            <div className="ruby-points gamification-rounded">+ {ruby} rubi</div>
          </div>
          <div>
            <i className="icon-ruby" />
          </div>
        </div>
      )}

      <Link className="modal-link" to="/mochila">Ver desempenho</Link>
    </div>
  </Modal>
)

const mapStateToProps = state => {
  return {
    wrongAmount: state.dailyMissionQuestions.wrongAmount,
    correctAmount: state.dailyMissionQuestions.correctAmount,
    experience: state.dailyMissionQuestions.experience,
    ruby: state.dailyMissionQuestions.ruby
  }
}

export default connect(mapStateToProps)(DailyMissionFinishedModal)
