import styled from "styled-components"
import { Xp, Ruby } from "Icons"

const StyledGamificationBar = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
`

const GamificationXp = styled.div`
  line-height: 20px;
  margin-right: 10px;
`

const GamificationRuby = styled.div`
  line-height: 20px;
`

const GamificationIconXp = styled(Xp)`
  float: left;
  margin-right: 5px;
`

const GamificationIconRuby = styled(Ruby)`
  float: left;
  margin-right: 5px;
`

export { StyledGamificationBar, GamificationXp, GamificationRuby, GamificationIconXp, GamificationIconRuby }
