import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import EssayRulesContent from "components/exams/essay-rules-content"

const EssayRulesModal = ({ opened, onClose, essayTitle }) => (
  <CloseableModal overlay opened={opened} onClose={onClose} >
    <EssayRulesContent essayTitle={essayTitle} />
  </CloseableModal>
)

export default EssayRulesModal
