import styled from "styled-components"
import { Link } from "react-router-dom"

const Base = styled(Link)`
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  display: inline-block;
  width: 100%;
  max-width: 316px;
  height: 60px;
  padding: 21px 0;
  border-radius: 30px;
  box-sizing: border-box;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.3;
  }
`

export default Base
