import styled from "styled-components"
import { Ruby } from "Icons"

const StyledRuby = styled(Ruby)`
  background-size: cover;
  width: 79px;
  height: 51px;
  margin: 0 auto;
`

export { StyledRuby }
