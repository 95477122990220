import styled from "styled-components"
import horizon from "planets/kai/kai-horizon.svg"

import { StyledHorizon, StyledHorizonContainer, StyledHorizonTitle } from "Horizon"

const PlanetHorizon = styled(StyledHorizon)`
  background: linear-gradient(180deg, #006a65 0%, #00e9d9 100%);
  overflow: hidden;
  &::before {
    background: url(${horizon}) repeat-x center top;
    background-size: auto 100%;
    width: 100%;
    margin: 0px auto;
    bottom: -2px;
    height: 100%;
    display: flex;
    justify-content: center;
  }
`

const ShortPlanetHorizon = PlanetHorizon

const PlanetHorizonContainer = styled(StyledHorizonContainer)``
const ShortPlanetHorizonContainer = PlanetHorizonContainer

const PlanetHorizonTitle = styled(StyledHorizonTitle)`
  position: absolute;
  right: 250px;
  @media (max-width: 1024px) {
    right: auto;
    top: 60px;
    left: 70%;
  }
`

export { PlanetHorizon, ShortPlanetHorizon, PlanetHorizonContainer, ShortPlanetHorizonContainer, PlanetHorizonTitle }
