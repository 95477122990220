import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Base from "Buttons/Base"
import { withPlanet } from "planet-context"
import { Arrow } from "Icons"

const StyledPrimaryButton = withPlanet(styled(Base)`
  background-color: ${props => props.planet.btnPrimary.backgroundColor};
  border: none;
  color: ${props => props.planet.btnPrimary.textColor};
  box-shadow: 2px 4px ${props => props.planet.btnPrimary.shadowColor};
  transform: translateY(0) translateZ(0);
  transition: all 150ms linear;
  margin-bottom: 4px;

  &:hover,
  &:active,
  &:focus {
    background-color: ${props => props.planet.btnPrimary.shadowColor};
    box-shadow: none;
    transform: translateY(4px) translateZ(2px);
    color: ${props => props.planet.btnPrimary.textColor};
  }
`)

const StyledButtonArrow = withPlanet(styled(Arrow)`
  position: absolute;
  top: 15px;
  right: 15px;
  transition: all 150ms linear;
  color: ${props => props.planet.btnPrimary.backgroundColor};
`)

const BtnPrimary = ({ arrow, children, ...props }) => (
  <StyledPrimaryButton {...props}>
    {arrow && <StyledButtonArrow direction="right" />}
    {children}
  </StyledPrimaryButton>
)

BtnPrimary.propTypes = {
  children: PropTypes.node.isRequired
}

export default BtnPrimary
