import React from "react"
import { Layout } from "Session"
import Modal from "components/modal"
import { Vigo } from "components/avatar"
import { BtnPrimary } from "components/button"
import { MixpanelTrack } from "tags/mixpanel"

class AccountPasswordShow extends React.Component {
  componentDidMount () {
    MixpanelTrack("Concluiu alteração de Senha")
  }

  render () {
    return (
      <Layout>
        <Modal backUrl="/entrar">
          <div className="modal-title">
            <h2>Você conseguiu!</h2>
            <p>Sua senha foi alterada com sucesso.</p>
            <p>Que tal voltarmos para nossa jornada?</p>
          </div>

          <Vigo />

          <BtnPrimary to="/entrar" value="Seguir" arrow />
        </Modal>
      </Layout>
    )
  }
}

export default AccountPasswordShow
