import { withPlanet } from "planet-context"
import styled from "styled-components"

const SliderButton = styled.i`
  position: absolute;
  top: 140px;
  width: 35px;
  height: 240px;
  cursor: pointer;
  z-index: 2;

  &::before {
    position: absolute;
    left: 13px;
    top: 110px;
  }

  @media (max-width: 768px) {
    width: 10px;

    &::before {
      display: none;
    }
  }
`

const SliderButtonWithArrow = styled(SliderButton)`
  &::before {
    content: "";
    border-style: solid;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    color: ${props => props.planet.dailyMission.questionSlider.arrowColor};
  }
`

const ButtonSliderPrevArrow = styled(SliderButtonWithArrow)`
  left: 0;
  border-radius: 0 20px 20px 0;
  background-color: ${props => props.planet.dailyMission.questionSlider.prev};

  &::before {
    transform: rotate(135deg) translateX(-1px) translateY(-2px);
  }
`

const ButtonSliderNextArrow = styled(SliderButtonWithArrow)`
  right: 0;
  border-radius: 20px 0 0 20px;
  background-color: ${props => props.planet.dailyMission.questionSlider.next};

  &::before {
    transform: rotate(315deg) translateX(-7px) translateY(5px);
  }
`

const NextArrow = withPlanet(ButtonSliderNextArrow)
const PrevArrow = withPlanet(ButtonSliderPrevArrow)

export { NextArrow, PrevArrow }
