import React from "react"
import FormSelect from "components/form/form-select"
import API from "api"
import {
  mySisuEnableSubmit,
  mySisuSetFormOption,
  mySisuDisableSubmit,
  mySisuHideChancesSection,
  mySisuSetUniversities,
  mySisuSetCourses,
  mySisuSetTableTitle
} from "MySisu/actions"
import { connect } from "react-redux"

class UniversityCourses extends React.Component {
  constructor (props) {
    super(props)

    this.handleChooseUniversity = this.handleChooseUniversity.bind(this)
    this.handleChooseCourse = this.handleChooseCourse.bind(this)
  }

  componentDidMount () {
    API.get("/my_sisu/university", response => {
      this.props.dispatch(mySisuSetUniversities(response.data.universities))
      this.props.dispatch(
        mySisuSetFormOption({
          universityId: "",
          courseId: ""
        })
      )
    })
  }

  handleChooseUniversity (event) {
    const universityId = event.target.value

    if (universityId === "") {
      this.props.dispatch(mySisuSetFormOption({ courseId: "", universityId: "" }))
      this.props.dispatch(mySisuSetCourses([]))
    } else {
      API.get(`/my_sisu/university/${universityId}/course`, response => {
        this.props.dispatch(mySisuSetCourses(response.data.courses))
        this.props.dispatch(mySisuSetFormOption({ courseId: "", universityId: universityId }))
      })
    }
    this.props.dispatch(mySisuDisableSubmit())
    this.props.dispatch(mySisuHideChancesSection())
  }

  handleChooseCourse (event) {
    const target = event.target
    if (event.target.value) {
      const chancesTableTitle = this.props.courses[target.selectedIndex - 1].name
      this.props.dispatch(mySisuSetTableTitle(chancesTableTitle))
      this.props.dispatch(mySisuEnableSubmit())
    } else {
      this.props.dispatch(mySisuDisableSubmit())
    }
    this.props.dispatch(mySisuSetFormOption({ courseId: target.value }))
    this.props.dispatch(mySisuHideChancesSection())
  }

  render () {
    const { universityId, courseId, universities, courses } = this.props

    return (
      <div>
        <FormSelect
          id="university"
          name="universityId"
          label="Universidade"
          onChange={this.handleChooseUniversity}
          value={universityId}
          required
        >
          <option> </option>
          {universities.map(university => (
            <option value={university.id} key={university.name}>
              {university.name}
            </option>
          ))}
        </FormSelect>

        <FormSelect
          id="course"
          name="courseId"
          label="Curso"
          onChange={this.handleChooseCourse}
          value={courseId}
          required
        >
          <option> </option>
          {courses.map(course => (
            <option value={course.id} key={course.name}>
              {course.name}
            </option>
          ))}
        </FormSelect>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    universityId: state.mySisuReducer.options.universityId,
    courseId: state.mySisuReducer.options.courseId,
    universities: state.mySisuReducer.universities,
    courses: state.mySisuReducer.courses
  }
}

export default connect(mapStateToProps)(UniversityCourses)
