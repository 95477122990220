import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import ExamPausableRulesContent from "components/exams/exam-pausable-rules-content"
import ExamUnpausableRulesContent from "components/exams/exam-unpausable-rules-content"

const ExamRulesModal = ({ title, opened, onClose, pausable, duration, examName }) => (
  <CloseableModal overlay opened={opened} onClose={onClose}>
    {pausable ? (
      <ExamPausableRulesContent title={title} />
    ) : (
      <ExamUnpausableRulesContent title={examName} duration={duration} />
    )}
  </CloseableModal>
)

export default ExamRulesModal
