import React from "react"
import {
  UserGamification,
  GamificationXp,
  GamificationRuby,
  GamificationIconXp,
  GamificationIconRuby,
  UserAvatarBorder,
  UserName,
  UserContainer,
  UserLink
} from "trail/users/styled"
import { UserAvatarTrail } from "trail/users"
import TrailUserBadgesModal from "trail/TrailUserBadgesModal"
import PropTypes from "prop-types"

class UserDetailAvatar extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      trailUserBadgesModal: false
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (e) {
    this.setState({ trailUserBadgesModal: true })
    e.preventDefault()
  }

  render () {
    const { user } = this.props
    const { trailUserBadgesModal } = this.state

    return (
      <UserContainer>
        <TrailUserBadgesModal opened={trailUserBadgesModal} user={user} />
        <UserLink to="/trail" onClick={this.handleClick}>
          <UserAvatarBorder>
            <UserAvatarTrail avatar={user.avatar} style={{ height: "53px", marginTop: "5px" }} />
          </UserAvatarBorder>
        </UserLink>
        <UserName>{user.name}</UserName>
        <UserGamification>
          <GamificationXp>
            <GamificationIconXp />
            {user.experience_total} xp
          </GamificationXp>
          <GamificationRuby>
            <GamificationIconRuby />
            {user.rubies}
          </GamificationRuby>
        </UserGamification>
      </UserContainer>
    )
  }
}

UserDetailAvatar.propTypes = {
  user: PropTypes.object
}
export default UserDetailAvatar
