import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import { UserBadges } from "trail/users"

const TrailUserBadgesModal = ({ opened, onClose, user }) => (
  <CloseableModal overlay opened={opened} onClose={onClose}>
    <UserBadges user={user} />
  </CloseableModal>
)

export default TrailUserBadgesModal
