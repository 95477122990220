import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import { BtnPrimary, BtnLight } from "components/button"
import UserAvatar from "components/user-avatar"
import { MixpanelTrack } from "tags/mixpanel"

const DailyMissionLeaveModal = ({ opened, onDismiss }) => (
  <CloseableModal opened={opened} closeButton={false} fullscreen color="purple">
    <h2 className="light caramel">Você quer mesmo sair dessa Missão?</h2>
    <p>
      Você ainda não respondeu todas as questões do dia.<br />
      Para concluir a Missão de hoje, é só retornar até o fim do dia.
    </p>

    <UserAvatar kind="sad" />

    <p>
      <strong>ATENÇÃO!</strong><br />
      Você receberá XP apenas pelas questões que já respondeu.
    </p>

    <div className="buttons-container">
      <BtnLight to="#" value="Voltar para a missão diária" onClick={onDismiss} />
      <BtnPrimary to="/missao-diaria/abandonada" value="Quero sair" arrow onClick={() => MixpanelTrack("Abandonou Missão diária")} />
    </div>
  </CloseableModal>
)

export default DailyMissionLeaveModal
