import React from "react"
import { Link } from "react-router-dom"

const ManualLink = ({ text, url }) => (
  <div className="manual-link">
    <Link to={url}>
      <i className="arrow-icon arrow-icon-right manual-link-arrow" />
      <p className="pink manual-link-text">
        <strong>{text}</strong>
      </p>
    </Link>
    <hr />
  </div>
)

export default ManualLink
