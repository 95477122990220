import React from "react"
import Modal from "components/modal"
import { BtnPrimary } from "components/button"
import UserAvatar from "components/user-avatar"

const DailyMissionCloseModal = () => (
  <Modal overlay>
    <h2>A missão de hoje acabou!</h2>
    <p>Volte para a trilha para acessar sua próxima missão.</p>

    <UserAvatar />

    <div className="buttons-container">
      <BtnPrimary to="/trilha" value="Voltar para a trilha" arrow />
    </div>
  </Modal>
)

export default DailyMissionCloseModal
