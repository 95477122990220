import React from "react"
import { Link } from "react-router-dom"
import CloseableModal from "components/modals/closeable-modal"
import { redirectToEvaluation } from "exam/essay-actions"

const EvaluatedEssayModal = ({ ruby, essaySessionId, opened, onClose }) => (
  <CloseableModal fullscreen opened={opened} onClose={onClose} color="honey-flower">
    <div className="white-titles">
      <h2 className="big">Redação Corrigida!</h2>
      <h3 className="big">Sua redação acabou de retornar de nossos corretores</h3>
      <h3>Você ganhou</h3>
    </div>

    <div className="earnings-container">
      <i className="earnings-brain" />

      <div className="ruby-container">
        <div>
          <div className="ruby-points gamification-rounded">{ruby} rubi</div>
        </div>
        <div>
          <i className="icon-ruby" />
        </div>
      </div>

      <Link className="modal-link" to="#" onClick={() => redirectToEvaluation(essaySessionId)}>
        Ver correção
        </Link>
    </div>
  </CloseableModal>
)

export default EvaluatedEssayModal
