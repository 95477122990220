import React from "react"

const FormGroup = ({ className = "", grid, hasError, children }) => (
  <div className={`form-group ${className} ${grid ? "grid" : ""} ${hasError ? "error" : ""}`}>
    {children}
    {!grid && <i className="error-icon">&times;</i>}
  </div>
)

export default FormGroup
