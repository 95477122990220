import React from "react"
import { UserGrid } from "trail/users/styled"
import { UserDetailAvatar } from "trail/users"

const UsersList = ({ users }) => (
  <UserGrid>
    {users.map((user, index) => (
      <UserDetailAvatar key={index} user={user} />
    ))}
  </UserGrid>
)

export default UsersList
