import React from "react"

const SatelliteMenuItem = ({ activeSection, slug, handleChangeSection, title }) => (
  <span to="#" onClick={() => handleChangeSection(slug)} className={`menu-item ${activeSection === slug ? "selected" : ""}`}>
    {title}
  </span>
)

const SatelliteMenu = ({ activeSection, handleChangeSection }) => (
  <div className="satellite-menu">
    <SatelliteMenuItem slug="waiting" handleChangeSection={handleChangeSection} activeSection={activeSection} title="AGUARDANDO LIBERAÇÃO" />
    <SatelliteMenuItem slug="released" handleChangeSection={handleChangeSection} activeSection={activeSection} title="LIBERADAS" />
    <SatelliteMenuItem slug="finished" handleChangeSection={handleChangeSection} activeSection={activeSection} title="FINALIZADAS" />
  </div>
)

export default SatelliteMenu
