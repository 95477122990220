import AuthenticatedRoute from "helpers/authenticated-route"
import OnboardingOffer from "onboarding/order/Offer"
import OnboardingPaymentBoleto from "onboarding/order/Boleto"
import OnboardingPaymentCreditcardProcessing from "onboarding/order/CreditcardProcessing"
import OnboardingPaymentCreditcardError from "onboarding/order/CreditcardError"
import OnboardingPaymentCreditcardWaiting from "onboarding/order/CreditcardWaiting"
import OnboardingOrder from "onboarding/order/Order"
import OnboardingPayment from "onboarding/order/Payment"
import OnboardingOrderSuccess from "onboarding/order/Success"

const Routes = [
  {
    router: AuthenticatedRoute,
    path: "/pedido",
    component: OnboardingOffer
  },
  {
    router: AuthenticatedRoute,
    path: "/pedido/proximo-ano",
    component: OnboardingOffer
  },
  {
    router: AuthenticatedRoute,
    path: "/pedido/compra",
    component: OnboardingOrder
  },
  {
    router: AuthenticatedRoute,
    path: "/pedido/compra/proximo-ano",
    component: OnboardingOrder
  },
  {
    router: AuthenticatedRoute,
    path: "/pedido/boleto",
    component: OnboardingPaymentBoleto
  },
  {
    router: AuthenticatedRoute,
    path: "/pedido/cartao/processando",
    component: OnboardingPaymentCreditcardProcessing
  },
  {
    router: AuthenticatedRoute,
    path: "/pedido/cartao/erro",
    component: OnboardingPaymentCreditcardError
  },
  {
    router: AuthenticatedRoute,
    path: "/pedido/cartao/aguardando",
    component: OnboardingPaymentCreditcardWaiting
  },
  {
    router: AuthenticatedRoute,
    path: "/pedido/pagamento",
    component: OnboardingPayment
  },
  {
    router: AuthenticatedRoute,
    path: "/pedido/concluido",
    component: OnboardingOrderSuccess
  }
]

export default Routes
