import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import { BtnPrimary, BtnLight } from "components/button"

const SignUpUsernameNoticeModal = ({
  opened,
  username,
  onDismiss,
  onContinue
}) => (
  <CloseableModal opened={opened} overlay closeButton={false}>
    <h2 className="modal-title">Lembre-se :)</h2>
    <p>
      Para <span className="pink">realizar o login na plataforma</span>, você
      precisará utilizar seu <br />
      <strong className="pink">Nome de Usuário</strong> ou seu{" "}
      <strong>Código de Ativação</strong>.
    </p>

    <div className="signup-notice-modal username-container">
      <p className="medium">Nome de Usuário para login:</p>
      <span className="username">
        <strong>{username}</strong>
      </span>
    </div>

    <div className="signup-notice-modal buttons-container">
      <BtnLight to="#" value="Revisar meus dados" onClick={onDismiss} />
      <BtnPrimary to="#" value="Continuar" onClick={onContinue} />
    </div>
  </CloseableModal>
)

export default SignUpUsernameNoticeModal
