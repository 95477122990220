import API from "api"
import { push } from "connected-react-router"

export const SET_CURRENT_ESSAY = "SET_CURRENT_ESSAY"
export const SET_CURRENT_ESSAY_ID = "SET_CURRENT_ESSAY_ID"
export const CLEAR_CURRENT_ESSAY = "CLEAR_CURRENT_ESSAY"
export const SET_ESSAY_SESSION = "SET_ESSAY_SESSION"
export const CLEAR_ESSAY_SESSION = "CLEAR_ESSAY_SESSION"

export function setCurrentEssay (essay) {
  return { type: SET_CURRENT_ESSAY, essay }
}

export function setCurrentEssayId (essay) {
  return { type: SET_CURRENT_ESSAY_ID, essay }
}

export function clearCurrentEssay () {
  return { type: CLEAR_CURRENT_ESSAY }
}

export function redirectToEvaluation (essaySessionId) {
  API.get(`/essay_feedbacks/${essaySessionId}`, response => {
    const { evaluation_url: evaluationUrl } = response.data

    if (evaluationUrl !== null) {
      window.open(evaluationUrl, "_blank")
    }
  })
}

export function getEssaySession (essaySessionId) {
  return dispatch => {
    API.get(
      `/essay_sessions/${essaySessionId}`,
      response => {
        const { pausable: pausable, exam_timer_id: examTimerId } = response.data
        dispatch({ type: SET_ESSAY_SESSION, pausable, examTimerId })
      },
      () => {
        dispatch(push("/simulados"))
      }
    )
  }
}

export function clearEssaySession () {
  return { type: CLEAR_ESSAY_SESSION }
}
