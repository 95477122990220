import React from "react"
import { Link } from "react-router-dom"
import { push } from "connected-react-router"
import { connect } from "react-redux"
import { Layout } from "Session"
import { Submit } from "components/button"
import Modal from "components/modal"
import Form from "components/form/form"
import FormInput from "components/form/form-input"
import NextStepRouter from "onboarding/next-step-router"
import { MixpanelSigninEvent, MixpanelTrack, MixpanelIdentify } from "tags/mixpanel"
import { updateCurrentUser } from "actions"
import { storeAuthToken, storeWebsocketToken } from "auth"
import AccountSessionInstabilityWarning from "./instability-warning"
import API from "api"

class AccountSessionNew extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: [],
      login: "",
      password: "",
      redirectTarget: ""
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value })
  }

  handleSubmit(event) {
    const { login, password } = this.state
    const { dispatch, requestInProgress } = this.props

    if (requestInProgress) {
      return
    }

    API.post(
      "/sessions",
      { user: { login, password } },
      response => {
        const { next_step: nextStep } = response.headers
        const { user, relative, token, websocket_token: websocketToken } = response.data
        const redirectTarget = NextStepRouter(nextStep)

        storeAuthToken(token)
        storeWebsocketToken(websocketToken)

        dispatch(updateCurrentUser(user, relative))
        dispatch(push(redirectTarget))

        MixpanelIdentify(response.data.user.id)
        window.hj("identify", response.data.user.id, { ...response.data.user })
        MixpanelTrack("Efetuou login")
        MixpanelSigninEvent(response.data.user, response.data.relative)

        if (user.package_school_year) {
          document.body.classList.add(`package-${user.package_school_year}`)
        }

      },
      error => {
        const errors = error.response.data["errors"]
        this.setState({ errors, password: "" })
      }
    )

    event.preventDefault()
  }

  render() {
    const { errors, password } = this.state
    const { requestInProgress } = this.props

    return (
      <Layout>
        <Modal backUrl="/">
          <div className="modal-title">
            <h2>Tenho conta</h2>
            <AccountSessionInstabilityWarning />
            <p>Para começar a aventura, precisamos de algumas informações:</p>
          </div>

          <Form onSubmit={this.handleSubmit}>
            <FormInput
              type="text"
              id="login"
              name="login"
              label="Nome de Usuário ou E-mail de Aluno"
              errors={errors}
              onChange={this.handleChange}
              required
            />

            <FormInput
              type="password"
              id="password"
              name="password"
              label="Senha"
              errors={errors}
              onChange={this.handleChange}
              value={password}
              required
            />

            <Submit value="Seguir" arrow disabled={requestInProgress} />
          </Form>

          <Link to="/esqueci-minha-senha" className="modal-link">
            Esqueci minha senha
          </Link>
        </Modal>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    requestInProgress: state.http.requestInProgress
  }
}

export default connect(mapStateToProps)(AccountSessionNew)
