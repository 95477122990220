import candy0Icon from "planets/roya/roya-candy-0.svg"
import candy1Icon from "planets/roya/roya-candy-1.svg"
import candy2Icon from "planets/roya/roya-candy-2.svg"
import candy3Icon from "planets/roya/roya-candy-3.svg"
import candy4Icon from "planets/roya/roya-candy-4.svg"
import candy5Icon from "planets/roya/roya-candy-5.svg"
import candy6Icon from "planets/roya/roya-candy-6.svg"

export const stone0 = {
  icon: candy0Icon,
  width: "60px",
  height: "60px",
  top: "-6px",
  left: "309px"
}

export const stone1 = {
  icon: candy1Icon,
  width: "60px",
  height: "60px",
  top: "122px",
  left: "145px"
}

export const stone2 = {
  icon: candy2Icon,
  width: "60px",
  height: "60px",
  top: "261px",
  left: "60px"
}

export const stone3 = {
  icon: candy3Icon,
  width: "60px",
  height: "60px",
  top: "355px",
  left: "262px"
}

export const stone4 = {
  icon: candy4Icon,
  width: "60px",
  height: "60px",
  top: "493px",
  left: "56px"
}

export const stone5 = {
  icon: candy5Icon,
  width: "60px",
  height: "60px",
  top: "591px",
  left: "323px"
}

export const stone6 = {
  icon: candy6Icon,
  width: "60px",
  height: "60px",
  top: "642px",
  left: "50px"
}
