import React from "react"
import Countdown from "components/countdown"

const ClockCountdown = ({ until, zero }) => (
  <div className="clock-countdown">
    <i className="clock-icon" />
    { zero ? <span>00H00</span> : <Countdown until={until} /> }
  </div>
)

export default ClockCountdown
