import React from "react"
import parseDate from "date-fns/parseISO"
import formatDate from "date-fns/format"
import { SecondsToHourMins } from "helpers/time-formatter"

const ExamUnstartableCard = ({ name, duration, totalQuestions, pausable, availableAt, meuSisuAvailable }) => (
  <div className="exam-card">
    <div className="exam-card-title">{name}</div>

    <div className="not-started-exam-card-info">
      <i className="checkbox-icon" />
      <span>{totalQuestions} questões</span>
    </div>

    <div className="not-started-exam-card-info">
      <i className="clock-icon white-transparent small" />
      <span>{SecondsToHourMins(duration)} de prova</span>
    </div>

    <div className="not-started-exam-card-info">
      <i className={`clock-icon white-transparent ${pausable ? "pause" : "not-pause"}`} />
      <span>{`${pausable ? "" : "Não"} Permite Pausar`}</span>
    </div>

    {meuSisuAvailable && (
      <div className="not-started-exam-card-info">
        <i className="chart-icon" />
        <span>MeuSiSU disponível</span>
      </div>
    )}

    <div className="not-started-exam-card-release-date">
      Liberação: {formatDate(parseDate(availableAt), "dd/MM/yyyy 'às' HH'h'mm")}
    </div>
  </div>
)

export default ExamUnstartableCard
