import iconXp from "icon-xp.svg"
import styled from "styled-components"

const Xp = styled.i`
  background: url(${iconXp}) no-repeat;
  display: block;
  height: 20px;
  width: 22px;
`

export default Xp
