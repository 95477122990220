import React from "react"
import { Redirect } from "react-router"
import { Layout } from "Session"
import { Submit } from "components/button"
import Modal from "components/modal"
import Form from "components/form/form"
import FormPassword from "components/form/form-password"
import Processing from "components/processing"
import API from "api"

class AccountPasswordEdit extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      password: "",
      password_confirmation: "",
      tokenError: null,
      errors: [],
      fireRedirect: false,
      fetched: false
    }

    this.token = this.props.match.params.token
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount () {
    API.get(
      `/passwords/${this.token}/edit`,
      () => {
        this.setState({ fetched: true })
      },
      error => {
        const { errors } = error.response.data
        this.setState({ tokenError: errors, fetched: true })
      }
    )
  }

  handleChange (event) {
    this.setState({ [event.target.id]: event.target.value })
  }

  handleSubmit (event) {
    const { password, password_confirmation } = this.state
    const reset_token = this.token

    API.put(
      "/passwords",
      { reset_token, password, password_confirmation },
      () => {
        this.setState({ fireRedirect: true })
      },
      error => {
        const errors = error.response.data["errors"]
        this.setState({ errors })
      }
    )

    event.preventDefault()
  }

  render () {
    const { fireRedirect, errors, tokenError, fetched } = this.state

    if (!fetched) {
      return <Processing />
    }

    if (tokenError !== null) {
      return (
        <Layout>
          <Modal backUrl="/entrar">
            <p>{tokenError}</p>
          </Modal>
        </Layout>
      )
    }

    return (
      <Layout>
        <Modal backUrl="/entrar">
          <div className="modal-title">
            <h2>Nova senha</h2>
            <p>Agora é só cadastrar uma nova senha e confirmar!</p>
          </div>

          <Form onSubmit={this.handleSubmit}>
            <FormPassword
              id="password"
              name="password"
              label="Nova senha"
              onChange={this.handleChange}
              errors={errors}
              required
            />

            <FormPassword
              id="password_confirmation"
              name="password_confirmation"
              label="Confirmação da nova senha"
              onChange={this.handleChange}
              errors={errors}
              required
            />

            <Submit value="Confirmar" arrow />
          </Form>

          {fireRedirect && <Redirect to="/esqueci-minha-senha/completo" />}
        </Modal>
      </Layout>
    )
  }
}

export default AccountPasswordEdit
