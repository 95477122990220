import React from "react"
import FormSelect from "components/form/form-select"
import API from "api"
import { connect } from "react-redux"
import {
  mySisuEnableSubmit,
  mySisuSetFormOption,
  mySisuDisableSubmit,
  mySisuHideChancesSection,
  mySisuSetScienceAreas,
  mySisuSetGroupings,
  mySisuSetStates,
  mySisuSetCities,
  mySisuSetTableTitle
} from "MySisu/actions"

class SearchCourse extends React.Component {
  constructor (props) {
    super(props)

    this.handleChooseScienceArea = this.handleChooseScienceArea.bind(this)
    this.handleChooseCourse = this.handleChooseCourse.bind(this)
    this.handleChooseState = this.handleChooseState.bind(this)
    this.handleChooseCity = this.handleChooseCity.bind(this)
  }

  componentDidMount () {
    API.get("/my_sisu/fields", response => {
      this.props.dispatch(mySisuSetScienceAreas(response.data.science_areas))

      this.props.dispatch(
        mySisuSetFormOption({
          groupingId: "",
          cityId: "",
          stateId: "",
          scienceAreaId: ""
        })
      )
    })
  }

  handleChooseScienceArea (event) {
    const scienceAreaId = event.target.value

    if (scienceAreaId === "") {
      this.props.dispatch(
        mySisuSetFormOption({
          scienceAreaId: "",
          groupingId: "",
          stateId: "",
          cityId: ""
        })
      )
      this.props.dispatch(mySisuSetGroupings([]))
      this.props.dispatch(mySisuSetStates([]))
      this.props.dispatch(mySisuSetCities([]))
    } else {
      API.get(`/my_sisu/fields?science_type=${scienceAreaId}`, response => {
        this.props.dispatch(mySisuSetGroupings(response.data.fields))

        this.props.dispatch(
          mySisuSetFormOption({
            scienceAreaId: scienceAreaId,
            groupingId: "",
            stateId: "",
            cityId: ""
          })
        )
      })
    }
    this.props.dispatch(mySisuDisableSubmit())
    this.props.dispatch(mySisuHideChancesSection())
  }

  handleChooseCourse (event) {
    const target = event.target
    const groupingId = target.value

    if (groupingId === "") {
      this.props.dispatch(
        mySisuSetFormOption({
          groupingId: "",
          stateId: "",
          cityId: ""
        })
      )
      this.props.dispatch(mySisuSetStates([]))
      this.props.dispatch(mySisuSetCities([]))
      this.props.dispatch(mySisuDisableSubmit())
    } else {
      API.get(`/my_sisu/fields/${groupingId}/states`, response => {
        this.props.dispatch(mySisuSetStates(response.data.states))
        this.props.dispatch(
          mySisuSetFormOption({
            groupingId: groupingId,
            stateId: "",
            cityId: ""
          })
        )

        const chancesTableTitle = this.props.groupings[target.selectedIndex - 1].name
        this.props.dispatch(mySisuSetTableTitle(chancesTableTitle))
        this.props.dispatch(mySisuEnableSubmit())
      })
    }
    this.props.dispatch(mySisuHideChancesSection())
  }

  handleChooseState (event) {
    const stateId = event.target.value
    const states = this.props.states

    if (stateId === "") {
      this.props.dispatch(
        mySisuSetFormOption({
          stateId: "",
          cityId: ""
        })
      )
      this.props.dispatch(mySisuSetCities([]))
    } else {
      const cities = states.find(s => s.state === stateId).cities
      this.props.dispatch(mySisuSetCities(cities))
      this.props.dispatch(
        mySisuSetFormOption({
          stateId: stateId,
          cityId: ""
        })
      )
    }
    this.props.dispatch(mySisuHideChancesSection())
  }

  handleChooseCity (event) {
    this.props.dispatch(mySisuHideChancesSection())
    this.props.dispatch(mySisuSetFormOption({ cityId: event.target.value }))
  }

  render () {
    const { scienceAreaId, groupingId, stateId, cityId, scienceAreas, groupings, states, cities } = this.props

    return (
      <div>
        <FormSelect
          id="scienceArea"
          name="scienceArea"
          label="Área de estudo"
          onChange={this.handleChooseScienceArea}
          value={scienceAreaId}
          required
        >
          <option> </option>
          {scienceAreas.map(area => (
            <option value={area.id} key={area.id}>
              {area.name}
            </option>
          ))}
        </FormSelect>

        <FormSelect
          id="grouping"
          name="groupingId"
          label="Curso"
          onChange={this.handleChooseCourse}
          value={groupingId}
          required
        >
          <option> </option>
          {groupings.map(course => (
            <option value={course.id} key={course.id}>
              {course.name}
            </option>
          ))}
        </FormSelect>

        <FormSelect id="state" name="stateId" label="Estado" onChange={this.handleChooseState} value={stateId} required>
          <option> </option>
          {states.map(state => (
            <option value={state.state} key={state.state}>
              {state.state}
            </option>
          ))}
        </FormSelect>

        <FormSelect id="city" name="cityId" label="Cidade" onChange={this.handleChooseCity} value={cityId} required>
          <option> </option>
          {cities.map(city => (
            <option value={city.id} key={city.id}>
              {city.name}
            </option>
          ))}
        </FormSelect>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    courseId: state.mySisuReducer.options.courseId,
    groupingId: state.mySisuReducer.options.groupingId,
    cityId: state.mySisuReducer.options.cityId,
    stateId: state.mySisuReducer.options.stateId,
    scienceAreaId: state.mySisuReducer.options.scienceAreaId,
    scienceAreas: state.mySisuReducer.scienceAreas,
    groupings: state.mySisuReducer.groupings,
    states: state.mySisuReducer.states,
    cities: state.mySisuReducer.cities
  }
}

export default connect(mapStateToProps)(SearchCourse)
