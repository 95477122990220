import Klaus from "components/avatars/klaus"
import KlausSad from "components/avatars/klaus-sad"
import KlausShip from "components/avatars/klaus-ship"
import Leri from "components/avatars/leri"
import LeriSad from "components/avatars/leri-sad"
import LeriShip from "components/avatars/leri-ship"
import Maya from "components/avatars/maya"
import MayaSad from "components/avatars/maya-sad"
import MayaShip from "components/avatars/maya-ship"
import Nuno from "components/avatars/nuno"
import NunoSad from "components/avatars/nuno-sad"
import NunoShip from "components/avatars/nuno-ship"
import Poppy from "components/avatars/poppy"
import PoppySad from "components/avatars/poppy-sad"
import PoppyMoneyShip from "components/avatars/poppy-money-ship"
import PoppyShip from "components/avatars/poppy-ship"
import Zen from "components/avatars/zen"
import ZenSad from "components/avatars/zen-sad"
import ZenShip from "components/avatars/zen-ship"
import Vigo from "components/avatars/vigo"
import VigoSad from "components/avatars/vigo-sad"

export {
  Klaus,
  KlausSad,
  KlausShip,
  Leri,
  LeriSad,
  LeriShip,
  Maya,
  MayaSad,
  MayaShip,
  Nuno,
  NunoSad,
  NunoShip,
  Poppy,
  PoppySad,
  PoppyMoneyShip,
  PoppyShip,
  Zen,
  ZenSad,
  ZenShip,
  Vigo,
  VigoSad
}

export const AVATARS = [
  {
    name: "Maya",
    bio: "Amiga, inteligente e parceira, ela encontra sempre as melhores soluções para os problemas.",
    component: Maya
  },
  {
    name: "Klaus",
    bio: "Um grandão de coração mole. Embora calado, gosta de estar onde as coisas acontecem.",
    component: Klaus
  },
  {
    name: "Leri",
    bio: "Tem um jeito engraçado e chama a atenção. Sempre prestativo e pronto para fazer rir.",
    component: Leri
  },
  {
    name: "Poppy",
    bio: "Muito popular e adora estar entre pessoas. Trabalhos em grupo são seu forte.",
    component: Poppy
  },
  {
    name: "Zen",
    bio: "Legal e descolado, conversa com todo mundo e adora uma aventura.",
    component: Zen
  },
  {
    name: "Nuno",
    bio: "Ele quer tanto ajudar que está sempre a postos para explicar algo!",
    component: Nuno
  }
]

export function findAvatar (avatar) {
  return AVATARS.find(a => a.name === avatar)
}

export function findAvatarIndex (avatar) {
  return AVATARS.map(a => a.name).indexOf(avatar)
}
