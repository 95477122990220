import React from "react"
import { connect } from "react-redux"
import { omit } from "helpers/object-utils"
import {
  Klaus,
  KlausSad,
  KlausShip,
  Leri,
  LeriSad,
  LeriShip,
  Maya,
  MayaSad,
  MayaShip,
  Nuno,
  NunoSad,
  NunoShip,
  Poppy,
  PoppySad,
  PoppyShip,
  Zen,
  ZenSad,
  ZenShip
} from "components/avatar"

const avatars = {
  Klaus: {
    ship: KlausShip,
    happy: Klaus,
    sad: KlausSad
  },
  Leri: {
    ship: LeriShip,
    happy: Leri,
    sad: LeriSad
  },
  Maya: {
    ship: MayaShip,
    happy: Maya,
    sad: MayaSad
  },
  Nuno: {
    ship: NunoShip,
    happy: Nuno,
    sad: NunoSad
  },
  Poppy: {
    ship: PoppyShip,
    happy: Poppy,
    sad: PoppySad
  },
  Zen: {
    ship: ZenShip,
    happy: Zen,
    sad: ZenSad
  }
}

const UserAvatar = ({ userAvatar, kind = "happy", ...rest }) => {
  const Avatar = avatars[userAvatar][kind]
  const props = omit(rest, "dispatch")

  return <Avatar {...props} />
}

const mapStateToProps = state => {
  return {
    userAvatar: state.auth.currentUser.avatar
  }
}

export default connect(mapStateToProps)(UserAvatar)
