import scenario from "planets/cryo/cryo-scenario.svg"
import flag from "planets/cryo/cryo-high-flag.svg"
import { stone0, stone1, stone2, stone3, stone4, stone5, stone6 } from "planets/cryo/stone"

export default {
  stones: [stone0, stone1, stone2, stone3, stone4, stone5, stone6],
  scenario: scenario,
  flag: flag,
  gradient: "linear-gradient(to right, #75b1eb 0%,#ffffff 44%,#ffffff 53%,#75b1eb 100%)",
  paddingTop: "20px"
}
