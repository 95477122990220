import styled from "styled-components"
import city from "planets/natus/natus-city.svg"
import sky from "planets/natus/natus-sky.svg"
import { StyledHorizon, StyledHorizonContainer, StyledHorizonTitle } from "Horizon"

const PlanetHorizon = styled(StyledHorizon)`
  background: linear-gradient(180deg, #49bf00 0%, #fffa52 100%);
  overflow: hidden;
  &::before {
    background: url(${city}) center top;
    background-size: auto 100%;
    width: 100%;
    margin: 0px auto;
    bottom: -30px;
    height: 300px;
    display: flex;
    justify-content: center;
  }
`

const ShortPlanetHorizon = styled(StyledHorizon)`
  background: linear-gradient(180deg, #49bf00 0%, #fffa52 100%);
`

const PlanetHorizonContainer = StyledHorizonContainer

const ShortPlanetHorizonContainer = styled(StyledHorizonContainer)`
  &::before {
    background: url(${sky}) no-repeat;
    height: 150px;
    bottom: 50px;
  }
  @media (max-width: 768px) {
    top: 40px;
  }
`

const PlanetHorizonTitle = styled(StyledHorizonTitle)`
  position: absolute;
  right: 250px;
  @media (max-width: 1024px) {
    right: auto;
    top: 60px;
    left: 70%;
  }
`

export { PlanetHorizon, ShortPlanetHorizon, PlanetHorizonContainer, ShortPlanetHorizonContainer, PlanetHorizonTitle }
