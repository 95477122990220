import React from "react"
import styled from "styled-components"
import { Racion, Logic, BluePlanet, MilkyWay, PlanetImage } from "Galaxy"
import background from "avatar-background.svg"

const StyledAvatar = styled.div`
  background: url(${background}) no-repeat center top;
  background-size: cover;
  height: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
  padding-top: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 0;

  .dotted {
    margin-bottom: 0px;
  }

  ${Racion} {
    position: fixed;
    top: 200px;
    left: -30px;
    z-index: -1;

    ${PlanetImage} {
      width: 328px;
      height: 331px;
    }
  }

  ${Logic} {
    position: fixed;
    top: 150px;
    right: 300px;
    z-index: -1;

    @media (max-width: 767px) {
      top: 100px;
      right: -100px;
    }

    @media (min-width: 768px) and (max-width: 1023px) {
      right: 100px;
    }

    ${PlanetImage} {
      width: 231px;
      height: 141px;
    }
  }

  ${MilkyWay} {
    position: fixed;
    top: 10px;
    left: 170px;
    z-index: -1;

    @media (max-width: 767px) {
      left: -80px;
    }

    @media (min-width: 768px) and (max-width: 1023px) {
      left: 50px;
    }
  }
`

const StyledBluePlanet = styled.div`
  position: fixed;
  top: -300px;
  right: -200px;
  overflow: hidden;
  z-index: -1;

  @media (max-width: 767px) {
    display: none;
  }
`

const Layout = ({ children }) => (
  <StyledAvatar>
    <Racion />
    <Logic />

    <StyledBluePlanet>
      <BluePlanet />
    </StyledBluePlanet>

    <MilkyWay />

    {children}
  </StyledAvatar>
)

export default Layout
