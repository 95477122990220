import React from "react"
import BagLayout from "components/layouts/bag"
import { PaymentContainer, Title, Button } from "backpack/payment/styled"

const NewPayment = () => {
  const currentYear = new Date().getFullYear()
  const nextYear = currentYear + 1

  return (
    <BagLayout activeSection="payment">
      <PaymentContainer>
        <Title>Garanta o Mindzup para {nextYear}!</Title>
        <p>
          Seu acesso ao Mindzup tem validade até dia 31/12/{currentYear}, às 23h59. Após essa data, sua conta será
          bloqueada e você não terá mais acesso aos livros digitais, aos jogos e às demais funcionalidades da
          plataforma.
        </p>
        <p>
          Para evitar esse bloqueio, você já pode adquirir seu material digital para {nextYear}! Basta clicar no botão
          abaixo e realizar a compra do Mindzup para o próximo ano.
        </p>
        <Button to="/pedido/proximo-ano" arrow>
          Comprar para {nextYear}
        </Button>
      </PaymentContainer>
    </BagLayout>
  )
}

export default NewPayment
