import scenario from "planets/logic/logic-scenario.svg"
import flag from "planets/logic/logic-high-flag.svg"
import { stone0, stone1, stone2, stone3, stone4, stone5, stone6 } from "planets/logic/stone"

export default {
  stones: [stone0, stone1, stone2, stone3, stone4, stone5, stone6],
  scenario: scenario,
  flag: flag,
  gradient: "linear-gradient(180deg,#D7F57F 0%,#8FFFAF 100%)",
  paddingTop: "20px"
}
