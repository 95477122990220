import bottomCurve from "planets/onis/bottom-curve"
import trail from "planets/onis/trail"
import {
  PlanetHorizon,
  ShortPlanetHorizon,
  PlanetHorizonContainer,
  ShortPlanetHorizonContainer,
  PlanetHorizonTitle
} from "planets/onis/horizon"
import editProfileBackground from "planets/onis/onis-edit-profile.svg"

export default {
  mainColor: "#ff7970",
  secondaryColor: "#ffcd7c",
  bottomCurve: bottomCurve,
  trail: trail,
  knowledgeAreaSlide: { prev: "#f4a7a2", next: "#f4a7a2" },
  dailyMission: {
    questionSlider: { prev: "#f08600", next: "#f08600", arrowColor: "#fff" }
  },
  btnPrimary: { backgroundColor: "#ff9229", textColor: "#fff", shadowColor: "#ce6d10" },
  btnLink: { backgroundColor: "#ff7970", activeTextColor: "#ff9229" },
  gamificationBar: {
    white: { textColor: "#f08600" },
    colored: { textColor: "#fff" }
  },
  header: { colored: { backgroundColor: "#8e3fac" } },
  horizon: {
    PlanetHorizon,
    ShortPlanetHorizon,
    PlanetHorizonContainer,
    ShortPlanetHorizonContainer,
    PlanetHorizonTitle
  },
  editProfileBackground: editProfileBackground,
  games: {
    title: "#ff7970",
    border: "#ff7970",
    detailsHeader: "#ffcd7c"
  }
}
