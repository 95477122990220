import bottomCurve from "planets/logic/bottom-curve"
import trail from "planets/logic/trail"
import {
  PlanetHorizon,
  ShortPlanetHorizon,
  PlanetHorizonContainer,
  ShortPlanetHorizonContainer,
  PlanetHorizonTitle
} from "planets/logic/horizon"
import editProfileBackground from "planets/logic/logic-edit-profile-stones.svg"

export default {
  mainColor: "#fe954e",
  secondaryColor: "#fde8da",
  bottomCurve: bottomCurve,
  trail: trail,
  knowledgeAreaSlide: { prev: "#f8af87", next: "#f8af87" },
  dailyMission: {
    questionSlider: { prev: "#ffc144", next: "#ffc144", arrowColor: "#fff" }
  },
  btnPrimary: { backgroundColor: "#8e3fac", textColor: "#fff", shadowColor: "#6e3085" },
  btnLink: { backgroundColor: "#ff9229", activeTextColor: "#8e3fac" },
  gamificationBar: {
    white: { textColor: "#f57825" },
    colored: { textColor: "#fff" }
  },
  header: { colored: { backgroundColor: "#8e3fac" } },
  horizon: {
    PlanetHorizon,
    ShortPlanetHorizon,
    PlanetHorizonContainer,
    ShortPlanetHorizonContainer,
    PlanetHorizonTitle
  },
  editProfileBackground: editProfileBackground,
  games: {
    title: "#8e3fac",
    border: "#8e3fac",
    detailsHeader: "#ff8cba"
  }
}
