import React from "react"
import { Header } from "Header"
import { GamificationBar } from "GamificationBar"
import { ScrollUp } from "helpers/scroll"
import {
  FirstColumnBadgeBlocked,
  SecondColumnBadgeBlocked,
  ThirdColumnBadgeBlocked,
  SpecialBadgeBlocked
} from "components/badge"
import {
  BadgeTitle,
  DarkGrayText,
  BlockedImagesContainer,
  BadgeContainer,
  PadlockIcon,
  ExpiredIconDetails
} from "backpack/badges/styled"
import BadgesList from "backpack/badges/BadgesList"
import BadgeProgress from "backpack/badges/BadgeProgress"

const getBlockedComponent = badge => {
  if (badge.special) {
    return SpecialBadgeBlocked
  } else {
    switch (BadgesList[badge.slug].column) {
      case 1:
        return FirstColumnBadgeBlocked
      case 2:
        return SecondColumnBadgeBlocked
      case 3:
        return ThirdColumnBadgeBlocked
    }
  }
}

const BlockedBadgeBase = ({ badge, progress, children }) => {
  const BlockedComponent = getBlockedComponent(badge)

  ScrollUp()

  return (
    <BadgeContainer>
      <Header backUrl="/mochila/conquistas" color="colored">
        <GamificationBar />
      </Header>
      {badge.expired ? (
        <div>
          <BadgeTitle>Badge expirada</BadgeTitle>
          {children}
          <BlockedImagesContainer>
            <ExpiredIconDetails />
            <BlockedComponent />
          </BlockedImagesContainer>

          <h3>
            <strong>Badge expirada!</strong>
          </h3>

          <DarkGrayText>{badge.trigger_expired_text} </DarkGrayText>
        </div>
      ) : (
        <div>
          <BadgeTitle>Badge bloqueda</BadgeTitle>
          {children}
          <BlockedImagesContainer>
            <PadlockIcon />
            <BlockedComponent />
          </BlockedImagesContainer>

          <h3>
            <strong>Badge bloqueda!</strong>
          </h3>

          <DarkGrayText>{badge.trigger_not_earned_text}</DarkGrayText>

          {progress && progress.map((progressItem, index) => <BadgeProgress key={index} progress={progressItem} />)}
        </div>
      )}
    </BadgeContainer>
  )
}

export default BlockedBadgeBase
