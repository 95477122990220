import scenario from "planets/groupem/groupem-scenario.svg"
import flag from "planets/groupem/groupem-high-flag.svg"
import { stone0, stone1, stone2, stone3, stone4, stone5, stone6 } from "planets/groupem/stone"

export default {
  stones: [stone0, stone1, stone2, stone3, stone4, stone5, stone6],
  scenario: scenario,
  flag: flag,
  gradient: "linear-gradient(180deg,#FFB450 0%,#FFB450 100%)",
  paddingTop: "0"
}
