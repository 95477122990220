import { createStore, applyMiddleware, compose } from "redux"
import { persistStore } from "redux-persist"
import createRootReducer from "reducers"
import { RESET_KEY } from "actions"
import thunk from "redux-thunk"
import { routerMiddleware } from "connected-react-router"
import { createBrowserHistory } from "history"

export const history = createBrowserHistory()

function resetMiddleware () {
  return next => (reducer, initialState, enhancer) => {
    const enhanceReducer = (state, action) => {
      if (action.type === RESET_KEY) {
        state = undefined
      }
      return reducer(state, action)
    }

    return next(enhanceReducer, initialState, enhancer)
  }
}

export function initialState () {
  return createRootReducer(history)
}

export default function configureStore (preloadedState) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancers(
      applyMiddleware(thunk),
      applyMiddleware(routerMiddleware(history)),
      resetMiddleware()
    )
  )

  const persistor = persistStore(store)

  return { persistor, store }
}
