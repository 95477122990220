import React from "react"
import BagLayout from "components/layouts/bag"
import { PaymentContainer, Title } from "backpack/payment/styled"
import { PoppyMoneyShip } from "components/avatar"

const Processing = () => (
  <BagLayout activeSection="payment">
    <PaymentContainer>
      <PoppyMoneyShip />
      <Title>Processando...</Title>
    </PaymentContainer>
  </BagLayout>
)

export default Processing
