import React from "react"
import HoursUtilized from "components/exams/hours-utilized"
import Grade from "components/exams/grade"
import { BtnLight } from "components/button"
import { Link } from "react-router-dom"
import { redirectToEvaluation } from "exam/essay-actions"

const ExamResults = ({ averageGrade, miniexams, essay, meuSisuAvailable, showMySisu }) => {
  let evaluationIds = []

  if (meuSisuAvailable) {
    miniexams.forEach(function (miniexam) {
      evaluationIds[miniexam.knowledge_area_slug] = miniexam.question_set_session_id
    })

    if (essay) {
      evaluationIds["essay"] = essay.essay_session_id
    }
  }

  return (
    <div>
      {miniexams.map((miniexam, index) => (
        <div className="exam-card-subitem" key={index}>
          <MiniexamResultItem miniexam={miniexam} />
        </div>
      ))}

      {essay && (
        <div className="exam-card-subitem">
          <EssayResultItem essay={essay} />
        </div>
      )}

      {averageGrade && (
        <div className="exam-card-aggregated-grade">
          Nota Geral: <strong>{averageGrade}</strong>
        </div>
      )}

      {averageGrade &&
        meuSisuAvailable && (
          <Link to="#" className="exam-card-link" onClick={e => showMySisu(e, evaluationIds)}>
            Ver MeuSiSu
          </Link>
        )}
    </div>
  )
}

const EssayResultItem = ({ essay }) => (
  <div>
    <div className="exam-card-subitem-top">
      <div>Redação</div>

      {essay.evaluated ? (
        <Grade grade={essay.grade} />
      ) : essay.in_correction ? (
        <span>Em correção</span>
      ) : (
        <span>Expirado</span>
      )}
    </div>

    <div className="exam-card-subitem-bottom">
      {essay.rubies && <div>Rubis: {essay.rubies}</div>}

      <div>
        {essay.evaluated && (
          <Link to="#" className="exam-card-link" onClick={() => redirectToEvaluation(essay.essay_session_id)}>
            Ver correção
          </Link>
        )}
      </div>
    </div>
  </div>
)

const MiniexamResultItem = ({ miniexam }) => (
  <div>
    <div className="exam-card-subitem-top">
      <div>{miniexam.knowledge_area}</div>
      {miniexam.completed ? <Grade grade={miniexam.grade} /> : <span>Expirado</span>}
    </div>

    {miniexam.completed && (
      <div className="exam-card-subitem-bottom">
        <div>{miniexam.experience_amount && <span>XP: {miniexam.experience_amount}</span>}</div>

        {miniexam.total_correct !== null &&
          miniexam.total_wrong !== null && (
            <div>
              Acertos: {miniexam.total_correct} | Erros: {miniexam.total_wrong}
            </div>
          )}
      </div>
    )}
  </div>
)

class ExamCalibratedFinalizedCard extends React.Component {
  constructor (props) {
    super(props)
    this.state = { showResults: false }
    this.toggleResults = this.toggleResults.bind(this)
  }

  toggleResults (e) {
    this.setState({ showResults: !this.state.showResults })
    e.preventDefault()
  }

  render () {
    const { showResults } = this.state
    const { examName, timeSpent, duration, averageGrade, miniexams, essay, meuSisuAvailable, showMySisu } = this.props

    return (
      <div className="exam-card">
        <div className="exam-card-title">{examName}</div>

        <div className="expiration-start-container">
          <HoursUtilized timeSpent={timeSpent} duration={duration} />

          <BtnLight
            to="#"
            color="white"
            value="Resultado"
            onClick={this.toggleResults}
            arrow={!showResults}
            arrowDown={showResults}
            small
          />
        </div>

        {showResults && (
          <ExamResults
            averageGrade={averageGrade}
            miniexams={miniexams}
            essay={essay}
            meuSisuAvailable={meuSisuAvailable}
            showMySisu={showMySisu}
          />
        )}
      </div>
    )
  }
}

export default ExamCalibratedFinalizedCard
