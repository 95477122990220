import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import { Vigo } from "components/avatar"

const DailyMissionDisabled = ({ opened, onClose }) => (
  <CloseableModal small opened={opened} onClose={onClose}>
    <h2>Missão diária indisponível!</h2>
    <p className="medium">Não existem missões diárias disponíveis nesse momento.</p>
    <Vigo />
  </CloseableModal>
)

export default DailyMissionDisabled
