import floor from "planets/pensatus/pensatus-area-floor.svg"
import stone from "planets/pensatus/pensatus-area-stone.svg"

export default {
  mainColor: "#ffe256",
  secondaryColor: "#ffffff",
  floor: {
    image: floor,
    height: 190
  },
  stone: {
    image: stone,
    width: 120,
    height: 99,
    left: 40
  }
}
