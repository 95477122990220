import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import PropTypes from "prop-types"

const BackIcon = styled.svg`
  margin-right: 5px;
  width: 32px;
  height: 32px;
`

const StyledBack = styled(Link)`
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  text-decoration: none;
  color: #ffffff;
  display: flex;
  align-items: center;

  &:hover {
    color: #ffffff;
    text-decoration: none;
  }
`

const Back = ({ to, label, ...rest }) => (
  <StyledBack to={to} {...rest}>
    <BackIcon viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m19.11 9.29a1 1 0 0 0 -1.41 0l-6.4 6.4a1 1 0 0 0 0 1.41l6.4 6.4a1 1 0 0 0 1.41-1.41l-5.7-5.69 5.69-5.69a1 1 0 0 0 .01-1.42z"
        fill="none"
      />
      <path
        d="m19.11 9.29a1 1 0 0 0 -1.41 0l-6.4 6.4a1 1 0 0 0 0 1.41l6.4 6.4a1 1 0 0 0 1.41-1.41l-5.7-5.69 5.69-5.69a1 1 0 0 0 .01-1.42z"
        fill="none"
      />
      <path
        d="m16 0a16 16 0 1 0 16 16 16 16 0 0 0 -16-16zm3.11 22.09a1 1 0 0 1 -1.41 1.41l-6.4-6.4a1 1 0 0 1 0-1.41l6.4-6.4a1 1 0 1 1 1.41 1.41l-5.7 5.7z"
        fill="#fff"
      />
    </BackIcon>

    {label}
  </StyledBack>
)

Back.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string,
}

Back.defaultProps = {
  label: "Voltar",
}

export { Back, StyledBack }
