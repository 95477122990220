import React from "react"
import image from "how-to-badge.svg"
import { Header } from "Header"
import { GamificationBar } from "GamificationBar"
import { ScrollUp } from "helpers/scroll"

const HowToEarnBadges = () => {
  ScrollUp()

  return (
    <div className="bag-manual-about-container">
      <Header backUrl="/mochila/mural" color="colored">
        <GamificationBar />
      </Header>
      <h1 className="purple">Como ganho Badges?</h1>
      <img src={image} />
      <p>
        Os badges são conquistas que você coleciona quando faz diferentes atividades na plataforma, quando vai bem em
        uma área do conhecimento ou quando simplesmente vem pro jogo.
      </p>
      <p>A melhor maneira de ganhar? É só praticar e conquistar muitas Missões Diárias!</p>
    </div>
  )
}

export default HowToEarnBadges
