import PublicRoute from "helpers/public-route"
import AuthenticatedRoute from "helpers/authenticated-route"
import OnboardingActivationNew from "onboarding/activation/new"
import OnboardingActivationConfirmation from "onboarding/activation/confirmation"
import OnboardingActivationAccount from "onboarding/activation/account"
import OnboardingActivationSuccess from "onboarding/activation/success"

const Routes = [
  {
    router: PublicRoute,
    path: "/criar-conta",
    component: OnboardingActivationNew
  },
  {
    router: PublicRoute,
    path: "/criar-conta/confirmacao",
    component: OnboardingActivationConfirmation
  },
  {
    router: PublicRoute,
    path: "/criar-conta/dados",
    component: OnboardingActivationAccount
  },
  {
    router: AuthenticatedRoute,
    path: "/criar-conta/sucesso",
    component: OnboardingActivationSuccess
  }
]

export default Routes
