import React from "react"
import { Link } from "react-router-dom"
import { push } from "connected-react-router"
import { connect } from "react-redux"
import { updateCurrentUser, logout } from "actions"
import { BtnPrimary } from "components/button"
import UserAvatar from "components/user-avatar"
import API from "api"
import { MixpanelTrack, MixpanelReset } from "tags/mixpanel"

class StudentInfo extends React.Component {
  constructor (props) {
    super(props)

    this.handleSignOut = this.handleSignOut.bind(this)
  }

  componentDidMount () {
    this.fetchProfile()
  }

  fetchProfile () {
    const { dispatch } = this.props

    API.get("/profile", response => {
      const { user, relative } = response.data
      dispatch(updateCurrentUser(user, relative))
    })
  }

  handleSignOut (event) {
    event.preventDefault()
    const { dispatch } = this.props

    API.delete("/sessions", () => {
      MixpanelTrack("Efetuou logout")
      MixpanelReset()

      dispatch(logout())
      dispatch(push("/"))
    })
  }

  render () {
    const { name, username, email, school_name, school_year, school_class } = this.props.currentUser

    return (
      <div className="user-info-container">
        <Link to="/mochila/editar-perfil">
          <div className="user-avatar-container">
            <div className="user-avatar-border">
              <UserAvatar />
            </div>
            <i className="icon-pencil" />
          </div>
        </Link>

        <div>
          <h2 className="light">{name}</h2>
          <p>{username}</p>
          <p>{email}</p>
          <p>{school_name}</p>
          <p>
            {school_year} | {school_class}
          </p>

          <BtnPrimary to="#" value="Sair" small onClick={this.handleSignOut} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  }
}

export default connect(mapStateToProps)(StudentInfo)
