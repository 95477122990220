import styled from "styled-components"
import { Xp, Ruby } from "Icons"
import { Link } from "react-router-dom"

const UserAvatarBorder = styled.div`
  border-radius: 50%;
  border: #8e3fac 3px solid;
  justify-content: center;
  width: 50px;
  height: 50px;
  overflow: hidden;
  margin-top: 5px;
  position: relative;
  margin: 0 auto;
`

const UserName = styled.div`
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 5px;
`

const UserContainer = styled.div`
  text-align: center;
  margin: 10px;
`

const UserGrid = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px;
`

const UserLink = styled(Link)`
  text-decoration: none;
`

const UserGamification = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 15px;
  text-transform: uppercase;
`

const GamificationXp = styled.div`
  line-height: 20px;
  margin-right: 10px;
`

const GamificationRuby = styled.div`
  line-height: 20px;
`

const GamificationIconXp = styled(Xp)`
  float: left;
  margin-right: 5px;
`

const GamificationIconRuby = styled(Ruby)`
  float: left;
  margin-right: 5px;
`

const BadgesContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px;
  float: left;
`

const UserNameBadges = styled.div`
  font-size: 39px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Montserrat";
  line-height: 30px;

  @media (max-width: 768px) {
    font-size: 25px;
    width: 100%;
  }
`

const UserColumn = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  float: left;
  width: 45%;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const BadgesTitle = styled.div`
  font-size: 25px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #8e3fac;
  font-family: "Montserrat";
  line-height: 40px;
`

const UserBadgesColumn = styled(UserColumn)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  float: left;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const UserBadgesSeparator = styled.div`
  height: 200px;
  border-left: #8d3fab24 2.5px dotted;
  margin-top: 50px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  float: left;

  @media (max-width: 768px) {
    margin-top: 15px;
    border-top: #8d3fab24 2.5px dotted;
    width: 100%;
    border-left: none;
    margin-left: 0px;
    height: 10px;
  }
`

const GamificationBadges = styled(UserGamification)`
  font-size: 25px;

  @media (max-width: 768px) {
    font-size: 18px;
    width: 50%;
  }
`

const UserAvatarBadges = styled.div`
  @media (max-width: 768px) {
    width: 100%;
  }
`

export {
  UserContainer,
  UserGamification,
  GamificationXp,
  GamificationRuby,
  GamificationIconXp,
  GamificationIconRuby,
  UserAvatarBorder,
  UserName,
  UserNameBadges,
  UserGrid,
  UserLink,
  BadgesTitle,
  BadgesContainer,
  UserBadgesSeparator,
  UserColumn,
  UserBadgesColumn,
  GamificationBadges,
  UserAvatarBadges,
}
