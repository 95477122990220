import React from "react"
import PropTypes from "prop-types"
import { withPlanet } from "planet-context"

const Horizon = ({ title, short, fixed, planet }) => {
  const {
    PlanetHorizon,
    PlanetHorizonContainer,
    PlanetHorizonTitle,
    ShortPlanetHorizon,
    ShortPlanetHorizonContainer
  } = planet.horizon

  const Wrapper = short ? ShortPlanetHorizon : PlanetHorizon
  const Container = short ? ShortPlanetHorizonContainer : PlanetHorizonContainer

  return (
    <Wrapper fixed={fixed}>
      <Container short={short}>{title && <PlanetHorizonTitle>{title}</PlanetHorizonTitle>}</Container>
    </Wrapper>
  )
}

Horizon.propTypes = {
  title: PropTypes.string,
  short: PropTypes.bool,
  fixed: PropTypes.bool,
  planet: PropTypes.shape({
    horizon: PropTypes.shape({
      PlanetHorizon: PropTypes.object.isRequired,
      PlanetHorizonContainer: PropTypes.object.isRequired,
      PlanetHorizonTitle: PropTypes.object.isRequired,
      ShortPlanetHorizon: PropTypes.object.isRequired,
      ShortPlanetHorizonContainer: PropTypes.object.isRequired
    })
  })
}

export default withPlanet(Horizon)
