export default [
  {
    title: "",
    description: "",
    items: [
      {
        text: "A cada rodada, os jogadores podem escolher entre duas opções de jogada:",
        items: [
          {
            text: "Mover a peça uma casa para frente, para trás ou para os lados (movimentos diagonais não são permitidos)."
          },
          {
            text: "Colocar uma barreira em uma casa vazia no tabuleiro. A barreira pode ser colocada na horizontal ou vertical, de modo que duas casas sejam bloqueadas."
          }
        ]
      },
      {
        text: "Jogo Limpo: Não é permitido criar uma situação que feche todas as rotas possíveis do oponente para alcançar seu objetivo. Pode-se aumentar a rota, mas não se pode fazer um bloqueio total.",
      },
      {
        text: "Cara a cara: Quando as duas peças se encontram(ocupam casas vizinhas), o jogador da vez pode saltar sobre a peça do oponente e ocupar a casa subsequente.Caso haja algum impedimento para ocupar esta casa(barreira ou borda do tabuleiro), é permitido ocupar uma das casas vizinhas(resultando em um movimento “na diagonal”).",
      }
    ]
  }
]