import React from "react"
import { Redirect } from "react-router"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { SecondsToHourMins } from "helpers/time-formatter"
import Grade from "components/exams/grade"
import Service from "Service"

class MiniexamDetails extends React.Component {
  constructor (props) {
    super(props)
    this.state = { fireRedirect: false, redirectTarget: "", redirectState: {} }
    this.handleContinue = this.handleContinue.bind(this)
    this.handleStart = this.handleStart.bind(this)
  }

  handleContinue (e) {
    this.createSession()
    e.preventDefault()
  }

  handleStart (e) {
    const { showRules, hasLanguage } = this.props

    if (showRules) {
      this.redirectToRules()
    } else {
      hasLanguage ? this.sendToLanguageChooser() : this.createSession()
    }

    e.preventDefault()
  }

  createSession () {
    const { miniexamId, requestInProgress } = this.props

    if (requestInProgress) return

    Service.startMiniexamSession(miniexamId, response => {
      const { question_set_session_id: questionSetSessionId } = response.data

      this.setState({
        fireRedirect: true,
        redirectTarget: "/simulados/responder",
        redirectState: { questionSetSessionId }
      })
    })
  }

  redirectToRules () {
    const { miniexamId, miniexamName, hasLanguage, examName, examDuration, pausable } = this.props

    this.setState({
      fireRedirect: true,
      redirectTarget: "/simulados/regras",
      redirectState: { miniexamId, miniexamName, hasLanguage, examName, examDuration, pausable }
    })
  }

  sendToLanguageChooser () {
    const { miniexamId } = this.props

    this.setState({
      fireRedirect: true,
      redirectTarget: "/simulados/idioma",
      redirectState: { miniexamId }
    })
  }

  render () {
    const { fireRedirect, redirectTarget, redirectState } = this.state

    if (fireRedirect) {
      return <Redirect to={{ pathname: redirectTarget, state: redirectState }} />
    }

    const { knowledgeArea, timeLeft, correct, incorrect, started, completed, grade, pausable } = this.props

    return (
      <div className="exam-card-subitem">
        <div className="exam-card-subitem-top">
          <div className="exam-card-subitem-title">{knowledgeArea}</div>

          <div>
            {completed ? (
              <Grade grade={grade} />
            ) : (
              <div>
                {started ? (
                  <Link to="#" className="exam-card-subitem-button" onClick={this.handleContinue}>
                    <span>Continuar</span>
                    <i className="arrow-icon arrow-icon-right" />
                  </Link>
                ) : (
                  <Link to="#" className="exam-card-subitem-button" onClick={this.handleStart}>
                    <span>Começar</span>
                    <i className="arrow-icon arrow-icon-right" />
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="exam-card-subitem-bottom">
          <div>
            {pausable && (
              <span>
                Restam <strong>{SecondsToHourMins(timeLeft)}</strong>
              </span>
            )}
          </div>

          {completed && correct !== "undefined" && correct !== null && incorrect !== "undefined" && incorrect !== null && (
            <div>
              Acertos: {correct} | Erros: {incorrect}
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    requestInProgress: state.http.requestInProgress
  }
}

export default connect(mapStateToProps)(MiniexamDetails)
