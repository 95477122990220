export default [
  {
    title: "Regras básicas",
    description: "",
    items: [
      {
        text: "O código é composto de 4 dígitos, utilizando algarismos de 1 a 6. Nenhum dígito pode ser usado mais de uma vez – por exemplo, o código não pode ser 2324, pois o 2 aparece duas vezes.",
      },
      {
        text: "Em cada rodada, alternadamente, o jogador dá um palpite na tentativa de adivinhar o código do seu oponente.",
      },
      {
        text: "O oponente recebe o palpite e deve dar a resposta:",
        items: [
          {
            text: "Um algarismo correto na posição correta é chamado “Na Mosca” e é marcado com um círculo verde totalmente preenchido",
          },
          {
            text: "Um algarismo correto, mas em posição errada é chamado “Golpe” e é marcado com um círculo preenchido pela metade.",
          },
          {
            text: "Um algarismo que não aparece no código não deve ser sinalizado com nenhum símbolo.",
          }
        ]
      },
    ]
  },
  {
    title: "Regras de finalização",
    description: "",
    items: [
      {
        text: "O primeiro jogador a adivinhar corretamente o código do seu oponente vence o jogo. Entretanto, se o jogador que decifrou o código em primeiro lugar foi aquele que começou o jogo, seu oponente tem o direito de tentar adivinhar o código do oponente. Se ele acertar, o jogo empata."
      },
      {
        text: "Se nenhum dos jogadores acertarem o código de seu oponente na quantidade de rodadas permitida pelo tabuleiro, o jogo termina empatado."
      }
    ]
  }
]