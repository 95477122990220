import React from "react"
import CloseableModal from "components/modals/closeable-modal"

const TermsAndConditionsModal = ({ opened, onClose }) => {
  return (
    <CloseableModal opened={opened} onClose={onClose} overlay>
      <h2 className="modal-title">Condições Gerais de Uso</h2>
      <div className="scrollable-container document-modal">
        <h3>CONDIÇÕES GERAIS DE USO ("CGU") DOS SERVIÇOS DISPONIBILIZADOS PLATAFORMA MINDZUP</h3>

        <p>
          O PRESENTE INSTRUMENTO ESTABELECE AS CONDIÇÕES GERAIS DE USO DA PLATAFORMA MINDZUP, RESPECTIVO SITE E EVENTUAL
          APLICATIVO ("<u>PLATAFORMA</u>"), OPERADA E DISPONIBILIZADA PELA MINDLAB DO BRASIL COMÉRCIO DE LIVROS LTDA.,
          COM SEDE NA RUA VERBO DIVINO, 528, 1° ANDAR, CHÁCARA SANTO ANTÔNIO, CEP: 04719-001, INSCRITA NO CNPJ/MF SOB O
          Nº. 10.391.836/0001-18 ("<u>SOCIEDADE</u>"), VOCÊ CONCORDA INTEGRALMENTE COM AS CONDIÇÕES AQUI DISPOSTAS.
        </p>

        <p>
          Para os fins desta CGU,{" "}
          <strong>
            <u>"Usuário"</u>
          </strong>{" "}
          é toda pessoa física que acessa a Plataforma.
        </p>

        <p>
          Caso você esteja usando e/ou contratando a Plataforma em nome de entidade e/ou pessoa jurídica, você garante
          estar autorizado e ter todos os poderes necessários para aceitar a CGU em nome da respectiva entidade e/ou
          pessoa jurídica, assim como garante que essa entidade e/ou pessoa jurídica concorda em indenizar a Sociedade
          por qualquer violação da CGU.
        </p>

        <p>
          Você garante ainda ser maior de idade de acordo com a legislação do país a partir do qual você está acessando
          as Plataformas ou, caso você seja menor de idade, garante estar acompanhado de seu representante legal.
        </p>

        <p>
          Todas as declarações, anuências ou obrigações atribuídas aos Usuários e/ou por eles assumidas nesta CGU
          alcançam, valem e são também assumidas pela(s) entidade(s) ou pessoa(s) jurídica(s) que os Usuários
          representam, ainda que isso não esteja expressamente previsto ou descrito na respectiva disposição ou
          previsão.
        </p>

        <p>
          Esta CGU, em conjunto com o Aviso de Privacidade da Plataforma, disponíveis no site, devidamente lidas e
          aceitas pelo Usuário, são válidas, legítimas e eficazes para todos os fins e efeitos de direito.
        </p>
        <h3>1.Plataforma</h3>
        <p>
          A Mindzup é uma plataforma que está disponível para alunos de escolas parceiras da MindLab. Nesta plataforma,
          o usuário pode realizar missões diárias, jogos de raciocínio, avaliações, além de acessar outros conteúdos.
          Esta plataforma poderá ser acessada através do site mindzup.com.br
        </p>

        <p>
          Os Usuários autorizam, desde já, a Sociedade a exibir qualquer tipo de publicidade na Plataforma, bem como
          estão cientes de que a eventual remuneração proveniente desta publicidade caberá exclusivamente à Sociedade,
          que não se responsabiliza pelo conteúdo exibido por terceiros, cuja responsabilidade caberá única e
          exclusivamente aos anunciantes.
        </p>

        <h3>2. CONDIÇÕES GERAIS DE USO DA PLATAFORMA</h3>

        <p>
          Para acessar as ferramentas e funcionalidades da Plataforma, o Usuário deverá criar uma conta de acesso e, se
          interessado, usufruir/contratar os serviços da Plataforma.
        </p>

        <p>
          Algumas funcionalidades da Plataforma podem estar reservadas para aqueles Usuários que contratarem planos
          pagos.
        </p>

        <p>
          Cada Usuário poderá criar apenas uma conta de acesso na Plataforma. É proibida a criação de contas de acesso
          por Usuários que tenham sido banidos da Plataforma por motivos de infração à Plataforma, bem como de violação
          aos direitos da Sociedade e/ou de terceiros.
        </p>

        <p>
          Após o preenchimento do cadastro e a opção pela utilização de algum serviço da Plataforma, a Sociedade enviará
          ao Usuário todas as instruções necessárias no tocante ao uso da Plataforma.
        </p>

        <p>
          As comunicações ocorrerão, preferencialmente, por meio do endereço de e-mail, telefone e WhatsApps cadastrados
          pelo Usuário, devendo o Usuário sempre manter os seus dados devidamente atualizados. Adicionalmente, a
          Sociedade poderá comunicar-se com o Usuário adotando quaisquer informações de contato constantes da conta de
          acesso.
        </p>

        <h3>3. CONDIÇÕES ESPECÍFICAS DA PLATAFORMA:</h3>

        <p>
          O Usuário tem a sua disposição uma série de produtos e serviços da Mind Lab, sendo que as condições de venda,
          preço e entrega destes produtos são definidas diretamente pela Sociedade, que se responsabiliza por elas.
        </p>

        <p>
          O acesso às funcionalidades se dá de acordo com os produtos e serviços específicos adquiridos pelos Usuários
          por meio da Plataforma. Cada item apresenta características próprias, como mecanismos próprios, prazo de
          expiração, origem, entre outros aspectos, de modo que o Usuário deve ler atentamente sua descrição antes da
          aquisição.
        </p>

        <p>
          As funcionalidades são indicadas na Plataforma, podendo ser alteradas a qualquer momento pela Sociedade, sem
          que nenhuma indenização seja devida ao Usuário em razão de variação de preços.
        </p>

        <p>
          As condições de utilização de cada funcionalidade adquirida pelo Usuário serão aquelas descritas na Plataforma
          no momento da aquisição, de modo que, ao contratar qualquer produto ou serviço disponibilizado na Plataforma,
          o Usuário declara estar ciente e de acordo com as condições a ele relacionadas.
        </p>

        <p>
          As funcionalidades estarão disponíveis ao Usuário apenas para acesso online, por meio da Plataforma, não sendo
          fornecidos CDs, DVDs, pen-drives ou qualquer outra mídia gravada com tais ferramentas, tampouco sendo possível
          o acesso à funcionalidade em modo offline. Sendo assim, é importante que o Usuário, ao adquirir qualquer
          produto da Plataforma, verifique a estabilidade e qualidade de sua conexão à internet.
        </p>

        <p>
          O acesso a qualquer funcionalidade disponibilizada na Plataforma poderá ser encerrado pela Sociedade a
          qualquer tempo, por qualquer motivo, e sem que haja a necessidade de aviso prévio ou outra formalidade, desde
          que respeitados os prazos de expiração indicados ao Usuário no momento da aquisição. Tal encerramento não
          acarretará qualquer responsabilidade à Sociedade.
        </p>

        <h3>4. DOS PAGAMENTOS</h3>

        <h4>4.1 Dos Planos e Serviços Pagos</h4>

        <p>A Sociedade poderá disponibilizar planos e/ou serviços pagos relativos à Plataforma.</p>

        <p>
          A Sociedade reserva-se o direito de ofertar planos, assinaturas ou serviços promocionais especiais, bem como
          reserva-se o direito de modificar, encerrar ou alterar os planos de assinatura, preços e ofertas promocionais
          a qualquer momento de acordo com esta CGU.
        </p>

        <p>
          No caso de alteração do preço de planos de assinatura mensal, a Sociedade comunicará antecipadamente ao
          Usuário no tocante à referida alteração.
        </p>

        <h4>4.2 Dos Meios de Pagamento </h4>

        <p>
          Em relação aos serviços e planos eventualmente pagos, a Sociedade se reserva o direito de aceitar quaisquer
          meios de pagamento, existentes ou que vierem a ser criados, mas não se compromete a aceitar nenhum meio de
          pagamento, especificamente, e nem mesmo a manter disponíveis os meios de pagamento atualmente aceitos, os
          quais poderão ser modificados a qualquer momento, sem a comunicação prévia dos Usuários, ao critério exclusivo
          da Sociedade.
        </p>

        <p>
          Os Usuários estão cientes e concordam com o fato de que algumas modalidades de pagamento poderão ser
          intermediadas por empresas independentes, não administradas pela Sociedade e em relação às quais a Sociedade
          não possui qualquer controle. A Sociedade não garante ou se responsabiliza pelas atividades destas empresas de
          gestão ou processamento de pagamentos e os Usuários concordam que quaisquer danos por eles ocasionados não
          serão de responsabilidade da Sociedade.
        </p>

        <p>
          Alguns meios de pagamento poderão, eventualmente, exigir uma aprovação de terceiros (e.g. administradora do
          cartão de crédito) ou etapas adicionais de segurança e controle. Nestes casos, a Sociedade se reserva no
          direito de aguardar tais aprovações e solicitar quaisquer informações adicionais do Usuário antes de concluir
          a transação.
        </p>

        <p>
          Os meios de pagamento disponíveis na Plataforma podem ser gerenciados ou administrados, no todo ou em parte,
          por terceiros. Nestes casos, o Usuário está ciente e concorda que estes terceiros poderão receber suas
          informações cadastrais e financeiras. A Sociedade não se responsabiliza pela forma como esses terceiros irão
          usar suas informações e/ou processar os pagamentos relativos às transações.
        </p>

        <p>
          A Sociedade poderá aceitar pagamentos parcelados ou criar condições especiais de pagamento, mas não se
          compromete a fornecer tais opções aos Usuários e poderá modificar as opções porventura existentes ao seu
          exclusivo critério, sem a comunicação prévia dos Usuários.
        </p>

        <p>
          Informações sobre as condições e os meios de pagamento disponíveis poderão ser verificadas pelos Usuários
          diretamente na Plataforma.
        </p>

        <h3>5. DO DIREITO DE ARREPENDIMENTO </h3>

        <p>
          Ao Usuário será facultado o exercício do direito de arrependimento em até 7 (sete) dias corridos contados da
          data da contração dos serviços da Plataforma. Para tanto, o Usuário deverá manifestar sua decisão de
          cancelamento conforme os respectivos termos e políticas aplicáveis às plataformas de terceiro utilizada para a
          disponibilização da Plataforma ou enviar um e-mail ajuda@mindlab.com.br.
        </p>

        <p>
          Em transações efetuadas mediante cartão de crédito ou meio de pagamento similar, a Sociedade notificará a
          administradora do meio de pagamento para efetuar o estorno do valor pago na fatura seguinte, de uma só vez,
          independentemente do número de parcelas utilizado na compra. O prazo de ressarcimento e o cancelamento das
          parcelas remanescentes será responsabilidade exclusiva da administradora do meio de pagamento.
        </p>

        <p>
          Em transações pagas via boleto bancário ou meio de pagamento similar, a restituição será efetuada por meio de
          depósito, em conta bancária de titularidade do Usuário, em até 60 dias, contados da data de exercício do
          arrependimento.
        </p>

        <p>
          Caso o Usuário tenha contratado um Serviço e tenha interesse em exercer o direito de arrependimento, o
          Usuário, além de seguir o trâmite acima, deverá estar ciente de que o arrependimento apenas será possível se o
          Usuário ainda não tiver usufruído de quaisquer dos serviços. Em outras palavras, o direito de arrependimento
          apenas poderá ser exercido se o Usuário ainda não tiver iniciado os Cursos.
        </p>

        <p>
          Todas as ocorrências que envolvam o cancelamento dos serviços após o prazo de 15 (quinze) dias corridos acima
          mencionado serão conduzidas pela Sociedade de acordo com a legislação aplicável.
        </p>

        <p>
          Caso o Usuário tenha interesse em cancelar o serviço contratado após o prazo de arrependimento, o Cliente
          deverá enviar um e-mail para ajuda@mindlab.com.br e aguardar os esclarecimentos que serão enviados pela
          Sociedade.
        </p>

        <h3>6. DO DIREITO DA PLATAFORMA </h3>

        <p>
          A Sociedade é a única e legítima titular de todos os direitos, inclusive intelectuais, relativos à Plataforma,
          incluindo os correspondentes códigos (fonte, objeto e outros), versões, atualizações, elementos visuais,
          interface e documentos técnicos, bem como dos textos, gráficos, dados, imagens, vídeos, conteúdos, cursos,
          sons, ilustrações, marcas, slogans, bases de dados, nomes empresariais, logotipos, ícones, conjunto-imagem
          (trade dress) constantes da Plataforma ou disponibilizadas na Plataforma pela Sociedade, os quais não poderão
          ser copiados, imitados ou usados, no todo ou em parte, sem a prévia permissão por escrito de um representante
          legal da Sociedade.
        </p>

        <p>
          Além disso, é expressamente proibida a utilização de metamarcas ou qualquer outra espécie de “código oculto”
          que contenha a expressão “MINDLAB”, “MINDZUP” ou quaisquer outras expressões idênticas ou semelhantes às
          marcas, logotipos, slogans, nomes de domínio, nomes empresariais e demais sinais distintivos relativos à
          Plataforma, sem a prévia permissão por escrito de um representante legal da Sociedade.
        </p>

        <p>
          A Sociedade garante aos Usuários, desde que respeitada a CGU e todas as políticas de documentos relativos à
          Plataforma, um direito temporário, limitado, revogável e não exclusivo de usar a Plataforma. No entanto, é
          expressamente proibido: (a) reproduzir ou retransmitir a Plataforma, em parte ou no todo; (b) usar dispositivo
          de acesso automatizado à Plataforma - e/ou à estrutura ou base de dados da Plataforma - que não tenha sido
          expressamente autorizado pela Sociedade; (c) usar dispositivo de mineração de dados e/ou que tenha
          funcionalidade similar para coletar e/ou extrair dados da Plataforma; (d) manipular ou exibir a Plataforma
          e/ou os seus respectivos conteúdos usando enquadramento ou tecnologia de navegação afim; e, (e) realizar
          engenharia reversa na Plataforma.
        </p>

        <p>
          A Sociedade reserva todos os direitos relativos à Plataforma que não tenham sido expressamente regulados nesta
          CGU. O Usuário - assim como a entidade ou pessoa jurídica que o Usuário representa - não terá qualquer direito
          em relação à Plataforma além daqueles expressamente previstos nesta CGU, não podendo, dentre outras medidas,
          sublicenciar, modificar, adaptar ou customizar a Plataforma sem a prévia aprovação da Sociedade.
        </p>

        <h3>7. RESPONSABILIDADES E DECLARAÇÕES DOS USUÁRIOS</h3>
        <h4>7.1 Declarações </h4>

        <p>
          Ao realizar o cadastro e/ou utilizar a Plataforma, o Usuário declara-se ciente de que a utilização de dados
          falsos, inválidos, incorretos ou de terceiros (sem a respectiva autorização) pode acarretar a interrupção do
          acesso à Plataforma, sem prévia notificação, e ainda, a depender do caso concreto, caracterizar crime ou ato
          ilícito, sujeitando o Usuário às penalidades previstas na legislação.
        </p>

        <p>
          O Usuário declara ser o legítimo e exclusivo titular dos direitos intelectuais relativos ao conteúdo por ele
          inserido na Plataforma ou, alternativamente, deter todas as autorizações necessárias para incluir e explorar o
          referido conteúdo através da Plataforma, ou seja, o Usuário declara que, ao utilizar a Plataforma, não
          infringe quaisquer direitos de terceiros ou da Sociedade. Ademais, o Usuário declara que suas atividades são
          lícitas e estão de acordo com a legislação vigente em sua jurisdição.
        </p>

        <p>
          O Usuário garante e declara estar ciente de que não poderá incluir na Plataforma conteúdo que possa ser
          ofensivo à comunidade online ou à sociedade, como, por exemplo, conteúdo que promova qualquer forma de
          racismo, segregação religiosa ou política, preconceito, intolerância, ódio ou dano de qualquer tipo, contra
          qualquer grupo ou indivíduo, que promova informações falsas, enganosas, atividades ilegais ou condutas que
          sejam abusivas, obscenas, pornográficas ou incompatíveis com a ordem pública, moral e bons costumes ou que
          envolvam a publicação e/ou a inserção de quaisquer materiais que contenham imagens de menores de idade (sem o
          consentimento expresso e por escrito dos responsáveis legais).
        </p>

        <p>
          O Usuário declara que está ciente que, caso o Usuário disponibilize vídeos ou fotografias contendo suas
          respectivas imagens nas Plataformas, a Sociedade pode ter acesso a dados pessoais sensíveis (e.g. dados de
          raça, dados de etnia, religião), sendo que o tratamento dos referidos dados, se - e quando aplicável -, será
          realizado nos termos do Aviso de Privacidade da Plataforma.
        </p>

        <h4>7.2 Obrigações </h4>

        <p>
          O Usuário deverá informar através da Plataforma todos os dados necessários para a formação de seu cadastro e
          desempenho adequado das funções da Plataforma, comprometendo-se a fornecer, sempre, informações verdadeiras,
          corretas, atuais e completas relativas aos seus dados cadastrais, responsabilizando-se civil e criminalmente
          por estas informações.
        </p>

        <p>O Usuário deverá manter o seu cadastro atualizado junto à Plataforma.</p>

        <p>
          O Usuário não poderá: (a) acessar a Plataforma de forma ilícita; (b) burlar mecanismos de autenticação e/ou de
          segurança da Plataforma; (c) permitir que terceiros acessem a Plataforma utilizando as suas credenciais; (d)
          utilizar a Plataforma para finalidade ilegal e/ou que viole direitos de terceiros ou da Sociedade.
        </p>

        <p>
          O Usuário deverá comunicar, imediatamente, a eventual perda, alteração não autorizada, extravio e/ou
          divulgação das informações de <i>login</i> e senha a ele eventualmente atribuídas para acessar a Plataforma.
        </p>

        <p>
          É expressamente vedada a utilização de <i>login</i> e senha por mais de uma pessoa ou o compartilhamento de
          dados de acesso entre múltiplos Usuários.
        </p>

        <p>
          É expressamente proibido que o conteúdo eventualmente disponibilizado na Plataforma seja copiado, reproduzido,
          compartilhado, imitado ou usado, no todo ou em parte, sem a prévia permissão por escrito dos respectivos
          titulares e/ou da Sociedade.{" "}
        </p>

        <h4>7.3 Consequências e penalidades</h4>

        <p>
          A Sociedade poderá excluir temporária ou definitivamente os Usuários - e/ou suspender o respectivo acesso à
          Plataforma -, caso seja verificado qualquer procedimento indevido, inconsistências e/ou incorreções nos
          cadastros do Usuário ou condutas que violem - ou possam violar - direitos de terceiros ou da Sociedade.
        </p>

        <p>
          Ademais, caso sejam verificados acessos simultâneos utilizando os mesmos dados de login e senha, se reserva à
          Sociedade o direito de suspender ou interromper o acesso do Usuário à Plataforma.
        </p>

        <p>
          A Sociedade poderá, independentemente de comunicação prévia, excluir da Plataforma qualquer conteúdo que
          constitua uma violação aos termos da CGU e/ou que a Sociedade entenda como sendo ofensivo, inadequado ou
          prejudicial a terceiros ou à própria Sociedade.
        </p>

        <p>
          Na hipótese da Sociedade ser demandada, a qualquer tempo, em razão de fatos ou atos cuja responsabilidade seja
          atribuída ao Usuário nos termos desta CGU, o Usuário deverá: (a) intervir voluntariamente no feito, pleiteando
          a imediata exclusão da Sociedade da lide, (b) assumir a responsabilidade integral e exclusiva pelo pagamento
          e/ou pelas providências reclamadas, e (c) ressarcir integralmente a Sociedade pelos custos e despesas
          porventura incorridos em consequência da demanda. Caso não se opere a referida exclusão, o Usuário responderá
          pelo pagamento e pelo cumprimento integral da decisão, ou, se aplicável, deverá ressarcir imediatamente a
          Sociedade.
        </p>

        <h3>8. LINKS</h3>

        <p>
          Quando o Usuário sair da Plataforma, a CGU não será mais aplicável. Sugerimos conferir os termos e as
          políticas de qualquer website e/ou aplicativo que você acessar e/ou para o qual você for direcionado.
        </p>

        <p>
          Por conta do exposto no parágrafo acima, o Usuário concorda em não responsabilizar a Sociedade por perdas,
          danos ou outros problemas de qualquer espécie que possam surgir da utilização de websites que contenham links
          para a Plataforma, ou cujos links estejam disponíveis na Plataforma, bem como está ciente de que qualquer
          negociação com tais websites não envolve, vincula e/ou obriga a Sociedade.
        </p>

        <h3>9. ÁREAS INTERATIVAS</h3>

        <p>
          A Plataforma poderá incluir áreas ou serviços interativos, como fóruns de discussão, redes sociais etc., ou
          ferramentas para comunicação direta com serviços interativos de terceiros (<i>FaceBook, Twitter, etc.</i>),
          nos quais o Usuário ou terceiros podem criar, publicar e/ou armazenar mensagens, comentários ou outros
          conteúdos (<u>"Áreas Interativas"</u>).
        </p>

        <p>
          Ao usar uma Área Interativa, o Usuário deve lembrar que essas áreas são abertas (a outros Usuários e às vezes
          ao público) e que qualquer informação publicada ou fornecida pelo Usuário poderá ser visualizada por
          terceiros. A Sociedade não se responsabiliza por informações postadas ou enviadas pelo Usuário no que se
          refere às Áreas Interativas, nem pela forma como outros indivíduos poderão usar essas informações, inclusive
          enviando mensagens não solicitadas ou ofensivas.
        </p>

        <p>
          As Áreas Interativas poderão envolver qualquer tipo de conteúdo ou informação e, se a Sociedade fornecer tais
          serviços, o Usuário concorda expressamente em apenas usar tais serviços para enviar mensagens apropriadas e
          que observem a legislação vigente. Segue abaixo uma lista exemplificativa de mensagens ilegais ou
          inapropriadas e, portanto, proibidas na Plataforma:
        </p>

        <ul>
          <li>
            possam ser ofensivas à comunidade online ou à sociedade, como, por exemplo, conteúdo que promova qualquer
            forma de racismo, segregação religiosa ou política, preconceito, intolerância, ódio ou danos de qualquer
            tipo contra qualquer grupo ou indivíduo;
          </li>

          <li>
            constituam assédio ou promovam o assédio de outra pessoa, sendo tal pessoa Usuário ou não da Plataforma;
          </li>

          <li>envolvam a transmissão de "spam", "correntes” ou correspondência não solicitada em massa;</li>

          <li>
            promovam informações falsas, enganosas, atividades ilegais ou conduta que seja abusiva, obscena ou
            incompatível com a ordem pública, moral e bons costumes;
          </li>

          <li>
            envolvam a publicação e/ou a inserção de quaisquer materiais, que contenham imagens de menores de idade, sem
            o consentimento expresso e por escrito dos responsáveis legais;
          </li>

          <li>
            promovam cópia ilegal ou não autorizada de trabalhos de terceiros protegidos por direito autoral ou similar;
            ou,
          </li>

          <li>
            constituam ou incentivem conduta que possa ser considerada ofensa criminal ou seja, de qualquer outra
            maneira, contrária à lei ou infrinja os direitos de qualquer terceiro em qualquer país do mundo.
          </li>
        </ul>

        <p>
          A Sociedade se reserva o direito de alterar esta lista a qualquer momento, e investigar e tomar medidas legais
          apropriadas, a seu exclusivo critério, contra qualquer Usuário que infrinja este dispositivo, inclusive e sem
          limitação, a remoção da comunicação inadequada e o término do direito de tais infratores de usar a Plataforma.
        </p>

        <p>
          Ao postar ou distribuir mensagens nas Áreas Interativas, você expressamente concede à Sociedade o direito
          definitivo, irrevogável e irretratável de tornar disponível o conteúdo dessas mensagens, que poderá ser
          livremente usado, copiado, publicado, traduzido e distribuído em qualquer meio e de qualquer forma, inclusive
          por terceiros, sem necessidade de sua expressa permissão no momento da utilização.
        </p>

        <p>
          Você concorda em não postar ou distribuir qualquer informação ou material cujos direitos, inclusive de
          propriedade intelectual, pertençam a terceiros, sem o consentimento expresso e por escrito de tais partes, e
          concorda também em exonerar a Sociedade de qualquer responsabilidade civil ou criminal no evento de qualquer
          demanda surgida de, ou relacionada com, a sua infração deste dispositivo
        </p>

        <h3>10. ISENÇÃO DA RESPONSABILIDADE</h3>

        <p>
          O conteúdo, as informações e os documentos inseridos na Plataforma são de responsabilidade única e exclusiva
          dos respectivos Usuários, que arcarão com as sanções civis e penais decorrentes dos prejuízos que
          eventualmente gerarem, isentando a Sociedade de toda e qualquer responsabilidade nesse sentido.
        </p>

        <p>
          A Plataforma poderá não funcionar corretamente por problemas da rede e/ou por conta de problemas de conexão de
          qualquer um dos terminais e servidores envolvidos, situações que fogem do controle da Sociedade e, portanto,
          não serão de responsabilidade da Sociedade. O Usuário está ciente de que a Plataforma poderá não ser
          compatível com outros programas instalados nas máquinas ou dispositivos dos Usuários e/ou poderá apresentar
          falhas, ainda desconhecidas da Sociedade, capazes de obstruir o acesso dos Usuários, gerar informações
          incorretas e/ou não armazenar adequadamente os dados oriundos das atividades dos Usuários na Plataforma.
        </p>

        <p>
          O USO QUE O USUÁRIO FAZ DA PLATAFORMA É POR SUA CONTA E RISCO. O USUÁRIO É O ÚNICO RESPONSÁVEL POR QUALQUER
          PERDA OU DANO DECORRENTE DESSE USO. A PLATAFORMA É FORNECIDA “NA FORMA EM QUE SE ENCONTRAM” E “CONFORME A
          DISPONIBILIDADE”. SEGUINDO OS LIMITES PERMITIDOS PELA LEI, A SOCIEDADE EXPRESSAMENTE SE ISENTA DE TODAS AS
          RESPONSABILIDADES, GARANTIAS OU CONDIÇÕES DE QUALQUER NATUREZA, SEJAM EXPRESSAS OU IMPLÍCITAS.
        </p>

        <p>
          A SOCIEDADE NÃO GARANTE QUE: (A) A PLATAFORMA ATENDE ÀS NECESSIDADES DO USUÁRIO; (B) A PLATAFORMA FUNCIONARÁ
          DE MANEIRA ININTERRUPTA, PONTUAL, SEGURA OU LIVRE DE ERROS; (C) O CONTEÚDO DISPONÍVEL OU GERADO NA PLATAFORMA
          SERÁ PRECISO OU CONFIÁVEL; (D) A QUALIDADE DA PLATAFORMA ATENDERÃO ÀS EXPECTATIVAS DO USUÁRIO, E; (E)
          QUAISQUER ERROS NO CONTEÚDO OU NA PLATAFORMA SERÃO EFETIVAMENTE CORRIGIDOS.
        </p>

        <p>
          A SOCIEDADE NÃO GARANTE QUE O CONTEÚDO DA PLATAFORMA ESTEJA LIVRE DE CONTAMINAÇÃO POR VÍRUS OU POR OUTROS
          ELEMENTOS CONTAMINANTES, PREJUDICIAIS OU DESTRUTIVOS. A SOCIEDADE ESFORÇA-SE PARA ASSEGURAR A CONFIABILIDADE
          DO CONTEÚDO, MAS NÃO PODE GARANTIR A EXATIDÃO, A CORREÇÃO OU A SEGURANÇA DO MESMO.
        </p>

        <h3>11. LIMITAÇÃO DA RESPONSABILIDADE</h3>

        <p>
          A SOCIEDADE, SEUS SÓCIOS, ADMINISTRADORES, FUNCIONÁRIOS OU AGENTES NÃO SERÃO RESPONSÁVEIS NO CASO DE QUAISQUER
          DANOS DIRETOS, INDIRETOS, INCIDENTAIS, ESPECIAIS, CONSEQUENCIAIS ETC. (MESMO QUE A SOCIEDADE TENHA SIDO
          AVISADA DA POSSIBILIDADE DE TAIS DANOS), RESULTANTES DE: (A) USO OU IMPOSSIBILIDADE DE USO DA PLATAFORMA (B)
          ACESSO NÃO AUTORIZADO, ALTERAÇÃO DAS TRANSMISSÕES OU DOS DADOS DOS USUÁRIOS; (C) DECLARAÇÕES OU ATOS DE
          TERCEIROS PRATICADOS ATRAVÉS DA PLATAFORMA; (D) CONFIANÇA DO USUÁRIO EM QUALQUER INFORMAÇÃO OBTIDA NA
          PLATAFORMA OU RESULTANTE DE ENGANOS, OMISSÕES, INTERRUPÇÕES, EXCLUSÕES DE ARQUIVOS, DEFEITOS, VÍRUS, ATRASOS
          EM OPERAÇÃO OU TRANSMISSÃO OU FALHAS DE DESEMPENHO, SEJAM ESTES RESULTANTES DE FORÇA MAIOR, FALHA DE
          COMUNICAÇÕES, ROUBO, DESTRUIÇÃO OU ACESSO NÃO AUTORIZADO AOS REGISTROS, PROGRAMAS OU SERVIÇOS DAS SOCIEDADES;
          (E) IMPOSSIBILIDADE DE USO OU PERDA DE DADOS RELACIONADOS À PLATAFORMA E/OU AOS USUÁRIOS; OU (F) QUALQUER
          OUTRO PROBLEMA RELACIONADO À PLATAFORMA E NÃO LISTADO EXPRESSAMENTE.
        </p>

        <h3>12. RESCISÃO, DESCONTINUAÇÃO E INTERRUPÇÃO DO ACESSO </h3>

        <p>
          Não obstante a CGU, a Sociedade reserva-se o direito de, sem aviso-prévio e de acordo com seus únicos e
          exclusivos critérios, bloquear o seu uso e acesso à Plataforma.
        </p>

        <p>
          A Sociedade, ainda, reserva-se no direito de, sem aviso-prévio e de acordo com seus únicos e exclusivos
          critérios, descontinuar a Plataforma, parcial ou integralmente.
        </p>

        <p>
          No caso de violação ou suspeita de violação da CGU e/ou da Aviso de Privacidade, a Sociedade poderá, sem a
          necessidade de qualquer interpelação ou notificação prévia, interromper o acesso do Usuário à Plataforma.
        </p>

        <p>
          A Sociedade poderá também denunciar e terminar quaisquer licenças reguladas por esta CGU ou por outros
          documentos que envolvam a Plataforma, suspendendo ou interrompendo o acesso de um Usuário à Plataforma,
          independentemente de justificativa e/ou comunicação prévia.
        </p>

        <p>
          Nestes casos, sem prejuízo das disposições constantes do Aviso de Privacidade, a Sociedade poderá excluir e/ou
          eliminar definitivamente todos e quaisquer dados do Usuário contidos ou inseridos na Plataforma.
        </p>

        <p>
          Nas hipóteses de interrupção de acesso e/ou de exclusão de dados previstas acima, o Usuário concorda em
          exonerar totalmente a Sociedade de toda e qualquer responsabilidade decorrente de quaisquer prejuízos ou danos
          experimentados pelo Usuário e/ou pela entidade ou pessoa jurídica que o Usuário representa.
        </p>

        <h3>13. EXCLUSÕES E LIMITAÇÕES </h3>

        <p>
          Nada do que está escrito na CGU tem como intenção excluir ou limitar qualquer condição, garantia, direito ou
          responsabilidade que não possa ser legalmente excluída ou limitada. Algumas jurisdições não permitem a
          exclusão de certas garantias ou condições ou a limitação ou exclusão de responsabilidade por perdas e danos.
          Consequentemente, apenas as limitações que forem permitidas por lei na sua jurisdição se aplicarão a você. Nos
          casos em que exclusão de responsabilidade não se mostrar possível, mas a limitação de responsabilidade for
          legalmente aplicável, a responsabilidade total da Sociedade estará limitada a R$ 2.000,00 (dois mil Reais).
        </p>

        <h3>14. ALTERAÇÕES, VALIDADE E VIGÊNCIA DAS CLÁUSULAS</h3>

        <p>
          Qualquer renúncia ou alteração dos dispositivos da CGU só terá efeito caso apresentada por escrito e assinada
          pelos representantes legais da Sociedade. Se uma cláusula da CGU for anulada ou considerada inaplicável, as
          demais cláusulas, sempre que possível, permanecerão válidas e vigentes.
        </p>

        <p>
          A Sociedade reserva-se no direito de alterar a CGU, bem como qualquer outra política, documento ou termo
          envolvendo a Plataforma, a qualquer momento e ao seu exclusivo critério. Os direitos não concedidos
          expressamente restam reservados à Sociedade.
        </p>

        <p>
          As alterações entrarão em vigor no momento da respectiva publicação na Plataforma. O uso da Plataforma depois
          da publicação constituirá aceitação de eventuais alterações da CGU. Dessa forma, aconselhamos os Usuários a
          relerem a CGU sempre que acessarem a Plataforma.
        </p>

        <p>
          Caso haja divergência entre o conteúdo desta CGU e aquele constante de outro documento assinado pelo Usuário
          (com exceção da Aviso de Privacidade), prevalecerá, sempre, o conteúdo do outro documento assinado pelo
          Usuário.
        </p>

        <h3>15. LEGISLAÇÃO APLICÁVEL E FORO</h3>

        <p>
          Qualquer litígio relacionado à presente CGU deverá ser submetido ao Foro da Comarca de São Paulo, Estado de
          São Paulo, Brasil, que prevalecerá sobre qualquer outro, por mais privilegiado que seja ou venha a se tornar,
          e será julgado conforme as leis da República Federativa do Brasil.
        </p>

        <h3>16. PERGUNTAS E ESCLARECIMENTOS </h3>

        <p>
          Perguntas, solicitações, reclamações e comentários sobre a Plataforma ou sobre a CGU poderão ser dirigidos ao
          ajuda@mindlab.com.br.
        </p>
        <p>
          <b>Última atualização:</b> 14/01/2022
        </p>
      </div>
    </CloseableModal>
  )
}

export default TermsAndConditionsModal
