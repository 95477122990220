import { BADGES_FETCH } from "backpack/actions"

const backpackInitialState = {
  badges: []
}

const backpackReducer = (state = backpackInitialState, action) => {
  switch (action.type) {
    case BADGES_FETCH:
      return Object.assign({}, state, {
        badges: action.badges
      })
    default:
      return state
  }
}

export default backpackReducer
