import React from "react"
import { SecondsToHourMins } from "helpers/time-formatter"

const HoursLeft = ({ timeLeft, duration }) => (
  <div className="exam-card-metadata">
    <i className="clock-icon white-transparent small" />
    <div>
      <span>Restam <strong>{SecondsToHourMins(timeLeft)}</strong></span>
      <div className="exam-card-metadata-subitem">Total: <strong>{SecondsToHourMins(duration)}</strong></div>
    </div>
  </div>
)

export default HoursLeft
