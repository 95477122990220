import FirstColumnBadgeBlocked from "components/badges/first-column-badge-blocked"
import SecondColumnBadgeBlocked from "components/badges/second-column-badge-blocked"
import ThirdColumnBadgeBlocked from "components/badges/third-column-badge-blocked"
import SpecialBadgeBlocked from "components/badges/special-badge-blocked"
import Newbie from "components/badges/newbie"
import HomoSapiensSapiensPlus from "components/badges/homo-sapiens-sapiens-plus"
import HumanityHero from "components/badges/humanity-hero"
import MagicOfLetters from "components/badges/magic-of-letters"
import MasterOfNumbers from "components/badges/master-of-numbers"
import MetWeeklyGoal from "components/badges/met-weekly-goal"
import Adventurous from "components/badges/adventurous"
import Epic from "components/badges/epic"
import Superstar from "components/badges/superstar"
import Apollo from "components/badges/apollo"
import ConqueredPlanet from "components/badges/conquered-planet"
import Conquer from "components/badges/conquer"
import AlienMaster from "components/badges/alien-master"
import GalacticMaster from "components/badges/galactic-master"
import UniverseMaster from "components/badges/universe-master"
import JoiningForces from "components/badges/joining-forces"
import MakingQuestions from "components/badges/making-questions"
import Imagining from "components/badges/imagining"
import DivingIntoMethods from "components/badges/diving-into-methods"
import SolvingProblems from "components/badges/solving-problems"
import PlanningAndOrdering from "components/badges/planning-and-ordering"
import InformationAnalysis from "components/badges/information-analysis"
import ResourceManagement from "components/badges/resource-management"
import AdvanceThinkingSkills from "components/badges/advanced-thinking-skills"
import PlanningTheFuture from "components/badges/planning-the-future"
import BenefitsOfCollaboration from "components/badges/benefits-of-collaboration"
import TacticsAndStrategies from "components/badges/tactics-and-strategies"
import InvestingResources from "components/badges/investing-resources"
import RiskManagement from "components/badges/risk-management"
import MemoryApplications from "components/badges/memory-applications"

export {
  FirstColumnBadgeBlocked,
  SecondColumnBadgeBlocked,
  ThirdColumnBadgeBlocked,
  SpecialBadgeBlocked,
  Newbie,
  HomoSapiensSapiensPlus,
  HumanityHero,
  MagicOfLetters,
  MasterOfNumbers,
  MetWeeklyGoal,
  Adventurous,
  Epic,
  Superstar,
  Apollo,
  ConqueredPlanet,
  Conquer,
  AlienMaster,
  GalacticMaster,
  UniverseMaster,
  JoiningForces,
  MakingQuestions,
  Imagining,
  DivingIntoMethods,
  SolvingProblems,
  PlanningAndOrdering,
  InformationAnalysis,
  ResourceManagement,
  AdvanceThinkingSkills,
  PlanningTheFuture,
  BenefitsOfCollaboration,
  TacticsAndStrategies,
  InvestingResources,
  RiskManagement,
  MemoryApplications
 }
