import React from "react"
import Layout from "GamesStation/Layout"
import { BtnPrimary } from "components/button"
import { findGameByUrl, findVideosById } from "GamesStation/details"
import { ScrollUp } from "helpers/scroll"
import { MixpanelTrack } from "tags/mixpanel"
import {
  GameDetailsContainer,
  GameCarouselContainer,
  GameImageContainer,
  GameTitle,
  GameDescriptionContainer
} from "GamesStation/styled"
import Carousel from "components/carousel/carousel"

const Instruction = ({ item, obs }) => (
  <div className="GameDetails-instructionItem">
    <div className="GameDetails-title">{item.title}</div>
    {item.description && <div className="GameDetails-text GameDetails-text-description">{item.description}</div>}
    <ul className="GameDetails-ruleList">
      {item.items.map((item, index) => <li key={index}>
        <span className="GameDetails-text">{item.text}</span>
        {item.items && <ol className="GameDetails-orderedList">
          {item.items.map((item, index) => <li key={index}>{item.text}</li>)}
        </ol>}
      </li>)}
    </ul>
    {obs && <span><strong>Observação:</strong> {obs}</span>}
  </div>
)

class GameDetails extends React.Component {
  constructor(props) {
    super(props)
    this.game = findGameByUrl(this.props.computedMatch.params.name)
    this.videos = findVideosById(this.game.id)
  }

  componentDidMount() {
    MixpanelTrack("Visualizou Detalhe do Jogo", { "JOGO SELECIONADO": this.game.id })
    ScrollUp()
  }

  render() {
    const { title, goal, instructions, setup, mode, url: gameUrl, component: GameComponent, id } = this.game
    const { description: modeDescription, playAction } = mode
    const { videos } = this.videos

    const gamesWithDiferentRules = [
      "yams",
      "domino",
      "minibridge",
      "wolfandsheep",
      "battlesubmarine",
      "mancala",
      "dudo"
    ]

    return (
      <Layout backUrl="/jogos/estacao" title={title}>
        <GameDetailsContainer>
          <GameCarouselContainer>
            <Carousel
              content={videos}
              type="video"
              size={videos.length}
            />
          </GameCarouselContainer>
          <GameDescriptionContainer>
            <div className="game-details-only-in-mobile">
              <GameTitle>{title}</GameTitle>
              <GameComponent />
            </div>
            {gamesWithDiferentRules.includes(id) && <div className="md-game-alert-tip">
              ATENÇÃO: ESTE JOGO CONTÉM ALGUMAS REGRAS DIFERENTES DAS ENCONTRADAS NO LIVRO DO ALUNO.
            </div>}

            {id === "gamao" && <div className="md-game-alert-tip">
              ATENÇÃO: ESTA É A VERSÃO COMPLETA DO JOGO GAMÃO.
            </div>}

            <div className="GameDetails-fixedTitle">
              Objetivo e Regras
            </div>

            <section className="GameDetails-textSection">
              <div className="GameDetails-title">
                Modo de Jogo
              </div>
              <p>{modeDescription}</p>
            </section>

            <section className="GameDetails-textSection">
              <div className="GameDetails-title">
                Objetivo do Jogo
              </div>
              <p>{goal}</p>
            </section>

            {setup && (
              <section className="GameDetails-textSection">
                <div className="GameDetails-title">
                  Preparação para o jogo
                </div>
                <p>{setup}</p>
              </section>
            )}

            <section className="GameDetails-textSection">
              <div className="GameDetails-title">
                Regras do jogo
              </div>
            </section>

            {instructions.map((item, index) => (
              <Instruction key={index} item={item} obs={item.obs} />
            ))}
          </GameDescriptionContainer>
          <GameImageContainer>
            <GameTitle>{title}</GameTitle>
            <GameComponent />
            <BtnPrimary to={`/jogos/procurando/${gameUrl}`} value={playAction} />
          </GameImageContainer>
        </GameDetailsContainer>
      </Layout>
    )
  }
}

export default GameDetails
