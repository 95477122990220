import stone0Icon from "planets/imagite/imagite-stone-0.svg"
import stone1Icon from "planets/imagite/imagite-stone-1.svg"
import stone2Icon from "planets/imagite/imagite-stone-2.svg"

export const stone0 = {
  icon: stone0Icon,
  width: "34px",
  height: "52px",
  top: "-20px",
  left: "310px"
}

export const stone1 = {
  icon: stone1Icon,
  width: "41px",
  height: "47px",
  top: "120px",
  left: "135px"
}

export const stone2 = {
  icon: stone2Icon,
  width: "39px",
  height: "52px",
  top: "255px",
  left: "60px"
}

export const stone3 = {
  icon: stone0Icon,
  width: "45px",
  height: "38px",
  top: "365px",
  left: "265px"
}

export const stone4 = {
  icon: stone1Icon,
  width: "47px",
  height: "50px",
  top: "485px",
  left: "50px"
}

export const stone5 = {
  icon: stone2Icon,
  width: "39px",
  height: "52px",
  top: "580px",
  left: "315px"
}

export const stone6 = {
  icon: stone0Icon,
  width: "45px",
  height: "38px",
  top: "650px",
  left: "55px"
}
