import React from "react"
import { connect } from "react-redux"
import ManualLink from "backpack/manual/manual-link"

const ManualAbout = ({ hasGames }) => (
  <div className="manual-about">
    <h3 className="purple light">Sobre o Mindzup</h3>
    <ManualLink text="Regras" url="regras" />
    <ManualLink text="Como ganho XP?" url="como-ganho-xp" />
    <ManualLink text="Como ganho Rubi?" url="como-ganho-rubi" />
    <ManualLink text="Como ganho Badges?" url="como-ganho-badges" />
    {hasGames && <ManualLink text="Partidas de jogos valem XP?" url="partidas-valem-xp" />}
    <ManualLink text="Como avanço na trilha?" url="como-avanco-na-trilha" />
    <ManualLink text="Como libero um planeta?" url="como-libero-um-planeta" />
    <ManualLink text="Quando tenho avaliação?" url="quando-tenho-avaliacao" />
  </div>
)

const mapStateToProps = state => {
  return {
    hasGames: state.auth.currentUser.has_games
  }
}

export default connect(mapStateToProps)(ManualAbout)
