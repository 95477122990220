import React from "react"
import API from "api"
import WarningAlert from "../../components/warning-alert"


class AccountSessionInstabilityWarning extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showWarning: false,
    }
  }
  componentDidMount() {
    API.get("/features", (response) => {
      const instabilityFlag = response.data["MINDZUP_NETWORK_INSTABILITY"]
      if (instabilityFlag) this.setState({ showWarning: true })
    })
  }
  render() {
    return this.state.showWarning ? (
      <WarningAlert text="Estamos passando por instabilidades na rede, o que pode aumentar o tempo de carregamento das páginas." />
    ) : null
  }
}

export default AccountSessionInstabilityWarning
