import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import PropTypes from "prop-types"

const PrevArrow = ({ onClick, className }) => (
  <i className={`arrow-icon-left questions-selector-arrow-prev ${className}`} onClick={onClick} />
)

PrevArrow.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string
}

const NextArrow = ({ onClick, className }) => (
  <i className={`arrow-icon-right questions-selector-arrow-next ${className}`} onClick={onClick} />
)

NextArrow.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string
}

class QuestionsSelector extends React.Component {
  componentDidUpdate () {
    this.slider.slickGoTo(this.getCurrentSlide())
  }

  getCurrentSlide () {
    const { questions, current } = this.props
    return questions.findIndex(q => q.id === current)
  }

  render () {
    const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      centerMode: false,
      speed: 200,
      slidesToShow: 12,
      initialSlide: this.getCurrentSlide(),
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      responsive: [
        { breakpoint: 768, settings: { slidesToShow: 3 } },
        { breakpoint: 1024, settings: { slidesToShow: 6 } }
      ]
    }

    const { questions, current, answered, onClick, answerKey } = this.props

    return (
      <div className="questions-selector">
        <Slider ref={ref => (this.slider = ref)} {...settings}>
          {questions.map((question, index) => {
            const { id } = question
            const active = current === id ? "active" : ""
            const inactive = !answerKey && answered.has(id) ? "inactive" : ""

            return (
              <div key={index} className="question-selector-container">
                <div className={`question-selector ${active} ${inactive}`} data-question={id} onClick={onClick}>
                  {index + 1}
                </div>
                {answerKey ? (
                  question.correctly_answered ? (
                    <i className="question-selector-icon check-icon" />
                  ) : (
                    <i className="question-selector-icon error-icon">x</i>
                  )
                ) : (
                  ""
                )}
              </div>
            )
          })}
        </Slider>
      </div>
    )
  }
}

QuestionsSelector.propTypes = {
  questions: PropTypes.array.isRequired,
  current: PropTypes.string,
  answered: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  answerKey: PropTypes.bool
}

export default QuestionsSelector
