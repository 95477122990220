import styled from "styled-components"
import sky from "planets/cryo/cryo-sky.svg"
import horizon from "planets/cryo/cryo-horizon.svg"
import { StyledHorizon, StyledHorizonContainer, StyledHorizonTitle } from "Horizon"

const PlanetHorizon = styled(StyledHorizon)`
  background: linear-gradient(
    to bottom,
    #212647 0%,
    #252d4e 8%,
    #304160 19%,
    #42627f 33%,
    #5c90aa 49%,
    #7cc9df 66%,
    #86dbf0 72%,
    #86dbf0 86%,
    #a2dfed 91%,
    #cde5e9 100%
  );
  overflow: hidden;
  &::before {
    background: url(${horizon}) repeat-x center top;
    background-size: auto 100%;
    width: 100%;
    margin: 0px auto;
    bottom: -2px;
    height: 60%;
    display: flex;
    justify-content: center;
  }
`

const ShortPlanetHorizon = styled(PlanetHorizon)`
  background: linear-gradient(
    to bottom,
    #212647 0%,
    #252d4e 8%,
    #304160 19%,
    #42627f 33%,
    #5c90aa 49%,
    #7cc9df 66%,
    #86dbf0 72%,
    #86dbf0 86%,
    #a2dfed 91%,
    #cde5e9 100%
  );
`

const PlanetHorizonContainer = styled(StyledHorizonContainer)`
  &::before {
    background: url(${sky}) no-repeat;
    height: 100%;
    bottom: 50px;
    left: 30%;
  }
`

const ShortPlanetHorizonContainer = styled(StyledHorizonContainer)`
  &::before {
    background: url(${sky}) no-repeat;
    height: 200px;
    left: 140px;
    bottom: 68px;
  }
  @media (max-width: 768px) {
    top: 40px;
  }
`

const PlanetHorizonTitle = styled(StyledHorizonTitle)`
  position: absolute;
  right: 100px;
  top: 70px;

  @media (max-width: 1024px) {
    right: 100px;
    top: 40px;
  }
`

export { PlanetHorizon, PlanetHorizonContainer, PlanetHorizonTitle, ShortPlanetHorizon, ShortPlanetHorizonContainer }
