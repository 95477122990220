import React from "react"
import BlueGalaxy from "Galaxy/themes/BlueGalaxy"
import GreenGalaxy from "Galaxy/themes/GreenGalaxy"
import OrangeGalaxy from "Galaxy/themes/OrangeGalaxy"
import PurpleGalaxy from "Galaxy/themes/PurpleGalaxy"

const GALAXIES = {
  "blue-galaxy": BlueGalaxy,
  "green-galaxy": GreenGalaxy,
  "orange-galaxy": OrangeGalaxy,
  "purple-galaxy": PurpleGalaxy
}

export const GalaxyContext = React.createContext()

export function withGalaxy (Component) {
  return function ThemedComponent (props) {
    return (
      <GalaxyContext.Consumer>
        {galaxy => galaxy && <Component {...props} galaxy={GALAXIES[galaxy.slug]} />}
      </GalaxyContext.Consumer>
    )
  }
}
