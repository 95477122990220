import React from "react"
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView"
import QuestionAlternative from "components/question-alternative"
import PropTypes from "prop-types"

const ExamQuestion = ({ question, alternativesDisabled, handleAlternative }) => {
  const { id, content, alternatives, answer, correct_answer, correctly_answered } = question

  return (
    <div>
      <FroalaEditorView model={content} />

      <hr className="question-separator" />

      {alternatives.map((alternative, alternativeIndex) => {
        const { content, alternative_name: alternativeName } = alternative
        const checked = answer && answer.alternative_name === alternativeName
        const selectedAlternativeWithCorrectAnswer = checked && correct_answer
        const correct = selectedAlternativeWithCorrectAnswer && correct_answer === answer.alternative_name
        const wrong = selectedAlternativeWithCorrectAnswer && correct_answer !== answer.alternative_name

        return (
          <QuestionAlternative
            id={id}
            alternativeName={alternativeName}
            content={content}
            checked={checked}
            correct={correct}
            correctAnswer={correct_answer}
            wrong={wrong}
            correctlyAnswered={correctly_answered}
            disabled={alternativesDisabled}
            onChange={handleAlternative}
            key={alternativeIndex}
          />
        )
      })}
    </div>
  )
}

ExamQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  alternativesDisabled: PropTypes.bool,
  handleAlternative: PropTypes.func
}

export default ExamQuestion
