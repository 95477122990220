import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { push } from "connected-react-router"
import { connect } from "react-redux"
import { Layout } from "Session"
import { Submit } from "components/button"
import Modal from "components/modal"
import Form from "components/form/form"
import FormInput from "components/form/form-input"
import FormCheckbox from "components/form/form-checkbox"
import FormPassword from "components/form/form-password"
import FormSelect from "components/form/form-select"
import { phoneInputMask, FormInputMasked } from "components/form/form-input-masked"
import TermsAndConditionsModal from "components/modals/terms-and-conditions-modal"
import PrivacyPolicyModal from "components/modals/privacy-policy-modal"
import GamificationManualModal from "components/modals/gamification-manual-modal"
import SignUpUsernameNoticeModal from "components/modals/signup-username-notice-modal"
import NextStepRouter from "onboarding/next-step-router"
import { storeAuthToken, storeWebsocketToken } from "auth"
import { updateCurrentUser } from "actions"
import API from "api"
import { MixpanelTrack } from "tags/mixpanel"
import { PixelTrack } from "tags/pixel"
import { useFormik } from "formik"
import { studentSchema } from "./schema"

const OnboardingActivationAccount = (props) => {
  const [schoolClasses, setSchoolClasses] = useState([])
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false)
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false)
  const [showGamificationManual, setShowGamificationManual] = useState(false)
  const [showSignUpUsernameNoticeModal, setShowSignUpUsernameNoticeModal] = useState(false)

  const { values, handleChange, handleSubmit, handleBlur, touched, errors, setErrors } = useFormik({
    initialValues: {
      name: "",
      username: "",
      school_class: "",
      email: "",
      password: "",
      password_confirmation: "",
      relative_name: "",
      relative_email: "",
      relative_relationship: "",
      relative_phone_number: "",
      relative_cpf: "",
      relative_rg: "",
      relative_email_confirm: "",
      terms_of_service: true,
    },
    validationSchema: studentSchema,
    validateOnBlur: true,
    validateOnChange: false,

    onSubmit: () => {
      setShowSignUpUsernameNoticeModal(true)
    },
  })

  useEffect(() => {
    PixelTrack("Inserção Dados Aluno e Responsável")

    API.get("/school_classes", (response) => {
      setSchoolClasses(response.data)
    })
  }, [])

  function submitData() {
    const { activationCode, partnerEmail, dispatch } = props

    const relative_email_value = partnerEmail ? partnerEmail : values.relative_email
    const school_class_value = partnerEmail ? schoolClasses.values().next().value : values.school_class

    API.post(
      "/accounts",
      {
        action: "activate",
        activation_code: activationCode,
        school_class: school_class_value,
        name: values.name,
        username: values.username,
        email: values.email,
        password: values.password,
        password_confirmation: values.password_confirmation,
        relative: {
          name: values.relative_name,
          email: relative_email_value,
          relationship: values.relative_relationship,
          phone_number: values.relative_phone_number,
          cpf: values.relative_cpf,
          rg: values.relative_rg,
        },
        terms_of_service: values.terms_of_service,
      },
      (response) => {
        const { next_step: nextStep } = response.headers
        const { user, relative, token, websocket_token: websocketToken } = response.data
        const redirectTarget = NextStepRouter(nextStep)

        storeAuthToken(token)
        storeWebsocketToken(websocketToken)

        dispatch(updateCurrentUser(user, relative))
        dispatch(push(redirectTarget))

        MixpanelTrack("Concluiu inserção dados do aluno", {
          "NOME COMPLETO CADASTRADO": values.name,
          "USERNAME CADASTRADO": values.username,
          "E-MAIL CADASTRADO": values.email,
        })

        MixpanelTrack("Concluiu inserção dados do responsável", {
          "NOME CADASTRADO": values.relative_name,
          "E-MAIL CADASTRADO": values.relative_email,
          "PARENTESCO CADASTRADO": values.relative_relationship,
          "TELEFONE CADASTRADO": values.relative_phone_number,
        })
      },
      (error) => {
        const { errors, schema } = error.response.data

        if (schema === "relatives") {
          const relativeErrors = errors.map((error) => {
            const keyName = Object.keys(error)[0]
            const newKeyName = `relative_${keyName}`
            return { [newKeyName]: error[keyName] }
          })
          setErrors( ...relativeErrors )
        } else {
          setErrors(...errors)
        }
        setShowSignUpUsernameNoticeModal(false)
      }
    )
  }

  const { askEmail, partnerEmail } = props

  return (
    <Layout>
      <Modal backUrl="/criar-conta/confirmacao">
        <Form onSubmit={handleSubmit}>
          <h2>Dados de Aluno</h2>

          <FormInput
            type="text"
            id="name"
            name="name"
            label="Nome completo"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.name && touched.name && [{ name: errors.name }]}
            required
          />

          <FormInput
            type="text"
            id="username"
            name="username"
            label="Nome de usuário"
            value={values.username}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.username && touched.username && [{ username: errors.username }]}
            required
          />

          {!partnerEmail && (
            <FormSelect
              id="school_class"
              name="school_class"
              label="Turma"
              onChange={handleChange}
              value={values.school_class}
              onBlur={handleBlur}
              defaultValue={schoolClasses.values().next().value}
              errors={errors.school_class && touched.school_class && [{ school_class: errors.school_class }]}
              required
            >
              <option value=""> </option>
              {schoolClasses.map((schoolClass) => (
                <option key={schoolClass}>{schoolClass}</option>
              ))}
            </FormSelect>
          )}

          {askEmail && (
            <FormInput
              type="email"
              id="email"
              name="email"
              label="E-mail"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              // errors={errors}
              required
            />
          )}

          <FormPassword
            id="password"
            name="password"
            label="Senha (mínimo 6 caracteres)"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.password && touched.password && [{ password: errors.password }]}
            required
          />

          <FormPassword
            id="password_confirmation"
            name="password_confirmation"
            label="Confirmação da senha"
            value={values.password_confirmation}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={
              errors.password_confirmation &&
              touched.password_confirmation && [{ password_confirmation: errors.password_confirmation }]
            }
            required
          />

          <h2>Dados de Responsável</h2>

          {!askEmail && (
            <p className="account-disclaimer">
              <strong>Atenção:</strong> você não pode utilizar os dados de Responsável para se logar na plataforma, ok?!
            </p>
          )}

          <FormInput
            type="text"
            id="relative_name"
            name="relative_name"
            label="Nome completo"
            value={values.relative_name}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.relative_name && touched.relative_name && [{ relative_name: errors.relative_name }]}
            required
          />

          <FormInput
            type="email"
            id="relative_email"
            name="relative_email"
            label={partnerEmail ? "" : "E-mail"}
            value={values.relative_email}
            disabled={values.partnerEmail}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.relative_email && touched.relative_email && [{ relative_email: errors.relative_email }]}
            required
          />

          <FormInput
            type="email"
            id="relative_email_confirm"
            name="relative_email_confirm"
            label={"Confirmação de E-mail"}
            value={values.relative_email_confirm}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={
              errors.relative_email_confirm &&
              touched.relative_email_confirm && [{ relative_email_confirm: errors.relative_email_confirm }]
            }
            required
          />

          <FormInputMasked
            mask={[/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
            type="text"
            id="relative_cpf"
            name="relative_cpf"
            // onBlur={(e) => checkCPF(e.target.value)}
            label={"CPF"}
            value={values.relative_cpf}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.relative_cpf && touched.relative_cpf && [{ relative_cpf: errors.relative_cpf }]}
            // errors={errors}
            required
          />

          <FormInput
            type="rg"
            id="relative_rg"
            name="relative_rg"
            label={"RG"}
            value={values.relative_rg}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.relative_rg && touched.relative_rg && [{ relative_rg: errors.relative_rg }]}
            required
          />

          <FormSelect
            id="relative_relationship"
            name="relative_relationship"
            label="Parentesco"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.relative_relationship}
            errors={
              errors.relative_relationship &&
              touched.relative_relationship && [{ relative_relationship: errors.relative_relationship }]
            }
            // errors={errors}
            required
          >
            <option value="" />
            <option value="FATHER">Pai</option>
            <option value="MOTHER">Mãe</option>
            <option value="GRANDFATHER">Avô</option>
            <option value="GRANDMOTHER">Avó</option>
            <option value="OTHER">Outro</option>
          </FormSelect>

          <FormInputMasked
            mask={phoneInputMask}
            type="text"
            id="relative_phone_number"
            name="relative_phone_number"
            label="Telefone ou celular"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.relative_phone_number}
            errors={
              errors.relative_phone_number &&
              touched.relative_phone_number && [{ relative_phone_number: errors.relative_phone_number }]
            }
            // errors={errors}
            required
          />

          <FormCheckbox
            id="terms_of_service"
            name="terms_of_service"
            onChange={handleChange}
            onBlur={handleBlur}
            checked={values.terms_of_service}
            // errors={errors}
          >
            <span>Concordo com os </span>
            <Link to="#" onClick={() => setShowTermsAndConditions(true)}>
              Condições Gerais de Uso
            </Link>
            <span>, </span>
            <Link to="#" onClick={() => setShowPrivacyPolicy(true)}>
              Aviso de Privacidade
            </Link>
            <span> e </span>
            <Link to="#" onClick={() => setShowGamificationManual(true)}>
              Manual da Gamificação
            </Link>
            .
          </FormCheckbox>

          <Submit value="Seguir" arrow />
        </Form>
      </Modal>

      <TermsAndConditionsModal opened={showTermsAndConditions} onClose={() => setShowTermsAndConditions(false)} />

      <PrivacyPolicyModal opened={showPrivacyPolicy} onClose={() => setShowPrivacyPolicy(false)} />

      <GamificationManualModal opened={showGamificationManual} onClose={() => setShowGamificationManual(false)} />

      <SignUpUsernameNoticeModal
        opened={showSignUpUsernameNoticeModal}
        username={values.username}
        onContinue={submitData}
        onDismiss={() => setShowSignUpUsernameNoticeModal(false)}
      />
    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
    activationCode: state.activation.activationCode,
    partnerEmail: state.activation.partnerEmail,
    schoolYear: state.activation.schoolYear,
    askEmail: state.activation.schoolYear.ask_email,
    schoolClass: state.activation.schoolClass,
  }
}

export default connect(mapStateToProps)(OnboardingActivationAccount)
