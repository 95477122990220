import ThemingRoute from "helpers/theming-route"
import GalaxyPage from "pages/GalaxyPage"

const Routes = [
  {
    router: ThemingRoute,
    path: "/galaxia",
    component: GalaxyPage
  }
]

export default Routes
