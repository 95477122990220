import styled from "styled-components"

const Expiration = styled.p`
  color: #ffffff;
  text-align: center;
`

const RemainingTime = styled.strong`
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 20px;
`

export { Expiration, RemainingTime }
