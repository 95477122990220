import React from "react"
import ActiveAvatar from "avatar/zen-ship.svg"
import InactiveAvatar from "avatar/zen-ship-inactive.svg"

const ZenShip = ({ inactive, className = "", ...rest }) => {
  if (inactive) {
    return <img src={InactiveAvatar} className={`inactive ${className}`} {...rest} />
  }
  return <img src={ActiveAvatar} className={className} {...rest} />
}

export default ZenShip
