import React from "react"
import PerformanceChart from "backpack/performance-chart"
import BagLayout from "components/layouts/bag"
import API from "api"
import { MixpanelTrack } from "tags/mixpanel"

class DailyMissionPerformance extends React.Component {
  constructor (props) {
    super(props)

    this.state = { summary: {}, details: [], dailyMissionDays: "" }
  }

  componentDidMount () {
    MixpanelTrack("Visualizou aba Desempenho")

    API.get("/bag/performance", response => {
      const { summary, details, daily_mission_days: dailyMissionDays } = response.data
      this.setState({ summary, details, dailyMissionDays })
    })
  }

  render () {
    const { summary, details, dailyMissionDays } = this.state

    return (
      <BagLayout activeSection="performance">
        <div className="bag-content-container">
          <div className="daily-mission-title">MISSÕES DIÁRIAS</div>
          <p className="daily-mission-days">{dailyMissionDays}</p>

          <div className="performance-summary">
            <p>Total de perguntas respondidas: {summary.total_answers}</p>
            <p>Total de acertos: {summary.correct_answers}</p>
            <p>Total de erros: {summary.incorrect_answers}</p>
          </div>

          <div className="percentage-bars-container">
            {details.map((item, index) => (
              <PerformanceChart
                knowledgeArea={item.knowledge_area}
                correctAnswers={item.correct_answers}
                incorrectAnswers={item.incorrect_answers}
                totalAnswers={item.total_answers}
                key={index}
              />
            ))}
          </div>
        </div>
      </BagLayout>
    )
  }
}

export default DailyMissionPerformance
