import React from "react"
import { connect } from "react-redux"
import { syncGamification } from "actions"
import {
  StyledGamificationBar,
  GamificationXp,
  GamificationRuby,
  GamificationIconXp,
  GamificationIconRuby
} from "GamificationBar/styled"
import CountUp from "react-countup"
import { STOP_GAMIFICATION_ANIMATION } from "actions"

class GamificationBar extends React.Component {
  constructor (props) {
    super(props)
    this.onCountUpEnd = this.onCountUpEnd.bind(this)
  }

  componentDidMount () {
    this.subscribeGamification()
  }

  subscribeGamification () {
    const { currentUserId, dispatch } = this.props
    dispatch(syncGamification(currentUserId))
  }

  onCountUpEnd () {
    const { dispatch } = this.props
    dispatch({ type: STOP_GAMIFICATION_ANIMATION })
  }

  render () {
    const { xp, ruby, countUp, prevXp, prevRuby } = this.props

    return (
      <StyledGamificationBar>
        <GamificationXp>
          <GamificationIconXp />
          {countUp ? <CountUp start={prevXp} end={xp} onEnd={this.onCountUpEnd} /> : xp} xp
        </GamificationXp>

        <GamificationRuby>
          <GamificationIconRuby />
          {countUp ? <CountUp start={prevRuby} end={ruby} onEnd={this.onCountUpEnd} /> : ruby}
        </GamificationRuby>
      </StyledGamificationBar>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentUserId: state.auth.currentUser.id,
    prevXp: state.gamification.prevXp,
    prevRuby: state.gamification.prevRuby,
    xp: state.gamification.xp,
    ruby: state.gamification.ruby,
    countUp: state.gamification.countUp
  }
}

export default connect(mapStateToProps)(GamificationBar)
