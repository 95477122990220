export default [
  {
    title: "",
    description: "",
    items: [
      {
        text: "O jogador da vez realiza um destes dois movimentos:",
        items: [
          {
            text: "Comprar uma carta: o jogador escolhe uma carta-camiseta do centro da mesa. As cartas compradas ficam expostas, lado a lado, na ordem de compra (organizadas da esquerda para a direita).",
          },
          {
            text: "Mostrar um cartão vermelho: o jogador mostra um cartão vermelho para um dos outros, à sua escolha, declarando o objetivo do oponente a quem está mostrando o cartão. Ao ser usado, o Cartão Vermelho é sempre descartado e nunca é reposto. Se o oponente possuir o objetivo declarado, ele (o oponente) o descarta e compra (recebe) um novo objetivo do monte de objetivos restantes (misturados). Se o oponente não possuir o objetivo declaro, o jogador que mostrou o Cartão Vermelho passa a vez e é a vez do próximo jogador.",
          }
        ]
      },
      {
        text: "O jogo acaba quando um dos jogadores cumprir UM de seus objetivos.",
      }
    ]
  }
]