import React from "react"
import FormGroup from "components/form/form-group"
import FormLabel from "components/form/form-label"
import ComponentHelper from "helpers/component-helper"
import MaskedInput from "react-text-mask"

const FormInputMasked = ({ type, id, name, required, label, errors = [], children, className = "", guide = true,...rest }) => (
  <FormGroup hasError={ComponentHelper.hasError(errors, name)}>
    {children}
    <MaskedInput
      type={type}
      id={id}
      name={name}
      className={`form-field ${className}`}
      {...rest}
      required={required}
      guide={guide}
    />
    <FormLabel htmlFor={id}>{ComponentHelper.label(errors, name, label)}</FormLabel>
  </FormGroup>
)

const phoneInputMask = rawValue => {
  let numbers = rawValue.replace(/\D/g, "")
  if (numbers.length <= 10) {
    return ["(", /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]
  } else {
    return ["(", /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]
  }
}

export { phoneInputMask, FormInputMasked }
