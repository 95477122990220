import styled from "styled-components"
import HeaderLink from "Header/HeaderLink"

const HeaderLeft = styled.div`
  display: flex;

  ${HeaderLink} {
    margin-right: 10px;
  }
`

export default HeaderLeft
