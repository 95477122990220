import React from "react"
import { Header } from "Header"
import { GamificationBar } from "GamificationBar"
import { BadgeTitle, DarkGrayText, BadgeContainer } from "backpack/badges/styled"
import BadgesList from "backpack/badges/BadgesList"
import { ScrollUp } from "helpers/scroll"

const ConqueredBadgeBase = ({ badge, children }) => {
  const badgeSlug = badge.slug
  const Component = BadgesList[badgeSlug].component

  ScrollUp()

  return (
    <BadgeContainer>
      <Header backUrl="/mochila/conquistas" color="colored">
        <GamificationBar />
      </Header>
      <BadgeTitle>Badge conquistada</BadgeTitle>
      {children}
      <Component />

      <h3>{badge.title}</h3>
      <p className="purple">{badge.description}</p>

      <hr />
      <DarkGrayText>{badge.trigger_earned_text}</DarkGrayText>
    </BadgeContainer>
  )
}

export default ConqueredBadgeBase
