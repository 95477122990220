import React from "react"
import image from "galaxies/blue-galaxy/logic.svg"
import Tooltip from "components/tooltip"
import { Header } from "Header"
import { GamificationBar } from "GamificationBar"
import { ScrollUp } from "helpers/scroll"

const HowToUnblockPlanet = () => {
  ScrollUp()

  return (
    <div className="bag-manual-about-container">
      <Header backUrl="/mochila/mural" color="colored">
        <GamificationBar />
      </Header>
      <h1 className="purple">Como libero um planeta?</h1>
      <div className={"planet blocked how-to-planet"}>
        <Tooltip>
          <i className="padlock-icon" />
        </Tooltip>
        <img src={image} />
      </div>
      <p>Os planetas são liberados automaticamente conforme o ano escolar vai passando!</p>
      <p>
        Você terá 3 planetas para desbravar no primeiro semestre. Já no segundo semestre, uma nova galáxia se abrirá,
        com mais 3 novos planetas.
      </p>
      <p>
        Em cada planeta você encontrará um mundo completamente novo, repleto de novos elementos visuais ainda mais
        incríveis, com Missões Diárias ainda mais desafiadoras.
      </p>
    </div>
  )
}

export default HowToUnblockPlanet
