import React from "react"
import Racion from "planets/racion"
import Logic from "planets/logic"
import Groupem from "planets/groupem"
import Imagite from "planets/imagite"
import Union from "planets/union"
import Pensatus from "planets/pensatus"
import Onis from "planets/onis"
import Axion from "planets/axion"
import Natus from "planets/natus"
import Kai from "planets/kai"
import Roya from "planets/roya"
import Cryo from "planets/cryo"

const planets = {
  Ración: Racion,
  Lógic: Logic,
  Groupem: Groupem,
  Imagite: Imagite,
  Unión: Union,
  Pensatus: Pensatus,
  Onis: Onis,
  Áxion: Axion,
  Natus: Natus,
  Kai: Kai,
  Roya: Roya,
  Cryo: Cryo
}

export const PlanetContext = React.createContext()

export function withPlanet (Component) {
  return function ThemedComponent (props) {
    return (
      <PlanetContext.Consumer>
        {planet => planet && <Component {...props} planet={planets[planet]} />}
      </PlanetContext.Consumer>
    )
  }
}
