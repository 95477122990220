import React from "react"
import BagLayout from "components/layouts/bag"
import parseDate from "date-fns/parseISO"
import formatDate from "date-fns/format"
import { PaymentContainer, Title, PurpleText, SquareText, PinkLink } from "backpack/payment/styled"

const BoletoWaitingPayment = ({ expirationDate, printHref }) => (
  <BagLayout activeSection="payment">
    <PaymentContainer>
      <Title>Aguardando pagamento - Boleto</Title>
      <p>
        Ainda não recebemos a confirmação de que seu boleto foi pago. Caso não tenha efetuado o pagamento, você tem até
        a data da validade de seu Boleto para realizar a compra.
      </p>
      <PurpleText>
        <strong>Lembre-se:</strong> após pagamento de seu Boleto, o banco tem até 4 dias úteis para liberar a
        confirmação
      </PurpleText>
      <SquareText>Validade de seu boleto: {formatDate(parseDate(expirationDate), "dd/MM/yyyy")}</SquareText>
      <PinkLink to="#" onClick={() => window.open(printHref, "boleto")}>
        Imprimir boleto
      </PinkLink>
    </PaymentContainer>
  </BagLayout>
)

export default BoletoWaitingPayment
