import scenario from "planets/natus/natus-scenario.svg"
import flag from "planets/natus/natus-high-flag.svg"
import { stone0, stone1, stone2, stone3, stone4, stone5, stone6 } from "planets/natus/stone"

export default {
  stones: [stone0, stone1, stone2, stone3, stone4, stone5, stone6],
  scenario: scenario,
  flag: flag,
  gradient: "linear-gradient(to right,#b46700 0%,#b3a003 40%,#b3a003 60%,#b46700 100%)",
  paddingTop: "20px"
}
