import React from "react"
import BagLayout from "components/layouts/bag"
import parseDate from "date-fns/parseISO"
import formatDate from "date-fns/format"
import {
  PaymentContainer,
  Title,
  PurpleText,
  PurpleSquareText,
  PaymentDoneViaBoletoText
} from "backpack/payment/styled"

const SuccessfulPayment = ({ year, paymentMethod, activationCode, paidAt }) => (
  <BagLayout activeSection="payment">
    <PaymentContainer>
      <Title>Pagamento realizado com Sucesso!</Title>
      <p>Que bom! Seu acesso ao Mindzup para {year} já está garantido :)</p>
      <p>
        Como o pagamento foi efetuado com sucesso, você continuará tendo acesso aos conteúdos e demais funcionalidades
        da plataforma, sem interrupções. Ao começarmos o ano de {year}, você continuará podendo acessar o Mindzup e seu
        acesso não será bloqueado.
      </p>
      <p>Em {year}, seu Código de Ativação será atualizado para:</p>
      <PurpleSquareText>{activationCode}</PurpleSquareText>
      <PurpleText>
        <strong>Atenção:</strong> Para saber a data de início das Missões Diárias em {year}, entre em contato com sua
        escola.
      </PurpleText>
      <PaymentDoneViaBoletoText>
        Pagamento efetuado no dia {formatDate(parseDate(paidAt), "dd/MM/yyyy")}, via {paymentMethod}
      </PaymentDoneViaBoletoText>
    </PaymentContainer>
  </BagLayout>
)

export default SuccessfulPayment
