import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Base from "Buttons/Base"
import { withPlanet } from "planet-context"

const StyledLinkButton = withPlanet(styled(Base)`
  background-color: transparent;
  color: ${props => props.planet.btnLink.backgroundColor};
  position: relative;

  &:hover,
  &:active,
  &:focus {
    color: ${props => props.planet.btnLink.activeTextColor} !important;
  }
`)

const BtnLink = ({ children, ...props }) => <StyledLinkButton {...props}>{children}</StyledLinkButton>

BtnLink.propTypes = {
  children: PropTypes.node.isRequired
}

export default BtnLink
