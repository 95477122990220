import styled from "styled-components"

const Rounded = styled.i`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
`

export default Rounded
