import React from "react"

const Grade = ({ grade }) => {
  if (grade || grade===0) {
    return (
      <div className="exam-card-subitem-grade">
        Nota: <strong>{grade}</strong>
      </div>
    )
  }
  return "Aguardando"
}

export default Grade
