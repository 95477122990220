const TYPES = {
  EI: "EI",
  EF: "EF",
  EM: "EM",
  OLYMPIC: "OLYMPIC"
}

const LISTGAMES = {
  [TYPES.EF]: {
    "1": [
      "wolfandsheep",
      "tictactoe",
      "rushhour"
    ],
    "2": [
      "checkers",
      "mancala",
      "mindsters",
      "domino"
    ],
    "3": [
      "hidden",
      "battlesubmarine"
    ],
    "4": [
      "mancala",
      "treasureisland",
      "rushhour",
      "minibridge"
    ],
    "5": [
      "codebreaker",
      "yams"
    ],
    "6": [
      "mancala",
      "redcard",
      "rushhour",
      "minibridge"
    ],
    "7": [
      "codebreaker",
      "wizards",
      "yams"
    ],
    "8": [
      "moonwalk",
      "gamao"
    ],
    "9": [
      "diamonds",
      "redcard",
      "dudo"
    ]
  },
  [TYPES.EI]: {
    "4": [
      "wolfandsheep",
      "rushhour"
    ],
    "5": [
      "mindsters",
      "tictactoe"
    ]
  },
  [TYPES.EM]: [
    "codebreaker",
    "mancala",
    "moonwalk",
    "octi",
    "quoridor",
    "treasureisland",
    "wolfandsheep",
    "rushhour"
  ],
  [TYPES.OLYMPIC]: [
    "abalone",
    "checkers",
    "octi",
    "quoridor"
  ]
}

export function getOlympicGames() {
  return LISTGAMES[TYPES.OLYMPIC]
}

export function getGamesByType(type, year = null) {
  return (Object.values(TYPES).indexOf(type) === -1)
    ? null
    : (type !== TYPES.EF && type !== TYPES.EI)
      ? LISTGAMES[type]
      : LISTGAMES[type][year]
}
