import styled from "styled-components"
import Tooltip from "components/tooltip"

const TrailTooltip = styled(Tooltip)`
  color: #000000;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  position: absolute;
  z-index: 2;
  animation: fadeIn 500ms ease-in-out;
`

const TrailTooltip0 = styled(TrailTooltip)`
  top: -40px;
  left: 290px;
`

const TrailTooltip1 = styled(TrailTooltip)`
  top: 80px;
  left: 115px;
`

const TrailTooltip2 = styled(TrailTooltip)`
  top: 230px;
  left: 35px;
`

const TrailTooltip3 = styled(TrailTooltip)`
  top: 320px;
  left: 245px;
`

const TrailTooltip4 = styled(TrailTooltip)`
  top: 450px;
  left: 25px;
`

const TrailTooltip5 = styled(TrailTooltip)`
  top: 545px;
  left: 295px;
`

const TrailTooltip6 = styled(TrailTooltip)`
  top: 605px;
  left: 35px;
`

export { TrailTooltip0, TrailTooltip1, TrailTooltip2, TrailTooltip3, TrailTooltip4, TrailTooltip5, TrailTooltip6 }
