import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import FormRadio from "components/form/form-radio"
import UniversityCourses from "MySisu/UniversityCourse"
import SearchCourse from "MySisu/SearchCourse"
import Form from "components/form/form"
import { Submit } from "components/button"
import Chances from "MySisu/Chances"
import API from "api"
import {
  mySisuDisableSubmit,
  mySisuSetFormOption,
  mySisuShowChancesSection,
  mySisuHideChancesSection,
  mySisuSetChances,
  mySisuResetForm
} from "MySisu/actions"
import { connect } from "react-redux"
import { Separator } from "MySisu/styled"

class MySisu extends React.Component {
  constructor (props) {
    super(props)

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  componentDidMount () {
    this.props.dispatch(mySisuResetForm())
  }

  handleInputChange (event) {
    this.props.dispatch(mySisuSetFormOption({ optionSelected: event.target.value }))
    this.props.dispatch(mySisuDisableSubmit())
    this.props.dispatch(mySisuHideChancesSection())
  }

  onClose () {
    this.props.dispatch(mySisuResetForm())
    this.props.onClose()
  }

  handleSubmit (event) {
    const { universityId, courseId, cityId, stateId, groupingId, evaluationIds } = this.props

    const params = {
      mathematics: evaluationIds["mathematics"],
      humanities: evaluationIds["humanities"],
      sciences: evaluationIds["sciences"],
      languages: evaluationIds["languages"],
      essay: evaluationIds["essay"],
      university: universityId,
      course: courseId,
      city: cityId,
      state: stateId,
      field: groupingId
    }

    const queryString = Object.keys(params)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join("&")

    API.get(`/my_sisu?${queryString}`, response => {
      this.props.dispatch(mySisuSetChances(response.data.odds))
      this.props.dispatch(mySisuShowChancesSection())
    })

    event.preventDefault()
  }

  render () {
    const { submitDisabled, optionSelected, showChances } = this.props

    return (
      <CloseableModal opened={this.props.opened} onClose={this.onClose} overlay color="my-sisu-background">
        <div className="my-sisu">
          <h2>MeuSISU</h2>
          <Separator />
          <p className="small">
            Com base no histórico de aprovação das universidades, o MeuSISU calcula, para a nota de cada simulado
            finalizado, suas chances de entrar no curso que deseja. A ferramenta considera todas as universidades que
            oferecem vagas para candidatos do ENEM por meio do SISU (Sistema de Seleção Unificada).
          </p>
          <Form onSubmit={this.handleSubmit}>
            <div className="radio-buttons-container">
              <FormRadio
                containerClassName="radio"
                id="university_course"
                name="optionSelected"
                value="university_course"
                onChange={this.handleInputChange}
                defaultChecked={optionSelected === "university_course"}
                required
              >
                Já sei qual curso quero!
              </FormRadio>
              <FormRadio
                containerClassName="radio"
                id="search_course"
                name="optionSelected"
                value="search_course"
                onChange={this.handleInputChange}
                defaultChecked={optionSelected === "search_course"}
                required
              >
                Ainda não sei qual curso quero
              </FormRadio>
            </div>

            {optionSelected === "university_course" && <UniversityCourses />}
            {optionSelected === "search_course" && <SearchCourse />}

            <Submit value="Calcular minha chances" disabled={submitDisabled} arrow />
          </Form>

          {showChances && <Chances />}
        </div>
      </CloseableModal>
    )
  }
}

const mapStateToProps = state => {
  return {
    submitDisabled: state.mySisuReducer.submitDisabled,
    optionSelected: state.mySisuReducer.options.optionSelected,
    universityId: state.mySisuReducer.options.universityId,
    courseId: state.mySisuReducer.options.courseId,
    groupingId: state.mySisuReducer.options.groupingId,
    cityId: state.mySisuReducer.options.cityId,
    stateId: state.mySisuReducer.options.stateId,
    showChances: state.mySisuReducer.showChances,
    scienceAreaId: state.mySisuReducer.scienceAreaId
  }
}

export default connect(mapStateToProps)(MySisu)
