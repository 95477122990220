export default [
  {
    title: "",
    description: "",
    items: [
      {
        text: "Começar com o desafio nº 1 e trabalhar, progressivamente, na sequência.",
      },
      {
        text: "Cada veículo pode mover-se para frente e para trás, na posição em que se encontra (horizontal ou vertical).",
      }
    ]
  }
]