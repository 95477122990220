import React from "react"
import DailyMissionFinishedModal from "components/modals/daily-mission-finished-modal"

const DailyMissionAbandonedModal = () => (
  <DailyMissionFinishedModal>
    <h2>Abandono de Missão</h2>
    <p className="medium">Você não respondeu todas as questões do dia.</p>
  </DailyMissionFinishedModal>
)

export default DailyMissionAbandonedModal
