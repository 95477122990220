import scenario from "planets/imagite/imagite-scenario.svg"
import flag from "planets/imagite/imagite-high-flag.svg"
import { stone0, stone1, stone2, stone3, stone4, stone5, stone6 } from "planets/imagite/stone"

export default {
  stones: [stone0, stone1, stone2, stone3, stone4, stone5, stone6],
  scenario: scenario,
  flag: flag,
  gradient: "linear-gradient(180deg,#9A6EA9 0%,#9A6EA9 100%)",
  paddingTop: "0"
}
