import React from "react"
import BadgesList from "backpack/badges/BadgesList"
import {
  FirstColumnBadgeBlocked,
  SecondColumnBadgeBlocked,
  ThirdColumnBadgeBlocked,
  SpecialBadgeBlocked
} from "components/badge"
import {
  BadgesSpecials,
  BadgesColumn,
  BadgesRowsContainer,
  BadgesSpecialsContainer,
  BadgesPerfomanceEngagement,
  BadgesContainerTitle
} from "trail/users/badges/styled"

const BadgesContainer = ({ specials, badges, BlockedBadge }) => {
  const Container = specials ? BadgesSpecials : BadgesColumn
  return (
    <Container>
      {badges.map((elem, index) => {
        const BadgeComponent = elem.conquered ? BadgesList[elem.slug].component : BlockedBadge
        return <BadgeComponent key={index} style={{ height: "50px" }} />
      })}
    </Container>
  )
}

const BadgesColumns = ({ badges, schoolYear }) => {
  const firstRow = badges.filter(badge => !badge.special && BadgesList[badge.slug].column === 1)
  const secondRow = badges.filter(badge => !badge.special && BadgesList[badge.slug].column === 2)
  const thirdRow = badges.filter(badge => !badge.special && BadgesList[badge.slug].column === 3)
  const specialsRow = badges.filter(badge => badge.special)

  return (
    <div>
      <div>
        {specialsRow.length > 0 && (
          <div>
            <BadgesSpecialsContainer>
              <BadgesContainerTitle>Badges Especiais</BadgesContainerTitle>
              <BadgesContainerTitle> MenteInovadora - {schoolYear}</BadgesContainerTitle>
              <BadgesContainer BlockedBadge={SpecialBadgeBlocked} badges={specialsRow} specials />
            </BadgesSpecialsContainer>
          </div>
        )}
        <BadgesPerfomanceEngagement>
          <BadgesContainerTitle>Engajamento e desempenho</BadgesContainerTitle>
          <BadgesRowsContainer>
            <BadgesContainer BlockedBadge={FirstColumnBadgeBlocked} badges={firstRow} />
            <BadgesContainer BlockedBadge={SecondColumnBadgeBlocked} badges={secondRow} />
            <BadgesContainer BlockedBadge={ThirdColumnBadgeBlocked} badges={thirdRow} />
          </BadgesRowsContainer>
        </BadgesPerfomanceEngagement>
      </div>
    </div>
  )
}

export default BadgesColumns
