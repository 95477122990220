import styled from "styled-components"
import Rounded from "Icons/Rounded"

const Clock = styled(Rounded)`
  width: 16px;
  height: 16px;
  background-color: #fff;
  margin: 0 5px 0 0;
  border: 2px solid ${props => props.color};

  &::before,
  &::after {
    content: "";
    position: absolute;
    border-color: ${props => props.color};
    border-style: solid;
    border-width: 2px 2px 2px 0;
  }

  &::before {
    transform: rotate(45deg);
    height: 3px;
    left: 9px;
    top: 4px;
  }

  &::after {
    height: 3px;
    left: 7px;
    top: 2px;
  }
`

export default Clock
