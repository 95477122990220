/*
  Returns an object without the omitKey.

  Example:
    |> omit({a: 1, b: 2}, "a")
    |> {b: 2}

*/
export function omit (obj, omitKey) {
  return Object.keys(obj).reduce((result, key) => {
    if (key !== omitKey) {
      result[key] = obj[key]
    }
    return result
  }, {})
}
