import bottomCurve from "planets/logic/bottom-curve"
import trail from "planets/groupem/trail"
import {
  PlanetHorizon,
  ShortPlanetHorizon,
  PlanetHorizonContainer,
  ShortPlanetHorizonContainer,
  PlanetHorizonTitle
} from "planets/groupem/horizon"
import editProfileBackground from "planets/groupem/groupem-edit-profile-stones.svg"

export default {
  mainColor: "#9fbd53",
  secondaryColor: "#fdffda",
  bottomCurve: bottomCurve,
  trail: trail,
  knowledgeAreaSlide: { prev: "#f8af87", next: "#f8af87" },
  dailyMission: {
    questionSlider: { prev: "#61A152", next: "#61A152", arrowColor: "#fff" }
  },
  btnPrimary: { backgroundColor: "#ff9229", textColor: "#fff", shadowColor: "#ce6d10" },
  btnLink: { backgroundColor: "#67a145", activeTextColor: "#ff9229" },
  sliderButtonArrowColor: "#fff",
  gamificationBar: {
    white: { textColor: "#4e8030" },
    colored: { textColor: "#fff" }
  },
  header: { colored: { backgroundColor: "#8e3fac" } },
  horizon: {
    PlanetHorizon,
    ShortPlanetHorizon,
    PlanetHorizonContainer,
    ShortPlanetHorizonContainer,
    PlanetHorizonTitle
  },
  editProfileBackground: editProfileBackground,
  games: {
    title: "#4e8030",
    border: "#4e8030",
    detailsHeader: "#ffb133"
  }
}
