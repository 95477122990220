import Abalone from "GamesStation/games/Abalone"
import Octi from "GamesStation/games/Octi"
import Damas from "GamesStation/games/Damas"
import Quoridor from "GamesStation/games/Quoridor"
import Hidden from "GamesStation/games/Hidden"
import Mancala from "GamesStation/games/Mancala"
import Moonwalk from "GamesStation/games/Moonwalk"
import CodeBreaker from "GamesStation/games/CodeBreaker"
import Diamonds from "GamesStation/games/Diamonds"
import Wizards from "GamesStation/games/Wizards"
import TreasureIsland from "GamesStation/games/TreasureIsland"
import RedCard from "GamesStation/games/RedCard"
import BattleSubmarine from "GamesStation/games/BattleSubmarine"
import Domino from "GamesStation/games/Domino"
import Gamao from "GamesStation/games/Gamao"
import MiniBridge from "GamesStation/games/MiniBridge"
import Perudo from "GamesStation/games/Perudo"
import RushHour from "GamesStation/games/RushHour"
import Yams from "GamesStation/games/Yams"
import WolfAndSheep from "GamesStation/games/WolfAndSheep"
import Mindsters from "GamesStation/games/Mindsters"
import TicTacToe from "GamesStation/games/TicTacToe"

export {
  Abalone,
  Octi,
  Damas,
  Quoridor,
  Hidden,
  Mancala,
  Moonwalk,
  CodeBreaker,
  Diamonds,
  Wizards,
  TreasureIsland,
  RedCard,
  BattleSubmarine,
  Domino,
  Gamao,
  MiniBridge,
  Perudo,
  RushHour,
  Yams,
  Mindsters,
  TicTacToe,
  WolfAndSheep
}
