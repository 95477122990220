import { Link } from "react-router-dom"
import styled from "styled-components"

const HeaderLink = styled(Link)`
  font-family: "Montserrat";
  font-weight: 300;
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
  line-height: 32px;
  display: flex;

  i {
    margin-right: 5px;
  }

  strong {
    font-weight: 500;
    margin-left: 3px;
  }

  &:active,
  &:focus,
  &:hover {
    text-decoration: underline;
    color: #ffffff;
  }
`

export default HeaderLink
