import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import { Header } from "Header"
import { GamificationBar } from "GamificationBar"
import { Question, DailyMissionClosedModal } from "daily-mission"
import QuestionAlternative from "components/question-alternative"
import DailyMissionLeaveModal from "components/modals/daily-mission-leave-modal"
import { BtnPrimary, BtnLink } from "Buttons"
import { NextArrow, PrevArrow } from "daily-mission/slider-buttons"

class AnswerForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      submitDisabled: true,
      leaveModal: false,
      allAnswered: false,
      backUrl: "/missao-diaria",
      backLabel: "Sair"
    }

    this.handleAnswer = this.handleAnswer.bind(this)
    this.handlePrev = this.handlePrev.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handleNextUnanswered = this.handleNextUnanswered.bind(this)
    this.handleAlternative = this.handleAlternative.bind(this)
    this.handleQuestion = this.handleQuestion.bind(this)
    this.handleBack = this.handleBack.bind(this)
    this.handleLeaveDismiss = this.handleLeaveDismiss.bind(this)
  }

  componentDidUpdate (prevProps) {
    const { questions, urlOnFinalization } = this.props

    if (prevProps.questions != questions) {
      const allAnswered = this.allQuestionsAnswered()

      this.setState({ allAnswered })

      if (allAnswered) {
        this.setState({ backUrl: urlOnFinalization, backLabel: "Finalizar" })
      }
    }
  }

  handleAnswer (event, knowledgeArea) {
    if (this.state.submitDisabled) return

    this.setState({ submitDisabled: true })

    const question = event.target.parentNode.parentNode
    const alternative = question.querySelector("input[type=radio]:checked")

    this.props.syncAnswer(knowledgeArea, alternative)

    event.preventDefault()
  }

  handleAlternative () {
    this.setState({ submitDisabled: false })
  }

  handlePrev (event) {
    event.preventDefault()
    this.prevSlide()
  }

  handleNext (event) {
    event.preventDefault()
    this.nextSlide()
  }

  isQuestionSlideUnanswered (slide) {
    return !slide.props.children[0].props.answered
  }

  allQuestionsAnswered () {
    const { questions } = this.props

    return questions.every(x => {
      return x.answered === true
    })
  }

  handleNextUnanswered (event) {
    event.preventDefault()
    const currentSlide = this.sliderRef.innerSlider.state.currentSlide
    const slides = this.sliderRef.props.children
    const firstUnanswered = slides.findIndex(this.isQuestionSlideUnanswered)
    const nextUnanswered = slides.findIndex((s, index) => this.isQuestionSlideUnanswered(s) && index > currentSlide)
    this.sliderRef.slickGoTo(nextUnanswered === -1 ? firstUnanswered : nextUnanswered)
  }

  prevSlide () {
    let slideCount = this.sliderRef.innerSlider.state.slideCount - 1
    let currentSlide = this.sliderRef.innerSlider.state.currentSlide

    if (currentSlide === 0) {
      this.sliderRef.slickGoTo(slideCount)
    } else {
      this.sliderRef.slickPrev()
    }
  }

  nextSlide () {
    let slideCount = this.sliderRef.innerSlider.state.slideCount - 1
    let currentSlide = this.sliderRef.innerSlider.state.currentSlide

    if (currentSlide === slideCount) {
      this.sliderRef.slickGoTo(0)
    } else {
      this.sliderRef.slickNext()
    }
  }

  handleQuestion (index) {
    const slug = this.sliderRef.props.children[index].key
    const selected = document.querySelector(`input[name=${slug}]:checked`)
    this.setState({ submitDisabled: selected === null })
  }

  handleBack (event) {
    if (!this.allQuestionsAnswered()) {
      this.setState({ leaveModal: true })
      event.preventDefault()
    }
  }

  handleLeaveDismiss (event) {
    this.setState({ leaveModal: false })
    event.preventDefault()
  }

  render () {
    const { submitDisabled, leaveModal, backUrl, backLabel, allAnswered } = this.state
    const { questions, answerSessionClosed, selected, urlOnFinalization } = this.props
    const totalQuestions = questions.length

    const settings = {
      dots: false,
      arrows: true,
      infinite: false,
      centerMode: false,
      adaptiveHeight: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: selected,
      afterChange: this.handleQuestion,
      prevArrow: <PrevArrow handlePrev={this.handlePrev} />,
      nextArrow: <NextArrow handleNext={this.handleNext} />
    }

    return (
      <div className="question-container fullscreen-modal-wrapper">
        <DailyMissionLeaveModal opened={leaveModal} onDismiss={this.handleLeaveDismiss} />
        {answerSessionClosed && <DailyMissionClosedModal />}

        <Header backUrl={backUrl} backLabel={backLabel} onBackClick={this.handleBack} color="white">
          <GamificationBar />
        </Header>

        <div className="question-slide">
          <Slider
            ref={slider => {
              this.sliderRef = slider
            }}
            {...settings}
          >
            {questions.map((question, index) => {
              const { slug, answered, knowledge_area: knowledgeArea } = question
              const { content, alternatives, correctAnswer } = question.question

              return (
                <div key={slug}>
                  <Question knowledgeArea={knowledgeArea} index={`0${index + 1}`} content={content}>
                    {alternatives.map((alternative, alternativeIndex) => {
                      const { name, content, state } = alternative

                      return (
                        <QuestionAlternative
                          key={alternativeIndex}
                          id={slug}
                          alternativeName={name}
                          content={content}
                          state={state}
                          correctAnswer={correctAnswer}
                          questionAnswered={answered}
                          onChange={this.handleAlternative}
                        />
                      )
                    })}
                  </Question>

                  <div className="buttons-container">
                    {allAnswered && (
                      <BtnPrimary to={urlOnFinalization} onClick={this.props.handleFinalization} arrow>
                        Finalizar
                      </BtnPrimary>
                    )}

                    {totalQuestions > 1 && answered && !allAnswered && (
                      <BtnPrimary to="#" arrow onClick={this.handleNext}>
                        Próxima questão
                      </BtnPrimary>
                    )}

                    {!answered && (
                      <BtnPrimary to="#" disabled={submitDisabled} onClick={e => this.handleAnswer(e, knowledgeArea)}>
                        Responder
                      </BtnPrimary>
                    )}

                    {totalQuestions > 1 && !answered && (
                      <BtnLink to="#" onClick={this.handleNextUnanswered}>
                        Pular questão
                      </BtnLink>
                    )}
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    )
  }
}

export default AnswerForm
