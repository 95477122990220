import React from "react"
import { push } from "connected-react-router"
import { connect } from "react-redux"
import Processing from "components/processing"
import API from "api"
import NextStepRouter from "onboarding/next-step-router"

class OnboardingPaymentCreditcardProcessing extends React.Component {
  constructor (props) {
    super(props)

    this.getUpdatedCheckoutStatus = this.getUpdatedCheckoutStatus.bind(this)
  }

  timeout () {
    setTimeout(this.getUpdatedCheckoutStatus, 3000)
  }

  getUpdatedCheckoutStatus () {
    const { dispatch } = this.props

    API.post("/checkout/status", {}, response => {
      if (response.headers.next_step !== "checkoutCreditcardProcessing") {
        const redirectTarget = NextStepRouter(response.headers.next_step)
        dispatch(push(redirectTarget))
      } else {
        this.timeout()
      }
    })
  }

  componentDidMount () {
    this.timeout()
  }

  render () {
    return <Processing />
  }
}

export default connect()(OnboardingPaymentCreditcardProcessing)
