import floor from "planets/kai/kai-checkpoint.svg"
import plant from "planets/kai/kai-plant.svg"

export default {
  mainColor: "#6ed3b6",
  secondaryColor: "#34b8b8",
  floor: {
    image: floor,
    height: 150
  },
  stone: {
    image: plant,
    width: 200,
    height: 150,
    left: 30
  }
}
