import React from "react"
import { connect } from "react-redux"
import Storytelling from "components/storytelling"
import { validateAvatarAccess } from "onboarding/avatar/validation"

class OnboardingAvatarStorytelling extends React.Component {
  componentDidMount () {
    validateAvatarAccess(this.props.dispatch)
  }

  render () {
    const titles = ["Olá, eu sou o Vigo!"]

    const messages = [
      "Sou um mediador e tenho o poder de ajudar os jovens a aprender.",
      "Eu e meus amigos vivíamos felizes no planeta Dion.",
      "Mas um dia nosso planeta foi invadido e todo nosso conhecimento foi roubado e espalhado pela galáxia.",
      "Precisamos viajar pelo universo para recuperar todo esse conhecimento!",
      "O planeta Dion depende da gente! Vamos, eu vou te ajudar nessa jornada."
    ]

    return (
      <div className="storytelling-container">
        <Storytelling titles={titles} messages={messages} redirectTarget="/criar-conta/escolher-avatar" />
      </div>
    )
  }
}

export default connect()(OnboardingAvatarStorytelling)
