import React from "react"
import { Route } from "react-router"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import { isAuthenticated } from "auth"
import NextStepRouter from "onboarding/next-step-router"
import API from "api"

class PublicRoute extends React.Component {
  componentDidMount () {
    if (!isAuthenticated()) return

    const { dispatch } = this.props

    API.get("/sessions", response => {
      const redirectTarget = NextStepRouter(response.headers.next_step)
      dispatch(push(redirectTarget))
    })
  }

  render () {
    if (isAuthenticated()) return null

    const { component: Component, ...rest } = this.props
    return <Route {...rest} render={props => <Component {...props} />} />
  }
}

export default connect()(PublicRoute)
