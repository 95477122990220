import React from "react"
import BagLayout from "components/layouts/bag"
import { PaymentContainer, Title, ContactChatText } from "backpack/payment/styled"

const CreditCardWaiting = () => (
  <BagLayout activeSection="payment">
    <PaymentContainer>
      <Title>Pagamento em análise</Title>
      <p>
        Ao processarmos seu pedido, ele foi encaminhado para análise. Nosso time irá avaliar sua compra e em breve lhe
        daremos um retorno.
      </p>
      <p>Essa análise pode demorar alguns dias, portanto, não se preocupe :)</p>

      <ContactChatText>
        <strong>Problemas?</strong> Entre em contato com nosso time de atendimento via chat ou telefone: (11) 3474-1770
      </ContactChatText>
    </PaymentContainer>
  </BagLayout>
)

export default CreditCardWaiting
