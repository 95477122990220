import React from "react"
import styled from "styled-components"
import Planet from "Galaxy/wrappers/Planet"
import PlanetImage from "Galaxy/wrappers/PlanetImage"
import logic from "galaxies/blue-galaxy/logic.svg"

const Logic = props => (
  <Planet {...props}>
    <PlanetImage src={logic} blocked={props.blocked} />
  </Planet>
)

export default styled(Logic)``
