import React from "react"
import { Redirect } from "react-router"
import { connect } from "react-redux"
import { Layout } from "Avatar"
import { BtnPrimary } from "components/button"
import AvatarSlide from "components/avatar-slide"
import { BottomCurve } from "components/bottom-curve"
import API from "api"
import { updateCurrentUser } from "actions"
import { MixpanelPeopleSet } from "tags/mixpanel"

class ChangeAvatar extends React.Component {
  constructor (props) {
    super(props)

    const { userAvatar } = this.props

    this.state = {
      fireRedirect: false,
      selectedAvatar: userAvatar
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleChoose = this.handleChoose.bind(this)
  }

  handleChange (avatar) {
    this.setState({ selectedAvatar: avatar })
  }

  handleChoose (event) {
    const { selectedAvatar } = this.state
    const { dispatch, userAvatar } = this.props

    event.preventDefault()

    if (userAvatar === selectedAvatar) {
      this.setState({ fireRedirect: true })
      return
    }

    API.put("/accounts", { avatar: selectedAvatar }, response => {
      const { user, relative } = response.data
      dispatch(updateCurrentUser(user, relative))

      MixpanelPeopleSet({
        avatar: selectedAvatar
      })

      this.setState({ fireRedirect: true })
    })
  }

  render () {
    const { fireRedirect, selectedAvatar } = this.state

    if (fireRedirect) {
      return <Redirect to="/mochila/editar-perfil" />
    }

    return (
      <Layout>
        <h2 className="big dotted">
          Escolha seu <strong>Avatar</strong>
        </h2>

        <BottomCurve>
          <AvatarSlide selected={selectedAvatar} onChange={this.handleChange} />

          <BtnPrimary to="/" value="Confirmar" onClick={this.handleChoose} arrow />
        </BottomCurve>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    userAvatar: state.auth.currentUser.avatar
  }
}

export default connect(mapStateToProps)(ChangeAvatar)
