import ThemingRoute from "helpers/theming-route"
import Trail from "trail/trail"

const Routes = [
  {
    router: ThemingRoute,
    path: "/trilha",
    component: Trail
  }
]

export default Routes
