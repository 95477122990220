import styled from "styled-components"
import city from "planets/axion/axion-city.svg"
import sky from "planets/axion/axion-sky.svg"
import { StyledHorizon, StyledHorizonContainer, StyledHorizonTitle } from "Horizon"

const PlanetHorizon = styled(StyledHorizon)`
  background: linear-gradient(180deg, #006a65 0%, #00e9d9 100%);
  overflow: hidden;
  &::before {
    background: url(${city}) repeat-x center top;
    background-size: auto 100%;
    width: 100%;
    margin: 0px auto;
    bottom: -1px;
    height: 300px;
    display: flex;
    justify-content: center;
  }
`

const ShortPlanetHorizon = styled(StyledHorizon)`
  background: linear-gradient(180deg, #006a65 0%, #00e9d9 100%);
`

const PlanetHorizonContainer = styled(StyledHorizonContainer)`
  &::before {
    background: url(${sky}) no-repeat;
    height: 200px;
    bottom: 110px;
    transform: scaleX(-1);

    @media (max-width: 768px) {
      left: -250px;
      width: 600px;
    }
  }
`
const ShortPlanetHorizonContainer = styled(StyledHorizonContainer)`
  &::before {
    background: url(${sky}) no-repeat;
    height: 150px;
    bottom: 50px;
  }
  @media (max-width: 768px) {
    top: 40px;
  }
`

const PlanetHorizonTitle = styled(StyledHorizonTitle)`
  position: absolute;
  right: 250px;
  @media (max-width: 1024px) {
    right: auto;
    top: 60px;
    left: 70%;
  }
`

export { PlanetHorizon, ShortPlanetHorizon, PlanetHorizonContainer, ShortPlanetHorizonContainer, PlanetHorizonTitle }
