import stone0Icon from "planets/elements/bottles/bottle-stone-0.svg"
import stone1Icon from "planets/elements/bottles/bottle-stone-1.svg"
import stone2Icon from "planets/elements/bottles/bottle-stone-2.svg"
import stone3Icon from "planets/elements/bottles/bottle-stone-3.svg"
import stone4Icon from "planets/elements/bottles/bottle-stone-4.svg"
import stone5Icon from "planets/elements/bottles/bottle-stone-5.svg"
import stone6Icon from "planets/elements/bottles/bottle-stone-6.svg"

export const stone0 = {
  icon: stone0Icon,
  width: "45px",
  height: "44px",
  top: "-10px",
  left: "310px"
}

export const stone1 = {
  icon: stone1Icon,
  width: "33px",
  height: "47px",
  top: "115px",
  left: "144px"
}

export const stone2 = {
  icon: stone2Icon,
  width: "39px",
  height: "54px",
  top: "256px",
  left: "60px"
}

export const stone3 = {
  icon: stone3Icon,
  width: "38px",
  height: "56px",
  top: "350px",
  left: "270px"
}

export const stone4 = {
  icon: stone4Icon,
  width: "35px",
  height: "50px",
  top: "480px",
  left: "55px"
}

export const stone5 = {
  icon: stone5Icon,
  width: "41px",
  height: "63px",
  top: "595px",
  left: "318px"
}

export const stone6 = {
  icon: stone6Icon,
  width: "53px",
  height: "50px",
  top: "633px",
  left: "50px"
}
