import React from "react"
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView"
import styled from "styled-components"
import { Container, Index, Title, Name, KnowledgeArea } from "daily-mission/knowledge-area"
import { withPlanet } from "planet-context"

const QuestionContainer = withPlanet(styled.div`
  width: 100%;
  margin: 0 auto;

  ${Container} {
    background-color: ${props => props.planet.mainColor}
    width: 90%;
    max-width: 90%;
    min-height: 135px;
    max-height: 135px;
    margin-bottom: 30px;
  }

  ${Index} {
    top: auto;
    bottom: 6px;
    font-size: 60px;
  }

  ${Title} {
    bottom: 15px;
  }

  ${Name} {
    font-size: 20px;
  }
`)

const QuestionContent = styled.div`
  color: #000000;
  font-size: 13px;
  line-height: 21px;
  margin: 0 auto 40px;
  width: 90%;

  & img {
    max-width: 100%;
  }
`

const Question = ({ knowledgeArea, index, content, children }) => (
  <QuestionContainer>
    <KnowledgeArea name={knowledgeArea} index={index} />

    <QuestionContent>
      <FroalaEditorView model={content} />
    </QuestionContent>

    <hr />

    {children}
  </QuestionContainer>
)

export default Question
