import React from "react"
import styled from "styled-components"
import Planet from "Galaxy/wrappers/Planet"
import PlanetImage from "Galaxy/wrappers/PlanetImage"
import imagite from "galaxies/green-galaxy/imagite.svg"

const Imagite = props => (
  <Planet {...props}>
    <PlanetImage src={imagite} blocked={props.blocked} />
  </Planet>
)

export default styled(Imagite)``
