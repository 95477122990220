import { env, mixpanelToken } from "config"
import mixpanel from "mixpanel-browser"

const shouldTrack = env === "production"

const MixpanelInitialize = () => {
  if (shouldTrack) {
    mixpanel.init(mixpanelToken)
  }
}

const MixpanelTrack = (event, props) => {
  if (shouldTrack) {
    mixpanel.track(event, props)
  }
}

const MixpanelPeopleSet = props => {
  if (shouldTrack) {
    mixpanel.people.set(props)
  }
}

const MixpanelReset = () => {
  if (shouldTrack) {
    mixpanel.reset()
  }
}

const MixpanelIdentify = id => {
  if (shouldTrack) {
    mixpanel.identify(id)
  }
}

const MixpanelSigninEvent = (user, relative) => {
  if (shouldTrack) {
    mixpanel.people.set({
      uuid: user.id,
      $name: user.name,
      "nome do usuario": user.username,
      $email: user.email,
      escola: user.school_name,
      "ano escolar": user.school_year,
      turma: user.school_class,
      "nome do responsavel": relative.name,
      parentesco: relative.relationship,
      "email responsavel": relative.email,
      "telefone responsavel": relative.phone_number,
      "status pagamento": "completo",
      "tem jogo": user.has_games,
      "tem livro": user.has_books
    })
  }
}

const MixpanelSignupEvent = (user, relative) => {
  if (shouldTrack) {
    mixpanel.alias(user.id)
    MixpanelSigninEvent(user, relative)
    MixpanelTrack("Concluiu cadastro")
  }
}

export {
  MixpanelInitialize,
  MixpanelTrack,
  MixpanelPeopleSet,
  MixpanelReset,
  MixpanelIdentify,
  MixpanelSignupEvent,
  MixpanelSigninEvent
}
