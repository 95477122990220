export default [
  {
    title: "",
    description: "",
    items: [
      {
        text: "Jogo para 2 jogadores.",
      },
      {
        text: "O lobo pode se mover uma casa diagonalmente, tanto para frente como para trás.",
      },
      {
        text: "As ovelhas podem se mover somente para frente, uma casa, na diagonal.",
      },
      {
        text: "As movimentações das peças só devem ocorrer nas casas escuras do tabuleiro.",
      }
    ]
  }
]