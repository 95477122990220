import React from "react"
import styled from "styled-components"
import Planet from "Galaxy/wrappers/Planet"
import PlanetImage from "Galaxy/wrappers/PlanetImage"
import pensatus from "galaxies/green-galaxy/pensatus.svg"

const Pensatus = props => (
  <Planet {...props}>
    <PlanetImage src={pensatus} blocked={props.blocked} />
  </Planet>
)

export default styled(Pensatus)``
