export default [
  {
    title: "",
    description: "",
    items: [
      {
        text: "O jogo é para dois oponentes.",
      },
      {
        text: "No início do jogo, cada jogador deve posicionar seus submarinos no Mapa da Frota.",
      },
      {
        text: "Os submarinos podem ser colocados na horizontal ou vertical, mas não na diagonal.",
      },
      {
        text: "Submarinos podem ser colocados nas bordas e cantos do tabuleiro.",
      },
      {
        text: "Cada jogador tem um Mapa dos Alvos vazio, onde ficarão todas as informações obtidas sobre o mapa do oponente.",
      },
      {
        text: "Cada jogador, na sua vez, tem três possibilidades de tentar atingir um submarino do oponente, escolhendo coordenadas. Se parte do submarino for atingida, aparecerá a imagem de fogo. Se todo o submarino foi atingido aparecerá a imagem do submarino coberta por fogo. Se nenhuma parte do submarino foi atingida, então aparecerá um “X”.",
      },
      {
        text: "No mapa da frota (mapa inferior da tela) é onde ficarão as informações de coordenadas e submarinos do próprio jogador.",
      },
      {
        text: "O jogo termina quando um dos jogadores afundar toda a frota do oponente.",
      }
    ]
  }
]