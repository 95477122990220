import React from "react"
import image from "icon-ruby.svg"
import { Header } from "Header"
import { GamificationBar } from "GamificationBar"
import { ScrollUp } from "helpers/scroll"

const HowToWinRuby = () => {
  ScrollUp()

  return (
    <div className="bag-manual-about-container">
      <Header backUrl="/mochila/mural" color="colored">
        <GamificationBar />
      </Header>
      <h1 className="purple">Como ganho Rubi?</h1>
      <img src={image} />
      <p>Rubis são &quot;artefatos especiais&quot;, muito raros, adquiridos com menos frequência que os XPs.</p>
      <p>
        Quanto mais você acessar o Mindzup, mais chances você terá de encontrar um Rubi. Seu engajamento dentro da
        plataforma, assim como seu desempenho nas Missões Diárias e Avaliações, poderão lhe render alguns desses
        artefatos especiais ;)
      </p>
    </div>
  )
}

export default HowToWinRuby
