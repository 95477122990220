import React from "react"
import { SpecialBadgeName, SpecialBadgeSubtitle } from "backpack/badges/styled"
import ConqueredBadgeBase from "backpack/badges/ConqueredBadgeBase"

const ConqueredSpecialBadge = ({ badge }) => (
  <ConqueredBadgeBase badge={badge}>
    <SpecialBadgeName>{badge.id}</SpecialBadgeName>
    <SpecialBadgeSubtitle>{badge.subtitle}</SpecialBadgeSubtitle>
  </ConqueredBadgeBase>
)

export default ConqueredSpecialBadge
