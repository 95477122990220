import React from "react"
import CloseableModal from "components/modals/closeable-modal"
const PrivacyPolicyModal = ({ opened, onClose }) => {
  return (
    <CloseableModal opened={opened} onClose={onClose} overlay>
      <h2 className="modal-title">Aviso de privacidade</h2>
      <div className="scrollable-container document-modal">
        <h4>CLÁUSULA 1 - Empresa Responsável, Plataformas e Encarregado:</h4>
        <p>
          1.1. AO ACESSAR, USAR OU SE CADASTRAR NOS APLICATIVOS E/OU SITES MINDLAB.COM.BR, MINDZUP, MINDLAB.NET,
          EDUCADOR360.COM, VOJO.COM.BR E EDUK.COM.BR (“PLATAFORMA”), FORNECER PRODUTOS OU SERVIÇOS, SE RELACIONAR E/OU
          USUFRUIR, DIRETA E/OU INDIRETAMENTE, DOS PRODUTOS E SERVIÇOS (Serviços) DA MINDLAB DO BRASIL COMÉRCIO DE
          LIVROS LTDA., COM SEDE NA RUA VERBO DIVINO, 528, 1° ANDAR, CHÁCARA SANTO ANTÔNIO, CEP: 04719-001, INSCRITA NO
          CNPJ/MF SOB O Nº. 10.391.836/0001-18 (“SOCIEDADE”), VOCÊ DECLARA ESTAR CIENTE E COMPREENDER INTEGRALMENTE OS
          TERMOS DO PRESENTE AVISO DE PRIVACIDADE (“Aviso de Privacidade”).
        </p>
        <p>
          1.2. Caso você esteja usando a Plataforma e/ou se relacione com a Sociedade em nome de entidade e/ou pessoa
          jurídica, você garante estar autorizado e ter todos os poderes necessários para tomar ciência e aceitar este
          Aviso de Privacidade em nome da respectiva entidade e/ou pessoa jurídica, assim como garante que a entidade
          e/ou pessoa jurídica que você representa concorda em indenizar a Sociedade por qualquer violação deste Aviso
          de Privacidade por você cometida.
        </p>
        <p>
          1.3. Caso a Plataforma e/ou os Serviços da Sociedade sejam de qualquer modo utilizados por menores de idade
          (menores de 18 anos), o tratamento dos dados pessoais destes menores deverá ser autorizado pelos responsáveis
          legais, que deverão estar cientes e de acordo com os termos deste Aviso de Privacidade.
        </p>
        <p>
          1.4. A Sociedade Mind Lab desenvolve tecnologias educacionais inovadoras para o aprimoramento de habilidades e
          competências cognitivas, sociais, emocionais e éticas, enquanto cada Plataforma fornecerá, em suma, os
          serviços abaixo listados. Pelos serviços disponibilizados, a Sociedade e as Plataformas tratarão Dados
          Pessoais, sendo que tal Tratamento será regido pelo presente Aviso de Privacidade.
        </p>
        <p>
          <strong>Mindzup:</strong> É uma plataforma que está disponível para alunos de escolas parceiras da MindLab.
          Nesta plataforma, o usuário pode realizar missões diárias, jogos de raciocínio, avaliações, além de acessar
          outros conteúdos.
        </p>
        <p>
          <strong>Mindlab.net:</strong> Nesta plataforma, os professores recebem a formação.
        </p>
        <p>
          <strong>Educador360:</strong> O Educador360 objetiva apoiar sua escola a desenvolver uma educação mais
          inovadora
        </p>
        <p>
          <strong>Vojo:</strong> O objetivo da Plataforma é conectar empresas parceiras a indivíduos interessados em
          oportunidades no mercado de trabalho
        </p>
        <p>
          <strong>EDUK:</strong> O objetivo da Plataforma é disponibilizar cursos diversos para terceiros e conectar
          empresas parceiras a indivíduos interessados em oportunidades no mercado de trabalho.
        </p>
        <p>
          1.5. A Sociedade indica a pessoa física abaixo qualificada para o cargo de “Encarregado”, que ficará então
          responsável por auxiliar e orientar os Titulares de dados em todas as questões relativas a este Aviso de
          Privacidade ou ao tratamento de dados pessoais realizado pela Sociedade, assim como para atuar como canal de
          comunicação entre os Titulares, a Sociedade e a Autoridade Nacional de Proteção de Dados.
        </p>
        <p>Encarregado: Jessica Farias</p>
        <p>E-mail: lgpd@mindlab.com.br</p>

        <h4>CLÁUSULA 2 - Definições</h4>

        <p>
          2.1. O Aviso de Privacidade será regido pelas definições dos termos abaixo listados. Caso haja algum termo não
          abordado neste Aviso, a interpretação deverá ser de acordo com a Lei Brasileira:
        </p>

        <p>
          (i) <b>Dado Pessoal:</b> informações relacionadas a você, que te identificam ou podem vir a te identificar;
        </p>

        <p>
          (ii) <b>Dado Pessoal Sensível:</b> são seus dados sobre origem racial ou étnica, convicção religiosa, opinião
          política, filiação à sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à
          saúde ou à vida sexual, dado genético ou biométrico que, em conjunto com Dado Pessoal, serão denominados Dados
          Pessoais;
        </p>

        <p>
          (iii) <b>Tratamento:</b> toda operação que realizarmos com seus Dados Pessoais, como as que se referem a
          coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição,
          processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação,
          comunicação, transferência, difusão ou extração;
        </p>

        <p>
          (iv) <b>Controlador:</b> no âmbito deste Aviso de Privacidade, e no escopo do relacionamento com Você, a Mind
          Lab (“Nós”), a quem competem as decisões referentes ao Tratamento dos seus Dados Pessoais;
        </p>

        <p>
          (v) <b>Operador:</b> pessoa natural ou jurídica, de direito público ou privado, que realiza o Tratamento de
          Dados Pessoais em nome do Controlador;
        </p>

        <p>
          (vi) <b>Titular dos Dados:</b> toda pessoa física que acessa a Plataforma e/ou que se relaciona, de forma
          direta ou indireta, com a Sociedade.
        </p>

        <h4>CLÁUSULA 3 - Dados Coletados</h4>

        <p>
          3.1. Ao utilizar a plataforma, nós coletamos algumas informações importantes para que Você possa usufruir dos
          produtos / serviços disponibilizados na mesma, que podem ser divididas em duas categorias:
        </p>

        <p>3.1.1. Dados disponibilizados pelos Usuários de forma ativa:</p>

        <ol>
          <li>
            <b>a) Quando do cadastro na Plataforma:</b> nome completo, CPF, e-mail, celular, gênero, data de nascimento,
            escolaridade e endereço;
          </li>

          <li>
            <b>b) Situação específica das Plataformas Vojo e eduK:</b> Há determinas vagas de trabalho disponibilizadas
            no Vojo e na eduK que podem exigir a disponibilização de dados de saúde.
          </li>
        </ol>

        <p>3.1.2. Dados coletados por Nós</p>

        <ol>
          <li>
            <b>a) Dados de Acesso:</b> informações coletadas por meio da utilização do website e aplicativo, como
            informações de tela e resolução, endereço do protocolo de Internet (IP), tempo médio gasto, data e
            localização do acesso. Além disso, caso o Usuário acione os Canais de Comunicação disponíveis na plataforma,
            os registros serão mantidos para fins de segurança.
          </li>

          <li>
            <b>b) Dados de Redes Sociais:</b> informações coletadas a partir do uso de nossas mídias sociais, como as
            estatísticas de acesso e interação com nossas páginas, podendo ter acesso às suas informações pessoais que
            esses terceiros forneçam a Nós.
          </li>
        </ol>

        <p>
          3.2. Especificamente em relação aos dados de acesso coletados pela plataforma, estes poderão ser obtidos
          através da utilização de <i>Cookies</i> e tecnologias semelhantes, o que será abordado na Cláusula 10. A
          implementação e acesso de Cookies se destina à melhoria da qualidade das informações fornecidas pela
          plataforma, tornando a experiência do Usuário cada vez mais aprimorada, com relação ao produtos e Serviços
          disponibilizados por Nós, neste canal.
        </p>

        <p>
          3.3. A Sociedade se reserva o direito de solicitar complementações de seus dados, deixando claro que Você
          sempre terá a opção de fornecê-los ou não. Lembramos aqui que poderá haver instabilidade ou mesmo a
          impossibilidade de prestação dos nossos Serviço, por conta da ausência desses dados essenciais.
        </p>

        <p>
          3.4. Ao acessar ou usar a Plataforma e/ou os Serviços, você Titular está ciente - como pessoa física e, sempre
          que aplicável, também como representante de menor de idade, de pessoa jurídica ou de entidade por você
          representada - do tratamento dos dados listados acima, bem como declara que ao fornecer dados de terceiros,
          pessoais ou não, na Plataforma (diretamente ou mediante a inserção de documentos contendo tais dados) e/ou
          para a Sociedade, tal disponibilização está de acordo com os termos da legislação vigente e não viola direitos
          de terceiros, responsabilizando-se por toda e qualquer reclamação ou prejuízo decorrente do tratamento de
          dados pessoais por você inseridos na Plataforma (diretamente ou mediante a inserção de documentos contendo
          tais dados) e/ou fornecidos, de qualquer modo, para a Sociedade.
        </p>

        <h4>CLÁUSULA 4 - Finalidades para Utilização dos Dados Pessoais</h4>
        <p>
          4.1. Os Dados Pessoais serão utilizados por Nós para melhorar a experiência. Listamos as finalidades
          específicas abaixo:
        </p>
        <ul>
          <li>Efetivar o cadastro do Usuário na Plataforma, permitindo acesso aos nossos serviços / produtos;</li>

          <li>Cumprimento de obrigações legais e/ou regulatórias;</li>

          <li>
            Analisar o nosso desempenho, medir a audiência, verificar hábitos de navegação, avaliar estatísticas de
            acesso e uso do site e aplicativo, seus recursos e funcionalidades;
          </li>

          <li>Medir e avaliar interações feitas pelos Usuários;</li>

          <li>Promoção, melhoria e desenvolvimento dos nossos serviços / produtos;</li>

          <li>Realização de Auditorias Internas e Externas;</li>

          <li>Realização de Auditorias Internas e Externas;</li>

          <li>Mapeamento de informações de mercado, estatísticas e elaboração de relatório de dados;</li>

          <li>Prestar serviços de atendimento e suporte;</li>

          <li>
            Análises relacionadas à segurança da plataforma e à segurança da informação, prevenindo erros/falhas
            técnicas;
          </li>

          <li>Prevenção antifraude;</li>

          <li>Comercialização de cursos e produtos;</li>

          <li>Emitir certificados;</li>

          <li>Enviar e-mails marketing e informações sobre a Plataforma;</li>

          <li>Realizar cobranças;</li>

          <li>Realização de back-ups;</li>

          <li>Efetuar entregas dos produtos adquiridos;</li>

          <li>Pesquisas de satisfação.</li>
        </ul>

        <h4>CLÁUSULA 5 - Compartilhamento das suas Informações </h4>

        <p>
          5.1. Os seus Dados Pessoais poderão ser compartilhados de acordo para as finalidades descritas na cláusula 4.
          Nós nos comprometemos a fazê-lo apenas com parceiros que empreguem alto nível de segurança da informação,
          estabelecendo cláusulas contratuais que não violem as disposições desse Aviso. Além disso, listamos abaixo as
          hipóteses de compartilhamento de suas informações:
        </p>

        <p>
          5.1.2. Com empresas de consultoria e escritórios de advocacia, para proteção dos nossos interesses, incluindo
          casos de demandas judiciais e administrativas;
        </p>

        <p>
          5.1.3. Em caso de operações societárias, quando a transferência dos Dados Pessoais for necessária para a
          continuidade dos Serviços ofertados, ou quando garantidos meios de anonimização;
        </p>

        <p>5.1.4. Com nossos clientes, para cumprimentos de auditorias públicas ou privadas;</p>

        <p>
          5.1.5. Com empresas fornecedoras, para armazenamento em nuvem, gerenciamento de banco de dados, análise de
          dados e melhorias das funcionalidades;
        </p>

        <p>
          5.1.6. Mediante ordem judicial ou por requerimento de Autoridades Públicas ou Reguladoras que detenham
          competência para requisição;
        </p>

        <p>
          5.1.7. Com órgãos e autoridades públicas, para fins de cumprimento de obrigações legais e/ou regulatórias.
        </p>

        <p>
          5.1.8. Com afiliadas e empresas do mesmo grupo sempre que tal compartilhamento for importante para a operação
          da Plataforma, para o fornecimento dos Serviços, para o cumprimento de obrigações legais ou regulatórias e/ou
          forem do interesse do Titular.
        </p>

        <p>
          5.1.9. A Sociedade poderá divulgar ou compartilhar informações, incluindo dados pessoais dos Titulares e de
          terceiros, se acreditar, de boa-fé, que o acesso, o uso, a conservação ou a divulgação das informações seja
          razoavelmente necessário para (a) detectar ou impedir fraudes, bem como resolver questões técnicas ou de
          segurança, e; (b) garantir a segurança da Plataforma, da Sociedade, dos Titulares e de terceiros. Neste caso,
          os dados e as informações poderão ser compartilhados com terceiros responsáveis pela investigação, incluindo
          órgãos do judiciário, autoridades e empresas de tecnologia da informação ou segurança da informação.
        </p>

        <p>5.1.10. Com empresas, organizações ou indivíduos que prestam serviços em nome da Sociedade.</p>

        <p>
          5.1.11. Com pessoas jurídicas que atuam em parceria com a Sociedade para as finalidades previstas na Cláusula
          4 acima, incluindo a prestação dos serviços ofertados e produção dos conteúdos disponibilizados nas
          Plataformas.
        </p>

        <h4>CLAÚSULA 6 - Armazenamento e Proteção das Informações</h4>

        <p>
          6.1. Todos os Dados Pessoais tratados por Nós serão armazenados em servidores próprios ou em serviços de nuvem
          confiáveis.
        </p>

        <p>
          6.2. Nós nos comprometemos a adotar todos os esforços razoáveis, considerando as soluções tecnológicas
          disponíveis e aplicáveis, para preservar a integridade e segurança das suas informações.
        </p>

        <p>
          6.3. Nós utilizamos o princípio de <i>privacy by design</i>, o que significa que pensamos a privacidade
          previamente ao fornecimento dos nossos serviços / produtos, respeitando e protegendo os seus Dados Pessoais em
          nossos procedimentos internos.
        </p>

        <p>
          6.4. Nós tomaremos todas as medidas que estiverem ao nosso alcance para manutenção da confidencialidade e
          segurança dos seus Dados Pessoais, tais como:
        </p>

        <p>(i) métodos padrões de mercado para criptografia dos dados;</p>

        <p>
          (ii) emprego de <i>software</i> de alta tecnologia para proteção contra acessos não autorizados à plataforma;
        </p>

        <p>
          (iii) acesso a locais de armazenamento de dados pessoais apenas por pessoas previamente autorizadas e
          comprometidas com o sigilo dos dados, inclusive mediante assinatura de termo de confidencialidade;
        </p>

        <p>
          (iv) utilização de mecanismos de controle e bloqueio de acesso, restringindo a utilização dos dados apenas
          para pessoas previamente autorizadas e responsáveis por determinado Tratamento de informações;
        </p>

        <p>
          (v) aplicação de mecanismos de autenticação de acesso aos registros capazes de individualizar o responsável
          pelo tratamento e acesso dos dados coletados em decorrência da utilização da plataforma.
        </p>

        <p>
          (vi) sistemas de monitoramento e testes de segurança periódicos, entre outras práticas em prol da segurança da
          informação e dos Dados Pessoais.
        </p>

        <p>
          6.5. Apesar disso, não é possível garantir de forma absoluta a não ocorrência de erros no sistema, incidentes
          de segurança, violações e acessos não autorizados, considerando que as práticas de segurança da internet
          encontram-se em constante evolução. Saliente-se, no entanto, Nós nos comprometemos a acompanhar as evoluções e
          implementar melhorias de segurança constantemente, caso sejam possíveis e razoáveis.
        </p>

        <h4>CLAÚSULA 7 - Boas Práticas dos Usuários</h4>

        <p>
          7.1. Considerando que você será usuário da Plataforma através de um dispositivo móvel ou computador, a
          segurança dos seus Dados Pessoais não depende exclusivamente de Nós, de modo que Você também deverá estar
          atento à disponibilidade das suas informações e nossas orientações.
        </p>

        <p>7.2. Para garantir o cuidado e a diligência com seus Dados Pessoais, você se compromete a:</p>

        <p>(i) Manter suas informações pessoais atualizadas, permitindo o funcionamento regular da plataforma;</p>

        <p>
          (ii) Proteger suas informações contra acessos não autorizados ao seu computador ou dispositivo móvel (celular,
          tablet, etc), contas e senhas;
        </p>

        <p>
          (iii) Não compartilhar sua senha com terceiros, já que a sua senha possui caráter pessoal e intransferível, e
          lembrar de atualizá-la com frequência;
        </p>

        <p>(iv) Não compartilhar a usabilidade de sua conta com terceiros, ainda que conhecidos;</p>

        <p>(v) Manter o programa antivírus e o sistema operacional do computador e/ou dispositivo móvel atualizados;</p>

        <p>
          (vi) Não permitir que o navegador, Website ou o Aplicativo salve automaticamente a sua senha em dispositivos
          compartilhados. Ainda que em dispositivos particulares, recomendamos que o Usuário não permita que o navegador
          memorize sua senha;
        </p>

        <p>
          (vii) Ao acessar a plataforma por um computador ou dispositivo móvel compartilhado, certificar-se de que
          encerrou sua navegação/acesso ao final dela;
        </p>

        <p>
          (viii) Manter seu navegador de internet ou dispositivo móvel atualizado, conforme orientação do fabricante;
        </p>

        <p>
          (ix) Ao acessar a internet em lugares públicos, tomar cuidado para que pessoas próximas não possam ver sua
          senha e seus dados pessoais, e não permitir a guarda automática de qualquer informação;
        </p>

        <p>
          (x) Certificar-se de que está utilizando uma conexão segura quando for digitar informações privadas no Website
          e/ou no Aplicativo.
        </p>

        <p>
          (xii) Entre outras práticas que possam ser eficientes para garantir a segurança e integridade das suas
          informações, no momento de utilização da plataforma
        </p>

        <p>
          7.3. Você concorda em não responsabilizar a Sociedade por perdas, danos ou outros problemas de qualquer
          espécie que possam surgir da utilização de websites que contenham links para a Plataforma ou cujos links
          estejam disponíveis na Plataforma, bem como está ciente de que tais websites podem não adotar práticas
          adequadas no tocante ao tratamento de dados pessoais e privacidade dos Titulares.
        </p>

        <h4>CLÁUSULA 8 - Período de Retenção </h4>

        <p>
          8.1. Nós manteremos os seus Dados Pessoais até atingir as finalidades descritas na Cláusula 4 e/ou para
          cumprimento de obrigações legais e regulatórias decorrentes dos Serviços prestados pela nossa Plataforma.
        </p>

        <p>
          8.2. Também nos comprometemos a excluir seus Dados Pessoais, interrompendo o seu tratamento, no momento em que
          Você revogar o seu consentimento, guardando apenas as informações permitidas e/ou determinadas pela Lei
          Brasileira.
        </p>

        <p>
          8.3. Poderemos também excluir seus dados, interrompendo o tratamento destes, mediante determinação de
          Autoridade competente e/ou ordem judicial.
        </p>

        <p>
          8.4. Você também poderá requerer a exclusão dos seus dados entrando em contato conosco, por meio dos nossos
          Canais de Atendimento. Nós nos comprometemos a empreender todos os esforços razoáveis para atender os seus
          pedidos, caso sejam cabíveis, no menor tempo possível e em cumprimento à Lei Brasileira.
        </p>

        <p>
          8.5. O Usuário se declara ciente que, com a exclusão de algumas informações, poderá haver uma impossibilidade
          de acesso a alguns e/ou todos os serviços / produtos na plataforma, em decorrência das nossas necessidades
          operacionais.
        </p>

        <p>
          8.6. Nós reservamos o direito de manter armazenado em seus servidores os dados necessários ao cumprimento da
          Lei Brasileira, ainda que diante de requisição de exclusão pelo Usuário.
        </p>

        <h4>
          CLÁUSULA 9 - <i>Cookies</i> e outras tecnologias{" "}
        </h4>

        <p>
          9.1. <i>Cookies</i> são pequenos arquivos que podem ou não ser adicionados no seu dispositivo eletrônico, e
          que permitem armazenar e reconhecer dados de sua navegação. Em sua navegação na plataforma, objetivando
          melhorarmos a sua experiência, poderão ser utilizados <i>Cookies</i>.
        </p>

        <p>
          9.2. Além disso, Nós também utilizamos <i>cookies</i> para entender tendências, administrar nossos serviços e
          também, mas sem se limitar a analisar os comportamentos do Usuário.
        </p>

        <h4>CLÁUSULA 10 - Direitos do Usuário </h4>

        <p>
          10.1. A Sociedade disponibilizará meios para que Você possa acessar, corrigir, excluir ou modificar os dados
          que foram por eles inseridos na Plataforma e/ou disponibilizados para a Sociedade, possam solicitar a
          correção, a exclusão ou a modificação dos respectivos dados pessoais. As opções dos Titulares de dados
          pessoais seguem abaixo listadas:
        </p>

        <ul>
          <li>
            Confirmação da existência de tratamento: Os Titulares de dados pessoais podem solicitar a confirmação no
            tocante à existência de tratamento de seus dados pessoais.
          </li>

          <li>
            Direito de acessar os seus dados: Os Titulares de dados pessoais podem solicitar uma cópia dos dados
            tratados, a qual será disponibilizada em um formato legível e eletrônico.
          </li>

          <li>
            Alteração ou correção de dados: Os Titulares de dados pessoais podem solicitar atualizações, alterações ou
            correções de seus dados em determinados casos, especialmente se os referidos dados estiverem incorretos ou
            desatualizados.
          </li>

          <li>
            Exclusão, bloqueio e/ou anonimização de dados: Os Titulares de dados pessoais podem solicitar a exclusão de
            seus dados da Plataforma e dos repositórios da Sociedade sem a necessidade de apresentar qualquer
            justificativa, assim como poderão solicitar a exclusão, o bloqueio e/ou a anonimização dos referidos dados
            pessoais.
          </li>

          <li>
            Portabilidade: Os Titulares de dados pessoais podem solicitar a portabilidade dos seus dados a outro
            fornecedor de serviços ou produtos.
          </li>

          <li>
            Informações sobre o compartilhamento dos dados: Os Titulares de dados pessoais poderão solicitar informações
            acerca das entidades públicas e/ou privadas com as quais a Sociedade compartilhou seus dados pessoais.
          </li>

          <li>
            Informações sobre o consentimento: Os Titulares de dados pessoais poderão solicitar informações sobre a
            possibilidade de não fornecer consentimento em situações específicas e sobre as consequências do não
            fornecimento do consentimento.
          </li>

          <li>
            Revogação de consentimento: Para os casos em que os Titulares outorgaram consentimento à Sociedade para o
            tratamento de seus dados pessoais, os Titulares poderão, a qualquer tempo, revogar este consentimento.
          </li>
        </ul>

        <p>
          10.2. Para que Você possa exercer seus direitos, basta entrar em contato através do aplicativo, e-mail
          lgpd@mindlab.com.br apontando suas dúvidas e/ou requerimentos relacionados a seus Dados Pessoais.
        </p>

        <p>
          10.3. Nos reservamos o direito de utilizar meios de autenticação dos Usuários no momento de suas solicitações,
          como forma de segurança e proteção à integralidade das informações, evitando as chances de acessos aos Dados
          Pessoais por terceiros desautorizados e vazamentos ou roubos de dados.
        </p>

        <p>
          10.4. Nós empreenderemos seus melhores esforços para responder as suas solicitações no menor tempo possível e
          de forma completa, clara e de acordo com as suas legítimas expectativas.
        </p>

        <p>
          10.5. No mais, Você fica ciente de que a exclusão das informações essenciais para gestão de sua conta junto
          implicará no término de seu cadastro, com consequente cancelamento do acesso.
        </p>

        <p>
          10.6. Há situações em que a Sociedade precisará manter ou não poderá alterar os dados pessoais e, por tal
          razão, não poderá atender plenamente às solicitações que você realizar, ainda que observadas as regras acima
          (e.g. quando a manutenção dos dados pessoais se mostrar necessária para o cumprimento de obrigações legais ou
          para atender ordem judicial).
        </p>

        <p>
          10.7. Você deverá informar imediatamente a Sociedade, através do Encarregado, quando for necessária a
          cooperação da Sociedade para alterar, atualizar, complementar, corrigir ou excluir dados pessoais que foram
          disponibilizados na Plataforma e/ou para a Sociedade. O Titular deverá fornecer todas as informações
          relevantes para que a Sociedade possa tomar as providências necessárias dentro de um prazo razoável.
        </p>

        <h4>CLÁUSULA 11 - Notificação do Usuário</h4>

        <p>
          <b>
            11.1. Nós poderemos te contatar por meio dos seguintes canais de comunicação: (i) Telefone; (ii) SMS; E
            (iii) E-mail.
          </b>
        </p>

        <p>
          11.2. Ao aceitar este Aviso, você concorda e nos autoriza a utilizarmos o seu contato telefônico para nos
          comunicarmos com Você através dos canis acima.
        </p>

        <h4>CLÁUSULA 12 - Transferência Internacional </h4>

        <p>
          12.1. A SOCIEDADE PROCESSA DADOS, INCLUINDO DADOS PESSOAIS, DENTRO DO BRASIL E EM OUTROS PAÍSES. É POSSÍVEL
          QUE ALGUNS DOS PAÍSES EM QUESTÃO TENHAM LEIS MENOS PROTETIVAS NO TOCANTE À PRIVACIDADE E/OU DADOS PESSOAIS, OU
          SEQUER POSSUAM REGULAMENTAÇÃO ESPECÍFICA SOBRE O ASSUNTO.
        </p>

        <p>
          12.2. VOCÊ ESTÁ CIENTE E CONCORDA QUE OS SEUS DADOS PESSOAIS E OS DADOS PESSOAIS DE TERCEIROS POR VOCÊ
          INSERIDOS NA PLATAFORMA OU DISPONIBILIZADOS À SOCIEDADE PODERÃO TRANSITAR POR - OU RECEBER TRATAMENTO EM -
          OUTROS PAÍSES.
        </p>

        <h4>CLÁUSULA 13 - Mudanças no Aviso de Privacidade </h4>

        <p>
          13.1. Considerando que estamos sempre buscando o aperfeiçoamento dos serviços / produtos disponibilizados,
          este Aviso de Privacidade pode passar por atualizações. Desta forma, recomendamos que Você visite
          periodicamente esta página.
        </p>

        <p>
          13.2. Caso sejam feitas alterações relevantes que necessitem de um novo consentimento dos Usuários, podemos
          colocar um aviso em nosso website, aplicativo ou te enviar um e-mail/SMS/WhatsApp, juntamente com o Aviso de
          Privacidade atualizada, para obtermos o seu consentimento.{" "}
          <b>
            <u>Por isso é muito importante manter seus dados de contato atualizados.</u>
          </b>
        </p>

        <p>
          13.3. O Usuário, desde já, reconhece e aceita que, assim que publicada a alteração deste Aviso, o acesso a
          plataforma passará a ser submetido ao Aviso atualizado.
        </p>

        <h4> CLÁUSULA 14 - Central de Atendimento </h4>

        <p>
          14.1. Você poderá entrar em contato conosco, a qualquer tempo, para dúvidas, solicitações, reclamações,
          elogios e quaisquer outras demandas relacionadas através do e-mail:
        </p>

        <p>
          <b>E-mail:</b> ajuda@mindlab.com.br
        </p>
        <p>
          <b>Última atualização:</b> 14/01/2022
        </p>
      </div>
    </CloseableModal>
  )
}

export default PrivacyPolicyModal
