import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { withPlanet } from "planet-context"

const GameContainer = withPlanet(styled(Link)`
  width: 340px;
  height: 270px;
  text-align: center;
  border: 3px solid transparent;
  box-sizing: border-box;
  text-decoration: none;

  &:hover {
    border-color: ${(props) => props.planet.games.border};
  }

  & img {
    height: 100px;
    display: block;
    margin: auto;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`)

const Title = withPlanet(styled.h2`
  font-family: "Montserrat";
  line-height: 1.2em;
  margin-bottom: 20px;
  font-size: 31px;
  font-weight: 700;
  color: ${(props) => props.planet.games.title};
  margin-top: 15px;
`)

const Subtitle = withPlanet(styled.p`
  font-size: 16px;
  line-height: 1.4em;
  color: ${(props) => props.planet.games.title};
  margin-top: 0;
  margin-bottom: 10px;
`)

const PlayerText = styled.p`
  font-size: 16px;
  line-height: 1.4em;
  color: #959595;
  font-weight: normal;
`

const GameListItem = ({ title, mode, waiting, playing, subtitle, findsPlayer, url, children }) => (
  <GameContainer to={url}>
    <Title>{title}</Title>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}

    {children}

    <PlayerText>
      <br />
      {mode.findsPlayer ? "Jogadores em partida: " + playing : "Single Player"}
      <br />
      {findsPlayer && <span>Jogadores aguardando: {waiting}</span>}
    </PlayerText>
  </GameContainer>
)

export default GameListItem
