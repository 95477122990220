import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import { BtnPrimary } from "components/button"
import { StyledRuby } from "daily-mission/RetrieveQuestionStyle"

const RetrieveQuestionUnavailableModal = ({ opened, onClose }) => (
  <CloseableModal opened={opened} onClose={onClose} overlay small>
    <StyledRuby />
    <h2>OPS!</h2>
    <p>Você não tem perguntas para resgatar.</p>

    <div className="buttons-container">
      <BtnPrimary to="#" value="Entendi" onClick={onClose} />
    </div>
  </CloseableModal>
)

export default RetrieveQuestionUnavailableModal
