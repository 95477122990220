import bottomCurve from "planets/axion/bottom-curve"
import trail from "planets/axion/trail"
import {
  PlanetHorizon,
  ShortPlanetHorizon,
  PlanetHorizonContainer,
  ShortPlanetHorizonContainer,
  PlanetHorizonTitle
} from "planets/axion/horizon"
import editProfileBackground from "planets/axion/axion-edit-profile.svg"

export default {
  mainColor: "#006a65",
  secondaryColor: "#00e9d9",
  bottomCurve: bottomCurve,
  trail: trail,
  knowledgeAreaSlide: { prev: "#00aa9d", next: "#00aa9d" },
  dailyMission: {
    questionSlider: { prev: "#00e9d9", next: "#00e9d9", arrowColor: "#fff" }
  },
  btnPrimary: { backgroundColor: "#ff9229", textColor: "#fff", shadowColor: "#ce6d10" },
  btnLink: { backgroundColor: "#006a65", activeTextColor: "#ff9229" },
  gamificationBar: {
    white: { textColor: "#006a65" },
    colored: { textColor: "#fff" }
  },
  header: { colored: { backgroundColor: "#8e3fac" } },
  horizon: {
    PlanetHorizon,
    ShortPlanetHorizon,
    PlanetHorizonContainer,
    ShortPlanetHorizonContainer,
    PlanetHorizonTitle
  },
  editProfileBackground: editProfileBackground,
  games: {
    title: "#7f89e3",
    border: "#7f89e3",
    detailsHeader: "#ffdd54"
  }
}
