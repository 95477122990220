import React from "react"
import { Redirect } from "react-router"
import { connect } from "react-redux"
import { Header } from "Header"
import { GamificationBar } from "GamificationBar"
import { BtnPrimary, BtnLink } from "components/button"
import ExamPausableRulesContent from "components/exams/exam-pausable-rules-content"
import ExamUnpausableRulesContent from "components/exams/exam-unpausable-rules-content"
import Service from "Service"
import { ScrollUp } from "helpers/scroll"

class ExamRules extends React.Component {
  constructor (props) {
    super(props)
    this.state = { fireRedirect: false, redirectTarget: "", redirectState: {} }
    this.handleClick = this.handleClick.bind(this)
  }

  UNSAFE_componentWillMount () {
    if (!this.getMiniexamId()) {
      this.setState({ fireRedirect: true, redirectTarget: "/simulados" })
    }
  }

  componentDidMount () {
    ScrollUp()
  }

  getMiniexamId () {
    const { state } = this.props.location

    return state ? state.miniexamId : null
  }

  handleClick (event) {
    event.preventDefault()
    const { miniexamId, hasLanguage } = this.props.location.state

    hasLanguage ? this.sendToLanguageChooser(miniexamId) : this.createSession(miniexamId)
  }

  sendToLanguageChooser (miniexamId) {
    this.setState({
      fireRedirect: true,
      redirectTarget: "/simulados/idioma",
      redirectState: { miniexamId }
    })
  }

  createSession (miniexamId) {
    const { requestInProgress } = this.props

    if (requestInProgress) return

    Service.startMiniexamSession(miniexamId, response => {
      const { question_set_session_id: questionSetSessionId } = response.data

      this.setState({
        fireRedirect: true,
        redirectTarget: "/simulados/responder",
        redirectState: { questionSetSessionId }
      })
    })
  }

  render () {
    const { fireRedirect, redirectTarget, redirectState } = this.state

    if (fireRedirect) {
      return <Redirect to={{ pathname: redirectTarget, state: redirectState }} />
    }

    const { miniexamName, examName, examDuration, pausable } = this.props.location.state

    return (
      <div>
        <Header backUrl="/simulados" color="colored" backLabel="Estação de Avaliações">
          <GamificationBar />
        </Header>

        <div className="exam-rules-container">
          {pausable ? (
            <ExamPausableRulesContent title={miniexamName} />
          ) : (
            <ExamUnpausableRulesContent title={examName} duration={examDuration} />
          )}
          <p>Ao clicar no botão abaixo, você concorda com as regras descritas acima.</p>

          <hr />

          <div className="buttons-container">
            <BtnLink to="/simulados" value="Começar mais tarde" />
            <BtnPrimary onClick={this.handleClick} to="#" value="Iniciar" arrow />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    requestInProgress: state.http.requestInProgress
  }
}

export default connect(mapStateToProps)(ExamRules)
