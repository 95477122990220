import React from "react"
import styled from "styled-components"
import { Back } from "Header/Back"
import PropTypes from "prop-types"

const HeaderBackContainer = styled.div`
  display: flex;
  flex-basis: 40%;
`

const HeaderBack = ({ backUrl, backLabel, onBackClick }) => (
  <HeaderBackContainer>
    <Back to={backUrl} label={backLabel} onClick={onBackClick} />
  </HeaderBackContainer>
)

HeaderBack.propTypes = {
  backUrl: PropTypes.string.isRequired,
  backLabel: PropTypes.string,
  onBackClick: PropTypes.func
}

export default HeaderBack
