import React from "react"
import styled from "styled-components"
import Planet from "Galaxy/wrappers/Planet"
import PlanetImage from "Galaxy/wrappers/PlanetImage"
import onis from "galaxies/orange-galaxy/onis.svg"

const Onis = props => (
  <Planet {...props}>
    <PlanetImage src={onis} blocked={props.blocked} />
  </Planet>
)

export default styled(Onis)``
