import React from "react"
import styled from "styled-components"
import PercentageBar from "components/PercentageBar"

const Progress = styled.div`
  max-width: 80%;
`

const Detail = styled.div`
  padding: 0 5px;
`

const PerformanceChart = ({ knowledgeArea, correctAnswers, incorrectAnswers, totalAnswers }) => {
  const started = totalAnswers > 0
  const correctPercentage = started ? Math.round((correctAnswers / totalAnswers) * 100) : 0
  const incorrectPercentage = started ? 100 - correctPercentage : 0

  return (
    <div className="performance-chart">
      <div className="knowledge-area-name">{knowledgeArea}</div>
      <span className="answered">{totalAnswers} questões respondidas</span>

      {started && (
        <Progress>
          <PercentageBar complete={correctPercentage} fillIncomplete={true} />

          <Detail>
            <span className="correct">
              <b>Acertos: {correctAnswers}</b> ({correctPercentage}%)
            </span>

            <span className="incorrect">
              <b>Erros: {incorrectAnswers}</b> ({incorrectPercentage}%)
            </span>
          </Detail>
        </Progress>
      )}
    </div>
  )
}

export default PerformanceChart
