import bottomCurve from "planets/kai/bottom-curve"
import trail from "planets/kai/trail"
import {
  PlanetHorizon,
  ShortPlanetHorizon,
  PlanetHorizonContainer,
  ShortPlanetHorizonContainer,
  PlanetHorizonTitle
} from "planets/kai/horizon"
import editProfileBackground from "planets/kai/kai-edit-profile.svg"

export default {
  mainColor: "#6ed3b6",
  secondaryColor: "#0093af",
  bottomCurve: bottomCurve,
  trail: trail,
  knowledgeAreaSlide: { prev: "#ee7223", next: "#ee7223" },
  dailyMission: {
    questionSlider: { prev: "#ee7223", next: "#ee7223", arrowColor: "#fff" }
  },
  btnPrimary: { backgroundColor: "#ff9229", textColor: "#fff", shadowColor: "#ce6d10" },
  btnLink: { backgroundColor: "#007694", activeTextColor: "#ff9229" },
  gamificationBar: {
    white: { textColor: "#007694" },
    colored: { textColor: "#fff" }
  },
  header: { colored: { backgroundColor: "#8e3fac" } },
  horizon: {
    PlanetHorizon,
    ShortPlanetHorizon,
    PlanetHorizonContainer,
    ShortPlanetHorizonContainer,
    PlanetHorizonTitle
  },
  editProfileBackground: editProfileBackground,
  games: {
    title: "#34b8b8",
    border: "#6ed3b6",
    detailsHeader: "#0093af"
  }
}
