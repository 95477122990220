import React from "react"
import ActiveAvatar from "avatar/poppy-ship.svg"
import InactiveAvatar from "avatar/poppy-ship-inactive.svg"

const PoppyShip = ({ inactive, className = "", ...rest }) => {
  if (inactive) {
    return <img src={InactiveAvatar} className={`inactive ${className}`} {...rest} />
  }
  return <img src={ActiveAvatar} className={className} {...rest} />
}

export default PoppyShip
