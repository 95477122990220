import React from "react"
import { SecondsToHourMinsSecs } from "helpers/time-formatter"

const InlineFixedClock = ({ timeSpent }) => (
  <div className="inline-countdown">
    <i className="clock-icon" />
    {SecondsToHourMinsSecs(timeSpent)}
  </div>
)

export default InlineFixedClock
