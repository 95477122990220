import React from "react"
import image from "how-to-stones.svg"
import { Header } from "Header"
import { GamificationBar } from "GamificationBar"
import { ScrollUp } from "helpers/scroll"

const HowToMoveForward = () => {
  ScrollUp()

  return (
    <div className="bag-manual-about-container">
      <Header backUrl="/mochila/mural" color="colored">
        <GamificationBar />
      </Header>
      <h1 className="purple">Como avanço na trilha?</h1>
      <img src={image} />
      <p>Para avançar na trilha de um planeta você precisa alcançar o número de XPs definidos em cada casa.</p>
      <p>
        Quanto mais questões você responder, mais XP irá ganhar, podendo assim avançar cada vez mais em sua trilha de
        conhecimento.
      </p>
      <p>E qual a melhor maneira de adquirir XP? Conquistando muitas, mas muitas Missões Diárias! ;)</p>
      <p>Recolha todo o conhecimento perdido que puder e ajude o Vigo.</p>
    </div>
  )
}

export default HowToMoveForward
