const schoolYear = {
    "BnzxdgW7Bt-1EF": "1º ano Ensino Fundamental",
    "lWKOu954cl-2EF": "2º ano Ensino Fundamental",
    "PHmI0k7W2q-3EF": "3º ano Ensino Fundamental",
    "uTQuWxLvLX-4EF": "4º ano Ensino Fundamental",
    "2OAa15mm4h-5EF": "5º ano",
    "xGYcvk58yO-6EF": "6º ano",
    "d1kqHp3b1z-7EF": "7º ano",
    "glIvs2N8BU-8EF": "8º ano",
    "G34SRE3bZA-9EF": "9º ano",
    "O6SulIB7c8-1EM": "1º ano Ensino Médio",
    "A7FTVimUsZ-2EM": "2º ano Ensino Médio",
    "4s89da68PS-3EM": "3º ano Ensino Médio",
    "8iT3k24o0L-4EI": "Educação Infantil de 4 anos",
    "tG19ay8D35-5EI": "Educação Infantil de 5 anos"
}

export default function getInitialsSchoolYearWithHash (description) {
    return Object.keys(schoolYear).filter(key => 
        (schoolYear[key] === description)
    )[0]
}
