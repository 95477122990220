import React from "react"
import { Header } from "Header"
import { GamificationBar } from "GamificationBar"

const UserMessage = ({ backUrl, backLabel, children }) => (
  <div className="message-container purple">
    <Header backUrl={backUrl} backLabel={backLabel} color="colored">
      <GamificationBar />
    </Header>

    <div className="message-content">{children}</div>
  </div>
)

export default UserMessage
