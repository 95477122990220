import React from "react"
import styled from "styled-components"
import { GamificationBar } from "GamificationBar"
import { Bag } from "components/button"
import { Header } from "Header"
import { withGalaxy } from "galaxy-context"

const GalaxyContainer = withGalaxy(styled.div`
  background: url(${props => props.galaxy.background}) no-repeat center top;
  background-size: cover;
  height: 100%;
  min-height: 860px;
  display: flex;
  flex-direction: column;
  position: relative;
`)

const Layout = ({ children }) => (
  <GalaxyContainer>
    <Header>
      <GamificationBar />
    </Header>

    <Bag />

    {children}
  </GalaxyContainer>
)

export default Layout
