import React from "react"
import Slider from "react-slick"

const PrevArrow = ({ onClick, className }) => (
  <i className={`arrow-icon arrow-icon-left exam-slide-arrow-prev ${className}`} onClick={onClick} />
)

const NextArrow = ({ onClick, className }) => (
  <i className={`arrow-icon arrow-icon-right exam-slide-arrow-next ${className}`} onClick={onClick} />
)

const CardsSlider = ({ children }) => {
  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          slidesToShow: 1
        }
      }
    ]
  }

  return (
    <Slider {...settings}>
      {children}
    </Slider>
  )
}


export default CardsSlider
