import React from "react"
import { connect } from "react-redux"
import CloseableModal from "components/modals/closeable-modal"
import { Vigo } from "components/avatar"

let NewPlanetAvailableModal = ({ opened, currentUser, messages, onClose }) => (
  <CloseableModal small opened={opened} onClose={onClose}>
    <h2 className="light">Missão Cumprida!</h2>

    <p>Olá, {currentUser.name}!</p>
    {messages.map((message, index) => (
      <p key={index}>{message}</p>
    ))}

    <h2 className="modal-message light">Boa sorte!</h2>

    <Vigo />
  </CloseableModal>
)

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  }
}

NewPlanetAvailableModal = connect(mapStateToProps)(NewPlanetAvailableModal)

export default NewPlanetAvailableModal
