import React from "react"

const EssayTheme = ({ theme }) => (
  <div className="essay-theme">
    <div className="essay-theme-prefix">
      <i className="essay-icon" />
      <span>Tema:</span>
    </div>
    <span className="essay-theme-title">{theme}</span>
  </div>
)

export default EssayTheme
