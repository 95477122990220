import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { StyledBack } from "Header/Back"
import { withPlanet } from "planet-context"
import { HeaderBack } from "Header"
import { StyledGamificationBar } from "GamificationBar"

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  padding: 10px 10px 5px;
  box-sizing: border-box;

  ${StyledGamificationBar} {
    color: #fff;
  }

  position: fixed;

`

const ColoredStyledHeader = styled(StyledHeader)`
  background-color: ${props => props.planet.header.colored.backgroundColor};

  ${StyledGamificationBar} {
    color: ${props => props.planet.gamificationBar.colored.textColor};
  }
`

const WhiteStyledHeader = styled(StyledHeader)`
  background-color: #ffffff;

  ${StyledGamificationBar} {
    color: ${props => props.planet.gamificationBar.white.textColor};
  }

  ${StyledBack} {
    color: ${props => props.planet.mainColor};

    path:last-child {
      fill: ${props => props.planet.mainColor};
    }
  }
`

const HeaderChildren = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  flex-grow: 2;
  flex-basis: 60%;
`

function HeaderByColor (color) {
  switch (color) {
    case "colored":
      return withPlanet(ColoredStyledHeader)
    case "white":
      return withPlanet(WhiteStyledHeader)
    case "":
      return StyledHeader
  }
}

const Header = ({ backUrl, backLabel, onBackClick, color, children }) => {
  const Component = HeaderByColor(color)

  React.useEffect(() => {
    window.onscroll = function() {
      if (window.scrollY > 25) {
        document.getElementById("header-back").style.opacity = 0
      } else {
        document.getElementById("header-back").style.opacity = 1
      }
    }
  }, [])

  return (
    <Component id="header-back">
      {backUrl && <HeaderBack backUrl={backUrl} backLabel={backLabel} onClick={onBackClick} />}

      <HeaderChildren>{children}</HeaderChildren>
    </Component>
  )
}

Header.propTypes = {
  backUrl: PropTypes.string,
  backLabel: PropTypes.string,
  onBackClick: PropTypes.func,
  color: PropTypes.string,
  children: PropTypes.node
}

Header.defaultProps = {
  color: ""
}

export default Header
