import floor from "planets/cryo/cryo-checkpoint.svg"
import mountain from "planets/cryo/cryo-mountain.svg"

export default {
  mainColor: "#b5dde4",
  secondaryColor: "#a5d2df",
  floor: {
    image: floor,
    height: 173
  },
  stone: {
    image: mountain,
    width: 151,
    height: 115,
    left: 35
  }
}
