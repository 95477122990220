import React from "react"
import Modal from "components/modal"
import { BtnPrimary } from "components/button"
import VigoMaintenance from "avatar/vigo-maintenance.svg"

const ServiceDown = () => (
  <Modal fullscreen={true} backUrl="/galaxia" color="honey-flower">
    <h2>Estação de Jogos em atualização</h2>

    <p>Olá, Mindzuper!</p>

    <p>
      Estamos atualizando e realizando alguns ajustes nos jogos.<br />
      Em breve, vamos liberar para acesso novamente.
    </p>

    <p>Esperamos você mais tarde :)</p>

    <BtnPrimary value="Ir para a galáxia" to="/galaxia" arrow />

    <img src={VigoMaintenance} style={{ height: "220px", marginTop: "30px" }} />
  </Modal>
)

export default ServiceDown
