import floor from "planets/roya/roya-checkpoint.svg"
import candy from "planets/roya/roya-candy.svg"

export default {
  mainColor: "#fecaf0",
  secondaryColor: "#fecaf0",
  floor: {
    image: floor,
    height: 160
  },
  stone: {
    image: candy,
    width: 200,
    height: 150,
    left: 30
  }
}
