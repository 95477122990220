import styled from "styled-components"

const Title = styled.h2`
  color: #ffffff;
  font-size: 39px;
  text-align: center;
  margin: 90px 0 10px;
`

export default Title
