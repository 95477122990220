import { Socket } from "phoenix"
import { websocketEndpoint } from "config"
import { getWebsocketToken } from "auth"

export function connectToChannel (channelName) {
  const token = getWebsocketToken()

  if (token === null) {
    window.setTimeout(function () {
      connectToChannel(channelName)
    }, 3000)
  }

  const socket = window.socket || new Socket(`${websocketEndpoint}/app/socket`, { params: { token } })
  window.socket = socket
  socket.connect()

  return socket.channel(channelName)
}

export function disconnectSocket () {
  window.socket && window.socket.disconnect(() => (window.socket = null))
}
