import React from "react"
import { push } from "connected-react-router"
import { connect } from "react-redux"
import GameListItem from "GamesStation/GameListItem"
import { findGameById } from "GamesStation/details"
import ServiceDown from "GamesStation/ServiceDown"
import { connectToChannel } from "socket-connect"
import { MixpanelTrack } from "tags/mixpanel"
import { GamesContainer } from "GamesStation/styled"

class GameList extends React.Component {
  constructor(props) {
    super(props)
    const updatedGames = props.games.map(game => {
      return { game_id: game, players_waiting: 0, players_in_game: 0 }
    })
    this.state = {
      games: updatedGames,
      maintenance: false,
      gamesFromChosenGrade: updatedGames
    }
  }

  componentDidMount() {
    switch (this.props.hasGames) {
      case true:
        MixpanelTrack("Acessou Estação de Jogos")
        this.subscribeChannel()
        break
      case false:
        this.props.dispatch(push("/galaxia"))
        break
    }
  }

  componentWillUnmount() {
    this.unsubscribeChannel()
  }

  subscribeChannel() {
    this.channel = connectToChannel("gaming:_stats")

    if (!this.channel) return

    this.channel.join()

    this.channel.on("update_stats", data => {
      this.setState({ games: this.getMatchingGames(data.stats) })
    })

    this.channel.on("maintenance", () => {
      this.setState({ maintenance: true })
    })
  }

  getMatchingGames(stats) {
    let currentGames
    currentGames = this?.state?.gamesFromChosenGrade || []
    currentGames = currentGames.map(game => {
      const givenRef = stats.find(g => game.game_id === g.game_id)
      return givenRef ? givenRef : game
    })
    return currentGames
  }

  unsubscribeChannel() {
    if (this.channel && this.channel.state === "joined") {
      this.channel.leave()
    }
  }

  render() {
    const { games, maintenance } = this.state

    if (maintenance) {
      return <ServiceDown />
    }

    return (
      <GamesContainer>
        {games.map((game, index) => {
          const { game_id: gameId, players_waiting: playersWaiting, players_in_game: playersInGame } = game

          if (findGameById(gameId)) {
            const { title, url, mode, component: GameComponent } = findGameById(gameId)

            return (
              <GameListItem
                title={title}
                findsPlayer={mode.findsPlayer}
                url={`detalhes/${url}`}
                waiting={playersWaiting}
                playing={playersInGame}
                mode={mode}
                key={index}
              >
                <GameComponent />
              </GameListItem>
            )
          }
        })}
      </GamesContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    hasGames: state.auth.currentUser.has_games,
  }
}

export default connect(mapStateToProps)(GameList)
