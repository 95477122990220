import React from "react"
import { Redirect } from "react-router"
import { GamificationBar } from "GamificationBar"
import { Header } from "Header"
import Form from "components/form/form"
import FormRadio from "components/form/form-radio"
import { Submit } from "components/button"
import languageEnglish from "language-english.svg"
import languageSpanish from "language-spanish.svg"
import Service from "Service"

class ExamLanguageChooser extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      fireRedirect: false,
      redirectTarget: "",
      redirectState: {},
      chosenLanguage: false,
      language: ""
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleLanguage = this.handleLanguage.bind(this)
  }

  UNSAFE_componentWillMount () {
    if (!this.getMiniexamId()) {
      this.setState({ fireRedirect: true, redirectTarget: "/simulados" })
    }
  }

  handleChange (e) {
    this.setState({ chosenLanguage: true, language: e.target.value })
  }

  handleLanguage (e) {
    e.preventDefault()
    const { language } = this.state
    const miniexamId = this.getMiniexamId()

    this.createSession(miniexamId, language)
  }

  getMiniexamId () {
    const { state } = this.props.location

    return state ? state.miniexamId : null
  }

  createSession (miniexamId, language) {
    Service.startLanguageMiniexamSession(miniexamId, language, response => {
      const { question_set_session_id } = response.data

      this.setState({
        fireRedirect: true,
        redirectTarget: "/simulados/responder",
        redirectState: { questionSetSessionId: question_set_session_id }
      })
    })
  }

  render () {
    const { fireRedirect, redirectTarget, redirectState, chosenLanguage } = this.state

    if (fireRedirect) {
      return <Redirect to={{ pathname: redirectTarget, state: redirectState }} />
    }

    return (
      <div>
        <Header backUrl="/simulados" color="colored">
          <GamificationBar />
        </Header>

        <div className="language-chooser-container">
          <h2 className="big">Linguagens e Códigos</h2>

          <p>
            Seguindo as regras do ENEM, parte das questões de Linguagens e Códigos trazem conteúdo em uma língua
            estrangeira. Portanto, antes de começar a responder as questões, precisamos que você indique qual delas você
            vai optar no ENEM ;)
          </p>

          <Form onSubmit={this.handleLanguage}>
            <div className="language-options">
              <div className="language-option">
                <img src={languageEnglish} />

                <FormRadio
                  id="language-choose-english"
                  name="language-choose"
                  value="ENGLISH"
                  required
                  onChange={this.handleChange}
                >
                  Inglês
                </FormRadio>
              </div>

              <div className="language-option">
                <img src={languageSpanish} />

                <FormRadio
                  id="language-choose-spanish"
                  name="language-choose"
                  value="SPANISH"
                  required
                  onChange={this.handleChange}
                >
                  Espanhol
                </FormRadio>
              </div>
            </div>

            <Submit disabled={!chosenLanguage} value="Confirmar" arrow />
          </Form>
        </div>
      </div>
    )
  }
}

export default ExamLanguageChooser
