import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import { Vigo } from "components/avatar"

const YearFinalizedWithoutRetrievableQuestion = ({ opened, onClose }) => {
  const currentYear = new Date().getFullYear()
  const nextYear = currentYear + 1

  return (
    <CloseableModal small opened={opened} onClose={onClose}>
      <h2>Jornada completada! Parabéns</h2>
      <p className="medium">
        Neste ano de {currentYear} você passou por diversos planetas, recolhendo o conhecimento perdido pelos habitantes
        de Dión. Agora, seu avatar irá sair de férias para descansar um pouquinho ;)
      </p>
      <p className="medium">Aguarde, que em {nextYear} outros planetas estarão à sua espera :)</p>
      <Vigo />
    </CloseableModal>
  )
}
export default YearFinalizedWithoutRetrievableQuestion
