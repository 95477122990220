import React from "react"
import { connect } from "react-redux"
import { BtnPrimary } from "components/button"
import { Link } from "react-router-dom"
import EssayFinalizedModal from "components/modals/essay-finalized-modal"
import EssayUploadFailureModal from "components/modals/essay-upload-failure-modal"
import API from "api"

class EssayUploadConfirmation extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showFinalizedModal: false,
      showErrorModal: false
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (e) {
    const { requestInProgress } = this.props

    if (requestInProgress) return

    let data = new FormData()
    data.append("file", this.props.file)

    API.putWithOptions(
      `/essay_sessions/${this.props.essaySessionId}`,
      data,
      { headers: { "Content-Type": "multipart/form-data" } },
      () => {
        this.setState({ showFinalizedModal: true })
      },
      () => {
        this.setState({ showErrorModal: true })
      }
    )

    e.preventDefault()
  }

  render () {
    const { showFinalizedModal, showErrorModal } = this.state
    const { requestInProgress } = this.props

    return (
      <div className="essay-upload-confirmation">
        <EssayFinalizedModal opened={showFinalizedModal} onClose={() => this.setState({ showFinalizedModal: false })} />
        <EssayUploadFailureModal opened={showErrorModal} onClose={() => this.setState({ showErrorModal: false })} />

        <BtnPrimary
          to="#"
          value="Enviar Redação"
          arrow
          disabled={requestInProgress}
          disabledValue="Enviando Redação..."
          onClick={this.handleClick}
        />

        <div className="image-link-container">
          <i className="picture-icon" />
          <Link to="#" onClick={this.props.changeImage}>
            Trocar Imagem
          </Link>
        </div>
        <img className="image-preview" src={this.props.imagePreviewUrl} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    requestInProgress: state.http.requestInProgress
  }
}

export default connect(mapStateToProps)(EssayUploadConfirmation)
