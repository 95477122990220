export default [
  {
    title: "Regras básicas",
    description: "",
    items: [
      {
        text: "O jogo é para 2 jogadores.",
      },
      {
        text: "Cada jogador, na sua vez, deverá marcar um valor de pontos, na “Tabela de Pontuação”, correspondente ao resultado obtido com os 5 dados, respeitando-se as características das categorias do jogo.",
      },
      {
        text: "São 13 categorias, portanto serão 13 rodadas. Em cada categoria (explicitadas mais a frente) só é marcado um valor.",
      },
      {
        text: "Em cada rodada, é permitido jogar os dados por até 3 vezes, deixando ou não um ou mais dados na mesa",
      },
      {
        text: "Não é necessário fazer os três lançamentos de dados em uma jogada. Se houver, na “Tabela de Pontuação”, uma categoria vazia para colocar o resultado dos dados na primeira ou na segunda tentativa, pode fazê-lo e passar a vez ao jogador seguinte.",
      },
      {
        text: "Quando, na “Tabela de Pontuação”, todas as categorias em que o resultado dos dados poderia ser registrado já estiverem preenchidas, o jogador deve escolher uma das categorias para “riscar” (colocar zero), não somando ponto algum. Não se pode “pular” a vez de jogar.",
      },
      {
        text: "Ao final das 13 rodadas, todas as categorias estarão preenchidas pelos jogadores. Os jogadores somam os pontos obtidos e aquele com a maior pontuação é o vencedor.",
      }
    ]
  },
  {
    title: "Descritivo das Categorias",
    description: "",
    items: [
      {
        text: "Um - é a soma dos dados com o número 1.",
      },
      {
        text: "Dois - é a soma dos dados com o número 2.",
      },
      {
        text: "Três - é a soma dos dados com o número 3.",
      },
      {
        text: "Quatro - é a soma dos dados com o número 4.",
      },
      {
        text: "Cinco - é a soma dos dados com o número 5.",
      },
      {
        text: "Seis - é a soma dos dados com o número 6.",
      },
      {
        text: "Soma - soma da pontuação das categorias anteriores (categorias de um a seis).",
      },
      {
        text: "Bônus - se a soma dos pontos das categorias anteriores for 63 ou mais, você ganhará um bônus de 35 pontos.",
      },
      {
        text: "Trinca - três dados com números iguais = soma do valor de TODOS os cinco dados.",
      },
      {
        text: "Quadra - quatro dados com números iguais = soma do valor de TODOS os cinco dados.",
      },
      {
        text: "Full house - (dois dados de um número + três dados de outro número) 25 pontos.",
      },
      {
        text: "Sequência baixa -  uma sequência que utilize quatro dados, por exemplo, 2,3,4 e 5 - ganhará 30 pontos.",
      },
      {
        text: "Sequência alta - uma sequência que utilize cinco dados, por exemplo, 1, 2,3,4 e 5 - ganhará 40 pontos.",
      },
      {
        text: "Chance - Soma de todos os dados (independente do valor).",
      },
      {
        text: "Yatzy - (cinco dados com números iguais) 50 pontos.",
      }
    ]
  }
]