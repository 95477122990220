import React from "react"
import { PerformanceName } from "backpack/badges/styled"
import ConqueredBadgeBase from "backpack/badges/ConqueredBadgeBase"

const ConqueredBadge = ({ badge }) => (
  <ConqueredBadgeBase badge={badge}>
    <PerformanceName>{badge.id}</PerformanceName>
  </ConqueredBadgeBase>
)

export default ConqueredBadge
