import React from "react"
import FormGroup from "components/form/form-group"
import FormLabel from "components/form/form-label"
import ComponentHelper from "helpers/component-helper"

const FormCheckbox = ({ id, name, required, errors = [], children, ...rest }) => (
  <FormGroup hasError={ComponentHelper.hasError(errors, name)}>
    <input type="checkbox" id={id} className="form-checkbox" name={name} required={required} {...rest} />
    <FormLabel htmlFor={id}>{children}</FormLabel>
  </FormGroup>
)

export default FormCheckbox
