import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import ClockCountdown from "components/clock-countdown"
import UserAvatar from "components/user-avatar"

const TimeIsUpModal = ({ opened, onClose }) => (
  <CloseableModal overlay opened={opened} onClose={onClose}>
    <h2 className="modal-title">Seu tempo de prova acabou!</h2>
    <p>Você utilizou todo o tempo disponível para essa avaliação.</p>
    <p>Como seu cronômetro expirou, as questões que não foram respondidas serão consideras incorretas.</p>
    <ClockCountdown zero />
    <UserAvatar kind="sad" />
  </CloseableModal>
)

export default TimeIsUpModal
