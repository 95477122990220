import React from "react"
import BagLayout from "components/layouts/bag"
import { PaymentContainer, Title, LargeTextButton, ContactChatText } from "backpack/payment/styled"

const CreditCardError = () => (
  <BagLayout activeSection="payment">
    <PaymentContainer>
      <Title>Cartão Inválido</Title>
      <p>Os dados do cartão informado estão inválidos. Talvez você tenha digitado alguma informação errada :)</p>
      <p>Verifique se está utilizando o cartão correto e preencha novamente os dados!</p>
      <LargeTextButton to="/pedido/compra" arrow>
        Rever dados de pagamento
      </LargeTextButton>

      <ContactChatText>
        <strong>Problemas?</strong> Entre em contato com nosso time de atendimento via chat ou telefone: (11) 3474-1770
      </ContactChatText>
    </PaymentContainer>
  </BagLayout>
)

export default CreditCardError
