import React from "react"

const ListBox = ({ items, message }) => (
  <div className="list-box">
    <ul>
      {items.map((item, index) => (
        <li key={index} className="pink">{item}</li>
      ))}
    </ul>
    {message && (
      <div className="list-box-message">{message}</div>
    )}
  </div>
)

export default ListBox
