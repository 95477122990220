import React from "react"
import { Link } from "react-router-dom"
import { Layout } from "Session"
import { BtnPrimary } from "components/button"
import Modal from "components/modal"
import { MixpanelTrack } from "tags/mixpanel"

class OnboardingPaymentCreditcardError extends React.Component {
  componentDidMount () {
    MixpanelTrack("Erro do pagamento", {
      "TIPO DO ERRO": "Pedido nao processado"
    })
  }

  render () {
    return (
      <Layout>
        <Modal color="purple">
          <h2 className="big">Pedido não processado</h2>

          <p>Infelizmente seu pedido não pode ser processado no momento.</p>
          <p>Por favor, tente novamente.</p>

          <BtnPrimary to="/pedido/compra" value="Tentar novamente" arrow />

          <div className="modal-link">
            Você pode alterar a forma de pagamento <Link to="/pedido/compra">clicando aqui!</Link>
          </div>

          <div className="modal-link">
            Dúvidas?{" "}
            <a to="https://ajuda.mindlab.com.br/hc/pt-br/requests/new" target="_blank" rel="noopener noreferrer">
              Fale com a gente no chat
            </a>
          </div>
        </Modal>
      </Layout>
    )
  }
}

export default OnboardingPaymentCreditcardError
