import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import { Link } from "react-router-dom"
import { Layout } from "Session"
import { BtnPrimary } from "components/button"
import { MixpanelTrack, MixpanelReset } from "tags/mixpanel"
import Modal from "components/modal"
import ListBox from "components/list-box"
import PaymentMethods from "components/payment-methods"
import { logout } from "actions"
import { formattedEndOfYear, formattedStartOfYear } from "helpers/time-formatter"
import API from "api"
import { PixelTrack } from "tags/pixel"

class OnboardingOffer extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      includedItems: ["Carregando..."],
      packagePrice: "VALOR DO PACOTE",
      packageYear: "...",
      packageStartDate: "...",
      packageEndDate: "...",
      maximumInstallments: 0,
      maximumInstallmentsParcelValue: 0,
      schoolName: "NOME DA ESCOLA",
      userName: "NOME DO ALUNO",
      userSchoolYear: "ANO ESCOLAR",
      userSchoolClass: "TURMA"
    }

    this.isNextYear = this.props.location.pathname.includes("proximo-ano")
  }

  componentDidMount () {
    PixelTrack("Tela Inicial de Pagamento")
    const offerEndpoint = `/checkout/offer${this.isNextYear ? "?next_year=true" : ""}`

    API.get(offerEndpoint, response => {
      const {
        package_year: packageYear,
        included_items: includedItems,
        package_price: packagePrice,
        user_name: userName,
        school_name: schoolName,
        user_school_year: userSchoolYear,
        user_school_class: userSchoolClass,
        maximum_installments: maximumInstallments,
        maximum_installments_parcel_value: maximumInstallmentsParcelValue
      } = response.data

      const packageStartDate = formattedStartOfYear(packageYear)
      const packageEndDate = formattedEndOfYear(packageYear)

      this.setState({
        packageYear,
        packageStartDate,
        packageEndDate,
        includedItems,
        packagePrice,
        userName,
        schoolName,
        userSchoolYear,
        userSchoolClass,
        maximumInstallments,
        maximumInstallmentsParcelValue
      })
    })
  }

  handleSignOut (event) {
    event.preventDefault()
    const { dispatch } = this.props

    API.delete("/sessions", () => {
      MixpanelTrack("Efetuou logout")
      MixpanelReset()

      dispatch(logout())
      dispatch(push("/"))
    })
  }

  render () {
    const {
      packageYear,
      packageStartDate,
      packageEndDate,
      includedItems,
      packagePrice,
      userName,
      schoolName,
      userSchoolYear,
      userSchoolClass,
      maximumInstallments,
      maximumInstallmentsParcelValue
    } = this.state

    const paymentUrl = `/pedido/compra${this.isNextYear ? "/proximo-ano" : ""}`
    const title = this.isNextYear ? `Adquirir Mindzup para ${packageYear}` : "Liberar acesso"
    const subtitle = this.isNextYear
      ? `Chegou a hora de adquirir seu material digital para ${packageYear}!`
      : "Chegou a hora do seu responsável fazer o pagamento para a experiência começar."

    return (
      <Layout>
        <Modal color="honey-flower" backUrl="/criar-conta" backLabel="Tenho código">
          <div className="modal-title">
            <h2 className="light">{title}</h2>
            <hr className="pink" />
          </div>

          <p>{subtitle}</p>
          <p>Verifique as informações abaixo e clique em SEGUIR para iniciar a compra.</p>
          <p className="pink">O que está incluso:</p>

          <ListBox items={includedItems} message={`Válido a partir de ${packageStartDate} até ${packageEndDate}.`} />

          <div className="subscription-offer-price">
            <p>
              <span className="subscription-offer-currency">{maximumInstallments}x</span>
              <span className="subscription-offer-value">{maximumInstallmentsParcelValue}</span>
            </p>
            <p className="subscription-offer-installments">Total de {packagePrice}</p>
          </div>

          <BtnPrimary to={paymentUrl} onClick={this.handleClick} value="Seguir" color="white" />

          <PaymentMethods />

          <p className="subscription-offer-exclusive">
            Oferta exclusiva para {schoolName}
            <br />
            {userSchoolYear} | {userSchoolClass}
            <br />
            <strong>{userName}</strong>
          </p>

          <div className="modal-link">
            Não é aluno?{" "}
            <Link to="#" onClick={e => this.handleSignOut(e)}>
              Clique aqui
            </Link>
          </div>
        </Modal>
      </Layout>
    )
  }
}

export default connect()(OnboardingOffer)
