import React from "react"
import Modal from "components/modal"
import { ScrollUp } from "helpers/scroll"

class CloseableModal extends React.Component {
  constructor (props) {
    super(props)

    this.state = { isOpen: this.props.opened || false }
    this.onClose = this.onClose.bind(this)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setState({ isOpen: nextProps.opened })
  }

  onClose () {
    this.setState({ isOpen: false })
    this.props.onClose && this.props.onClose()
  }

  render () {
    const { isOpen } = this.state
    const { children, ...props } = this.props
    const closeButton = typeof this.props.closeButton === "undefined" ? true : this.props.closeButton

    if (isOpen) {
      ScrollUp()

      return (
        <Modal {...props} closeButton={closeButton} onClose={this.onClose}>
          {children}
        </Modal>
      )
    }
    return null
  }
}

export default CloseableModal
