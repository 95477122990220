import styled from "styled-components"
import iconExpired from "icon-expired.svg"

const Expired = styled.i`
  mask: url(${iconExpired}) no-repeat;
  background-color: #f78f8f;
  display: block;
`

export default Expired
