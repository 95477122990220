export default [
  {
    title: "Regra de movimento",
    description: "",
    items: [
      {
        text: "As peças só podem mover-se na diagonal, uma casa à frente.",
      }
    ]
  },
  {
    title: "Regras para captura",
    description: "",
    items: [
      {
        text: "Quando duas peças oponentes se confrontam, o jogador da vez é obrigado a capturar (se a casa atrás da peça do oponente estiver livre).",
      },
      {
        text: "É obrigatório também capturar para trás.",
      },
      {
        text: "Se houver possibilidade de captura múltipla, o jogador deve capturar todas as peças da sequência. Se duas (ou mais) capturas múltiplas forem possíveis, deve-se fazer a maior delas.",
      }
    ]
  },
  {
    title: "Dama",
    description: "",
    items: [
      {
        text: "Uma peça que atinge a linha do lado oposto do tabuleiro torna-se Dama. A Dama pode mover-se por quantas casas quiser, para frente e para trás, desde que não haja peças obstruindo o caminho, inclusive ao fazer uma captura.",
      },
      {
        text: "A Dama pode fazer uma sequência de capturas múltiplas e se colocar em qualquer casa livre que escolher ao longo da diagonal(não é necessário que se coloque imediatamente atrás da peça capturada).",
      },
      {
        text: "Durante uma captura múltipla, se uma peça atinge o final do tabuleiro torna- se Dama, e se for possível continuar capturando de acordo com as regras de captura das Damas, deve realizar todas as capturas possíveis.",
      },
      {
        text: "A Dama não pode voltar para trás (na mesma linha) para realizar capturas múltiplas.",
      }
    ]
  }
]