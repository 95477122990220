import React from "react"
import { Header } from "Header"
import { GamificationBar } from "GamificationBar"
import { ScrollUp } from "helpers/scroll"
import { withGalaxy } from "galaxy-context"

const EvaluationDates = ({ galaxy }) => {
  ScrollUp()

  return (
    <div className="bag-manual-about-container">
      <Header backUrl="/mochila/mural" color="colored">
        <GamificationBar />
      </Header>
      <h1 className="purple">Quando tenho avaliação?</h1>
      <img src={galaxy.satellite} />
      <p>
        A Estação de Avaliações ficará liberada para você acessa-la a qualquer momento, porém suas avaliações
        permanecerão bloqueadas até a data de início da avaliação.
      </p>
      <p>
        As datas das avaliações são definidas pela sua escola. Para saber mais, você pode entrar falar com seu
        professor, ou entrar em contato com nosso super time de atendentes estelares pelo chat, esse botão verdinho que
        fica alí no canto ;)
      </p>
    </div>
  )
}

export default withGalaxy(EvaluationDates)
