import React from "react"
import { Redirect } from "react-router"
import { connect } from "react-redux"
import { Layout } from "Session"
import { Submit } from "components/button"
import Modal from "components/modal"
import Form from "components/form/form"
import { FormInputMasked } from "components/form/form-input-masked"
import API from "api"
import { setActivationCode, setPartnerEmail, setSchool, clearSchool } from "actions"
import { MixpanelTrack, MixpanelPeopleSet } from "tags/mixpanel"
import { PixelTrack } from "tags/pixel"
import AES256 from "aes-everywhere"
import queryString from "query-string"
import { gympassKey } from "config"

class OnboardingActivationNew extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      activationCode: "",
      partnerEmail: "",
      errors: [],
      fireRedirect: false,
      isPartnerOnboarding: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.props.dispatch(clearSchool())
  }

  handleChange (event) {
    this.setState({ activationCode: event.target.value })
  }

  handleSubmit (event) {
    let activationCode = this.state.activationCode.toUpperCase()
    if (activationCode === "") return

    let partnerEmail = this.state.partnerEmail
    if (this.state.isPartnerOnboarding && !partnerEmail) return

    API.post(
      "/accounts",
      { action: "validate", activation_code: activationCode },
      (response) => {
        const { school_year, school_name, school_class } = response.data
        this.props.dispatch(setActivationCode(activationCode))
        this.props.dispatch(setPartnerEmail(partnerEmail))
        this.props.dispatch(setSchool(school_name, school_year, school_class))

        MixpanelPeopleSet({
          "codigo de ativacao": activationCode,
        })

        MixpanelTrack("Confirmou Código de Ativação")

        this.setState({ fireRedirect: true })
      },
      (error) => {
        MixpanelTrack("Erro de Código de Ativação")
        const errors = error.response.data["errors"]
        this.setState({ errors })
      }
    )

    event.preventDefault()
  }

  componentDidMount () {
    PixelTrack("Inserção de Código")

    if (this.props.activationCode !== null) {
      this.setState({ activationCode: this.props.activationCode })
    }

    if (this.props && this.props.location && this.props.location.search) {
      const queryEncryped = queryString.parse(this.props.location.search)
      const { activationCode, email: partnerEmail } = queryEncryped

      try {
        const decryptedActivationCode = AES256.decrypt(activationCode, gympassKey)
        const decrypedPartnerEmail = AES256.decrypt(partnerEmail, gympassKey)

        this.setState({
          activationCode: decryptedActivationCode,
          partnerEmail: decrypedPartnerEmail,
          isPartnerOnboarding: true,
        })
      } catch (error) {
        console.error(error)
        console.error("Erro ao decriptar query params")
        return error
      }
    }
  }

  render () {
    const { fireRedirect, activationCode, errors } = this.state

    return (
      <Layout>
        <Modal backUrl="/">
          <div className="modal-title">
            <h2>Tenho código</h2>
            <p>Para começar a aventura, precisamos de algumas informações:</p>
          </div>

          <Form onSubmit={this.handleSubmit}>
            <FormInputMasked
              mask={[
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /(E|e|I|i)/,
                /(F|f|M|m|I|i)/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
              ]}
              type="text"
              id="activation_code"
              name="activation_code"
              label="Código da escola"
              autoComplete="off"
              className="text-uppercase"
              value={activationCode}
              onChange={this.handleChange}
              errors={errors}
              required
            />

            <Submit value="Seguir" arrow />
          </Form>

          {fireRedirect && <Redirect to="/criar-conta/confirmacao" />}
        </Modal>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    activationCode: state.activation.activationCode,
    isOnboardingPartner: state.isOnboardingPartner,
    partnerEmail: state.partnerEmail,
  }
}

export default connect(mapStateToProps)(OnboardingActivationNew)
