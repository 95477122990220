import iconRuby from "icon-ruby.svg"
import styled from "styled-components"

const Ruby = styled.i`
  background: url(${iconRuby}) no-repeat;
  display: block;
  height: 20px;
  width: 31px;
`

export default Ruby
