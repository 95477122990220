import React from "react"
import { Link } from "react-router-dom"
import { Layout } from "Session"
import { BtnPrimary, BtnLight } from "components/button"
import logo from "app-logo.svg"
import TermsAndConditionsModal from "components/modals/terms-and-conditions-modal"
import PrivacyPolicyModal from "components/modals/privacy-policy-modal"
import { PixelTrack } from "tags/pixel"
import { disconnectSocket } from "socket-connect"

const AppName = () => (
  <h1 className="app-name">
    <img src={logo} />
  </h1>
)

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showTermsAndConditions: false,
      showPrivacyPolicy: false,
    }

    this.showTermsAndConditions = this.showTermsAndConditions.bind(this)
    this.showPrivacyPolicy = this.showPrivacyPolicy.bind(this)
  }

  componentDidMount() {
    PixelTrack("Tela Inicial Mindzup")
    disconnectSocket()
  }

  showTermsAndConditions(e) {
    this.setState({ showTermsAndConditions: true })
    e.preventDefault()
  }

  showPrivacyPolicy(e) {
    this.setState({ showPrivacyPolicy: true })
    e.preventDefault()
  }

  render() {
    const { showTermsAndConditions, showPrivacyPolicy } = this.state

    return (
      <Layout>
        <div className="sessions">
          <AppName />

          <div className="session-actions">
            <BtnPrimary to="/entrar" value="Entrar" />
            <BtnLight to="/criar-conta" value="Criar conta" />

            <div>
              Problemas?{" "}
              <Link target="_blank" to="https://ajuda.mindlab.com.br/hc/pt-br" className="session-link">
                Clique aqui
              </Link>
            </div>

            <TermsAndConditionsModal
              opened={showTermsAndConditions}
              onClose={() => this.setState({ showTermsAndConditions: false })}
            />

            <PrivacyPolicyModal
              opened={showPrivacyPolicy}
              onClose={() => this.setState({ showPrivacyPolicy: false })}
            />

            <div>
              <Link to="#" className="session-link" onClick={this.showTermsAndConditions}>
                Condições Gerais de Uso
              </Link>

              <span> | </span>

              <Link to="#" className="session-link" onClick={this.showPrivacyPolicy}>
                Aviso de Privacidade
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Home
