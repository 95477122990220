export default [
  {
    title: "",
    description: "",
    items: [
      {
        text: "Cada jogador, na sua vez, coloca uma peça da sua cor no tabuleiro, mas apenas se uma ou mais peças do oponente ficarem posicionadas entre a peça colocada e uma outra que já estava no tabuleiro.",
      },
      {
        text: "As peças do oponente cercadas pela peça inserida pelo jogador da vez devem ser viradas, mudando sua cor (captura).",
      },
      {
        text: "Peças podem ser cercadas na vertical, horizontal ou diagonais. Com apenas uma peça é possível cercar peças do oponente em mais de uma direção simultaneamente.",
      },
      {
        text: "Se não for possível cercar peças do oponente, perde-se a vez.",
      },
      {
        text: "O jogo termina quando não houver mais nenhum movimento possível para nenhum dos jogadores.",
      }
    ]
  }
]