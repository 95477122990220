import styled from "styled-components"
import sky from "planets/groupem/groupem-sky.svg"
import background from "planets/groupem/groupem-edit-profile-background.svg"
import { StyledHorizon, StyledHorizonContainer, StyledHorizonTitle } from "Horizon"

const PlanetHorizon = styled(StyledHorizon)`
  background: linear-gradient(180deg, #61a152 0%, #ffcf71 100%);
  overflow: hidden;
`

const ShortPlanetHorizon = styled(PlanetHorizon)`
  background: linear-gradient(180deg, #61a152 0%, #ffee92 100%);

  &::before {
    left: 0;
    margin-left: 0;
    bottom: 20px;

    @media (max-width: 768px) {
      left: -250px;
    }
  }

  &::after {
    content: "";
    background: url(${background}) repeat;
    width: 100%;
    height: 61px;
    display: block;
    position: absolute;
    bottom: 0;
    z-index: 3;

    @media (max-width: 768px) {
      height: 18px;
    }
  }
`

const PlanetHorizonContainer = styled(StyledHorizonContainer)`
  &::before {
    background: url(${sky}) no-repeat;
    height: 136px;
    bottom: 110px;
    transform: scaleX(-1);
  }
`

const ShortPlanetHorizonContainer = styled(PlanetHorizonContainer)`
  &::before {
    height: 90px;
    bottom: 80px;
    left: -100px;
  }
`

const PlanetHorizonTitle = styled(StyledHorizonTitle)`
  position: absolute;
  right: 100px;

  @media (max-width: 1024px) {
    right: auto;
    top: 55px;
  }
`

export { PlanetHorizon, PlanetHorizonContainer, PlanetHorizonTitle, ShortPlanetHorizon, ShortPlanetHorizonContainer }
