import { connectToChannel } from "socket-connect"
import { REHYDRATE } from "redux-persist"
import { initialState } from "store"
import { MixpanelPeopleSet } from "tags/mixpanel"
import { removeAuthToken, removeWebsocketToken } from "auth"
import API from "api"

export const RESET_KEY = "RESET"
export const STORAGE_KEY = "APOLLO"
export const SET_ACTIVATION_CODE = "SET_ACTIVATION_CODE"
export const SET_PARTNER_EMAIL= "SET_PARTNER_EMAIL"
export const SET_SCHOOL = "SET_SCHOOL"
export const CLEAR_SCHOOL = "CLEAR_SCHOOL"
export const SET_DAILY_MISSION_QUESTIONS = "SET_DAILY_MISSION_QUESTIONS"
export const SET_DAILY_MISSION_CORRECT_AMOUNT = "SET_DAILY_MISSION_CORRECT_AMOUNT"
export const SET_DAILY_MISSION_WRONG_AMOUNT = "SET_DAILY_MISSION_WRONG_AMOUNT"
export const SET_DAILY_MISSION_EXPERIENCE = "SET_DAILY_MISSION_EXPERIENCE"
export const SET_DAILY_MISSION_RUBY = "SET_DAILY_MISSION_RUBY"
export const SET_DAILY_MISSION_TRAIL_STEP = "SET_DAILY_MISSION_TRAIL_STEP"
export const SET_DAILY_MISSION_TRAIL = "SET_DAILY_MISSION_TRAIL"
export const SET_CURRENT_PLANET = "SET_CURRENT_PLANET"
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER"
export const SYNC_GAMIFICATION = "SYNC_GAMIFICATION"
export const SET_CURRENT_GALAXY = "SET_CURRENT_GALAXY"
export const START_GAMIFICATION_ANIMATION = "START_GAMIFICATION_ANIMATION"
export const STOP_GAMIFICATION_ANIMATION = "STOP_GAMIFICATION_ANIMATION"

export function setActivationCode (activationCode) {
  return { type: SET_ACTIVATION_CODE, activationCode }
}

export function setPartnerEmail (partnerEmail) {
  return { type: SET_PARTNER_EMAIL, partnerEmail }
}

export function setSchool (schoolName, schoolYear, schoolClass) {
  return { type: SET_SCHOOL, schoolName, schoolYear, schoolClass }
}

export function clearSchool () {
  return { type: CLEAR_SCHOOL }
}

export function setDailyMissionQuestions (questions) {
  return { type: SET_DAILY_MISSION_QUESTIONS, questions }
}

export function setDailyMissionCorrectAmount (correctAmount) {
  return { type: SET_DAILY_MISSION_CORRECT_AMOUNT, correctAmount }
}

export function setDailyMissionWrongAmount (wrongAmount) {
  return { type: SET_DAILY_MISSION_WRONG_AMOUNT, wrongAmount }
}

export function setDailyMissionExperience (experience) {
  return { type: SET_DAILY_MISSION_EXPERIENCE, experience }
}

export function setDailyMissionRuby (ruby) {
  return { type: SET_DAILY_MISSION_RUBY, ruby }
}

export function setDailyMissionTrailStep (step) {
  return { type: SET_DAILY_MISSION_TRAIL_STEP, step }
}

export function setDailyMissionTrail (completedScenarios, planet) {
  return { type: SET_DAILY_MISSION_TRAIL, completedScenarios, planet }
}

export function logout () {
  return dispatch => {
    removeAuthToken()
    removeWebsocketToken()

    const reducer = initialState()

    dispatch({ type: RESET_KEY })
    dispatch({ type: REHYDRATE, key: STORAGE_KEY, payload: reducer })
  }
}

export function updateCurrentUser (user, relative) {
  return { type: UPDATE_CURRENT_USER, user, relative }
}

export function syncCurrentPlanet () {
  return dispatch => {
    API.get("/planets", response => {
      const { name: planet } = response.data

      if (!planet) {
        return
      }

      dispatch({ type: SET_CURRENT_PLANET, planet })
    })
  }
}

export function syncCurrentGalaxy () {
  return dispatch => {
    API.get("/galaxies", response => {
      const { galaxies } = response.data
      const currentGalaxy = galaxies.find(galaxy => galaxy.current)
      const nearGalaxy = galaxies.find(galaxy => !galaxy.current)
      const galaxy = currentGalaxy || nearGalaxy
      dispatch(setCurrentGalaxy(galaxy.name, galaxy.slug))
    })
  }
}

export function setCurrentGalaxy (name, slug) {
  return { type: SET_CURRENT_GALAXY, galaxy: { name, slug } }
}

export function syncGamification (userId) {
  return dispatch => {
    const channel = connectToChannel(`gamification:${userId}`)

    if (!channel) return

    channel.join()
    channel.on("update", points => {
      dispatch({ type: START_GAMIFICATION_ANIMATION })
      dispatch({ type: SYNC_GAMIFICATION, xp: points.xp, ruby: points.ruby })
      MixpanelPeopleSet({
        xps: points.xp,
        rubis: points.ruby
      })
    })
  }
}
