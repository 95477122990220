import React from "react"
import { Link } from "react-router-dom"
import Modal from "components/modal"
import UserAvatar from "components/user-avatar"
import Processing from "components/processing"
import API from "api"

const BATTLE_SUBMARINE_WIN_SCORE = 19
const GAME_LIST = [
  "gamao",
  "domino",
  "battle-submarine",
  "rush-hour",
  "mini-bridge",
  "yams"
]

const WithExperience = ({ experience }) => (
  <div className="earnings-container">
    <i className="earnings-brain" />

    <div className="xp-points gamification-rounded">+ {experience} xp</div>

    <Link className="modal-link" to="/jogos/estacao">
      Voltar para a Estação de Jogos
    </Link>
  </div>
)

const WithoutExperience = () => (
  <div className="earnings-container">
    <UserAvatar />
    <div className="gamification-rounded">
      <p className="match-warn-tip">Você já jogou mais de 3 partidas hoje</p>
      <div className="xp-points">0 xp</div>
    </div>

    <Link className="modal-link" to="/jogos/estacao">
      Voltar para a Estação de Jogos
    </Link>
  </div>
)

class MatchFinished extends React.Component {
  constructor (props) {
    super(props)
    this.state = { fetched: false, limited: false }
  }

  findRankGeneric(gameData) {
    if (!gameData.scoreCPU)
      return 1
    if (gameData.scorePlayer > gameData.scoreCPU)
      return 1
    return 2
  }

  componentDidMount () {
    const gameData = localStorage.getItem(this.getGameId()) && JSON.parse(localStorage.getItem(this.getGameId()))
    const gameName = this.selectGame()
    const rank = this.applyRank(gameName, gameData)

    API.patch(`/singlematch/${this.getGameId()}`, { rank }, response => {
      if (response.data.error && response.data.error === "already_awarded_today") {
        this.setState({ fetched: true, limited: true })
      } else {
        this.setState({ fetched: true })
      }
      if (localStorage.getItem(this.getGameId())) {
        localStorage.removeItem(this.getGameId())
      }
    })
  }

  getTitle () {
    return this.state.limited ? "Apenas suas 3 primeiras partidas do dia valem xp" : "Você ganhou"
  }

  getGameRank() {
    const rank = {
      [btoa("gamao")]: "match",
      [btoa("domino")]: "match",
      [btoa("battle-submarine")]: "match",
      [btoa("rush-hour")]: "levels",
      [btoa("mini-bridge")]: "match",
      [btoa("yams")]: "match"
    }
    return rank[this.getHash()]
  }

  getHash() {
    const params = new URLSearchParams(window.location.search)
    const id = params.get("hash")
    return id
  }

  getGameId() {
    const names = {
        [btoa("gamao")]: "gamao",
        [btoa("domino")]: "domino",
        [btoa("battle-submarine")]: "battle-submarine",
        [btoa("rush-hour")]: "rush-hour",
        [btoa("mini-bridge")]: "mini-bridge",
        [btoa("yams")]: "yams"
    }
    return names[this.getHash()]
  }

  selectGame() {
    return Object.keys(localStorage)
      .reduce((acc, name) => {
        if (acc)
          return acc
        const found =
          !!GAME_LIST.find((element) => element === name)
        if (found)
          return name
      }, undefined)
  }

  applyRank(game, gameData) {
    switch (game) {
      case "gamao":
      case "domino":
      case "yams":
      case "rush-hour":
        return this.findRankGeneric(gameData)
      case "battle-submarine":
        return this.getBattleSubmarineRank(gameData.scorePlayer.totalHit, gameData.scoreCPU.totalHit) 
      case "mini-bridge":
        return this.getMiniBridgeRank(gameData.scorePlayer, gameData.scoreCPU_1, gameData.scoreCPU_2)
      default:
        return undefined
    }
  }

  getBattleSubmarineRank(playerHit, cpuHit) {
    if (playerHit === BATTLE_SUBMARINE_WIN_SCORE)
      return 1
    if (cpuHit === BATTLE_SUBMARINE_WIN_SCORE)
      return 2
  }

  getMiniBridgeRank(player, cpu1, cpu2) {
    const participants = [
      { type: "player", score: player },
      { type: "cpu1", score: cpu1 },
      { type: "cpu2", score: cpu2 }
    ]

    // eslint-disable-next-line no-unused-vars
    const [_found, rank] =
      participants
        .sort((participantA, participantB) => participantB.score - participantA.score)
        .reduce(([found, rank], currentValue) => {
          if (found)
            return [found, rank]

          const newRank = !rank ? 1 : rank + 1
          
          if (currentValue.type !== "player")
            return [false, newRank]
          return [true, newRank]
        }, [false, undefined])
    return rank
  }



  render () {

    if (!this.state.fetched) {
      return <Processing />
    }
    
    return (
      <Modal fullscreen color="purple">
        <div>
          <h2>Partida concluída!</h2>
          <p>{this.getTitle()}</p>
        </div>
        {!this.state.limited ? <WithExperience experience={45} /> : <WithoutExperience />}
      </Modal>
    )
  }
}

export default MatchFinished
