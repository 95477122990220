import React from "react"
import { connect } from "react-redux"
import { Vigo } from "components/avatar"
import CloseableModal from "components/modals/closeable-modal"

const LastPlanetEndedNotification = ({ opened, onClose, currentUser }) => (
  <CloseableModal opened={opened} onClose={onClose}>
    <h2 className="light">Galáxia Conquistada!</h2>

    <p>Olá, {currentUser.name}. Parabéns!!</p>

    <p>
      Você fez um ótimo trabalho ajudando o povo de Dión durante esse ano! Conseguiu recolher várias pedras do
      conhecimento e aprendeu muito ao longo dessa jornada de desenvolvimento dentro do universo Mindzup :)
    </p>

    <p>
      Agora, é hora de descansar para continuarmos nossa jornada no próximo ano. Vamos ficar por aqui, catalogando o
      conhecimento recolhido por você ;)
    </p>

    <h2 className="modal-message light">Um abraço e até o ano que vem!</h2>

    <Vigo />
  </CloseableModal>
)

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  }
}

export default connect(mapStateToProps)(LastPlanetEndedNotification)
