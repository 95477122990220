import React from "react"
import styled from "styled-components"
import { Sun, BluePlanet, NeighboringPlanets } from "Galaxy"
import background from "galaxies/blue-galaxy/blue-background.svg"

const StyledSession = styled.div`
  background: url(${background}) no-repeat center top;
  background-size: cover;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
  z-index: 0;

  @media (max-height: 460px) {
    align-items: flex-start;
  }
`

const StyledNeighboringPlanets = styled.div`
  position: fixed;
  top: -330px;
  left: -40px;
  z-index: -1;

  @media (max-width: 767px) {
    top: -160px;
    left: -50px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    top: -200px;
    left: -100px;
  }
`

const StyledBluePlanet = styled.div`
  position: fixed;
  top: -250px;
  right: -150px;
  overflow: hidden;
  z-index: -1;

  @media (max-width: 767px) {
    top: -40px;
    right: -40px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    top: -140px;
    right: -140px;
  }
`

const StyledSun = styled.div`
  width: 100%;
  height: 345px;
  position: fixed;
  bottom: 0;
  overflow: hidden;
  z-index: -1;

  @media (max-height: 460px) {
    height: 270px;
  }
`

const Layout = ({ children }) => (
  <StyledSession>
    <StyledNeighboringPlanets>
      <NeighboringPlanets />
    </StyledNeighboringPlanets>

    <StyledBluePlanet>
      <BluePlanet />
    </StyledBluePlanet>

    <StyledSun>
      <Sun />
    </StyledSun>

    {children}
  </StyledSession>
)

export default Layout
