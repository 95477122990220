export const MY_SISU_ENABLE_SUBMIT = "MY_SISU_ENABLE_SUBMIT"
export const MY_SISU_DISABLE_SUBMIT = "MY_SISU_DISABLE_SUBMIT"
export const MY_SISU_SET_OPTION = "MY_SISU_SET_OPTION"
export const MY_SISU_SHOW_CHANCES = "MY_SISU_SHOW_CHANCES"
export const MY_SISU_HIDE_CHANCES = "MY_SISU_HIDE_CHANCES"
export const MY_SISU_SET_CHANCES = "MY_SISU_SET_CHANCES"
export const MY_SISU_RESET_MY_SISU = "MY_SISU_RESET_MY_SISU"
export const MY_SISU_SET_UNIVERSITIES = "MY_SISU_SET_UNIVERSITIES"
export const MY_SISU_SET_COURSES = "MY_SISU_SET_COURSES"
export const MY_SISU_SET_SCIENCE_AREAS = "MY_SISU_SET_SCIENCE_AREAS"
export const MY_SISU_SET_GROUPINGS = "MY_SISU_SET_GROUPINGS"
export const MY_SISU_SET_STATES = "MY_SISU_SET_STATES"
export const MY_SISU_SET_CITIES = "MY_SISU_SET_CITIES"
export const MY_SISU_SET_TABLE_TITLE = "MY_SISU_SET_TABLE_TITLE"

export function mySisuEnableSubmit () {
  return { type: MY_SISU_ENABLE_SUBMIT }
}

export function mySisuDisableSubmit () {
  return { type: MY_SISU_DISABLE_SUBMIT }
}

export function mySisuSetFormOption (options) {
  return { type: MY_SISU_SET_OPTION, options: options }
}

export function mySisuShowChancesSection () {
  return { type: MY_SISU_SHOW_CHANCES }
}

export function mySisuHideChancesSection () {
  return { type: MY_SISU_HIDE_CHANCES }
}

export function mySisuSetChances (chances) {
  return { type: MY_SISU_SET_CHANCES, chances: chances }
}

export function mySisuResetForm () {
  return { type: MY_SISU_RESET_MY_SISU }
}

export function mySisuSetUniversities (universities) {
  return { type: MY_SISU_SET_UNIVERSITIES, universities: universities }
}

export function mySisuSetCourses (courses) {
  return { type: MY_SISU_SET_COURSES, courses: courses }
}

export function mySisuSetScienceAreas (scienceAreas) {
  return { type: MY_SISU_SET_SCIENCE_AREAS, scienceAreas: scienceAreas }
}

export function mySisuSetGroupings (groupings) {
  return { type: MY_SISU_SET_GROUPINGS, groupings: groupings }
}

export function mySisuSetStates (states) {
  return { type: MY_SISU_SET_STATES, states: states }
}

export function mySisuSetCities (cities) {
  return { type: MY_SISU_SET_CITIES, cities: cities }
}

export function mySisuSetTableTitle (chancesTableTitle) {
  return { type: MY_SISU_SET_TABLE_TITLE, chancesTableTitle: chancesTableTitle }
}
