import React from "react"
import styled from "styled-components"
import Planet from "Galaxy/wrappers/Planet"
import PlanetImage from "Galaxy/wrappers/PlanetImage"
import kai from "galaxies/purple-galaxy/kai.svg"

const Kai = props => (
  <Planet {...props}>
    <PlanetImage src={kai} blocked={props.blocked} />
  </Planet>
)

export default styled(Kai)``
