import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { push } from "connected-react-router"
import { Header } from "Header"
import { GamificationBar } from "GamificationBar"
import Processing from "components/processing"
import DailyMissionAlreadyDoneModal from "components/modals/daily-mission-already-done-modal"
import DailyMissionUnavailableModal from "components/modals/daily-mission-unavailable-modal"
import { setDailyMissionTrailStep, setDailyMissionTrail } from "actions"
import { Times, ReverseArraySlice } from "helpers/array-utils"
import UserAvatar from "components/user-avatar"
import { Horizon } from "Horizon"
import { Content, Scenario, Stone, Flag, UserCounter } from "trail"
import { MixpanelTrack } from "tags/mixpanel"
import { connectToChannel } from "socket-connect"
import { ScrollTo } from "helpers/scroll"
import DailyMissionDisabled from "trail/DailyMissionDisabled"
import TrailUsersDetailsModal from "trail/TrailUsersDetailsModal"
import YearFinalizedWithRetrievableQuestion from "trail/YearFinalizedWithRetrievableQuestion"
import YearFinalizedWithoutRetrievableQuestion from "trail/YearFinalizedWithoutRetrievableQuestion"

import {
  TrailTooltip0,
  TrailTooltip1,
  TrailTooltip2,
  TrailTooltip3,
  TrailTooltip4,
  TrailTooltip5,
  TrailTooltip6
} from "trail/TrailTooltip"

const TOOLTIPS = [
  TrailTooltip0,
  TrailTooltip1,
  TrailTooltip2,
  TrailTooltip3,
  TrailTooltip4,
  TrailTooltip5,
  TrailTooltip6
]

const ExperienceForNext = ({ position, experience }) => {
  const Tooltip = TOOLTIPS[position]

  return (
    <Tooltip>
      <i className="padlock-icon" /> {experience} xp
    </Tooltip>
  )
}

class Trail extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      activeAvatar: false,
      fetched: false,
      dailyMissionAlreadyDone: true,
      dailyMissionAlreadyDoneModal: false,
      dailyMissionUnavailable: false,
      dailyMissionUnavailableModal: false,
      yearFinalizedWithoutRetrievableQuestionModal: false,
      yearFinalizedWithRetrievableQuestionModal: false,
      yearFinalizedWithoutRetrievableQuestion: false,
      yearFinalizedWithRetrievableQuestion: false,
      dailyMissionDisabled: false,
      dailyMissionDisabledModal: false,
      trailUsersDetailsModal: false,
      experienceForNext: 0,
      step: 0,
      nextStep: 1,
      steps: 7,
      completedScenarios: 0,
      unblockTime: null,
      usersDetails: null,
      openModal: false
    }

    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount () {
    const { steps } = this.state
    const { dispatch, previousPlanet, previousStep, completedScenarios } = this.props

    const channel = connectToChannel("daily_mission")

    if (!channel) return

    channel.join().receive("error", () => {
      dispatch(push("/"))
    })

    channel.on("update_trail", result => {
      const { planet, position } = result.trail_position
      const newCompletedScenarios = Math.floor(position / steps)

      const usersOnTrail = result.users_on_trail
      const latestPosition = usersOnTrail.length > 0 && usersOnTrail[usersOnTrail.length - 1].position
      const completedScenariosClassmates = Math.floor(latestPosition / steps)
      const step = position - steps * newCompletedScenarios

      // update redux when it is on initial state
      // or when there is a new completed scenario
      // or when the previous planet is different than current planet
      if (previousStep === -1 || previousPlanet !== planet || completedScenarios < newCompletedScenarios) {
        dispatch(setDailyMissionTrailStep(step))
      }

      dispatch(setDailyMissionTrail(newCompletedScenarios, planet))

      if (result.daily_mission_today && this.state.dailyMissionUnavailable) {
        this.setState({ dailyMissionUnavailableModal: false })
      }

      this.setState({
        fetched: true,
        experienceForNext: result.next_position_exp,
        activeAvatar:
          result.daily_mission_today &&
          !result.daily_mission_already_done &&
          !result.year_finalized_with_retrievable_question &&
          !result.year_finalized_without_retrievable_question,
        dailyMissionAlreadyDone: result.daily_mission_already_done,
        dailyMissionUnavailable: !result.daily_mission_today,
        dailyMissionDisabled: result.daily_mission_disabled,
        yearFinalizedWithoutRetrievableQuestion: result.year_finalized_without_retrievable_question,
        yearFinalizedWithRetrievableQuestion: result.year_finalized_with_retrievable_question,
        usersOnTrail: usersOnTrail,
        step: step,
        nextStep: step + 1,
        unblockTime: result.daily_mission_available_at,
        completedScenarios: newCompletedScenarios,
        latestPosition: latestPosition,
        completedScenariosClassmates: completedScenariosClassmates
      })
    })
  }

  componentDidUpdate (prevProps) {
    const { fetched, step, openModal } = this.state

    !openModal && this.scrollToAvatar()

    if (fetched && prevProps.previousStep < step) {
      setTimeout(() => {
        this.animateAvatar(prevProps.previousStep, step)
      }, 1000)
    }
  }

  scrollToAvatar () {
    const { completedScenarios, fetched, step } = this.state

    if (fetched && completedScenarios > 0) {
      const distanceBetweenStones = 100
      const totalCompletedHeight = 818 * completedScenarios
      const scrollTarget = totalCompletedHeight + distanceBetweenStones * step

      ScrollTo(scrollTarget)
    }
  }

  animateAvatar (current, last) {
    const next = current + 1
    this.props.dispatch(setDailyMissionTrailStep(next))

    if (next < last) {
      setTimeout(() => {
        this.animateAvatar(next, last)
      }, 2000)
    }
  }

  renderUserCounter (position) {
    const { usersOnTrail, steps } = this.state
    const usersByPosition = usersOnTrail && usersOnTrail.find(item => item.position == position)
    const currentScenario = Math.floor(position / steps)
    const stonePosition = position - steps * currentScenario

    if (usersByPosition) {
      return (
        <Link to="#" onClick={() => this.userCounterClick(usersByPosition.users)}>
          <UserCounter position={stonePosition}>+ {usersByPosition.total_users}</UserCounter>
        </Link>
      )
    }
  }

  userCounterClick (users) {
    MixpanelTrack("Acessou lista de amigos na trilha")
    this.setState({ trailUsersDetailsModal: true, usersDetails: users })
  }

  handleClick (e) {
    const {
      dailyMissionAlreadyDone,
      dailyMissionUnavailable,
      dailyMissionDisabled,
      yearFinalizedWithRetrievableQuestion,
      yearFinalizedWithoutRetrievableQuestion
    } = this.state

    if (yearFinalizedWithRetrievableQuestion) {
      MixpanelTrack("Tentou acessar Missão Diária após o fim dos agrupamentos e só tem questão para resgatar amanhã")
      this.setState({ yearFinalizedWithRetrievableQuestionModal: true, openModal: true })
      e.preventDefault()
    } else if (yearFinalizedWithoutRetrievableQuestion) {
      MixpanelTrack("Tentou acessar Missão Diária após o fim dos agrupamentos e não tem mais questões para resgatar")
      this.setState({ yearFinalizedWithoutRetrievableQuestionModal: true, openModal: true })
      e.preventDefault()
    } else if (dailyMissionDisabled) {
      MixpanelTrack("Tentou acessar Missão Diária sem agrupamentos liberados")
      this.setState({ dailyMissionDisabledModal: true, openModal: true })
      e.preventDefault()
    } else if (dailyMissionUnavailable) {
      MixpanelTrack("Tentou acessar Missão Diária num dia não permitido")
      this.setState({ dailyMissionUnavailableModal: true, openModal: true })
      e.preventDefault()
    } else if (dailyMissionAlreadyDone) {
      MixpanelTrack("Tentou acessar Missão Diária já finalizada")
      this.setState({ dailyMissionAlreadyDoneModal: true, openModal: true })
      e.preventDefault()
    }
  }

  render () {
    const {
      activeAvatar,
      fetched,
      nextStep,
      steps,
      experienceForNext,
      dailyMissionUnavailableModal,
      dailyMissionAlreadyDoneModal,
      yearFinalizedWithRetrievableQuestionModal,
      yearFinalizedWithoutRetrievableQuestionModal,
      dailyMissionDisabledModal,
      unblockTime,
      completedScenarios,
      trailUsersDetailsModal,
      usersDetails,
      usersOnTrail,
      completedScenariosClassmates
    } = this.state

    if (!fetched) {
      return <Processing />
    }

    const { previousStep } = this.props
    const hasUsersAfterLastUserScenario = completedScenariosClassmates > completedScenarios
    const lastCheckpoint = nextStep === steps
    let userCounterPosition = completedScenarios * steps

    return (
      <div className="trail-container">
        <DailyMissionAlreadyDoneModal
          opened={dailyMissionAlreadyDoneModal}
          unblockTime={unblockTime}
          onClose={() => this.setState({ dailyMissionAlreadyDoneModal: false })}
        />
        <DailyMissionUnavailableModal
          opened={dailyMissionUnavailableModal}
          unblockTime={unblockTime}
          onClose={() => this.setState({ dailyMissionUnavailableModal: false })}
        />
        <YearFinalizedWithRetrievableQuestion
          opened={yearFinalizedWithRetrievableQuestionModal}
          onClose={() => this.setState({ yearFinalizedWithRetrievableQuestionModal: false })}
        />
        <YearFinalizedWithoutRetrievableQuestion
          opened={yearFinalizedWithoutRetrievableQuestionModal}
          onClose={() => this.setState({ yearFinalizedWithoutRetrievableQuestionModal: false })}
        />

        <DailyMissionDisabled
          opened={dailyMissionDisabledModal}
          onClose={() => this.setState({ dailyMissionDisabledModal: false })}
        />
        <TrailUsersDetailsModal
          opened={trailUsersDetailsModal}
          users={usersDetails}
          onClose={() => this.setState({ trailUsersDetailsModal: false })}
        />
        <Header backUrl="/galaxia" backLabel="Galáxia">
          <GamificationBar />
        </Header>
        <Horizon />
        <Content>
          {Times(completedScenarios).map((_, index) => {
            return (
              <Scenario key={index}>
                {Times(steps).map((_, index) => (
                  <Flag key={index} position={index} />
                ))}
              </Scenario>
            )
          })}

          {/* Current checkpoints */}
          <Scenario>
            {Times(previousStep).map((_, index) => (
              <Flag key={index} position={index} />
            ))}

            {ReverseArraySlice(steps, steps - previousStep).map(index => (
              <div key={index}>
                <Stone key={index} position={index} />
                {usersOnTrail && this.renderUserCounter(userCounterPosition + index)}
              </div>
            ))}

            <Link to="/missao-diaria" onClick={this.handleClick}>
              <UserAvatar
                kind="ship"
                inactive={!activeAvatar}
                className={`trail-avatar trail-avatar-${previousStep}`}
              />
            </Link>

            {experienceForNext && nextStep < steps && (
              <ExperienceForNext position={nextStep} experience={experienceForNext} />
            )}
          </Scenario>

          {/* Creates rest of scenarios when there are classmates at checkpoints more advanced than the user scenario*/}
          {hasUsersAfterLastUserScenario &&
            Times(completedScenariosClassmates - completedScenarios).map((_, scenarioIndex) => {
              userCounterPosition += steps
              return (
                <Scenario key={scenarioIndex}>
                  {ReverseArraySlice(steps, steps).map((_, index) => (
                    <div key={index}>
                      <Stone key={index} position={index} />
                      {usersOnTrail && this.renderUserCounter(userCounterPosition + index)}
                    </div>
                  ))}
                  {lastCheckpoint && scenarioIndex == 0 && (
                    <ExperienceForNext position={0} experience={experienceForNext} />
                  )}
                </Scenario>
              )
            })}

          {/* Next checkpoints (when the Avatar is on the last checkpoint of above scenario) */}
          {!hasUsersAfterLastUserScenario && lastCheckpoint && (
            <Scenario>
              {Times(steps).map((_, index) => (
                <Stone key={index} position={index} />
              ))}

              <ExperienceForNext position={0} experience={experienceForNext} />
            </Scenario>
          )}
        </Content>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    previousPlanet: state.dailyMissionTrail.previousPlanet,
    previousStep: state.dailyMissionTrail.previousStep,
    completedScenarios: state.dailyMissionTrail.completedScenarios
  }
}

export default connect(mapStateToProps)(Trail)
