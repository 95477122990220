import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import { AVATARS, findAvatarIndex } from "components/avatar"

const PrevArrow = ({ onClick }) => <i className="arrow-icon-left avatar-slide-arrow-prev" onClick={onClick} />

const NextArrow = ({ onClick }) => <i className="arrow-icon-right avatar-slide-arrow-next" onClick={onClick} />

class AvatarSlide extends React.Component {
  constructor (props) {
    super(props)
    this.state = { name: "", bio: "" }

    this.handleSlider = this.handleSlider.bind(this)
  }

  componentDidMount () {
    const { selected } = this.props

    if (selected !== null) {
      const index = findAvatarIndex(selected)
      this.sliderRef.slickGoTo(index)
      this.handleSlider(index)
    } else {
      this.handleSlider(0)
    }
  }

  handleSlider (index) {
    const { name, bio } = AVATARS[index]
    this.setState({ name, bio })
    this.props.onChange(name)
  }

  render () {
    const { name, bio } = this.state
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      afterChange: this.handleSlider,
      centerMode: true,
      centerPadding: 0,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 768,
          settings: { slidesToShow: 1 }
        },
        {
          breakpoint: 1024,
          settings: { slidesToShow: 3 }
        }
      ]
    }

    return (
      <div className="avatar-slide">
        <Slider
          ref={slider => {
            this.sliderRef = slider
          }}
          {...settings}
        >
          {AVATARS.map((avatar, index) => {
            const { component: UserAvatar } = avatar

            return (
              <div key={index} className="avatar-slide-item">
                <UserAvatar style={{ height: "200px" }} />
              </div>
            )
          })}
        </Slider>

        <h2 className="avatar-slide-name big">{name}</h2>
        <p className="avatar-slide-bio">{bio}</p>
      </div>
    )
  }
}

export default AvatarSlide
