import styled from "styled-components"
import sky from "planets/union/union-sky.svg"
import { StyledHorizon, StyledHorizonContainer, StyledHorizonTitle } from "Horizon"

const PlanetHorizon = styled(StyledHorizon)`
  background: linear-gradient(180deg, #373655 0%, #2c616d 100%);
`

const ShortPlanetHorizon = PlanetHorizon

const PlanetHorizonContainer = styled(StyledHorizonContainer)`
  &::before {
    background: url(${sky}) no-repeat;
    height: 200px;
    bottom: 90px;

    @media (max-width: 768px) {
      left: -43px;
    }
  }
`

const ShortPlanetHorizonContainer = styled(PlanetHorizonContainer)`
  &::before {
    background: url(${sky}) no-repeat;
    height: 150px;
    bottom: 50px;

    @media (max-width: 768px) {
      bottom: 75px;
      height: 100px;
    }
  }
`

const PlanetHorizonTitle = StyledHorizonTitle

export { PlanetHorizon, ShortPlanetHorizon, PlanetHorizonContainer, ShortPlanetHorizonContainer, PlanetHorizonTitle }
