import styled from "styled-components"
import PropTypes from "prop-types"
import { withPlanet } from "planet-context"

const Container = styled.div`
  background: ${props => props.planet.mainColor};
  background: linear-gradient(
    180deg,
    ${props => props.planet.mainColor} 0%,
    ${props => props.planet.secondaryColor} 100%
  );
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-x: hidden;
`

Container.propTypes = {
  planet: PropTypes.shape({
    mainColor: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string.isRequired
  })
}

export default withPlanet(Container)
