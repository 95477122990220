import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import { Header, HeaderLink, HeaderLeft } from "Header"
import QuestionsSelector from "components/questions-selector"
import InlineCountdown from "Shared/InlineCountdown"
import { BtnPrimary, BtnLink } from "components/button"
import ExamFinalizedModal from "components/modals/exam-finalized-modal"
import ExamRulesModal from "components/modals/exam-rules-modal"
import ExamPauseModal from "components/modals/exam-pause-modal"
import TimeIsUpModal from "components/modals/time-is-up-modal"
import DuplicatedSessionModal from "components/modals/duplicated-session-modal"
import ExamQuestion from "components/exams/question"
import { connectToChannel } from "socket-connect"
import API from "api"
import { HeaderBack } from "Header"

class ExamAnswer extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      submitDisabled: true,
      allAnswered: false,
      finishModal: false,
      rulesModal: false,
      pauseModal: false,
      timeIsUpModal: false,
      duplicatedSessionModal: false,
      pausable: null,
      totalQuestions: 0,
      current: null,
      title: "",
      knowledgeArea: "",
      questions: [],
      answered: new Set(),
      finishTime: Date.now(),
      duration: 0,
      examName: "",
      examTimerId: null,
      timeLeft: 0
    }

    this.handleQuestionSelector = this.handleQuestionSelector.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handleAlternative = this.handleAlternative.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleFinalize = this.handleFinalize.bind(this)
  }

  UNSAFE_componentWillMount () {
    const { state } = this.props.location

    if (state) {
      this.questionSetSessionId = state.questionSetSessionId
    } else {
      console.error("[exam/answer] No state, dead inside!")
      this.redirectToStation()
    }
  }

  componentDidMount () {
    if (!this.questionSetSessionId) {
      return
    }

    API.get(`/question_set_sessions/${this.questionSetSessionId}`, response => {
      if (response.data.finalized) {
        console.log("[exam/answer] response.data.finalized: true")
        this.redirectToStation()
        return
      }

      const {
        total_questions: totalQuestions,
        miniexam_name: title,
        duration: duration,
        time_left: timeLeft,
        knowledge_area: knowledgeArea,
        questions: questions,
        pausable: pausable,
        exam_name: examName,
        exam_timer_id: examTimerId
      } = response.data

      const finishTime = Date.now() + timeLeft
      const answered = new Set(questions.filter(q => q.answer !== null).map(q => q.id))

      this.setState(
        {
          totalQuestions,
          title,
          knowledgeArea,
          questions,
          finishTime,
          answered,
          pausable,
          duration,
          examName,
          examTimerId,
          timeLeft
        },
        () => {
          this.subscribeQuestionSetSessionChannel()
          this.subscribeTimerChannel()

          this.updateAnswered(() => {
            this.goToNextUnanswered()
          })
        }
      )
    })
  }

  componentWillUnmount () {
    this.unsubscribeChannels()
  }

  redirectToStation () {
    this.props.dispatch(push("/simulados"))
  }

  subscribeQuestionSetSessionChannel () {
    this.questionSetSessionChannel = connectToChannel(`question_set_session:${this.questionSetSessionId}`)

    if (!this.questionSetSessionChannel) return

    this.questionSetSessionChannel.join().receive("error", response => {
      const { reason } = response

      if (reason === "already_joined") {
        this.setState({ duplicatedSessionModal: true })
      } else {
        this.redirectToStation()
      }
    })
  }

  subscribeTimerChannel () {
    const { pausable, examTimerId } = this.state
    const timerTopic = pausable ? `pausable_timer:${this.questionSetSessionId}` : `unpausable_timer:${examTimerId}`

    this.timerChannel = connectToChannel(timerTopic)

    if (!this.timerChannel) return

    this.timerChannel.join().receive("error", response => {
      console.error("[exam/answer] Impossible to enter timer: ", response)
      this.redirectToStation()
    })

    this.timerChannel.on("expired", () => {
      this.unsubscribeChannels()
      this.setState({ timeIsUpModal: true })
    })

    this.timerChannel.on("update_clock", payload => {
      const finishTime = Date.now() + payload.remaining_time
      this.setState({ finishTime })
    })
  }

  unsubscribeChannels () {
    if (this.questionSetSessionChannel && this.questionSetSessionChannel.state === "joined") {
      this.questionSetSessionChannel.leave()
    }

    if (this.timerChannel && this.timerChannel.state === "joined") {
      this.timerChannel.leave()
    }
  }

  getCurrentIndex () {
    const { questions, current } = this.state
    return questions.findIndex(q => q.id === current)
  }

  goToNextUnanswered () {
    if (this.state.allAnswered) {
      return
    }

    const { questions, current } = this.state
    const firstUnanswered = questions.find(q => q.answer === null)
    let newCurrent = null

    if (current === null) {
      newCurrent = firstUnanswered
    } else {
      const currentIndex = this.getCurrentIndex()
      newCurrent = questions.find((q, index) => index > currentIndex && q.answer === null)

      if (typeof newCurrent === "undefined") {
        newCurrent = firstUnanswered
      }
    }

    this.setState({ current: newCurrent.id, submitDisabled: true })
  }

  handleQuestionSelector (e) {
    this.setState({ current: e.target.dataset.question })
  }

  handleNext (e) {
    this.goToNextUnanswered()
    e.preventDefault()
  }

  handleAlternative () {
    this.setState({ submitDisabled: false })
  }

  handleSave (event, id) {
    this.setState({ submitDisabled: true })

    const { answered } = this.state
    const question = event.target.parentNode.parentNode
    const { value } = question.querySelector("input[type=radio]:checked")

    this.questionSetSessionChannel
      .push("answer", { question_answer_id: id, alternative_name: value })
      .receive("ok", () => {
        this.setState({ answered: answered.add(id) }, () => {
          this.updateAnswered(() => {
            this.goToNextUnanswered()
          })
        })
      })
      .receive("error", () => {
        // retry
      })
      .receive("timeout", () => {
        // retry
      })

    event.preventDefault()
  }

  updateAnswered (callback) {
    const { answered, totalQuestions } = this.state
    const allAnswered = answered.size === totalQuestions
    this.setState({ allAnswered }, callback)
  }

  handleFinalize (e) {
    const { requestInProgress } = this.props

    if (requestInProgress) return

    API.put(`/question_set_sessions/${this.questionSetSessionId}`, {}, () => {
      console.log("[exam/answer] Finalized session")
      this.redirectToStation()
    })

    e.preventDefault()
  }

  render () {
    const {
      questions,
      totalQuestions,
      title,
      knowledgeArea,
      current,
      answered,
      finishTime,
      submitDisabled,
      allAnswered,
      finishModal,
      rulesModal,
      pauseModal,
      timeIsUpModal,
      duplicatedSessionModal,
      pausable,
      duration,
      examName
    } = this.state

    return (
      <div className="fullscreen-modal-wrapper">
        <TimeIsUpModal opened={timeIsUpModal} onClose={() => this.redirectToStation()} />
        <DuplicatedSessionModal opened={duplicatedSessionModal} onClose={() => this.redirectToStation()} />

        <ExamFinalizedModal
          opened={finishModal}
          knowledgeArea={knowledgeArea}
          onFinalize={this.handleFinalize}
          onDismiss={() => this.setState({ finishModal: false })}
        />

        <ExamRulesModal
          pausable={pausable}
          title={title}
          opened={rulesModal}
          duration={duration}
          examName={examName}
          onClose={() => this.setState({ rulesModal: false })}
        />

        <ExamPauseModal opened={pauseModal} onDismiss={() => this.setState({ pauseModal: false })} />

        <Header color="colored">
          <HeaderLink to="#" onClick={() => this.setState({ rulesModal: true })}>
            Regras
          </HeaderLink>

          <HeaderLeft>
            {!allAnswered && pausable && (
              <HeaderLink to="#" onClick={() => this.setState({ pauseModal: true })}>
                <i className="pause-icon" /> Pausar <strong>e sair</strong>
              </HeaderLink>
            )}

            {!allAnswered && !pausable && <HeaderBack backUrl="/simulados" backLabel="Sair" />}

            {allAnswered && (
              <HeaderLink to="#" onClick={() => this.setState({ finishModal: true })}>
                <i className="check-icon" /> FINALIZAR
              </HeaderLink>
            )}
          </HeaderLeft>
        </Header>

        <div className="exam-answer-title">
          <h2 className="light">{title}</h2>

          {!duplicatedSessionModal && pausable !== null && (
            <InlineCountdown color={pausable ? "purple" : "red"} finishTime={finishTime} />
          )}
        </div>

        <QuestionsSelector
          questions={questions}
          current={current}
          answered={answered}
          onClick={this.handleQuestionSelector}
        />

        <hr />

        <div className="exam-question">
          <h3>
            Questão {this.getCurrentIndex() + 1} de {totalQuestions}
          </h3>

          {questions.map((question, index) => {
            const { id } = question

            return (
              <div key={index} style={{ display: current === id ? "block" : "none" }}>
                <ExamQuestion question={question} handleAlternative={this.handleAlternative} />

                <div className="buttons-container">
                  <BtnLink to="#" value="Pular questão" onClick={this.handleNext} />
                  <BtnPrimary to="#" value="Salvar" disabled={submitDisabled} onClick={e => this.handleSave(e, id)} />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    requestInProgress: state.http.requestInProgress
  }
}

export default connect(mapStateToProps)(ExamAnswer)
