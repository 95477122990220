import scenario from "planets/racion/racion-scenario.svg"
import flag from "planets/racion/racion-high-flag.svg"
import { stone0, stone1, stone2, stone3, stone4, stone5, stone6 } from "planets/racion/stone"

export default {
  stones: [stone0, stone1, stone2, stone3, stone4, stone5, stone6],
  scenario: scenario,
  flag: flag,
  gradient: "linear-gradient(180deg,#c9fff5 0%,#a093da 100%)",
  paddingTop: "20px"
}
