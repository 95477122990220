import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import {
  setDailyMissionQuestions,
  setDailyMissionCorrectAmount,
  setDailyMissionWrongAmount,
  setDailyMissionExperience,
  setDailyMissionRuby
} from "actions"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import endOfDay from "date-fns/endOfDay"
import Countdown from "components/countdown"
import UserAvatar from "components/user-avatar"
import { Bag } from "components/button"
import { Header } from "Header"
import { GamificationBar } from "GamificationBar"
import {
  Container,
  Title,
  Expiration,
  RemainingTime,
  KnowledgeArea,
  KnowledgeAreaSlide,
  KnowledgeAreaSlideItemLink,
  DailyMissionClosedModal,
  RetrievedQuestion,
  RetrieveQuestionConfirmationModal,
  RetrieveQuestionInsufficientRubiesModal,
  RetrieveQuestionUnavailableModal
} from "daily-mission"
import { UserAvatarBottomCurve } from "components/bottom-curve"
import { MixpanelTrack } from "tags/mixpanel"
import { connectToChannel } from "socket-connect"
import Service from "Service"
import API from "api"

const PrevArrow = ({ onClick }) => <i className="arrow-icon-left knowledge-area-slide-arrow-prev" onClick={onClick} />
const NextArrow = ({ onClick }) => <i className="arrow-icon-right knowledge-area-slide-arrow-next" onClick={onClick} />

class DailyMissionNew extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      endOfDay: endOfDay(new Date()),
      answerSessionClosed: false,
      retrieveQuestionConfirmationModal: false,
      retrieveQuestionInsufficientRubiesModal: false,
      retrieveQuestionUnavailableModal: false,
      retrievedQuestionStarted: false,
      retrievedQuestionAnswered: false
    }

    this.askRetrieveQuestionConfirmation = this.askRetrieveQuestionConfirmation.bind(this)
    this.confirmRetrieveQuestion = this.confirmRetrieveQuestion.bind(this)
  }

  componentDidMount () {
    MixpanelTrack("Acessou Missão Diária")

    this.fetchDailyMissionQuestions()
    this.fetchRetrievedQuestion()
    this.subscribeToAnswerSession()
  }

  fetchDailyMissionQuestions () {
    const { dispatch } = this.props

    Service.getDailyMissionQuestions(response => {
      dispatch(setDailyMissionQuestions(response.data.question_answers))
      dispatch(setDailyMissionCorrectAmount(response.data.correct_count))
      dispatch(setDailyMissionWrongAmount(response.data.wrong_count))
      dispatch(setDailyMissionExperience(response.data.experience))
      dispatch(setDailyMissionRuby(response.data.ruby))

      this.checkAllAnswered()
    })
  }

  subscribeToAnswerSession () {
    const { dispatch } = this.props
    const channel = connectToChannel("daily_mission")

    if (!channel) return

    channel.join().receive("error", () => {
      dispatch(push("/"))
    })

    channel.on("close_answer_sessions", () => {
      this.setState({ answerSessionClosed: true })
    })
  }

  fetchRetrievedQuestion () {
    API.get("/daily_mission_question_retrieve", response => {
      const { started, answered } = response.data
      this.setState({ retrievedQuestionStarted: started, retrievedQuestionAnswered: answered })
    })
  }

  askRetrieveQuestionConfirmation (event) {
    const { retrievedQuestionStarted } = this.state

    if (retrievedQuestionStarted) {
      this.confirmRetrieveQuestion()
    } else {
      this.setState({ retrieveQuestionConfirmationModal: true })
    }

    event.preventDefault()
  }

  confirmRetrieveQuestion () {
    const { dispatch } = this.props

    API.post(
      "/daily_mission_question_retrieve",
      {},
      () => {
        dispatch(push("/missao-diaria/resgate"))
      },
      error => {
        const { enough_rubies, retrievable_question } = error.response.data.errors

        this.setState({
          retrieveQuestionConfirmationModal: false,
          retrieveQuestionInsufficientRubiesModal: !enough_rubies,
          retrieveQuestionUnavailableModal: !retrievable_question
        })
      }
    )
  }

  render () {
    const { dailyMissionQuestions } = this.props

    const {
      endOfDay,
      answerSessionClosed,
      retrieveQuestionConfirmationModal,
      retrieveQuestionInsufficientRubiesModal,
      retrieveQuestionUnavailableModal,
      retrievedQuestionStarted,
      retrievedQuestionAnswered
    } = this.state

    const settings = {
      dots: false,
      arrows: true,
      infinite: false,
      centerMode: true,
      centerPadding: 0,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            slidesToShow: 1,
            centerPadding: "30px"
          }
        }
      ]
    }

    return (
      <Container>
        <RetrieveQuestionConfirmationModal
          onConfirmation={this.confirmRetrieveQuestion}
          opened={retrieveQuestionConfirmationModal}
          onClose={() => this.setState({ retrieveQuestionConfirmationModal: false })}
        />

        <RetrieveQuestionInsufficientRubiesModal
          opened={retrieveQuestionInsufficientRubiesModal}
          onClose={() => this.setState({ retrieveQuestionInsufficientRubiesModal: false })}
        />

        <RetrieveQuestionUnavailableModal
          opened={retrieveQuestionUnavailableModal}
          onClose={() => this.setState({ retrieveQuestionUnavailableModal: false })}
        />

        {answerSessionClosed && <DailyMissionClosedModal />}

        <Header backUrl="/trilha">
          <GamificationBar />
        </Header>

        <Bag />

        <Title>Missão Diária</Title>

        <Expiration>
          Expira em{" "}
          <RemainingTime>
            <Countdown until={endOfDay} />
          </RemainingTime>
        </Expiration>

        <KnowledgeAreaSlide>
          <Slider {...settings}>
            <KnowledgeAreaSlideItemLink to="#" onClick={this.askRetrieveQuestionConfirmation}>
              <RetrievedQuestion started={retrievedQuestionStarted} answered={retrievedQuestionAnswered} />
            </KnowledgeAreaSlideItemLink>

            {dailyMissionQuestions.map((dailyMissionQuestion, index) => {
              const { answered, knowledge_area: knowledgeArea } = dailyMissionQuestion

              return (
                <div key={index}>
                  <KnowledgeAreaSlideItemLink
                    to={{
                      pathname: "/missao-diaria/responder",
                      state: { selected: index }
                    }}
                    onClick={() =>
                      MixpanelTrack("Escolheu Área do Conhecimento", {
                        "ÁREA SELECIONADA": knowledgeArea
                      })
                    }
                  >
                    <KnowledgeArea name={knowledgeArea} index={`0${index + 1}`} answered={answered} />
                  </KnowledgeAreaSlideItemLink>
                </div>
              )
            })}
          </Slider>
        </KnowledgeAreaSlide>

        <UserAvatarBottomCurve avatar={UserAvatar} />
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    dailyMissionQuestions: state.dailyMissionQuestions.questions
  }
}

export default connect(mapStateToProps)(DailyMissionNew)
