import React from "react"
import { push } from "connected-react-router"
import { connect } from "react-redux"
import { BtnPrimary } from "components/button"
import { Layout } from "Session"
import Modal from "components/modal"
import { logout } from "actions"
import { MixpanelTrack, MixpanelReset } from "tags/mixpanel"
import API from "api"

class OnboardingPaymentCreditcardWaitingNextYear extends React.Component {
  constructor (props) {
    super(props)
    this.handleSignOut = this.handleSignOut.bind(this)
  }

  handleSignOut (event) {
    event.preventDefault()
    const { dispatch } = this.props

    API.delete("/sessions", () => {
      MixpanelTrack("Efetuou logout")
      MixpanelReset()

      dispatch(logout())
      dispatch(push("/"))
    })
  }

  render () {
    return (
      <Layout>
        <Modal color="purple">
          <h2>Pagamento em análise</h2>

          <p>
            Ao processarmos seu pedido, ele foi encaminhado para análise. Nosso time irá avaliar sua compra e em breve
            lhe daremos um retorno.
          </p>

          <p>Essa análise pode demorar alguns dias, portanto, não se preocupe :)</p>

          <BtnPrimary onClick={this.handleSignOut} to="#" value="Sair" arrow />

          <div className="modal-link">
            Dúvidas?{" "}
            <a to="https://ajuda.mindlab.com.br/hc/pt-br/requests/new" target="_blank" rel="noopener noreferrer">
              Fale com a gente no chat
            </a>
          </div>
        </Modal>
      </Layout>
    )
  }
}

export default connect()(OnboardingPaymentCreditcardWaitingNextYear)
