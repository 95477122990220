import React from "react"
import { connect } from "react-redux"
import SingleGameLayout from "GamesStation/SingleGameLayout"
import styled from "styled-components"
// import Channel from "iframe-channel"
// import { externalGameServer } from "config"
import NotFound from "components/not-found"

const MarketJSGame = styled.iframe`
    height: 100%;
    width: 100%;
    margin: 0;
    border: 0;
    z-index: 1;
`

const IFrameContainer = styled.div`
    height: calc(100vh - 55px);
    padding-top: 55px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

class SingleGame extends React.Component {
    constructor(props) {
        super(props)

        this.channel = null

        this.state = {
            title: null, //Titulo do jogo
            gameId: null, // Identificador do jogo
            hasError: false,
            score: null,
        }
        // this.handleLoad = this.handleLoad.bind(this)
        // this.sendDatatoServer = this.sendDatatoServer.bind(this)
    }

    componentDidMount() {
        const { title, gameId } = this.props.location.state
        this.setState({ title, gameId })
        // this.handleLoad()
    }

    sendDatatoServer(data) {
        const { gameId } = this.state
        localStorage.setItem(gameId, JSON.parse(data))
        this.setState({ "score": data })
    }


    handleLoad() {
        // this.channel = new Channel({
        //     targetOrigin: externalGameServer
        // })

        // this.channel.subscribe("score", (data, message, event) => {
        //     console.log(data, message, event)
        //     this.sendDatatoServer(data)
        //     return null
        // })

        // this.channel.subscribe("connect", () => {
        //     // have connected
        //     const { gameId } = this.state
        //     const info = {
        //         game: gameId,
        //         user: this.props.username
        //     }
        //     const msg = JSON.stringify(info)
        //     this.channel.postMessage("info", msg).then((data) => {
        //         if (!data) {
        //             this.setState({ "hasError": true })
        //         }
        //     })
        // })
    }

    componentWillUnmount() {
        // destroy channel
        // Each Channel instance will add 'message' and 'beforeunload' event listener to window
        // object. So make sure destory the instance once it's unused.
        this.channel && this.channel.destroy()
    }

    parseGameId(id) {
        const names = {
            ["gamao"]: "backgammon",
            ["domino"]: "dominoes",
            ["battle-submarine"]: "battleships",
            ["rush-hour"]: "carParkPuzzle",
            ["mini-bridge"]: "spades",
            ["yams"]: "yatzy"
        }
        return names[id]
    }

    gameResource() {
        const prefix = window.location.href.includes("localhost") ? "http://localhost:3001/games/" : "/games/"
        const user = "?user=Você"
        const game = this.parseGameId(this.props.location.state.gameId)
        return prefix + game + user
    }

    render() {
        const { title, hasError } = this.state
        return (
            hasError ? <NotFound /> : <SingleGameLayout backUrl="/jogos/estacao" title={title}>
                <IFrameContainer>
                    <MarketJSGame id="mkgames" src={this.gameResource()} name="hades" />
                </IFrameContainer>
            </SingleGameLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        username: state.auth.currentUser.username
    }
}

export default connect(mapStateToProps)(SingleGame)
