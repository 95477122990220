import React from "react"
import { SecondsToHourMins } from "helpers/time-formatter"

const ExamUnpausableRulesContent = ({ title, duration }) => (
  <div>
    <h2 className="light">{title}</h2>

    <div className="exam-rules">
      <p>
        <strong>Regras:</strong>
      </p>
      <p>
        O {title} tem <strong>{SecondsToHourMins(duration)} de duração</strong>. Ao término desse tempo, a prova será
        encerrada e você não poderá mais responder às questões em branco.
      </p>
      <p>
        <strong>1.</strong> O tempo de prova é corrido e não poderá ser pausado. Portanto, garanta que você está
        <strong> descansado, bem alimentado e pronto para muitas horas seguidas de prova!</strong>
      </p>
      <p>
        <strong>2.</strong> O tempo disponível será contabilizado pelo <strong>relógio</strong> e você poderá
        visualizá-lo na página de responder questões do Simulado. Essa <strong>contagem oficial</strong> é a que vale,
        ok?
      </p>
      <p>
        <strong>3.</strong> O tempo total do Simulado considera o tempo de prova oficial do ENEM e mais 30 minutos
        adicionais para sua melhor organização e possíveis interrupções. No entanto, caso você esteja començando o
        Simulado próximo a hora de sua expiração, o tempo de prova será reduzido proporcionalmente.
      </p>
      <p>
        <strong>4.</strong> Você terá {SecondsToHourMins(duration)} para realizar os mini-simulados, podendo alternar
        entre eles e realizá-los na ordem que preferir. Além disso, é possível revisar as questões já respondidas antes
        de finalizar cada uma das áreas ;)
      </p>
      <p>
        <strong>5.</strong> Após responder as 45 questões de cada área,{" "}
        <strong>clique para finalizar o mini-simulado</strong>. Se der tempo, recomendamos que revise suas respostas
        antes de finalizar!
      </p>
      <p>
        <strong>6.</strong> Quando o tempo do relógio zerar, os{" "}
        <strong>mini simulados serão automaticamente finalizados!</strong>. Caso você deixe questões sem resposta, elas
        serão consideradas erradas no cálculo da sua <strong>nota ENEM</strong>, ok? Caso você não tenha respondido
        nenhuma questão de alguma das áreas, o mini simulado será EXPIRADO e você ficará sem nota nessa área. Fique
        atento ao tempo!
      </p>
      <p>
        <strong>7.</strong> Para as áreas finalizadas, o <strong>RESULTADO PARCIAL</strong> (com a nota na escala ENEM)
        ficará disponível ao final do tempo de prova. No caso da Redação, nossos especialistas tem até 10 dias úteis
        para disponibilizar sua correção e nota.
      </p>
      <p>
        <strong>8.</strong> A <strong>NOTA GERAL</strong> e o{" "}
        <strong>
          MeuSiSu só serão ativados se você terminar os dois dias de prova do Simulado 180, e apenas após a correção de
          sua Redação
        </strong>. Isso porque para estimar suas chances reais de ser aprovado, precisamos da sua nota nas 4 áreas do
        conhecimento e Redação ;)
      </p>
      <br /> <br />
      <br />
    </div>
  </div>
)

export default ExamUnpausableRulesContent
