import styled from "styled-components"
import mountain from "planets/logic/logic-mountain.svg"
import mountain2 from "planets/logic/logic-mountain2.svg"
import sky from "planets/logic/logic-sky.svg"
import { StyledHorizon, StyledHorizonContainer, StyledHorizonTitle } from "Horizon"

const PlanetHorizon = styled(StyledHorizon)`
  background: linear-gradient(180deg, #f57825 0%, #ffffff 100%);
  overflow: hidden;

  &::before {
    background: url(${mountain}) no-repeat;
    left: 50%;
    margin-left: -323px;
    bottom: -1px;
    width: 646px;
    height: 51px;

    @media (max-width: 768px) {
      left: 0;
      margin-left: 0;
    }
  }
`

const ShortPlanetHorizon = styled(PlanetHorizon)`
  background: linear-gradient(180deg, #f57825 0%, #ffffff 80%, #ffee92 100%);

  &::before {
    left: 0;
    margin-left: 0;
    bottom: 20px;

    @media (max-width: 768px) {
      left: -250px;
    }
  }

  &::after {
    content: "";
    background: url(${mountain2}) no-repeat;
    width: 150px;
    height: 114px;
    display: block;
    position: absolute;
    bottom: -45px;
    left: 5px;
    z-index: 3;
  }
`

const PlanetHorizonContainer = styled(StyledHorizonContainer)`
  &::before {
    background: url(${sky}) no-repeat;
    height: 136px;
    bottom: 110px;
  }
`

const ShortPlanetHorizonContainer = styled(PlanetHorizonContainer)`
  &::before {
    height: 90px;
    bottom: 80px;
    left: -100px;
  }
`

const PlanetHorizonTitle = styled(StyledHorizonTitle)`
  position: absolute;
  right: 100px;

  @media (max-width: 1024px) {
    right: auto;
    top: 55px;
  }
`

export { PlanetHorizon, PlanetHorizonContainer, PlanetHorizonTitle, ShortPlanetHorizon, ShortPlanetHorizonContainer }
