import React from "react"
import image from "bag.svg"
import { Header } from "Header"
import { GamificationBar } from "GamificationBar"
import { ScrollUp } from "helpers/scroll"

const Rules = () => {
  ScrollUp()

  return (
    <div className="bag-manual-about-container">
      <Header backUrl="/mochila/mural" color="colored">
        <GamificationBar />
      </Header>
      <h1 className="purple">Regras</h1>
      <img src={image} />
      <p>
        Dentro do Mindzup você encontrará um mundo completamente novo, com desafios diários que o ajudarão a desenvolver
        habilidades importantes para o seu dia-a-dia e para seu futuro.
      </p>
      <p>
        Você entrará em uma jornada pela busca do Conhecimento Perdido, em um universo repleto de seres animados,
        cenários fantasiosos, e muito, muito aprendizado!
      </p>
      <p>A regra? Fazer parte dessa aventura de conhecimento e evolução enquanto você aprende muita coisa nova.</p>
    </div>
  )
}

export default Rules
