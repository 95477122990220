import ThemingRoute from "helpers/theming-route"
import ExamAnswer from "exam/answer"
import Satellite from "exam/satellite"
import ExamLanguageChooser from "exam/language-chooser"
import ExamRules from "exam/exam-rules"
import EssayRules from "exam/essay-rules"
import EssayUpload from "exam/essay-upload"
import EssayTopic from "exam/essay-topic"
import AnswerKey from "exam/answer-key"

const Routes = [
  {
    router: ThemingRoute,
    path: "/simulados/responder",
    component: ExamAnswer
  },
  {
    router: ThemingRoute,
    path: "/simulados/idioma",
    component: ExamLanguageChooser
  },
  {
    router: ThemingRoute,
    path: "/simulados",
    component: Satellite
  },
  {
    router: ThemingRoute,
    path: "/simulados/regras",
    component: ExamRules
  },
  {
    router: ThemingRoute,
    path: "/simulados/redacao/regras",
    component: EssayRules
  },
  {
    router: ThemingRoute,
    path: "/simulados/redacao/enviar",
    component: EssayUpload
  },
  {
    router: ThemingRoute,
    path: "/simulados/redacao/tema",
    component: EssayTopic
  },
  {
    router: ThemingRoute,
    path: "/simulados/gabarito",
    component: AnswerKey
  }
]

export default Routes
