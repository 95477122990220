import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import { Layout } from "Session"
import Modal from "components/modal"
import Form from "components/form/form"
import { Submit } from "components/button"
import FormInput from "components/form/form-input"
import PaymentMethods from "components/payment-methods"
import FormSelect from "components/form/form-select"
import API from "api"
import NextStepRouter from "onboarding/next-step-router"
import { phoneInputMask, FormInputMasked } from "components/form/form-input-masked"
import { MixpanelTrack, MixpanelPeopleSet } from "tags/mixpanel"

class OnboardingOrder extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      submitDisabled: false,
      discount_code_read_only: false,
      errors: [],
      cities: [],
      fullname: "",
      email: "",
      phone: "",
      tax_document: "",
      address_street: "",
      address_street_number: "",
      address_complement: "",
      address_city_code: "",
      address_state: "",
      address_zipcode: "",
      address_district: "",
      payment_method: "",
      discount_code: ""
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleStateInputChange = this.handleStateInputChange.bind(this)
    this.isNextYear = this.props.location.pathname.includes("proximo-ano")
  }

  componentDidMount () {
    this.fetchOrder()
    this.fetchCustomer()
  }

  fetchOrder () {
    API.get(`/checkout/order${this.isNextYear ? "?next_year=true" : ""}`, response => {
      const { headers, data } = response
      this.validateStep(headers.next_step)

      const { discount_code, discount_code_read_only } = data
      this.setState({ discount_code, discount_code_read_only })
    })
  }

  validateStep (nextStep) {
    const allowedSteps = ["checkout", "checkoutPayment", "checkoutCreditcardError"]

    if (!allowedSteps.includes(nextStep)) {
      const redirectTarget = NextStepRouter(nextStep)
      this.props.dispatch(push(redirectTarget))
    }
  }

  fetchCustomer () {
    API.get("/checkout/customer", response => {
      this.setState(response.data)
      this.handleCitiesFromState(this.state.address_state)
    })
  }

  handleSubmit (event) {
    if (this.state.submitDisabled) return

    this.setState({ submitDisabled: true })

    const {
      fullname,
      email,
      phone,
      tax_document,
      address_street,
      address_street_number,
      address_complement,
      address_city_code,
      address_state,
      address_zipcode,
      address_district,
      discount_code
    } = this.state

    const { dispatch } = this.props
    const orderEndpoint = `/checkout/order${this.isNextYear ? "?next_year=true" : ""}`

    API.post(
      orderEndpoint,
      {
        customer: {
          fullname,
          email,
          phone,
          tax_document,
          address_street,
          address_street_number,
          address_complement,
          address_city_code,
          address_state,
          address_zipcode,
          address_district
        },
        discount_code: discount_code
      },
      response => {
        MixpanelPeopleSet({
          "cupom de desconto": discount_code,
          "status pagamento": response.data.paid ? "completo" : "pendente"
        })

        MixpanelTrack("Concluiu inserção dados pagador", {
          "NOME CADASTRADO": fullname,
          "E-MAIL CADASTRADO": email,
          "ENDEREÇO CADASTRADO": address_street,
          "TELEFONE CADASTRADO": phone
        })

        dispatch(push("/pedido/pagamento"))
      },
      error => {
        const errors = error.response.data["errors"]
        this.setState({ errors, submitDisabled: false })
      }
    )

    event.preventDefault()
  }

  handleInputChange (event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleStateInputChange (event) {
    this.handleInputChange(event)
    this.handleCitiesFromState(event.target.value)
  }

  handleCitiesFromState (state) {
    if (state != "") {
      API.get(`/${state}/cities`, response => {
        this.setState({ cities: response.data })
      })
    } else {
      this.setState({ cities: [] })
    }
  }

  render () {
    const { errors, submitDisabled, discount_code_read_only } = this.state
    const backUrl = `/pedido${this.isNextYear ? "/proximo-ano" : ""}`

    return (
      <Layout>
        <Modal backUrl={backUrl}>
          <h2>Pedido</h2>

          <Form onSubmit={this.handleSubmit}>
            <h3>Dados do Pagador</h3>
            <FormInput
              type="text"
              id="fullname"
              name="fullname"
              label="Nome Completo"
              value={this.state.fullname}
              onChange={this.handleInputChange}
              errors={errors}
              required
            />
            <FormInput
              type="email"
              id="email"
              name="email"
              label="E-mail"
              value={this.state.email}
              onChange={this.handleInputChange}
              errors={errors}
              required
            />
            <FormInputMasked
              type="tel"
              id="phone"
              mask={phoneInputMask}
              name="phone"
              label="Telefone"
              value={this.state.phone}
              onChange={this.handleInputChange}
              errors={errors}
              required
            />
            <FormInputMasked
              type="tel"
              id="tax_document"
              mask={[/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
              name="tax_document"
              label="CPF"
              value={this.state.tax_document}
              onChange={this.handleInputChange}
              errors={errors}
              required
            />
            <FormInputMasked
              type="text"
              id="address_zipcode"
              mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
              name="address_zipcode"
              label="CEP"
              value={this.state.address_zipcode}
              onChange={this.handleInputChange}
              errors={errors}
              required
            />
            <FormInput
              type="text"
              id="address_street"
              name="address_street"
              label="Endereço"
              value={this.state.address_street}
              onChange={this.handleInputChange}
              errors={errors}
              required
            />
            <FormInput
              type="tel"
              id="address_street_number"
              name="address_street_number"
              label="Número"
              value={this.state.address_street_number}
              onChange={this.handleInputChange}
              errors={errors}
              required
            />
            <FormInput
              type="text"
              id="address_complement"
              name="address_complement"
              label="Complemento"
              value={this.state.address_complement || ""}
              onChange={this.handleInputChange}
              errors={errors}
              required
            />
            <FormInput
              type="text"
              id="address_district"
              name="address_district"
              label="Bairro"
              value={this.state.address_district}
              onChange={this.handleInputChange}
              errors={errors}
              required
            />

            <FormSelect
              id="address_state"
              name="address_state"
              value={this.state.address_state}
              label="Estado"
              onChange={this.handleStateInputChange}
              errors={errors}
              required
            >
              <option value="" />
              <option value="AL">Alagoas</option>
              <option value="AP">Amapá</option>
              <option value="AM">Amazonas</option>
              <option value="BA">Bahia </option>
              <option value="CE">Ceará</option>
              <option value="DF">Distrito Federal </option>
              <option value="ES">Espírito Santo</option>
              <option value="GO">Goiás</option>
              <option value="MA">Maranhão</option>
              <option value="MT">Mato Grosso</option>
              <option value="MS">Mato Grosso do Sul</option>
              <option value="MG">Minas Gerais</option>
              <option value="PA">Pará</option>
              <option value="PB">Paraíba</option>
              <option value="PR">Paraná</option>
              <option value="PE">Pernambuco</option>
              <option value="PI">Piauí</option>
              <option value="RJ">Rio de Janeiro</option>
              <option value="RN">Rio Grande do Norte</option>
              <option value="RS">Rio Grande do Sul</option>
              <option value="RO">Rondônia</option>
              <option value="RR">Roraima</option>
              <option value="SC">Santa Catarina</option>
              <option value="SP">São Paulo</option>
              <option value="SE">Sergipe</option>
              <option value="TO">Tocantins</option>
            </FormSelect>

            <FormSelect
              id="address_city_code"
              name="address_city_code"
              label="Cidade"
              onChange={this.handleInputChange}
              value={this.state.address_city_code}
              errors={errors}
              required
            >
              <option> </option>
              {this.state.cities.map(city => (
                <option value={city.code} key={city.name}>
                  {city.name}
                </option>
              ))}
            </FormSelect>

            <FormInputMasked
              mask={[/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
              type="text"
              id="discount_code"
              name="discount_code"
              label="Cupom de Desconto"
              value={this.state.discount_code}
              onChange={this.handleInputChange}
              errors={errors}
              readOnly={discount_code_read_only}
            />

            <Submit value="Seguir" disabled={submitDisabled} arrow />
          </Form>

          <PaymentMethods />
        </Modal>
      </Layout>
    )
  }
}

export default connect()(OnboardingOrder)
