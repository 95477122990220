import React from "react"
import parseDate from "date-fns/parseISO"
import formatDate from "date-fns/format"
import EssayTheme from "components/exams/essay-theme"

const EssayUnstartableCard = ({ essayTitle, theme, availableAt }) => (
  <div className="exam-card">
    <div className="exam-card-title">{essayTitle}</div>

    <EssayTheme theme={theme} />

    <div className="not-started-exam-card-release-date">
      Liberação: {formatDate(parseDate(availableAt), "dd/MM/yyyy 'às' HH'h'mm")}
    </div>
  </div>
)

export default EssayUnstartableCard
