import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import UserAvatar from "components/user-avatar"
import { MAX_FILE_SIZE } from "helpers/file-uploader"

const InvalidFileModal = ({ opened, onClose }) => (
  <CloseableModal opened={opened} onClose={onClose} small overlay>
    <h2 className="light honey-flower">Imagem não permitida</h2>
    <p>A imagem que você está tentando enviar está fora dos padrões permitidos.</p>
    <p>Sua foto precisa ter tamanho máximo {MAX_FILE_SIZE}MB e ser do tipo JPEG ou PNG, ok?! ;)</p>
    <UserAvatar kind="sad" />
    <strong>Dúvidas?</strong> Fale com a gente no chat ou{" "}
    <a
      href="https://ajuda.mindlab.com.br/hc/pt-br/requests/new"
      className="modal-link"
      target="_blank"
      rel="noopener noreferrer"
    >
      clique aqui!
    </a>
  </CloseableModal>
)

export default InvalidFileModal
