import React from "react"
import {
  GamificationXp,
  GamificationRuby,
  GamificationIconXp,
  GamificationIconRuby,
  UserNameBadges,
  BadgesTitle,
  UserBadgesSeparator,
  UserBadgesColumn,
  GamificationBadges,
  UserColumn,
  UserAvatarBadges
} from "trail/users/styled"
import { UserAvatarTrail } from "trail/users"
import { BadgesColumns } from "trail/users/badges"

const UserBadges = ({ user }) => (
  <div>
    <UserColumn>
      <UserNameBadges>{user.name}</UserNameBadges>
      <UserAvatarBadges>
        <UserAvatarTrail avatar={user.avatar} style={{ marginTop: "20px" }} />
      </UserAvatarBadges>
      <GamificationBadges>
        <GamificationXp>
          <GamificationIconXp />
          {user.experience_total} xp
        </GamificationXp>
        <GamificationRuby>
          <GamificationIconRuby />
          {user.rubies}
        </GamificationRuby>
      </GamificationBadges>
    </UserColumn>
    <UserBadgesSeparator />
    <UserBadgesColumn>
      <BadgesTitle>Badges Conquistadas</BadgesTitle>
      <BadgesColumns badges={user.badges} schoolYear={user.school_year} />
    </UserBadgesColumn>
  </div>
)

export default UserBadges
