import React from "react"
import { Link } from "react-router-dom"
import { push } from "connected-react-router"
import parseDate from "date-fns/parseISO"
import formatDate from "date-fns/format"
import isBefore from "date-fns/isBefore"
import { connect } from "react-redux"
import { BtnLight } from "components/button"
import { Layout } from "Session"
import Modal from "components/modal"
import Copy from "lib/copy-text-to-clipboard"
import NextStepRouter from "onboarding/next-step-router"
import { MixpanelTrack } from "tags/mixpanel"
import API from "api"

class OnboardingPaymentBoleto extends React.Component {
  constructor (props) {
    super(props)

    this.state = this.initialState()
    this.handleCopy = this.handleCopy.bind(this)
    this.openExternalBoleto = this.openExternalBoleto.bind(this)
    this.generateNewBoleto = this.generateNewBoleto.bind(this)
  }

  initialState () {
    return { lineCode: "...", expirationDate: "...", printHref: "...", totalValue: "...", processing: true }
  }

  handleCopy () {
    Copy(this.state.lineCode)
  }

  componentDidMount () {
    const { dispatch } = this.props

    API.get(
      "/checkout/payment",
      response => {
        const { data, headers } = response

        if (headers.next_step !== "checkoutBoleto") {
          const redirectTarget = NextStepRouter(headers.next_step)
          dispatch(push(redirectTarget))
        } else {
          const {
            boleto_line_code: lineCode,
            boleto_expiration_date: expirationDate,
            boleto_print_href: printHref,
            total_value: totalValue
          } = data

          this.setState({ lineCode, expirationDate, printHref, totalValue, processing: false })
        }
      },
      () => {
        dispatch(push("/pedido/compra"))
      }
    )

    MixpanelTrack("Visualizou o boleto de pagamento")
  }

  openExternalBoleto () {
    window.open(this.state.printHref, "boleto")
  }

  generateNewBoleto (event) {
    const { dispatch, requestInProgress } = this.props

    if (requestInProgress) return

    this.setState(this.initialState())

    API.post(
      "/checkout/payment",
      { new_boleto: true },
      response => {
        const { data } = response

        const {
          boleto_line_code: lineCode,
          boleto_expiration_date: expirationDate,
          boleto_print_href: printHref,
          total_value: totalValue
        } = data

        this.setState({ lineCode, expirationDate, printHref, totalValue, processing: false })
      },
      () => {
        dispatch(push("/pedido/compra"))
      }
    )

    event.preventDefault()
  }

  formatBrazilianDate (date) {
    const parsedDate = parseDate(date)

    if (parsedDate == "Invalid Date") {
      return date
    } else {
      return formatDate(parsedDate, "dd/MM/yyyy")
    }
  }

  render () {
    const { requestInProgress } = this.props
    const { lineCode, expirationDate, totalValue, processing } = this.state
    const expired = isBefore(parseDate(expirationDate), new Date())

    return (
      <Layout>
        <Modal>
          <h2>Boleto Gerado</h2>

          <p>Seu boleto foi gerado com sucesso e uma cópia foi enviada para seu email!</p>

          <p>
            Para concretizar a compra e liberar seu acesso, você deverá realizar o pagamento até o dia{" "}
            {this.formatBrazilianDate(expirationDate)}.
          </p>

          <br />
          <br />

          <h3 className="purple">
            Valor do boleto: <b>{totalValue}</b>
          </h3>

          {!processing && !expired && (
            <div>
              <div className="line-code-container">
                <p>{lineCode}</p>

                <Link to="#" onClick={this.handleCopy} className="pink modal-link">
                  Copiar código
                </Link>
              </div>

              <BtnLight to="#" onClick={this.openExternalBoleto} value="Visualizar o boleto" />
            </div>
          )}

          {expired && (
            <div>
              <br />
              <br />
              <p>
                Seu boleto está <strong>vencido</strong>, clique no botão abaixo para gerar um novo.
              </p>
              <BtnLight
                to="#"
                value="Gerar novo boleto"
                disabled={requestInProgress}
                onClick={this.generateNewBoleto}
              />
            </div>
          )}

          <p className="small">
            Após a realização do pagamento, o prazo padrão para
            <br />
            compensação de um boleto é de até 4 dias úteis.
          </p>
        </Modal>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    requestInProgress: state.http.requestInProgress
  }
}

export default connect(mapStateToProps)(OnboardingPaymentBoleto)
