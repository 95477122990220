import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import UserAvatar from "components/user-avatar"

const EssayUploadFailureModal = ({ opened, onClose }) => (
  <CloseableModal opened={opened} onClose={onClose} small overlay>
    <h2 className="light honey-flower">Ops! Falha no envio.</h2>
    <p>Encontramos um problema ao enviar a sua redação para nossos corretores.</p>
    <p>Por favor, tente novamente dentro de alguns minutos ;)</p>
    <UserAvatar kind="sad" />
    <strong>Dúvidas?</strong> Fale com a gente no chat ou{" "}
    <a
      href="https://ajuda.mindlab.com.br/hc/pt-br/requests/new"
      className="modal-link"
      target="_blank"
      rel="noopener noreferrer"
    >
      clique aqui!
    </a>
  </CloseableModal>
)

export default EssayUploadFailureModal
