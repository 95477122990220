import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import ClockCountdown from "components/clock-countdown"
import UserAvatar from "components/user-avatar"

const BlockedGalaxyModal = ({ opened, onClose, unblockTime }) => (
  <CloseableModal opened={opened} onClose={onClose}>
    <h2 className="modal-title">Galáxia Bloqueada</h2>
    <p className="medium">Ooops! Essa galáxia ainda está bloqueada, ela só será liberada em:</p>
    <ClockCountdown until={unblockTime} />
    <UserAvatar kind="sad" />
  </CloseableModal>
)

export default BlockedGalaxyModal
