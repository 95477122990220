import React from "react"
import parseDate from "date-fns/parseISO"
import formatDate from "date-fns/format"
import { SecondsToHourMins } from "helpers/time-formatter"

const SocioEmotionalExamUnstartableCard = ({ name, total_questions, duration, availableAt }) => (
  <div className="exam-card">
    <div className="exam-card-title">{name}</div>

    <div className="not-started-exam-card-info">
      <i className="checkbox-icon" />
      <span>{`Avaliação em ${total_questions} etapas`}</span>
    </div>

    <div className="not-started-exam-card-info">
      <i className="clock-icon white-transparent small" />
      <span>{`Estimativa de ${SecondsToHourMins(duration)}`}</span>
    </div>

    <div className="not-started-exam-card-release-date">
      Liberação: {formatDate(parseDate(availableAt), "dd/MM/yyyy 'às' HH'h'mm")}
    </div>
  </div>
)

export default SocioEmotionalExamUnstartableCard
