export default [
  {
    title: "",
    description: "",
    items: [
      {
        text: "Jogo para 2 a 4 jogadores",
      },
      {
        text: "Em cada rodada um jogador pode fazer uma das duas ações:",
        items: [
          {
            text: "Virar uma nova carta no centro e pegar para ele mesmo ou deixá-la e não pegar nenhuma carta.",
          },
          {
            text: "Pegar para si uma das cartas que já estão viradas para cima, no centro.",
          }
        ]
      },
      {
        text: "Ao pegar uma nova carta do centro, o jogador deve sempre devolver uma de suas cartas. Cada jogador deve SEMPRE permanecer com quatro cartas.",
      },
      {
        text: "O jogo termina quando um dos jogadores conseguir reunir para si quatro cartas da mesma família de mindster.",
      }
    ]
  }
]