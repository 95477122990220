import { push } from "connected-react-router"
import NextStepRouter from "onboarding/next-step-router"
import API from "api"

const validNextSteps = ["avatarRegistration", "checkoutCreditcardWaiting", "checkoutCompleted"]

export const validateAvatarAccess = dispatch => {
  API.get("/sessions", response => {
    const { next_step: nextStep } = response.headers

    if (!validNextSteps.includes(nextStep)) {
      const redirectTarget = NextStepRouter(nextStep)
      dispatch(push(redirectTarget))
    }
  })
}
