import React from "react"
import styled from "styled-components"
import Planet from "Galaxy/wrappers/Planet"
import PlanetImage from "Galaxy/wrappers/PlanetImage"
import axion from "galaxies/orange-galaxy/axion.svg"

const Axion = props => (
  <Planet {...props}>
    <PlanetImage src={axion} blocked={props.blocked} />
  </Planet>
)

export default styled(Axion)``
