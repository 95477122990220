import React from "react"
import styled from "styled-components"
import { Header } from "Header"
import { GamificationBar } from "GamificationBar"
import { Horizon } from "Horizon"

const GameStationContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
`

const isGameFinishedOrRoundCompleted = (gameData) => {
  return gameData.finished === true || gameData.numberRounds > 1 
}

const isLocal = () => {
  return window.location.href.includes("localhost")
}

const SingleGameLayout = ({ children, backUrl, backLabel, title }) => {

  const [compiledBackUrl, setCompiledBackUrl] = React.useState(backUrl)

  React.useEffect(() => {
    let gameId = window.location.href.split("/")
    gameId = gameId[gameId.length - 1]

    const checkGames = setInterval(() => {
      const saveUrl = "/matches/callback/singleplayer?hash="+btoa(gameId)
      const save = () => setCompiledBackUrl(saveUrl) 
      if (isLocal()) {
        save()
      } else {
        try {
          const gameData = JSON.parse(localStorage.getItem(gameId))
          if (isGameFinishedOrRoundCompleted(gameData)) {
            save()
          }
        } catch (e) {
          console.log("invalid game save...")
        }
      }
    }, 500)

    return () => {
      clearInterval(checkGames)
    }
  }, [])

  return (
    <GameStationContainer>
      <Header backUrl={compiledBackUrl} backLabel={backLabel}>
        <GamificationBar />
      </Header>
      <Horizon title={title} smaller fixed />

      {children}
    </GameStationContainer>
  )
}

export default SingleGameLayout
