import floor from "planets/logic/logic-checkpoint.svg"
import smallMountain from "planets/logic/logic-small-mountain.svg"

export default {
  mainColor: "#8cffad",
  secondaryColor: "#ffffff",
  floor: {
    image: floor,
    height: 215
  },
  stone: {
    image: smallMountain,
    width: 58,
    height: 119,
    left: 40
  }
}
