import React from "react"
import { Route, Redirect } from "react-router"
import { isAuthenticated } from "auth"

const AuthenticatedRoute = ({ component: Component, componentProps, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...Object.assign({}, componentProps, props)} />
      ) : (
        <Redirect
          to={{
            pathname: "/entrar",
            state: { from: props.location }
          }}
        />
      )
    }
  />
)

export default AuthenticatedRoute
