import React from "react"
import { Link } from "react-router-dom"

const BtnLight = ({
  to,
  value,
  arrow,
  arrowDown,
  hourglass,
  disabled,
  small,
  color = "",
  ...rest
}) => (
  <Link
    to={to}
    disabled={disabled}
    tabIndex={disabled ? "-1" : null}
    className={`btn btn-light ${color} ${small ? "small" : ""}`}
    {...rest}
  >
    {value}

    {arrowDown && <i className="arrow-icon arrow-icon-down" />}
    {arrow && <i className="arrow-icon arrow-icon-right" />}
    {hourglass && <i className="hourglass-icon" />}
  </Link>
)

export default BtnLight
