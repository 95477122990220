import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import { Link } from "react-router-dom"
import parseDate from "date-fns/parseISO"
import formatDate from "date-fns/format"
import { BtnLight } from "components/button"
import EssayTheme from "components/exams/essay-theme"
import { redirectToEvaluation, setCurrentEssay } from "exam/essay-actions"

const EssayResult = ({ essaySessionId, grade, competences }) => (
  <div>
    {competences.map((competence, index) => (
      <div className="exam-card-subitem exam-card-subitem-top" key={index}>
        <div>{competence.name}</div>
        <div>
          Nota: <strong>{competence.grade}</strong>
        </div>
      </div>
    ))}

    <div className="exam-card-aggregated-grade">
      Nota Geral: <strong>{grade}</strong>
    </div>

    <Link to="#" className="exam-card-link" onClick={() => redirectToEvaluation(essaySessionId)}>
      Ver correção
    </Link>
  </div>
)

class EssayStartableCard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showDetails: false
    }

    this.toggleDetails = this.toggleDetails.bind(this)
    this.handleStart = this.handleStart.bind(this)
    this.handleContinue = this.handleContinue.bind(this)
  }

  toggleDetails () {
    this.setState({ showDetails: !this.state.showDetails })
  }

  handleStart (e) {
    this.setCurrentEssayAndPush("/simulados/redacao/regras")

    e.preventDefault()
  }

  handleContinue (e) {
    this.setCurrentEssayAndPush("/simulados/redacao/tema")

    e.preventDefault()
  }

  setCurrentEssayAndPush (pushTarget) {
    const { dispatch, theme, themeImage, essayTitle, essaySessionId, essayId } = this.props

    dispatch(setCurrentEssay({ essayTitle, theme, themeImage, essaySessionId, essayId }))

    dispatch(push(pushTarget))
  }

  essayBtnProps () {
    const { showDetails } = this.state
    const { evaluated, inCorrection, started } = this.props

    switch (true) {
      case evaluated:
        return { value: "Resultado", arrow: !showDetails, arrowDown: showDetails, onClick: this.toggleDetails }
      case inCorrection:
        return { value: "Em correção", hourglass: true }
      case started:
        return { value: "Continuar", arrow: true, onClick: this.handleContinue }
      default:
        return { value: "Começar", arrow: true, onClick: this.handleStart }
    }
  }

  render () {
    const { showDetails } = this.state
    const {
      essayTitle,
      essaySessionId,
      theme,
      expiresAt,
      completed,
      evaluated,
      expired,
      grade,
      competences
    } = this.props

    return (
      <div className="exam-card">
        <div className="exam-card-title">{essayTitle}</div>

        <EssayTheme theme={theme} />

        {expired && !completed ? (
          <div className="expiration-start-container expired">
            <BtnLight to="#" color="white" small value="Expirado" />
          </div>
        ) : (
          <div className="expiration-start-container">
            {evaluated ? (
              <div className="essay-evaluated">
                <i className="evaluated-icon" /> Corrigido
              </div>
            ) : (
              <div>
                Expira em <strong>{formatDate(parseDate(expiresAt), "dd/MM/yyyy 'às' HH'h'mm")}</strong>
              </div>
            )}

            <BtnLight to="#" color="white" small {...this.essayBtnProps()} />
          </div>
        )}

        {showDetails && <EssayResult essaySessionId={essaySessionId} grade={grade} competences={competences} />}
      </div>
    )
  }
}

export default connect()(EssayStartableCard)
