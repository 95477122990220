import {
  SET_CURRENT_ESSAY,
  SET_CURRENT_ESSAY_ID,
  CLEAR_CURRENT_ESSAY,
  SET_ESSAY_SESSION,
  CLEAR_ESSAY_SESSION
} from "exam/essay-actions"

const currentEssayInitialState = {
  theme: "",
  themeImage: "",
  essayTitle: "",
  essaySessionId: null,
  essayId: null
}

const currentEssayReducer = (state = currentEssayInitialState, action) => {
  switch (action.type) {
    case SET_CURRENT_ESSAY:
      return Object.assign({}, state, {
        theme: action.essay.theme,
        themeImage: action.essay.themeImage,
        essayTitle: action.essay.essayTitle,
        essaySessionId: action.essay.essaySessionId,
        essayId: action.essay.essayId
      })
    case SET_CURRENT_ESSAY_ID:
      return Object.assign({}, state, {
        essaySessionId: action.essay.essaySessionId
      })
    case CLEAR_CURRENT_ESSAY:
      return Object.assign({}, state, currentEssayInitialState)
    default:
      return state
  }
}

const essaySessionInitialState = {
  pausable: null,
  examTimerId: null
}

const essaySessionReducer = (state = essaySessionInitialState, action) => {
  switch (action.type) {
    case SET_ESSAY_SESSION:
      return Object.assign({}, state, {
        pausable: action.pausable,
        examTimerId: action.examTimerId
      })
    case CLEAR_ESSAY_SESSION:
      return Object.assign({}, state, essaySessionInitialState)
    default:
      return state
  }
}

export { currentEssayReducer, essaySessionReducer }
