import React from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { Header } from "Header"
import { GamificationBar } from "GamificationBar"
import Form from "components/form/form"
import FormInput from "components/form/form-input"
import FormPassword from "components/form/form-password"
import FormSelect from "components/form/form-select"
import { Submit } from "components/button"
import { ScrollUp } from "helpers/scroll"
import UserAvatar from "components/user-avatar"
import { updateCurrentUser } from "actions"
import API from "api"
import { MixpanelTrack, MixpanelPeopleSet } from "tags/mixpanel"
import { BtnPrimary } from "components/button"
import { AvatarHeader, AvatarHeaderTitle } from "backpack/avatar-header"

const PersonInfo = ({ title, value }) => (
  <div className="user-info">
    <p className="pink">
      <strong>{title}</strong>
    </p>
    <p className="small">{value}</p>
  </div>
)

let UserInfo = ({ username, email, schoolName, schoolYear, schoolClass }) => (
  <div className="user-profile-info-container">
    <PersonInfo title="Nome de usuário" value={username} />
    {email && <PersonInfo title="E-mail" value={email} />}
    <PersonInfo title="Escola" value={schoolName} />
    <PersonInfo title="Ano / Turma" value={`${schoolYear} | ${schoolClass}`} />
  </div>
)

const mapStateToUserInfoProps = state => {
  return {
    username: state.auth.currentUser.username,
    email: state.auth.currentUser.email,
    schoolName: state.auth.currentUser.school_name,
    schoolYear: state.auth.currentUser.school_year,
    schoolClass: state.auth.currentUser.school_class
  }
}

UserInfo = connect(mapStateToUserInfoProps)(UserInfo)

let RelativeInfo = ({ name, email, phoneNumber, relationship }) => (
  <div className="user-profile-info-container">
    <h3>Dados de responsáveis</h3>
    <span>Responsável #1</span>
    <PersonInfo title="Nome completo" value={name} />
    <PersonInfo title="E-mail" value={email} />
    <PersonInfo title="Telefone" value={phoneNumber} />
    <PersonInfo title="Grau de Parentesco" value={relationship} />
  </div>
)

const mapStateToRelativeInfoProps = state => {
  return {
    name: state.auth.currentUser.relative.name,
    email: state.auth.currentUser.relative.email,
    phoneNumber: state.auth.currentUser.relative.phone_number,
    relationship: state.auth.currentUser.relative.relationship
  }
}

RelativeInfo = connect(mapStateToRelativeInfoProps)(RelativeInfo)

class EditProfile extends React.Component {
  constructor (props) {
    super(props)
    const { name, username, school_class } = props.currentUser

    this.state = {
      name: name,
      username: username,
      school_class: school_class,
      schoolClasses: [],
      editPassword: false,
      showSuccessMessage: false,
      waiting: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleEditPassword = this.handleEditPassword.bind(this)
  }

  componentDidMount () {
    ScrollUp()

    API.get("/school_classes", response => {
      this.setState({ schoolClasses: response.data })
    })
  }

  handleChange (event) {
    this.setState({
      [event.target.name]: event.target.value,
      showSuccessMessage: false
    })
  }

  handleEditPassword (event) {
    this.setState({ editPassword: true })
    event.preventDefault()
  }

  handleSubmit (event) {
    const { name, username, school_class, password, password_confirmation } = this.state
    const { dispatch } = this.props
    this.setState({ waiting: true })

    API.patch(
      "/profile",
      { name, username, school_class, password, password_confirmation },
      response => {
        MixpanelPeopleSet({
          $name: name,
          turma: school_class,
          "nome do usuario": username
        })

        MixpanelTrack("Alterou dados do perfil", {
          NOME: name,
          TURMA: school_class,
          "NOME DO USUARIO": username
        })

        const { user, relative } = response.data
        dispatch(updateCurrentUser(user, relative))
        
        this.setState({
          errors: [],
          editPassword: false,
          showSuccessMessage: true,
          waiting: false
        })
      },
      error => {
        const errors = error.response.data["errors"]
        this.setState({ errors, waiting: false })
      }
    )

    event.preventDefault()
  }

  render () {
    const {
      name,
      username,
      school_class,
      schoolClasses,
      errors,
      editPassword,
      showSuccessMessage,
      waiting
    } = this.state

    return (
      <div>
        <Header backUrl="/mochila" color="colored">
          <GamificationBar />
        </Header>

        <AvatarHeader>
          <AvatarHeaderTitle>{name}</AvatarHeaderTitle>
          <UserAvatar />
        </AvatarHeader>

        <div className="change-avatar-btn">
          <BtnPrimary to="/mochila/editar-perfil/avatar" value="Trocar Avatar" />
        </div>

        <UserInfo />

        <div className="change-info-form">
          <h3>Dados Cadastrais</h3>

          <Form onSubmit={this.handleSubmit}>
            <FormInput
              maxLength="255"
              type="text"
              id="name"
              name="name"
              label="Nome completo"
              value={name}
              errors={errors}
              onChange={this.handleChange}
              required
            />

            <FormInput
              type="text"
              id="username"
              name="username"
              label="Nome de Usuário"
              value={username}
              errors={errors}
              onChange={this.handleChange}
              required
            />

            <FormSelect
              id="school_class"
              name="school_class"
              label="Turma"
              onChange={this.handleChange}
              value={school_class}
              errors={errors}
              required
            >
              {schoolClasses.map(schoolClass => (
                <option value={schoolClass} key={schoolClass}>
                  {schoolClass}
                </option>
              ))}
            </FormSelect>

            {!editPassword && (
              <FormInput
                id="current_password"
                name="current_password"
                type="password"
                label="Senha"
                value="********"
                readOnly
              >
                <Link to="#" className="form-input-link" onClick={this.handleEditPassword}>
                  Alterar
                </Link>
              </FormInput>
            )}

            {editPassword && (
              <div>
                <FormPassword
                  id="password"
                  name="password"
                  errors={errors}
                  label="Nova senha (de 6 a 8 digitos)"
                  onChange={this.handleChange}
                  required
                />

                <FormPassword
                  id="password_confirmation"
                  name="password_confirmation"
                  errors={errors}
                  label="Confirme a nova senha"
                  onChange={this.handleChange}
                  required
                />
              </div>
            )}

            <Submit value="Salvar" waiting={waiting} />
            {showSuccessMessage && <p className="edit-profile-success">Suas informações foram atualizadas ;)</p>}
          </Form>
        </div>

        <RelativeInfo />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  }
}

export default connect(mapStateToProps)(EditProfile)
