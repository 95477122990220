import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import UserAvatar from "components/user-avatar"
import UserMessage from "components/layouts/user-message"
import Processing from "components/processing"
import { findGameByUrl, MAKER_TYPES } from "GamesStation/details"
import { BtnLight } from "components/button"
import { connectToChannel } from "socket-connect"
import { MixpanelTrack } from "tags/mixpanel"

class SearchingOpponent extends React.Component {
  constructor(props) {
    super(props)
    this.game = findGameByUrl(this.props.computedMatch.params.name)
  }

  componentDidMount() {

    const { title, url, maker } = this.game

    if (maker === MAKER_TYPES.MARKETJS) {
      this.props.dispatch(push(`/jogos/singlegame/${url}`, { title, gameId: url }))
    }
    this.subscribeChannel()
  }

  componentWillUnmount() {
    this.unsubscribeChannel()
  }

  subscribeChannel() {
    MixpanelTrack("Procurou Oponente")

    this.channel = connectToChannel(`gaming:${this.game.id}`)

    if (!this.channel) return

    this.channel.join()

    this.channel.on("match_ready", msg => {
      window.location.href = msg.url
    })

    this.channel.on("queue_expired", () => {
      MixpanelTrack("Não encontrou oponente")
      this.props.dispatch(push("/jogos/sem-oponente"))
    })
  }

  unsubscribeChannel() {
    if (typeof this.channel !== "undefined") {
      this.channel.leave()
    }
  }

  render() {
    const { title, mode } = this.game

    if (!mode.findsPlayer) {
      <Processing />
    }

    return (
      <UserMessage backUrl="/jogos/estacao">
        <p className="title-small">Procurando oponente para jogar</p>
        <h2 className="caramel light">{title}</h2>

        <UserAvatar />

        <p>
          Estamos procurando alguém para jogar com você... <br />
          Espere um pouquinho nessa tela que já já sua partida vai começar!
        </p>

        <BtnLight
          to="/jogos/estacao"
          value="Cancelar Busca"
          onClick={() => MixpanelTrack("Cancelou procura de oponente")}
        />
      </UserMessage>
    )
  }
}

export default connect()(SearchingOpponent)
