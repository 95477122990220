import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import { Vigo } from "components/avatar"

const BlockedSatelliteModal = ({ opened, onClose }) => (
  <CloseableModal opened={opened} onClose={onClose} small>
    <h2 className="light">Estação de Avaliações fechada!</h2>
    <p>Suas avaliações ainda não foram liberadas.</p>
    <p>Enquanto terminamos de limpar as naves por aqui, resgate todo o conhecimento que puder conquistando as Missões Diárias.</p>
    <Vigo />
  </CloseableModal>
)

export default BlockedSatelliteModal
