import React from "react"
import { Link } from "react-router-dom"
import CloseableModal from "components/modals/closeable-modal"
import { Header } from "Header"
import { GamificationBar } from "GamificationBar"
import { BtnPrimary } from "components/button"
import finalized from "finalized.svg"

const ExamFinalizedModal = ({ opened, knowledgeArea, onFinalize, onDismiss }) => (
  <CloseableModal opened={opened} closeButton={false} fullscreen color="honey-flower">
    <Header color="colored">
      <GamificationBar />
    </Header>

    <h2>Bom trabalho!</h2>

    <div className="exam-finalized-content">
      <img src={finalized} />

      <p>
        Você acaba de responder todas as questões de {knowledgeArea}! Você pode revisar as questões já respondidas ou
        finalizar a prova, clicando no botão <strong className="pink">{"\"FINALIZAR\""}</strong>.
      </p>
    </div>

    <BtnPrimary to="#" value="Finalizar" onClick={onFinalize} check />
    <Link to="#" className="exam-finalized-link" onClick={onDismiss}>
      Revisar minhas respostas
    </Link>
  </CloseableModal>
)

export default ExamFinalizedModal
