import API from "api"

class Service {
  static getDailyMissionQuestions (successCallback, failureCallback) {
    return API.post("/daily_mission_questions", {}, successCallback, failureCallback)
  }

  static startMiniexamSession (miniexamId, successCallback, failureCallback) {
    return API.post("/question_set_sessions", { miniexam_id: miniexamId }, successCallback, failureCallback)
  }

  static startLanguageMiniexamSession (miniexamId, language, successCallback, failureCallback) {
    return API.post(
      "/question_set_sessions",
      { miniexam_id: miniexamId, language: language },
      successCallback,
      failureCallback
    )
  }
}

export default Service
