import React from "react"
import { connect } from "react-redux"
import CloseableModal from "components/modals/closeable-modal"
import { Vigo } from "components/avatar"

const NewGalaxyAvailableModal = ({ opened, onClose, currentUser }) => (
  <CloseableModal small opened={opened} onClose={onClose}>
    <h2 className="light">Galáxia Conquistada!</h2>

    <p>Olá, {currentUser.name}!</p>
    <p>Você é realmente um Conquistador de Conhecimento. Estou impressionado!</p>
    <p>Agora que você já recolheu todo o conhecimento dessa Galáxia, temos uma nova e desafiadora jornada.</p>
    <p>Vá para a próxima Galáxia e continue a resgatar nosso conhecimento.</p>

    <h2 className="modal-message light">Boa sorte!</h2>

    <Vigo />
  </CloseableModal>
)

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  }
}

export default connect(mapStateToProps)(NewGalaxyAvailableModal)
