import React from "react"
import { connect } from "react-redux"
import { Layout } from "Session"
import { BtnPrimary } from "components/button"
import Modal from "components/modal"
import { confirmCheckout } from "actions/checkout"
import { endOfCurrentYear } from "helpers/time-formatter"

const OnboardingOrderSuccessCurrentYear = ({ currentUser, dispatch, requestInProgress }) => (
  <Layout>
    <Modal color="purple">
      <h2>Pagamento realizado com sucesso!</h2>

      <p>Sua compra foi efetuada com sucesso e você já pode dar continuidade à sua experiência.</p>
      <p>Lembre-se: Seu acesso é valido até dia {endOfCurrentYear()}.</p>
      <p>Para avançar, basta clicar no botão abaixo ;)</p>

      <BtnPrimary
        onClick={() => confirmCheckout(currentUser, dispatch)}
        to="#"
        value="Continuar"
        disabled={requestInProgress}
        arrow
      />

      <div className="modal-link">
        Dúvidas?{" "}
        <a href="https://ajuda.mindlab.com.br/hc/pt-br/requests/new" target="_blank" rel="noopener noreferrer">
          Fale com a gente no chat
        </a>
      </div>
    </Modal>
  </Layout>
)

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    requestInProgress: state.http.requestInProgress
  }
}

export default connect(mapStateToProps)(OnboardingOrderSuccessCurrentYear)
