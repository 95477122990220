import React from "react"
import styled from "styled-components"
import PercentageBar from "components/PercentageBar"

const Container = styled.div`
  margin: 20px 0;
`

const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
`

const Progress = styled.div`
  width: 30%;
  margin: 0 auto;
  text-align: left;
`

const Info = styled.div`
  font-size: 12px;
  margin: 5px 0 20px 5px;
  color: #8e3fac;
`

const BadgeProgress = ({ progress }) => (
  <Container>
    <Title>{progress.type}</Title>

    <Progress>
      <PercentageBar complete={progress.percentage} />
      <Info>
        <strong>Atualmente você tem:</strong> {progress.current}
      </Info>
    </Progress>
  </Container>
)

export default BadgeProgress
