import React from "react"
import { Redirect } from "react-router"
import { Layout } from "Session"
import { Submit } from "components/button"
import Modal from "components/modal"
import Form from "components/form/form"
import FormInput from "components/form/form-input"
import API from "api"
import { MixpanelTrack } from "tags/mixpanel"

class AccountPasswordNew extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      email: "",
      errors: [],
      fireRedirect: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount () {
    MixpanelTrack("Acessou Esqueci Senha")
  }

  handleChange (event) {
    this.setState({ email: event.target.value })
  }

  handleSubmit (event) {
    const { email } = this.state

    API.post(
      "/passwords",
      { email },
      () => {
        this.setState({ fireRedirect: true })
      },
      error => {
        const errors = error.response.data["errors"]
        this.setState({ errors })
      }
    )

    event.preventDefault()
  }

  render () {
    const { fireRedirect, errors } = this.state

    return (
      <Layout>
        <Modal backUrl="/entrar">
          <div className="modal-title">
            <h2>Esqueci minha senha</h2>
            <p>Envie pra gente seu e-mail, ou o e-mail de seu responsável, que te ajudamos a recadastrar sua senha.</p>
          </div>

          <Form onSubmit={this.handleSubmit}>
            <FormInput
              type="email"
              id="email"
              name="email"
              label="E-mail (seu ou de seu responsável)"
              errors={errors}
              onChange={this.handleChange}
              required
            />

            <Submit value="Enviar" />
          </Form>

          {fireRedirect && <Redirect to="/esqueci-minha-senha/email-enviado" />}
        </Modal>
      </Layout>
    )
  }
}

export default AccountPasswordNew
