export default [
  {
    title: "",
    description: "",
    items: [
      {
        text: "Jogo para 2 jogadores.",
      },
      {
        text: "Cada jogador começa com 4 sementes em cada cova. A cova maior à direita é seu depósito (Kalaha) e a cova maior da esquerda é o depósito de seu oponente.",
      },
      {
        text: "A cada jogada, você tira todas as sementes de uma cova sua e as distribui, uma a uma, no sentido anti-horário (em direção ao seu depósito), nas covas seguintes.",
      },
      {
        text: "Quando passar pela sua Kalaha, deposita uma semente e continua distribuindo as sementes restantes em cada uma das covas de seu oponente, mas não na Kalaha dele.",
      },
      {
        text: "Toda vez que a última semente for depositada na Kalaha do próprio jogador, joga-se novamente.",
      },
      {
        text: "Toda vez que a última semente for depositada em uma cova vazia do seu próprio lado e houver sementes do oponente na cova oposta, o jogador captura todas estas sementes do oponente e as transfere para o seu depósito, junto com a única semente depositada na cova vazia.",
        items: [
          {
            text: "OBSERVAÇÃO: se sua semente cair em uma cova vazia sua e do lado oposto não houver peças do oponente, a captura deve ser apenas da sua própria peça, levando ao depósito (kalaha).",
          }
        ]
      },
      {
        text: "Quando um dos jogadores não tiver mais nenhuma semente em suas covas, deverá aguardar seu oponente terminar as sementes.",
      },
      {
        text: "O vencedor da partida será quem tiver a maior quantidade de sementes na kalaha.",
      }
    ]
  }
]