import React from "react"
import FormInputFile from "components/form/form-input-file"
import EssayPrintPage from "components/exams/essay-print-page"

const EssayUploadInstructions = ({ essayTitle, onChange }) => (
  <div className="essay-upload-instructions">
    <h3 className="dark-gray">{essayTitle}</h3>
    <h3 className="big purple light">Como enviar sua redação?</h3>
    <hr className="instructions-hr" />
    <div className="instructions-items-container">
      <p>
        <strong>
          <span className="pink">1.</span> Prepare sua folha:
        </strong>
      </p>
      <p>
        Utilize nosso modelo de Folha de Redação ou uma folha de caderno comum
        (17cm de largura).
      </p>
      <p>
        <strong>
          <span className="pink">2.</span> Escreva:
        </strong>
      </p>
      <p>
        Sua redação deve ter no máximo 30 linhas. Escreva a caneta, com letra
        legível e sem rasuras!
      </p>
      <p>
        <strong>
          <span className="pink">3.</span> Digitalize:
        </strong>
      </p>
      <p>
        Após finalizada, tire uma foto da sua redação ou digitalize-a, salvando
        o arquivo no seu computador ou celular.
      </p>
      <p>
        <strong>
          <span className="pink">4.</span> Envie:
        </strong>
      </p>
      <p>
        Carregue abaixo a imagem da sua Redação. Confira se está legível e
        clique no botão enviar redação.
      </p>
      <p>
        <span className="pink">Atenção:</span> a imagem de sua redação precisa
        ter no <strong>máximo 5MB</strong> e estar em formato{" "}
        <strong>JPEG</strong> ou <strong>PNG</strong>, ok?!
      </p>
    </div>
    <hr />

    <FormInputFile arrow value="Selecionar imagem" onChange={onChange} />
    <EssayPrintPage />
  </div>
)

export default EssayUploadInstructions
