import React from "react"
import styled from "styled-components"
import Planet from "Galaxy/wrappers/Planet"
import PlanetImage from "Galaxy/wrappers/PlanetImage"
import cryo from "galaxies/purple-galaxy/cryo.svg"

const Cryo = props => (
  <Planet {...props}>
    <PlanetImage src={cryo} blocked={props.blocked} />
  </Planet>
)

export default styled(Cryo)``
