export default [
  {
    title: "Regras de movimento",
    description: "",
    items: [
      {
        text: "As esferas devem deslocar-se uma casa por vez.",
      },
      {
        text: "Uma esfera pode andar em qualquer direção.",
      },
      {
        text: "Duas ou três esferas podem andar para frente, para trás e para os lados, empurrando a esfera de trás contra as da frente.",
      },
      {
        text: "Mais do que três esferas não se movimentam na mesma jogada.",
      }
    ]
  },
  {
    title: "Regras para empurrar as esferas do oponente",
    description: "",
    items: [
      {
        text: "As esferas do oponente só podem ser empurradas quando se possui uma superioridade numérica (quando há mais esferas empurrando do que sendo empurradas):",
        items: [
          {
            text: "Duas esferas podem empurrar uma esfera.",
          },
          {
            text: "Três esferas podem empurrar uma esfera.",
          },
          {
            text: "Três esferas podem empurrar duas esferas.",
          }
        ]
      }
    ]
  }
]