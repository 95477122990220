import scenario from "planets/roya/roya-scenario.svg"
import flag from "planets/roya/roya-high-flag.svg"
import { stone0, stone1, stone2, stone3, stone4, stone5, stone6 } from "planets/roya/stone"

export default {
  stones: [stone0, stone1, stone2, stone3, stone4, stone5, stone6],
  scenario: scenario,
  flag: flag,
  gradient: "#ffccef",
  paddingTop: "0px"
}
