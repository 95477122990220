import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import { VigoSad } from "components/avatar"

const YearFinalizedWithRetrievableQuestion = ({ opened, onClose }) => {
  return (
    <CloseableModal small opened={opened} onClose={onClose}>
      <h2>Missao diária finalizada!</h2>
      <p className="medium">
        As perguntas da missão diária acabaram, mas você pode voltar amanhã para resgatar as perguntas que errou e
        avançar na trilha!
      </p>
      <VigoSad />
    </CloseableModal>
  )
}

export default YearFinalizedWithRetrievableQuestion
