import React from "react"
import { connect } from "react-redux"
import { goBack } from "connected-react-router"
import { Header, HeaderLink } from "Header"
import EssayPrintPage from "components/exams/essay-print-page"
import EssayRulesModal from "components/modals/essay-rules-modal"
import { BtnPrimary } from "components/button"
import Processing from "components/processing"
import EssayTimerChannel from "exam/EssayTimerChannel"
import { getEssaySession, clearEssaySession } from "exam/essay-actions"

class EssayTopic extends React.Component {
  constructor (props) {
    super(props)
    this.state = { showRulesModal: false, themeImageLoaded: false }
  }

  componentDidMount () {
    const { essaySessionId } = this.props

    if (!essaySessionId) {
      return
    }

    this.props.dispatch(getEssaySession(essaySessionId))
  }

  componentWillUnmount () {
    this.props.dispatch(clearEssaySession())
  }

  render () {
    const { theme, themeImage, essayTitle, essaySessionId, pausable, examTimerId } = this.props
    const { showRulesModal, themeImageLoaded } = this.state

    if (!essaySessionId) {
      this.props.dispatch(goBack())
      return null
    }

    if (!themeImageLoaded) {
      return (
        <Processing>
          <img src={themeImage} style={{ display: "none" }} onLoad={() => this.setState({ themeImageLoaded: true })} />
        </Processing>
      )
    }

    return (
      <div className="essay-topic-container">
        {pausable === false && examTimerId && <EssayTimerChannel examTimerId={examTimerId} />}

        <EssayRulesModal
          essayTitle={essayTitle}
          opened={showRulesModal}
          onClose={() => this.setState({ showRulesModal: false })}
        />

        <Header backUrl="/simulados" color="colored" backLabel="Estação de Avaliações">
          <HeaderLink to="#" onClick={() => this.setState({ showRulesModal: true })}>
            Regras
          </HeaderLink>
        </Header>

        <h3 className="big purple">
          <strong>Tema</strong>
        </h3>

        <h3 className="big purple light">{theme}</h3>
        <hr className="instructions-hr" />
        <img src={themeImage} />

        <hr />

        <BtnPrimary to="/simulados/redacao/enviar" value="Enviar Redação" arrow />

        <EssayPrintPage />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    theme: state.currentEssayReducer.theme,
    themeImage: state.currentEssayReducer.themeImage,
    essayTitle: state.currentEssayReducer.essayTitle,
    essaySessionId: state.currentEssayReducer.essaySessionId,
    pausable: state.essaySessionReducer.pausable,
    examTimerId: state.essaySessionReducer.examTimerId
  }
}

export default connect(mapStateToProps)(EssayTopic)
