import React from "react"
import { Link } from "react-router-dom"
import { push } from "connected-react-router"
import { connect } from "react-redux"
import Grade from "components/exams/grade"
import { redirectToEvaluation, setCurrentEssay } from "exam/essay-actions"
import API from "api"

class EssayDetails extends React.Component {
  constructor (props) {
    super(props)
    this.handleStart = this.handleStart.bind(this)
    this.handleContinue = this.handleContinue.bind(this)
  }

  handleStart (e) {
    this.setCurrentEssayAndPush("/simulados/redacao/regras")

    e.preventDefault()
  }

  handleContinue (e) {
    const { essay, requestInProgress } = this.props

    if (requestInProgress) return

    API.post("/essay_sessions", { essayId: essay.essay_id }, () => {
      this.setCurrentEssayAndPush("/simulados/redacao/tema")
    })

    e.preventDefault()
  }

  setCurrentEssayAndPush (pushTarget) {
    const { dispatch, essay } = this.props

    dispatch(
      setCurrentEssay({
        essayTitle: essay.essay_title,
        theme: essay.theme,
        themeImage: essay.theme_image,
        essaySessionId: essay.essay_session_id,
        essayId: essay.essay_id
      })
    )

    dispatch(push(pushTarget))
  }

  render () {
    const { essay } = this.props

    return (
      <div className="exam-card-subitem">
        <div className="exam-card-subitem-top">
          <div className="exam-card-subitem-title">Redação</div>

          {essay.evaluated ? (
            <Grade grade={essay.grade} />
          ) : (
            <div>
              {essay.started ? (
                <Link to="#" className="exam-card-subitem-button" onClick={this.handleContinue}>
                  <span>Continuar</span>
                  <i className="arrow-icon arrow-icon-right" />
                </Link>
              ) : essay.in_correction ? (
                <span>Em correção</span>
              ) : (
                <Link to="#" className="exam-card-subitem-button" onClick={this.handleStart}>
                  <span>Começar</span>
                  <i className="arrow-icon arrow-icon-right" />
                </Link>
              )}
            </div>
          )}
        </div>

        <div className="exam-card-subitem-bottom">
          <div />

          <div>
            {essay.evaluated && (
              <Link to="#" className="exam-card-link" onClick={() => redirectToEvaluation(essay.essay_session_id)}>
                Ver correção
              </Link>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    requestInProgress: state.http.requestInProgress
  }
}

export default connect(mapStateToProps)(EssayDetails)
