import { env, pixelID } from "config"
import ReactPixel from "react-facebook-pixel"

const shouldTrack = env === "production"

const options = {
  autoConfig: true,
  debug: false
}

const PixelInitialize = () => {
  if (shouldTrack) {
    ReactPixel.init(pixelID, options)
  }
}

const PixelTrack = eventName => {
  if (shouldTrack) {
    ReactPixel.trackCustom(eventName, {})
  }
}

export { PixelInitialize, PixelTrack }
