import React from "react"
import { Redirect } from "react-router"
import { connect } from "react-redux"
import parseDate from "date-fns/parseISO"
import formatDate from "date-fns/format"
import HoursLeft from "components/exams/hours-left"
import QuestionsLeft from "components/exams/questions-left"
import PercentageBar from "components/PercentageBar"
import { BtnLight } from "components/button"
import Service from "Service"

class SingleExamStartableCard extends React.Component {
  constructor (props) {
    super(props)
    this.state = { fireRedirect: false, redirectTarget: "", redirectState: {} }
    this.handleStart = this.handleStart.bind(this)
  }

  handleStart (e) {
    const { miniexamId, miniexamName, started, hasLanguage, pausable, requestInProgress } = this.props

    if (started) {
      if (requestInProgress) return

      Service.startMiniexamSession(miniexamId, response => {
        const { question_set_session_id: questionSetSessionId } = response.data

        this.setState({
          fireRedirect: true,
          redirectTarget: "/simulados/responder",
          redirectState: { questionSetSessionId }
        })
      })
    } else {
      this.setState({
        fireRedirect: true,
        redirectTarget: "/simulados/regras",
        redirectState: { miniexamId, miniexamName, hasLanguage, pausable }
      })
    }

    e.preventDefault()
  }

  render () {
    const { fireRedirect, redirectTarget, redirectState } = this.state

    if (fireRedirect) {
      return <Redirect to={{ pathname: redirectTarget, state: redirectState }} />
    }

    const {
      miniexamName,
      totalQuestions,
      questionsAnswered,
      duration,
      timeLeft,
      expiresAt,
      started,
      completePercentage
    } = this.props

    return (
      <div className="exam-card">
        <div className="exam-card-title">{miniexamName}</div>

        <div className="exam-stats-container">
          <QuestionsLeft questionsAnswered={questionsAnswered} totalQuestions={totalQuestions} />
          <HoursLeft timeLeft={timeLeft} duration={duration} />
        </div>

        <PercentageBar theme="white" complete={completePercentage} />
        <div className="progress-bar-text">seu progresso na prova: {completePercentage}%</div>

        <div className="expiration-start-container">
          <div>
            Expira em <strong>{formatDate(parseDate(expiresAt), "dd/MM/yyyy 'às' HH'h'mm")}</strong>
          </div>

          <BtnLight
            to="#"
            color="white"
            value={started ? "Continuar" : "Começar"}
            onClick={this.handleStart}
            small
            arrow
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    requestInProgress: state.http.requestInProgress
  }
}

export default connect(mapStateToProps)(SingleExamStartableCard)
