export default [
  {
    title: "",
    description: "",
    items: [
      {
        text: "Cada jogador tem 4 peças e 12 pinos. A cada jogada, o jogador pode mover sua peça uma casa ou colocar um pino de direção em uma de suas peças. Uma peça não pode se mover sem o pino direcional correspondente.",
      },
      {
        text: "O jogador só pode mover sua peça nas direções indicadas pela inserção de um pino.",
      },
      {
        text: "Uma peça pode saltar peças, do mesmo jogador ou do oponente, parando na casa livre imediatamente posterior, com a condição de que um pino esteja inserido na direção do movimento.",
      },
      {
        text: "Uma peça pode fazer saltos múltiplos, com a condição de que existam pinos inseridos na direção de cada movimento. É proibido saltar sobre a mesma casa duas vezes na mesma jogada.",
      },
      {
        text: "Uma peça que salte a peça do próprio jogador ou do oponente pode, mas não é obrigatório, retirar a peça do tabuleiro, remover seus pinos e usá-los em outras jogadas.",
      }
    ]
  }
]