export default [
  {
    title: "Regras básicas",
    description: "",
    items: [
      {
        text:
          "Jogo para 4 jogadores. Cada jogador escolhe um tabuleiro, que indica qual “Wizard” (elemento) será nesta partida. São 13 rodadas.",
      },
      {
        text: "Em cada rodada, há 4 opções de jogada:",
        items: [
          {
            text: "Aposta no presente: colocar uma peça do elemento da rodada sobre a casa correspondente.",
          },
          {
            text: "Aposta no futuro: colocar uma peça de outro elemento sobre a sua casa correspondente.",
          },
          {
            text: "Aposta com curinga: colocar uma “peça-wizard” em qualquer lugar do tabuleiro.",
          },
          {
            text: "Passar a vez: não colocar nenhuma peça nessa rodada, dizendo “passo”.",
          },
        ],
      },
      {
        text:
          "Caso ocorra empate na rodada: os jogadores que empataram colocam mais uma peça fechada em jogo, na disputa (seguindo-se as mesmas regras). Vence a peça mais alta. Se empatar novamente, repete-se o procedimento.",
        items: [
          {
            text: "Se um dos jogadores não tiver mais peças para colocar, sai da disputa.",
          },
          {
            text:
              "Se nenhum dos jogadores tiver peças para colocar, soma-se as peças em disputa, desprezando-se as peças-wizards.",
          },
        ],
      },
      {
        text:
          "13ª rodada: os jogadores expõem todas as peças que sobraram em pé no seu tabuleiro. Vence quem tiver a maior soma destas peças.",
      },
    ],
  },
  {
    title: "Regras de desempate",
    description: "",
    items: [
      {
        text: "Nessa versão do jogo Quatro Elementos pode haver empate.",
      },
    ],
  },
]
