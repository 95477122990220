import styled from "styled-components"

const OddsCircle = styled.i`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
`

const OddsCircleRed = styled(OddsCircle)`
  background-color: #f13a30;
`

const OddsCircleGreen = styled(OddsCircle)`
  background-color: #6db61c;
`

const ChancesContainer = styled.div`
  background-color: #fff;
  padding: 15px;
  border-radius: 25px;
  font-family: "Montserrat", sans-serif;

  @media (max-width: 768px) {
    overflow-x: scroll;
  }
`

const TableTitle = styled.div`
  font-size: 12px;
  color: #8e3fac;
  margin-bottom: 20px;
`

const Table = styled.table`
  font-size: 11px;
`

const Th = styled.th`
  color: #d8d8d8;
  font-weight: 400;
`

const BottomText = styled.p`
  margin-top: 20px;
  font-size: 10px;
  color: #8e3fac;
`

const Separator = styled.hr`
  border-top: dotted 4px;
  border-bottom: 0;
  margin-bottom: 20px;
  color: #d8d8d8;
`

const OddContainer = styled.div`
  width: 52px;
`

export { OddsCircleRed, OddsCircleGreen, ChancesContainer, TableTitle, Table, Th, BottomText, Separator, OddContainer }
