import React from "react"
import { connect } from "react-redux"
import BlockedBadge from "backpack/badges/BlockedBadge"
import BlockedSpecialBadge from "backpack/badges/BlockedSpecialBadge"
import ConqueredBadge from "backpack/badges/ConqueredBadge"
import ConqueredSpecialBadge from "backpack/badges/ConqueredSpecialBadge"
import { fetchBadges } from "backpack/actions"
import API from "api"

class BadgeDetails extends React.Component {
  constructor (props) {
    super(props)
    this.state = { progress: [] }
  }

  componentDidMount () {
    const { dispatch } = this.props

    dispatch(fetchBadges())
    this.fetchBadgeProgress()
  }

  componentDidUpdate (prevProps) {
    if (this.props.badges !== prevProps.badges) {
      this.fetchBadgeProgress()
    }
  }

  findBadge () {
    const { badges, computedMatch } = this.props
    const { badge: badgeSlug } = computedMatch.params
    return badges.find(b => b.slug === badgeSlug)
  }

  fetchBadgeProgress () {
    const badge = this.findBadge()

    if (!badge || badge.conquered) return

    API.get(`/badges/${badge.slug}`, response => {
      const { progress } = response.data
      this.setState({ progress })
    })
  }

  getBadgeComponent (badge) {
    if (badge.special) {
      return badge.conquered ? ConqueredSpecialBadge : BlockedSpecialBadge
    } else {
      return badge.conquered ? ConqueredBadge : BlockedBadge
    }
  }

  render () {
    const badge = this.findBadge()

    if (!badge) return

    const { progress } = this.state
    const Component = this.getBadgeComponent(badge)

    return <Component badge={badge} progress={progress} />
  }
}

const mapStateToProps = state => {
  return {
    badges: state.backpackReducer.badges
  }
}

export default connect(mapStateToProps)(BadgeDetails)
