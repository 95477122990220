import styled from "styled-components"

const BadgesSpecials = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  a {
    width: 40%;
    text-align: center;
  }

  img {
    margin: 5px;
  }

  @media (max-width: 768px) {
    img {
      margin-bottom: 5px;
    }
  }
`

const BadgesColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BadgesRowsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-around;
`

const BadgesSpecialsContainer = styled.div`
  margin-top: 5px;
`

const BadgesPerfomanceEngagement = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 5px;

  img {
    margin-bottom: 5px;
  }
`

const BadgesContainerTitle = styled.h2`
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #8e3fac;
  font-family: "Montserrat";
`

export {
  BadgesSpecials,
  BadgesColumn,
  BadgesRowsContainer,
  BadgesSpecialsContainer,
  BadgesPerfomanceEngagement,
  BadgesContainerTitle,
}
