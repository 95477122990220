import styled from "styled-components"
import { Link } from "react-router-dom"
import { Padlock, Expired } from "Icons"
import Stars from "stars.svg"
import Glow from "glow.svg"

const EarnedImageContainer = styled.div`
  background: url(${Stars}) no-repeat center top;
  position: relative;
  margin-top: 15px;
  height: 260px;
  width: 200px;

  &::before {
    content: "";
    background: url(${Glow}) no-repeat center top;
    position: absolute;
    top: -50px;
    left: 50%;
    margin-left: -175px;
    width: 350px;
    height: 350px;
    display: block;
    z-index: -1;
  }

  img {
    margin-top: 60px;
  }
`

const EarnedText = styled.div`
  max-width: 100%;
  border-radius: 41px;
  background-color: #591279;
  color: #fff;
  font-size: 14px;
  line-height: 30px;
  margin: 0 auto;
  padding: 5px 15px;
`

const EarnedTitle = styled.h2`
  font-family: "Montserrat";
  line-height: 1.2em;
  color: #fff;
  margin-bottom: 20px;
  font-size: 31px;
  font-weight: 500;
`

const EarnedTitleLight = styled.h2`
  font-family: "Montserrat";
  line-height: 1.2em;
  color: #fff;
  margin-bottom: 20px;
  font-size: 31px;
  font-weight: 300;
`

const EarnedParagraph = styled.p`
  margin-top: 20px;
`

const BadgeContainer = styled.div`
  padding-top: 70px;
  text-align: center;

  img {
    margin: 30px 0;
  }

  hr {
    margin-top: 60px;
    margin-bottom: 30px;
  }
`

const PerformanceName = styled.div`
  color: #9cbcfc;
  font-family: "Montserrat", sans-serif;
  font-size: 31px;
  font-weight: 700;
  line-height: 28px;

  @media (max-width: 768px) {
    font-size: 25px;
  }
`

const SpecialBadgeName = styled.div`
  color: #da8f32;
  font-family: "Montserrat", sans-serif;
  font-size: 31px;
  font-weight: 700;
  line-height: 28px;

  @media (max-width: 768px) {
    font-size: 25px;
  }
`

const SpecialBadgeSubtitle = styled.div`
  color: #eeb927;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
`

const BlockedImagesContainer = styled.div`
  position: relative;
`

const DarkGrayText = styled.p`
  color: #959595;
`

const BadgeTitle = styled.h2`
  font-size: 31px;
  font-weight: 500;
  color: #8e3fac;
`

const BadgesSpecials = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  a {
    width: 20%;
    text-align: center;
  }

  img {
    margin-bottom: 100px;
  }

  @media (max-width: 768px) {
    img {
      margin-bottom: 30px;
    }
  }
`

const BadgesColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BadgesRowsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
`

const BadgesSpecialsContainer = styled.div`
  margin-top: 20px;
`

const BadgesPerfomanceEngagement = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;

  img {
    margin-bottom: 100px;
  }

  @media (max-width: 768px) {
    img {
      margin-bottom: 30px;
    }
  }
`

const BadgesContainerTitle = styled.h2`
  width: 100%;
  text-align: center;
`

const BadgesContainerSubtitle = styled.h2`
  width: 100%;
  text-align: center;
`

const BadgeLink = styled(Link)`
  img {
    height: 150px;
  }

  @media (max-width: 768px) {
    img {
      height: 65px;
    }
  }
`

const PadlockIcon = styled(Padlock)`
  margin: 0 auto;
  mask-size: 70px;
  height: 70px;
  width: 70px;
  position: absolute;
  right: 0;
  left: 0;
  top: 60px;
`

const ExpiredIcon = styled(Expired)`
  top: 32px;
  margin: 0 auto;
  mask-size: 80px;
  height: 80px;
  width: 80px;
  position: absolute;
  right: 0;
  left: 0;

  @media (max-width: 768px) {
    mask-size: 35px;
    height: 35px;
    width: 35px;
    top: 13px;
  }
`

const ExpiredIconDetails = styled(Expired)`
  top: 60px;
  margin: 0 auto;
  mask-size: 80px;
  height: 80px;
  width: 80px;
  position: absolute;
  right: 0;
  left: 0;
`

const BadgeImage = styled.img`
  height: 150px;
`

export {
  EarnedImageContainer,
  EarnedText,
  EarnedTitle,
  EarnedTitleLight,
  EarnedParagraph,
  BadgeTitle,
  DarkGrayText,
  BlockedImagesContainer,
  PerformanceName,
  SpecialBadgeName,
  SpecialBadgeSubtitle,
  BadgeContainer,
  BadgesSpecials,
  BadgesColumn,
  BadgesRowsContainer,
  BadgesSpecialsContainer,
  BadgesPerfomanceEngagement,
  BadgesContainerTitle,
  BadgesContainerSubtitle,
  BadgeLink,
  PadlockIcon,
  ExpiredIcon,
  ExpiredIconDetails,
  BadgeImage,
}
