import React from "react"
import BagLayout from "components/layouts/bag"
import ManualMyMural from "backpack/manual/my-mural"
import ManualAbout from "backpack/manual/manual-about"
import { MixpanelTrack } from "tags/mixpanel"

const DailyMissionMural = () => {
  MixpanelTrack("Visualizou aba Manual")

  return (
    <BagLayout activeSection="mural">
      <div className="bag-content-container">
        <ManualMyMural />
        <ManualAbout />
      </div>
    </BagLayout>
  )
}

export default DailyMissionMural