import styled from "styled-components"
import PropTypes from "prop-types"
import { withPlanet } from "planet-context"

const Content = styled.div`
  background: ${props => props.planet.trail.gradient};
  min-height: 818px;
  padding-top: ${props => props.planet.trail.paddingTop};
  box-sizing: border-box;
`

Content.propTypes = {
  planet: PropTypes.shape({
    trail: PropTypes.shape({
      gradient: PropTypes.string.isRequired
    })
  })
}

export default withPlanet(Content)
