import ThemingRoute from "helpers/theming-route"
import DailyMissionNew from "daily-mission/new"
import QuestionAnswer from "daily-mission/QuestionAnswer"
import RetrievedQuestionAnswer from "daily-mission/RetrievedQuestionAnswer"
import DailyMissionAbandonedModal from "components/modals/daily-mission-abandoned-modal"
import DailyMissionSuccessModal from "components/modals/daily-mission-success-modal"

const Routes = [
  {
    router: ThemingRoute,
    path: "/missao-diaria",
    component: DailyMissionNew
  },
  {
    router: ThemingRoute,
    path: "/missao-diaria/responder",
    component: QuestionAnswer
  },
  {
    router: ThemingRoute,
    path: "/missao-diaria/abandonada",
    component: DailyMissionAbandonedModal
  },
  {
    router: ThemingRoute,
    path: "/missao-diaria/finalizada",
    component: DailyMissionSuccessModal
  },
  {
    router: ThemingRoute,
    path: "/missao-diaria/resgate",
    component: RetrievedQuestionAnswer
  }
]

export default Routes
