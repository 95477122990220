import React from "react"
import BagLayout from "components/layouts/bag"
import { connect } from "react-redux"
import BadgesList from "backpack/badges/BadgesList"
import { MixpanelTrack } from "tags/mixpanel"
import {
  FirstColumnBadgeBlocked,
  SecondColumnBadgeBlocked,
  ThirdColumnBadgeBlocked,
  SpecialBadgeBlocked
} from "components/badge"
import {
  BadgesSpecials,
  BadgesColumn,
  BadgesRowsContainer,
  BadgesSpecialsContainer,
  BadgesPerfomanceEngagement,
  BadgesContainerTitle,
  BadgesContainerSubtitle,
  BadgeLink,
  ExpiredIcon,
  BlockedImagesContainer
} from "backpack/badges/styled"
import { fetchBadges } from "backpack/actions"

const BadgesContainer = ({ specials, badges, BlockedBadge }) => {
  const Container = specials ? BadgesSpecials : BadgesColumn
  return (
    <Container>
      {badges.map((elem, index) => {
        const BadgeComponent = elem.conquered ? BadgesList[elem.slug].component : BlockedBadge

        return (
          <BadgeLink to={`/mochila/conquistas/${elem.slug}`} key={index}>
            <BlockedImagesContainer>
              {elem.expired && <ExpiredIcon />}
              <BadgeComponent />
            </BlockedImagesContainer>
          </BadgeLink>
        )
      })}
    </Container>
  )
}

class DailyMissionBadges extends React.Component {
  componentDidMount () {
    this.props.dispatch(fetchBadges())
    MixpanelTrack("Visualizou aba Conquistas")
  }

  render () {
    const { schoolYear, badges } = this.props
    const firstRow = badges.filter(badge => !badge.special && BadgesList[badge.slug].column === 1)
    const secondRow = badges.filter(badge => !badge.special && BadgesList[badge.slug].column === 2)
    const thirdRow = badges.filter(badge => !badge.special && BadgesList[badge.slug].column === 3)
    const specialsRow = badges.filter(badge => badge.special)

    return (
      <BagLayout activeSection="badges">
        <div className="bag-badges-container">
          {specialsRow.length > 0 && (
            <div>
              <BadgesSpecialsContainer>
                <BadgesContainerTitle>Badges Especiais</BadgesContainerTitle>
                <BadgesContainerSubtitle>MenteInovadora - {schoolYear}</BadgesContainerSubtitle>
                <BadgesContainer BlockedBadge={SpecialBadgeBlocked} badges={specialsRow} specials />
              </BadgesSpecialsContainer>
              <hr />
            </div>
          )}
          <BadgesPerfomanceEngagement>
            <BadgesContainerTitle>Badges</BadgesContainerTitle>
            <BadgesContainerSubtitle>Engajamento e desempenho</BadgesContainerSubtitle>
            <BadgesRowsContainer>
              <BadgesContainer BlockedBadge={FirstColumnBadgeBlocked} badges={firstRow} />
              <BadgesContainer BlockedBadge={SecondColumnBadgeBlocked} badges={secondRow} />
              <BadgesContainer BlockedBadge={ThirdColumnBadgeBlocked} badges={thirdRow} />
            </BadgesRowsContainer>
          </BadgesPerfomanceEngagement>
        </div>
      </BagLayout>
    )
  }
}

const mapStateToProps = state => {
  return {
    schoolYear: state.auth.currentUser.school_year,
    badges: state.backpackReducer.badges
  }
}

export default connect(mapStateToProps)(DailyMissionBadges)
