import "core-js/stable"
import "regenerator-runtime/runtime"
import "tags/hotjar"
import "tags/zendesk"
import { MixpanelInitialize } from "tags/mixpanel"
//import { PixelInitialize } from "tags/pixel"
import React from "react"
import ReactDOM from "react-dom"
import ErrorBoundary from "error-boundary"
import { Provider } from "react-redux"
import { ConnectedRouter } from "connected-react-router"
import Routes from "routes"
import Processing from "components/processing"
import API from "api"
import { PersistGate } from "redux-persist/es/integration/react"
import configureStore, { history } from "store"
import "../css/app.css"

const { persistor, store } = configureStore()


API.configDefaults(store)
MixpanelInitialize()
//PixelInitialize()

const App = () => (
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={<Processing />} persistor={persistor}>
        <ConnectedRouter history={history}>
          <div className="container">
            <Routes />
          </div>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
)

ReactDOM.render(<App />, document.getElementById("app"))
