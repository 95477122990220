import React from "react"
import { SpecialBadgeName, SpecialBadgeSubtitle } from "backpack/badges/styled"
import BlockedBadgeBase from "backpack/badges/BlockedBadgeBase"

const BlockedSpecialBadge = ({ badge, progress }) => (
  <BlockedBadgeBase badge={badge} progress={progress}>
    <SpecialBadgeName>{badge.id}</SpecialBadgeName>
    <SpecialBadgeSubtitle>{badge.subtitle}</SpecialBadgeSubtitle>
  </BlockedBadgeBase>
)

export default BlockedSpecialBadge
