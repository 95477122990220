import React from "react"
import { connect } from "react-redux"
import { syncCurrentPlanet, syncCurrentGalaxy } from "actions"
import { PlanetContext } from "planet-context"
import { GalaxyContext } from "galaxy-context"
import AuthenticatedRoute from "helpers/authenticated-route"

let Theme = ({ component: Component, galaxy, planet, dispatch, ...props }) => {
  if (!planet) {
    dispatch(syncCurrentPlanet())
    return null
  }

  if (typeof galaxy === "string") {
    dispatch(syncCurrentGalaxy())
    return null
  }

  return (
    <GalaxyContext.Provider value={galaxy}>
      <PlanetContext.Provider value={planet}>
        <Component {...props} />
      </PlanetContext.Provider>
    </GalaxyContext.Provider>
  )
}

const mapStateToProps = state => {
  return {
    planet: state.auth.currentPlanet,
    galaxy: state.auth.currentGalaxy
  }
}

Theme = connect(mapStateToProps)(Theme)

const ThemingRoute = ({ component, ...rest }) => (
  <AuthenticatedRoute component={Theme} componentProps={{ component, ...rest }} />
)

export default ThemingRoute
