import {
  MY_SISU_ENABLE_SUBMIT,
  MY_SISU_DISABLE_SUBMIT,
  MY_SISU_SET_OPTION,
  MY_SISU_SHOW_CHANCES,
  MY_SISU_HIDE_CHANCES,
  MY_SISU_SET_CHANCES,
  MY_SISU_RESET_MY_SISU,
  MY_SISU_SET_UNIVERSITIES,
  MY_SISU_SET_COURSES,
  MY_SISU_SET_SCIENCE_AREAS,
  MY_SISU_SET_GROUPINGS,
  MY_SISU_SET_STATES,
  MY_SISU_SET_CITIES,
  MY_SISU_SET_TABLE_TITLE
} from "MySisu/actions"

const mySisuInitialState = {
  submitDisabled: true,
  options: {
    optionSelected: "university_course",
    universityId: "",
    courseId: "",
    groupingId: "",
    cityId: "",
    stateId: "",
    scienceAreaId: ""
  },
  showChances: false,
  chances: [],
  universities: [],
  courses: [],
  scienceAreas: [],
  groupings: [],
  states: [],
  cities: [],
  chancesTableTitle: ""
}

const mySisuReducer = (state = mySisuInitialState, action) => {
  switch (action.type) {
    case MY_SISU_ENABLE_SUBMIT:
      return Object.assign({}, state, {
        submitDisabled: false
      })
    case MY_SISU_DISABLE_SUBMIT:
      return Object.assign({}, state, {
        submitDisabled: true
      })
    case MY_SISU_SET_OPTION:
      return Object.assign({}, state, { options: { ...state.options, ...action.options } })
    case MY_SISU_SHOW_CHANCES:
      return Object.assign({}, state, {
        showChances: true
      })
    case MY_SISU_HIDE_CHANCES:
      return Object.assign({}, state, {
        showChances: false
      })
    case MY_SISU_SET_COURSES:
      return Object.assign({}, state, {
        courses: action.courses
      })
    case MY_SISU_SET_CHANCES:
      return Object.assign({}, state, {
        chances: action.chances
      })
    case MY_SISU_SET_UNIVERSITIES:
      return Object.assign({}, state, {
        universities: action.universities
      })
    case MY_SISU_SET_SCIENCE_AREAS:
      return Object.assign({}, state, {
        scienceAreas: action.scienceAreas
      })
    case MY_SISU_SET_GROUPINGS:
      return Object.assign({}, state, {
        groupings: action.groupings
      })
    case MY_SISU_SET_STATES:
      return Object.assign({}, state, {
        states: action.states
      })
    case MY_SISU_SET_CITIES:
      return Object.assign({}, state, {
        cities: action.cities
      })
    case MY_SISU_RESET_MY_SISU:
      return Object.assign({}, state, mySisuInitialState)
    case MY_SISU_SET_TABLE_TITLE:
      return Object.assign({}, state, {
        chancesTableTitle: action.chancesTableTitle
      })
    default:
      return state
  }
}

export default mySisuReducer
