import API from "api"

export const BADGES_FETCH = "BADGES_FETCH"

export function fetchBadges () {
  return dispatch => {
    API.get("/badges", response => {
      dispatch({ type: BADGES_FETCH, badges: response.data })
    })
  }
}
