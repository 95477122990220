import React from "react"
import formatDistanceToNow from "date-fns/formatDistanceToNow"
import parseISO from "date-fns/parseISO"
import pt from "date-fns/locale/pt"

const Countdown = ({ until }) => (
  <span>
    {typeof until === "string"
      ? formatDistanceToNow(parseISO(until), { locale: pt })
      : formatDistanceToNow(until, { locale: pt })}
  </span>
)

export default Countdown
