import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import ClockCountdown from "components/clock-countdown"
import UserAvatar from "components/user-avatar"

const DailyMissionAlreadyDoneModal = ({ opened, onClose, unblockTime }) => (
  <CloseableModal small opened={opened} onClose={onClose}>
    <h2>Missão Diária bloqueada</h2>
    <p className="medium">Você só pode fazer uma missão por dia. A próxima será liberada em:</p>
    <ClockCountdown until={unblockTime} />
    <UserAvatar kind="sad" />
  </CloseableModal>
)

export default DailyMissionAlreadyDoneModal
