import React from "react"
import { connect } from "react-redux"
import { Header } from "Header"
import { GamificationBar } from "GamificationBar"
import Processing from "components/processing"
import Menu from "components/exams/menu"
import CardsSlider from "components/exams/cards-slider"
import ExamCalibratedStartableCard from "components/exams/exam-calibrated-startable-card"
import SingleExamStartableCard from "components/exams/single-exam-startable-card"
import ExamCalibratedFinalizedCard from "components/exams/exam-calibrated-finalized-card"
import ExamSimpleCalibratedFinalizedCard from "components/exams/exam-simple-calibrated-finalized-card"
import ExamSlimFinalizedCard from "components/exams/exam-slim-finalized-card.js"
import ExamUnstartableCard from "components/exams/exam-unstartable-card"
import EssayStartableCard from "components/exams/essay-startable-card"
import EssayUnstartableCard from "components/exams/essay-unstartable-card"
import SocioEmotionalExamStartableCard from "components/exams/socio-emotional-exam-startable-card"
import SocioEmotionalExamUnstartableCard from "components/exams/socio-emotional-exam-unstartable-card"
import SocioEmotionalExamFinalizedCard from "components/exams/socio-emotional-exam-finalized-card"
import { clearCurrentEssay } from "exam/essay-actions"
import { MySisu } from "MySisu"
import API from "api"

function filterExams(exams, section) {
	switch (section) {
		case "finished":
			return exams.filter(e => e.completed || e.expired)
		case "released":
			return exams.filter(e => !e.completed && e.available)
		case "waiting":
			return exams.filter(e => !e.completed && !e.available && !e.expired)
	}
}

function disclaimerBySection(section) {
	switch (section) {
		case "finished":
			return "Nesse momento, nenhuma avaliação ou redação se encontra finalizada."
		case "released":
			return "Nesse momento, não há nenhuma avaliação ou redação disponível."
		case "waiting":
			return "Nesse momento, não há nenhuma avaliação ou redação aguardando liberação."
	}
}

const ExamUnstartableByStrategy = ({ exam }) => {
	if (exam.miniexams) {
		return (
			<ExamUnstartableCard
				name={exam.name}
				duration={exam.duration}
				totalQuestions={exam.total_questions}
				pausable={exam.pausable}
				meuSisuAvailable={exam.meu_sisu_available}
				availableAt={exam.available_at}
			/>
		)
	} else {
		return (
			<ExamUnstartableCard
				name={exam.miniexam_name}
				duration={exam.duration}
				totalQuestions={exam.total_questions}
				pausable={exam.pausable}
				meuSisuAvailable={exam.meu_sisu_available}
				availableAt={exam.available_at}
			/>
		)
	}
}

const ExamStartableCardByStrategy = ({ exam }) => {
	const {
		total_questions: totalQuestions,
		questions_answered: questionsAnswered,
		duration: duration,
		time_spent: timeSpent,
		time_left: timeLeft,
		expires_at: expiresAt,
		started: started,
		complete_percentage: completePercentage,
		pausable: pausable
	} = exam

	const cardProps = {
		totalQuestions,
		questionsAnswered,
		duration,
		timeSpent,
		timeLeft,
		expiresAt,
		started,
		completePercentage,
		pausable
	}

	if (exam.miniexams) {
		return <ExamCalibratedStartableCard exam={exam} miniexams={exam.miniexams} essay={exam.essay} {...cardProps} />
	} else {
		return (
			<SingleExamStartableCard
				miniexamId={exam.miniexam_id}
				miniexamName={exam.miniexam_name}
				hasLanguage={exam.has_language}
				{...cardProps}
			/>
		)
	}
}

const ExamFinalizedCardByStrategy = ({ exam, showMySisu }) => {
	switch (exam.strategy) {
		case "CALIBRATED":
			return (
				<ExamCalibratedFinalizedCard
					examName={exam.name}
					timeSpent={exam.time_spent}
					duration={exam.duration}
					averageGrade={exam.average_grade}
					miniexams={exam.miniexams}
					meuSisuAvailable={exam.meu_sisu_available}
				/>
			)
		case "SIMPLE_CALIBRATED":
			return (
				<ExamSimpleCalibratedFinalizedCard
					miniexamName={exam.miniexam_name}
					timeSpent={exam.time_spent}
					duration={exam.duration}
					experience={exam.experience_amount}
					completed={exam.completed}
				/>
			)
		case "SLIM":
			return (
				<ExamSlimFinalizedCard
					miniexamName={exam.miniexam_name}
					timeSpent={exam.time_spent}
					duration={exam.duration}
					experience={exam.experience_amount}
					completed={exam.completed}
					questionSetSessionId={exam.question_set_session_id}
				/>
			)
		case "GROUPED_TIMED":
			return (
				<ExamCalibratedFinalizedCard
					examName={exam.name}
					timeSpent={exam.time_spent}
					duration={exam.duration}
					averageGrade={exam.average_grade}
					miniexams={exam.miniexams}
					essay={exam.essay}
					meuSisuAvailable={exam.meu_sisu_available}
					showMySisu={showMySisu}
				/>
			)
	}
}

const createLink = (link, hash) => `${link}hash_map=${hash}`

const ReleasedEvaluations = ({ exams, socioEmotionals }) => {

	socioEmotionals = socioEmotionals.map(se => {
	const provider_links = [ Object.values(se.provider_links)[0] ]
		se.miniexams = provider_links.map((link, index) => ({
			started: se.completed_links[0] == true,
			completed: se.completed_links[index] == true,
			name: `Parte ${index + 1}`,
			url: createLink(link, se.hash_map),
			enabled: (() => {
				const completed = () => se.completed_links[index] == true

				if (index == 0) {
					return !completed(0)
				}
				if (index == 1) {
					return !completed(1) && completed(0)
				}
				if (index == 2) {
					return !completed(2) && completed(1) && completed(0)
				}

			})()
		}))
		se.complete_percentage = (100 / se.miniexams.length) * se.miniexams.filter(m => m.completed).length
		se.complete_percentage = Math.round(se.complete_percentage)
		const nextStep = se.miniexams.find(m => !m.completed)
		if (nextStep) {
			se.nextStep = nextStep.url
		}

		return se
	})

	return <CardsSlider>
		{socioEmotionals.map((socioEmotional, index) => (
			<div key={`socioEmotional-${index}`}>
				<SocioEmotionalExamStartableCard
					expiresAt={socioEmotional.expires_at}
					name={socioEmotional.name}
					socioEmotionalExamId={"1"}
					completePercentage={socioEmotional.complete_percentage}
					started={socioEmotional.started}
					miniexams={socioEmotional.miniexams}
					link={socioEmotional.nextStep}
				/>
			</div>
		))}

		{exams.map((exam, index) => (
			<div key={`exam-${index}`}>
				<ExamStartableCardByStrategy exam={exam} />
			</div>
		))}
	</CardsSlider>
}

const WaitingEvaluations = ({ exams, socioEmotionals }) => (
	<CardsSlider>
		{socioEmotionals.map((socioEmotional, index) => (
			<div key={`socioEmotional-${index}`}>
				<SocioEmotionalExamUnstartableCard
					name={socioEmotional.name}
					availableAt={socioEmotional.available_at}
					duration={socioEmotional.completed_links.length * (15 * 60)}
					total_questions={socioEmotional.completed_links.length}
				/>
			</div>
		))}

		{exams.map((exam, index) => (
			<div key={`exam-${index}`}>
				<ExamUnstartableByStrategy exam={exam} />
			</div>
		))}
	</CardsSlider>
)

const FinishedEvaluations = ({ exams, socioEmotionals, showMySisu }) => (
	<CardsSlider>
		{socioEmotionals.map((socioEmotional, index) => (
			<div key={`socioEmotional-${index}`}>
				<SocioEmotionalExamFinalizedCard completed={socioEmotional.completed} expired={socioEmotional.expired} name={socioEmotional.name} />
			</div>
		))}

		{exams.map((exam, index) => (
			<div key={`exam-${index}`}>
				<ExamFinalizedCardByStrategy exam={exam} showMySisu={showMySisu} />
			</div>
		))}
	</CardsSlider>
)

const SatelliteEvaluations = ({ exams, socioEmotionals, section, showMySisu }) => (
	<div className="satellite-content satellite-content-exams">
		<p className="satellite-content-title">Avaliações</p>

		{section === "released" && <ReleasedEvaluations exams={exams} socioEmotionals={socioEmotionals} />}
		{section === "waiting" && <WaitingEvaluations exams={exams} socioEmotionals={socioEmotionals} />}
		{section === "finished" && (
			<FinishedEvaluations exams={exams}  socioEmotionals={socioEmotionals} showMySisu={showMySisu} />
		)}
	</div>
)

const SatelliteEssays = ({ essays, section }) => (
	<div className="satellite-content satellite-content-essays">
		<p className="satellite-content-title">Redações</p>

		<CardsSlider>
			{(section === "released" || section === "finished") &&
				essays.map((essay, index) => (
					<div key={index}>
						<EssayStartableCard
							essayId={essay.essay_id}
							essayTitle={essay.essay_title}
							theme={essay.theme}
							themeImage={essay.theme_image}
							expired={essay.expired}
							expiresAt={essay.expires_at}
							started={essay.started}
							inCorrection={essay.in_correction}
							evaluated={essay.evaluated}
							completed={essay.completed}
							grade={essay.grade}
							competences={essay.competences}
							essaySessionId={essay.essay_session_id}
						/>
					</div>
				))}

			{section === "waiting" &&
				essays.map((essay, index) => (
					<div key={index}>
						<EssayUnstartableCard essayTitle={essay.essay_title} theme={essay.theme} availableAt={essay.available_at} />
					</div>
				))}
		</CardsSlider>
	</div>
)

class Satellite extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			activeSection: "released",
			fetched: false,
			exams: [],
			essays: [],
			mySisuOpened: false,
			evaluationIds: [],
			socioEmocionals: []
		}
		this.socioEmotionals = []
		this.handleChangeSection = this.handleChangeSection.bind(this)
		this.showMySisu = this.showMySisu.bind(this)
	}

	componentDidMount() {
		const { dispatch } = this.props
		dispatch(clearCurrentEssay())

		API.get("/evaluations", response => {
			console.log(response.data)
			// const { essays, exams } = response.data
			// const socioEmotionals = this.socioEmotionals
			const { essays, exams, socio_emotionals: socioEmotionals } = response.data
			this.setState({ exams, essays, socioEmotionals, fetched: true })
		})
	}

	handleChangeSection(section) {
		this.setState({ activeSection: section })
	}

	showMySisu(event, evaluationIds) {
		this.setState({ mySisuOpened: true, evaluationIds: evaluationIds })
		event.preventDefault()
	}

	render() {
		const { exams, essays, socioEmotionals, activeSection, fetched, mySisuOpened, evaluationIds } = this.state

		if (!fetched) {
			return <Processing />
		}

		for (const elem of socioEmotionals) {
			elem.completed = (elem.completed_links[0] == true)
		}

		const filteredExams = filterExams(exams, activeSection)
		const filteredEssays = filterExams(essays, activeSection)
		const filteredSocioEmotionalExams = filterExams(socioEmotionals, activeSection)

		return (
			<div className="satellite-container">
				<Header backUrl="/galaxia" color="colored">
					<GamificationBar />
				</Header>

				<MySisu
					opened={mySisuOpened}
					evaluationIds={evaluationIds}
					onClose={() => this.setState({ mySisuOpened: false })}
				/>

				<h2>Estação de Avaliações</h2>
				<Menu activeSection={activeSection} handleChangeSection={this.handleChangeSection} />

				<div className="satellite-content-container">
					{(filteredExams.length > 0 || filteredSocioEmotionalExams.length > 0) && (
						<SatelliteEvaluations
							exams={filteredExams}
							socioEmotionals={filteredSocioEmotionalExams}
							section={activeSection}
							showMySisu={this.showMySisu}
						/>
					)}
					{filteredEssays.length > 0 && <SatelliteEssays essays={filteredEssays} section={activeSection} />}

					{filteredExams.length === 0 && filteredSocioEmotionalExams.length === 0 && filteredEssays.length == 0 && (
						<p className="satellite-disclaimer">{disclaimerBySection(activeSection)}</p>
					)}
				</div>
			</div>
		)
	}
}

export default connect()(Satellite)
