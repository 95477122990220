import scenario from "planets/pensatus/pensatus-scenario.svg"
import flag from "planets/pensatus/pensatus-high-flag.svg"
import { stone0, stone1, stone2, stone3, stone4, stone5, stone6 } from "planets/pensatus/stone"

export default {
  stones: [stone0, stone1, stone2, stone3, stone4, stone5, stone6],
  scenario: scenario,
  flag: flag,
  gradient: "linear-gradient(180deg,#FFE156 0%,#FFE156 100%)",
  paddingTop: "20px"
}
