import React from "react"
import FormGroup from "components/form/form-group"
import FormLabel from "components/form/form-label"
import ComponentHelper from "helpers/component-helper"

const FormRadio = props => {
  const {
    id,
    name,
    value,
    required,
    errors = [],
    className = "",
    onChange,
    children,
    containerClassName = "",
    ...rest
  } = props
  return (
    <FormGroup hasError={ComponentHelper.hasError(errors, name)} className={containerClassName}>
      <input
        type="radio"
        id={id}
        className={`form-radio ${className}`}
        name={name}
        value={value}
        required={required}
        onClick={onChange}
        {...rest}
      />
      <FormLabel htmlFor={id}>{children}</FormLabel>
    </FormGroup>
  )
}

export default FormRadio
