import styled from "styled-components"
import sky from "planets/imagite/imagite-sky.svg"
import background from "planets/imagite/imagite-edit-profile-background.svg"
import { StyledHorizon, StyledHorizonContainer, StyledHorizonTitle } from "Horizon"

const PlanetHorizon = styled(StyledHorizon)`
  background: linear-gradient(180deg, #435dd7 0%, #ffb2b4 100%);
  overflow: hidden;

  &::after {
    content: "";
    background: url(${background}) no-repeat;
    width: 100%;
    height: 120px;
    display: block;
    position: absolute;
    bottom: 40px;
    transform: scaleX(-1);

    @media (max-width: 768px) {
      height: 50px;
      width: 500px;
    }
  }
`

const ShortPlanetHorizon = styled(PlanetHorizon)`
  background: linear-gradient(180deg, #435dd7 0%, #ffb2b4 100%);

  &::before {
    left: 0;
    margin-left: 0;
    bottom: 20px;

    @media (max-width: 768px) {
      left: -250px;
    }
  }

  &::after {
    content: "";
    background: url(${background}) no-repeat;
    width: 100%;
    height: 120px;
    display: block;
    position: absolute;
    bottom: 0;

    @media (max-width: 768px) {
      height: 50px;
    }
  }
`

const PlanetHorizonContainer = styled(StyledHorizonContainer)`
  &::before {
    background: url(${sky}) no-repeat;
    height: 136px;
    bottom: 110px;
    transform: scaleX(-1);

    @media (max-width: 768px) {
      left: -43px;
    }
  }
`

const ShortPlanetHorizonContainer = styled(PlanetHorizonContainer)`
  &::before {
    height: 90px;
    bottom: 80px;
    left: -100px;
  }
`

const PlanetHorizonTitle = styled(StyledHorizonTitle)`
  position: absolute;
  right: 100px;

  @media (max-width: 1024px) {
    right: auto;
    top: 55px;
  }
`

export { PlanetHorizon, PlanetHorizonContainer, PlanetHorizonTitle, ShortPlanetHorizon, ShortPlanetHorizonContainer }
