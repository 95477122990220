import React from "react"
import styled from "styled-components"
import warningIcon from "../../images/warning.svg"

const Row = styled.div`
  display: flex
  flexDirection: row
`

const WarningAlert = ({ text }) => {
  return (
    <div className="md-game-alert-tip">
      <Row>
        <img src={warningIcon} />
        {text}
      </Row>
    </div>
  )
}

export default WarningAlert
