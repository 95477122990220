import React from "react"
import { Link } from "react-router-dom"
import CloseableModal from "components/modals/closeable-modal"
import { BtnPrimary } from "components/button"

const ExamPauseModal = ({ opened, onDismiss }) => (
  <CloseableModal opened={opened} closeButton={false} fullscreen>
    <div className="exam-pause">
      <i className="pause-icon" />
      <h2 className="modal-title">Pausar avaliação</h2>

      <p>Não se preocupe, vamos guardar suas respostas :)</p>
      <p>Quando você voltar para essa Área do Conhecimento, suas respostas estarão salvas e o tempo continuará de onde você parou.</p>

      <div className="exam-pause-buttons">
        <Link to="#" className="exam-pause-dismiss" onClick={onDismiss}>Voltar ao respondedor</Link>
        <BtnPrimary to="/simulados" value="Quero pausar!" />
      </div>
    </div>
  </CloseableModal>
)

export default ExamPauseModal
