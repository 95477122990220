import Loadable from "react-loadable"
import ThemingRoute from "helpers/theming-route"
import Processing from "components/processing"
import EditProfile from "backpack/edit-profile"
import HowToWinRuby from "backpack/how-to-win-ruby.js"
import HowToWinXP from "backpack/how-to-win-xp.js"
import HowToMoveForward from "backpack/how-to-move-forward"
import HowToEarnBadges from "backpack/how-to-earn-badges"
import HowToUnblockPlanet from "backpack/how-to-unblock-planet"
import Rules from "backpack/rules"
import PlayToWinXp from "backpack/play-to-win-xp"
import EvaluationDates from "backpack/evaluation-dates"
import DailyMissionPerformance from "backpack/performance"
import DailyMissionMural from "backpack/mural"
import Payment from "backpack/payment/Payment"
import { DailyMissionBadges, BadgeDetails } from "backpack/badges"
import ChangeAvatar from "backpack/change-avatar"

const Book = Loadable({
  loader: () => import("backpack/book" /* webpackChunkName: "books" */),
  loading: Processing
})

const Routes = [
  {
    router: ThemingRoute,
    path: "/mochila",
    component: DailyMissionPerformance
  },
  {
    router: ThemingRoute,
    path: "/mochila/mural",
    component: DailyMissionMural
  },
  {
    router: ThemingRoute,
    path: "/mochila/pagamento",
    component: Payment
  },
  {
    router: ThemingRoute,
    path: "/mochila/conquistas",
    component: DailyMissionBadges
  },
  {
    router: ThemingRoute,
    path: "/mochila/conquistas/:badge",
    component: BadgeDetails
  },
  {
    router: ThemingRoute,
    path: "/mochila/editar-perfil",
    component: EditProfile
  },
  {
    router: ThemingRoute,
    path: "/mochila/como-ganho-rubi",
    component: HowToWinRuby
  },
  {
    router: ThemingRoute,
    path: "/mochila/como-ganho-xp",
    component: HowToWinXP
  },
  {
    router: ThemingRoute,
    path: "/mochila/como-avanco-na-trilha",
    component: HowToMoveForward
  },
  {
    router: ThemingRoute,
    path: "/mochila/como-ganho-badges",
    component: HowToEarnBadges
  },
  {
    router: ThemingRoute,
    path: "/mochila/como-libero-um-planeta",
    component: HowToUnblockPlanet
  },
  {
    router: ThemingRoute,
    path: "/mochila/regras",
    component: Rules
  },
  {
    router: ThemingRoute,
    path: "/mochila/partidas-valem-xp",
    component: PlayToWinXp
  },
  {
    router: ThemingRoute,
    path: "/mochila/quando-tenho-avaliacao",
    component: EvaluationDates
  },
  {
    router: ThemingRoute,
    path: "/mochila/livro",
    component: Book
  },
  {
    router: ThemingRoute,
    path: "/mochila/editar-perfil/avatar",
    component: ChangeAvatar
  }
]

export default Routes
