export default [
  {
    title: "",
    description: "",
    items: [
      {
        text: "Um jogador fica com as figuras de animais e o outro com as sombras.",
      },
      {
        text: "Em sua vez, cada jogador coloca uma peça em uma casa no tabuleiro.",
      },
      {
        text: "O jogo termina quando um dos jogadores conseguir colocar três peças suas em sequência na horizontal, vertical ou diagonal.",
      }
    ]
  }
]