import React from "react"
import styled from "styled-components"
import Planet from "Galaxy/wrappers/Planet"
import PlanetImage from "Galaxy/wrappers/PlanetImage"
import natus from "galaxies/orange-galaxy/natus.svg"

const Natus = props => (
  <Planet {...props}>
    <PlanetImage src={natus} blocked={props.blocked} />
  </Planet>
)

export default styled(Natus)``
