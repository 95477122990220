import React from "react"
import ClipLoader from "halogenium/lib/ClipLoader"

const Submit = ({ value, disabled, arrow, waiting }) => (
  <button type="submit" disabled={disabled} className={`btn btn-primary ${waiting ? "waiting" : ""}`}>
    {value}

    {arrow && <i className="btn-icon arrow-icon arrow-icon-right" />}
    {waiting && <ClipLoader className="btn-loader" size="25px" />}
  </button>
)

export default Submit
