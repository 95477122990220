import React from "react"
import image from "icon-xp.svg"
import { Header } from "Header"
import { GamificationBar } from "GamificationBar"
import { ScrollUp } from "helpers/scroll"

const HowToWinXP = () => {
  ScrollUp()

  return (
    <div className="bag-manual-about-container">
      <Header backUrl="/mochila/mural" color="colored">
        <GamificationBar />
      </Header>
      <h1 className="purple">Como ganho XP?</h1>
      <img src={image} />
      <p>
        Os experience points (pontos de experiência), os famosos XP, são a única forma que você possui para avançar na
        trilha de seu planeta.
      </p>
      <p>Para adquiri-los, você precisa conquistar as Missões Diárias e/ou disputar partidas na Estação de Jogos.</p>
      <p>
        Toda essa experiência adquirida gera XP, portanto, quanto mais questões responder, e mais partidas jogar, mais
        você avançará na busca pelo conhecimento perdido :)
      </p>
      <p>Por isso, responder e jogar um pouquinho por dia fará com que você avance ainda mais!</p>
    </div>
  )
}

export default HowToWinXP
