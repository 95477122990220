import styled from "styled-components"

const CarouselContainer = styled.div`
    width: 100%;
    position: relative;
    margin: auto;

    @media only screen and (max-width: 768px) {
        max-width: 100vw;

        iframe {
            max-width: 100%;
        }
    }
`
const CarouselSlides = styled.div`
    display: block;
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
      
      
    @-webkit-keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
    }
    
    @keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
    }
`

const CarouselLabels = styled.div`
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
`
const CarouselIndicator = styled.div`
    text-align:center;
`

const DotIndicator = styled.span`
    cursor: pointer;
    height: 20px;
    width: 20px;
    line-height: 20px;
    margin: 0 5px;
    background-color: #333;
    opacity: ${props => props.active === true ? 1 : 0.5};
    color: white;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
    &:hover {
        background-color: #717171;
    }
`

const CarouselNavigation = styled.a`
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    user-select: none;
    background: #333;
    color: white;
    text-decoration: none;
    left: 30px;
    &:hover {
        background-color: rgba(0,0,0,0.8);
    }
`

const PrevNavigator = styled(CarouselNavigation)`
    border-radius: 4px;
`

const NextNavigator = styled(CarouselNavigation)`
    right: 30px;
    left: auto;
    border-radius: 4px;
`


export {
    CarouselContainer,
    CarouselSlides,
    CarouselLabels,
    CarouselIndicator,
    DotIndicator,
    PrevNavigator,
    NextNavigator
}