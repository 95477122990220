export const MAX_FILE_SIZE = 5

function validFile (file) {
  return file.size < 1024 * 1024 * MAX_FILE_SIZE && ["image/png", "image/jpeg"].includes(file.type)
}

export function Upload (file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()

    if (validFile(file)) {
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        resolve(reader.result)
      }
    } else {
      reject({ file: null })
    }
  })
}
