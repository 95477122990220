import bottomCurve from "planets/cryo/bottom-curve"
import trail from "planets/cryo/trail"
import {
  PlanetHorizon,
  ShortPlanetHorizon,
  PlanetHorizonContainer,
  ShortPlanetHorizonContainer,
  PlanetHorizonTitle
} from "planets/cryo/horizon"
import editProfileBackground from "planets/cryo/cryo-edit-profile.svg"

export default {
  mainColor: "#212647",
  secondaryColor: "#8fcce0",
  bottomCurve: bottomCurve,
  trail: trail,
  knowledgeAreaSlide: { prev: "#8fcce0", next: "#8fcce0" },
  dailyMission: {
    questionSlider: { prev: "#8fcce0", next: "#8fcce0", arrowColor: "#fff" }
  },
  btnPrimary: { backgroundColor: "#ff9229", textColor: "#fff", shadowColor: "#ce6d10" },
  btnLink: { backgroundColor: "#8fcce", activeTextColor: "#ff9229" },
  sliderButtonArrowColor: "#fff",
  gamificationBar: {
    white: { textColor: "#8fcce0" },
    colored: { textColor: "#fff" }
  },
  header: { colored: { backgroundColor: "#8e3fac" } },
  horizon: {
    PlanetHorizon,
    ShortPlanetHorizon,
    PlanetHorizonContainer,
    ShortPlanetHorizonContainer,
    PlanetHorizonTitle
  },
  editProfileBackground: editProfileBackground,
  games: {
    title: "#629cc1",
    border: "#8fcce0",
    detailsHeader: "#1f3a5c"
  }
}
