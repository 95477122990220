import floor from "planets/natus/natus-checkpoint.svg"
import bottle from "planets/natus/natus-bottle.svg"

export default {
  mainColor: "#b46700",
  secondaryColor: "#b3a900",
  floor: {
    image: floor,
    height: 165
  },
  stone: {
    image: bottle,
    width: 58,
    height: 119,
    left: 40
  }
}
