import styled from "styled-components"

const Notification = styled.span`
  background-color: #d0021b;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 22px;
  width: 20px;
  height: 20px;
  font-size: 12px;
`

export default Notification
