import React from "react"

const FormInputFile = ({ value, arrow, onChange, ...rest }) => (
  <div className="btn btn-primary btn-file-upload">
    <span>{value}</span>
    <input
      className="btn-input-file-upload"
      type="file"
      onChange={onChange}
      {...rest} />
    {arrow && <i className="btn-icon arrow-icon arrow-icon-right" />}
  </div>
)

export default FormInputFile
