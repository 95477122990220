import bottomCurve from "planets/natus/bottom-curve"
import trail from "planets/natus/trail"
import {
  PlanetHorizon,
  ShortPlanetHorizon,
  PlanetHorizonContainer,
  ShortPlanetHorizonContainer,
  PlanetHorizonTitle
} from "planets/natus/horizon"
import editProfileBackground from "planets/natus/natus-edit-profile.svg"

export default {
  mainColor: "#49bf00",
  secondaryColor: "#fff852",
  bottomCurve: bottomCurve,
  trail: trail,
  knowledgeAreaSlide: { prev: "#c7d323", next: "#c7d323" },
  dailyMission: {
    questionSlider: { prev: "#d89b01", next: "#d89b01", arrowColor: "#fff" }
  },
  btnPrimary: { backgroundColor: "#ff9229", textColor: "#fff", shadowColor: "#ce6d10" },
  btnLink: { backgroundColor: "#49bf00", activeTextColor: "#d89b01" },
  gamificationBar: {
    white: { textColor: "#49bf00" },
    colored: { textColor: "#fff" }
  },
  header: { colored: { backgroundColor: "#8e3fac" } },
  horizon: {
    PlanetHorizon,
    ShortPlanetHorizon,
    PlanetHorizonContainer,
    ShortPlanetHorizonContainer,
    PlanetHorizonTitle
  },
  editProfileBackground: editProfileBackground,
  games: {
    title: "#7f89e3",
    border: "#7f89e3",
    detailsHeader: "#ffdd54"
  }
}
