import React from "react"

const SocioEmotionalExamFinalizedCard = ({ completed, expired, name }) => (
  <div className="exam-card">
    <div className="exam-card-title">{name}</div>
    <div className="exam-card-finalized">
      { !completed && expired ?
        <>
          <i className="clock-icon white-transparent small" />
          <span>Expirada</span>
        </>
      :
        <>
          <i className="check-icon white-transparent" />
          <span>Entregue</span>
        </>
    }
    </div>
  </div>
)

export default SocioEmotionalExamFinalizedCard
