export const GRADES_LIST_EF = [
  {
    id: "1EF",
    gradeType: "EF",
    gradeYear: 1,
    title: "1º Ano",
    subtitle: "ENSINO FUNDAMENTAL",
    bg: "#3876b9",
    bg_contrast: "#233a7c"
  },
  {
    id: "2EF",
    gradeType: "EF",
    gradeYear: 2,
    title: "2º Ano",
    subtitle: "ENSINO FUNDAMENTAL",
    bg: "#775884",
    bg_contrast: "#602058"
  },
  {
    id: "3EF",
    gradeType: "EF",
    gradeYear: 3,
    title: "3º Ano",
    subtitle: "ENSINO FUNDAMENTAL",
    bg: "#e9946c",
    bg_contrast: "#d64f20"
  },
  {
    id: "4EF",
    gradeType: "EF",
    gradeYear: 4,
    title: "4º Ano",
    subtitle: "ENSINO FUNDAMENTAL",
    bg: "#b7bc6c",
    bg_contrast: "#98a631"
  },
  {
    id: "5EF",
    gradeType: "EF",
    gradeYear: 5,
    title: "5º Ano",
    subtitle: "ENSINO FUNDAMENTAL",
    bg: "#29b6b9",
    bg_contrast: "#1da2a5"
  },
  {
    id: "6EF",
    gradeType: "EF",
    gradeYear: 6,
    title: "6º Ano",
    subtitle: "ENSINO FUNDAMENTAL",
    bg: "#ce1421",
    bg_contrast: "#ac1919"
  },
  {
    id: "7EF",
    gradeType: "EF",
    gradeYear: 7,
    title: "7º Ano",
    subtitle: "ENSINO FUNDAMENTAL",
    bg: "#379759",
    bg_contrast: "#0c683e"
  },
  {
    id: "8EF",
    gradeType: "EF",
    gradeYear: 8,
    title: "8º Ano",
    subtitle: "ENSINO FUNDAMENTAL",
    bg: "#c692ad",
    bg_contrast: "#a8597f"
  },
  {
    id: "9EF",
    gradeType: "EF",
    gradeYear: 9,
    title: "9º Ano",
    subtitle: "ENSINO FUNDAMENTAL",
    bg: "#7f7f7f",
    bg_contrast: "#4e4d4d"
  }
]


export const GRADES_LIST_EM = [
  {
    id: "2EM",
    gradeType: "EM",
    gradeYear: 2,
    title: "ENSINO MÉDIO",
    subtitle: "",
    bg: "#0a2a44",
    bg_contrast: "#4e5258"
  },
  {
    id: "1EM",
    gradeType: "EM",
    gradeYear: 1,
    title: "ENSINO MÉDIO",
    subtitle: "",
    hide: true,
    bg: "transparent",
    bg_contrast: "transparent"
  },
  {
    id: "3EM",
    gradeType: "EM",
    gradeYear: 3,
    title: "ENSINO MÉDIO",
    subtitle: "",
    hide: true,
    bg: "transparent",
    bg_contrast: "transparent"
  }
]
export const GRADES_LIST_EI = [
  {
    id: "EI4",
    gradeType: "EI",
    gradeYear: 4,
    title: "4 anos",
    subtitle: "EDUCAÇÃO INFANTIL",
    bg: "#A0ADCD",
    bg_contrast: "#3E5376"    
  },
  {
    id: "EI5",
    gradeType: "EI",
    gradeYear: 5,
    title: "5 anos",
    subtitle: "EDUCAÇÃO INFANTIL",
    bg: "#C8B1C3",
    bg_contrast: "#6C5364"
  }
]

export function getAllGrades () {
  return GRADES_LIST_EI.concat(GRADES_LIST_EF).concat(GRADES_LIST_EM)
}
