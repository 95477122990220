import * as Yup from "yup"
import { cpf } from "cpf-cnpj-validator"

const name_regex = /^(?![’'-])[\p{L}'’-]+(?:\s{1,}[\p{L}'’-]+)*$/u
const username_regex = /^[a-zA-Z0-9]{2,}$/

export const studentSchema = Yup.object({
  name: Yup.string()
    .required("obrigatório")
    .min(3, "muito curto")
    .matches(name_regex, "não pode conter números ou caracteres especiais/Nome completo separado por espaços")
    .test("is-full-name", "separado por espaços", (value) => {
      if (value) {
        return value.split(" ").length > 1
      }
    })
    .trim(),
  username: Yup.string()
    .required("obrigatório")
    .matches(username_regex, "não pode conter caracteres especiais e precisa ter ao menos 2 caracteres")
    .trim(),
  school_class: Yup.string().required("obrigatório"),
  email: Yup.string().email("E-mail inválido").trim(),
  password: Yup.string()
    .required("obrigatório")
    .min(6, "muito curto")
    .matches(/^[a-zA-Z0-9]{6,}$/i, "não pode conter caracteres especiais"),
  password_confirmation: Yup.string()
    .required("obrigatório")
    .oneOf([Yup.ref("password"), null], ". As senhas não conferem"),
  relative_name: Yup.string()
    .required("obrigatório")
    .min(3, "muito curto")
    .matches(name_regex, "não pode conter números ou caracteres especiais")
    .test("is-full-name", "separado por espaços", (value) => {
      if (value) {
        return value.split(" ").length > 1
      }
    })
    .trim(),
  relative_email: Yup.string().email("inválido").required("obrigatório"),
  relative_relationship: Yup.string().required("obrigatório"),
  relative_phone_number: Yup.string(),
  relative_cpf: Yup.string()
    .required("obrigatório")
    .test("is-valid-cpf", "CPF inválido", (value) => {
      if (value) {
        return cpf.isValid(value)
      }
    }),
  relative_rg: Yup.string()
    .required("obrigatório")
    .min(5, "muito curto")
    .matches(/^[a-zA-Z0-9]+$/, "não pode conter caracteres especiais")
    .trim(),
  relative_email_confirm: Yup.string()
    .email("inválido")
    .required("obrigatório")
    .oneOf([Yup.ref("relative_email"), null], ". Os e-mails não conferem"),
  terms_of_service: Yup.boolean()
    .oneOf([true], "Você precisa aceitar os termos de uso para continuar")
    .required("Você precisa aceitar os termos de uso para continuar"),
})
