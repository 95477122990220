export default [
  {
    title: "",
    description: "",
    items: [
      {
        text: "Escolher a versão do Jogo que deseja jogar (há duas versões possíveis: jogar dominó ou bloquear dominó).",
      },
      {
        text: "O jogo é para dois jogadores.",
      },
      {
        text: "Cada jogador recebe 7 peças e não as mostra para  seu oponente. As peças restantes ficam viradas para baixo, na mesa.",
      },
      {
        text: "O jogador que tiver a peça 6/6 começa o jogo com esta peça colocando-a no centro da mesa; se ninguém tiver esta peça, começa a partida quem tiver a 5/5, ou a 4/4, ou a 3/3...",
      },
      {
        text: "O próximo jogador coloca uma peça adjacente na ponta da peça inicial, de modo que quantidades iguais se toquem. O próximo a jogar deve colocar uma peça em uma das pontas abertas da fileira que vai se formando, e assim sucessivamente.",
      },
      {
        text: "Caso um jogador não tenha uma peça que se encaixe, deve comprar uma peça da mesa. Se a peça que comprou não se encaixar, o jogador deve comprar outra até conseguir uma que possa ser usada naquela rodada.",
        items: [
          {
            text: "OBSERVAÇÃO: na versão do jogo “Bloquear o Dominó” não há peças para compra! Sendo assim, caso o jogador não tenha uma peça que se encaixe, este deverá passar a vez.",
          }
        ]
      },
      {
        text: "Caso todas as peças extras da mesa tenham sido usadas e o jogador não possa jogar, ele passa a vez.",
      },
      {
        text: "Quando um dos jogadores usa sua última peça, a rodada termina. As quantidades representadas em todas as peças restantes, que estão com o(s) oponentes(s), são somadas e os pontos dados para o jogador que encerrou a partida. No caso do jogo ficar paralisado (nenhum jogador possuir peça possível de ser colocada e não haver mais peças de compra), cada jogador receberá a pontuação referente às quantidades restantes nas peças do(s) demais jogador(es).",
      }
    ]
  }
]