export default [
  {
    title: "Regras básicas",
    description: "",
    items: [
      {
        text: "Jogo para 2 jogadores",
      },
      {
        text: "A cada rodada, o jogador deve escolher uma pergunta a ser feita ao oponente sobre o esconderijo da peça dele.As perguntas só podem ser respondidas com “sim” ou “não”.",
        items: [
          {
            text: "Exemplo de pergunta: “Olhando na direção das árvores, você vê um balanço?”",
          },
          {
            text: "Para fazer a perguntas, o jogador seleciona uma das 4 direções: Casas, Árvores, Lago ou Carros. Depois o jogador deve escolher um ponto de referência.",
          }
        ]
      },
      {
        text: "As perguntas devem ser formuladas de modo a descrever o que a “peça” do jogador oponente vê quando olha em uma das quatro direções do parque (casas, árvores, lago e rua).",
      }
    ]
  },
  {
    title: "Usando os marcadores",
    description: "",
    items: [
      {
        text: "Quando o oponente responde a sua pergunta, você deve colocar marcadores para marcar as casas:",
        items: [
          {
            text: "Os marcadores vermelhos devem ser usados para marcar as casas do tabuleiro em que não é possível que a peça do oponente esteja (a partir das respostas obtidas). Clique UMA vez sobre a casa que deseja marcar para aparecer o marcador vermelho.",
          },
          {
            text: "Os marcadores verdes devem ser usados para marcar as casas do tabuleiro em que ainda é possível que a peça do oponente esteja (a partir das respostas obtidas). Clique DUAS vezes sobre a casa que deseja marcar para aparecer o marcador verde.",
          }
        ]
      }
    ]
  },
  {
    title: "Fim do jogo",
    description: "",
    items: [
      {
        text: "O jogador pode, ao invés de formular uma pergunta, tentar adivinhar o esconderijo da peça do oponente, apontando para a casa no tabuleiro em que o jogador acredita que a peça esteja.",
        items: [
          {
            text: "Se o palpite estiver errado, o oponente pode mudar a peça de seu personagem para outro lugar do esconderijo, e o jogo continua.",
          },
          {
            text: "Se o palpite estiver correto, o jogador vence o jogo.",
          }
        ]
      }
    ]
  }
]