import React from "react"
import BoletoWaitingPayment from "backpack/payment/BoletoWaitingPayment"
import CreditCardError from "backpack/payment/CreditCardError"
import NewPayment from "backpack/payment/NewPayment"
import SuccessfulPayment from "backpack/payment/SuccessfulPayment"
import Processing from "backpack/payment/Processing"
import CreditCardWaiting from "backpack/payment/CreditCardWaiting"
import API from "api"

const PaymentComponent = ({ checkoutStatus, order, activationCode }) => {
  switch (checkoutStatus) {
    case "checkout":
    case "checkoutPayment":
    case "checkoutCreditcardInput":
      return <NewPayment />
    case "checkoutBoletoWaiting":
    case "checkoutBoletoView":
      return <BoletoWaitingPayment expirationDate={order.boleto.expiration_date} printHref={order.boleto.print_href} />
    case "checkoutCompleted":
      return (
        <SuccessfulPayment
          year={order.year}
          paymentMethod={order.payment_method}
          activationCode={activationCode}
          paidAt={order.paid_at}
        />
      )
    case "checkoutCreditcardError":
      return <CreditCardError />
    case "checkoutCreditcardProcessing":
      return <Processing />
    case "checkoutCreditcardWaiting":
      return <CreditCardWaiting />

  }
}

class Payment extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      renewData: {},
      fetched: false
    }
  }

  componentDidMount () {
    API.get("/renew", response => {
      this.setState({ renewData: response.data, fetched: true })
    })
  }

  render () {
    const { renewData, fetched } = this.state

    if (!fetched) {
      return null
    }

    return (
      <PaymentComponent
        checkoutStatus={renewData.checkout_status}
        order={renewData.order}
        activationCode={renewData.activation_code}
      />
    )
  }
}

export default Payment
