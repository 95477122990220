/* eslint-env node */
const env = process.env.ENV
const moipPublicKey = process.env.MOIP_PUBLIC_KEY
const sentryDsnPublic = process.env.SENTRY_PROJECT_DSN
const HotjarId = process.env.HOTJAR_ID
const HotjarSv = process.env.HOTJAR_SV
const mixpanelToken = process.env.MIXPANEL_TOKEN
const pixelID = process.env.PIXEL_ID
const publicCryptoKey = process.env.PUBLIC_CRYPTO_KEY
const apiEndpoint = process.env.API_ENDPOINT
const websocketEndpoint = process.env.WEBSOCKET_ENDPOINT
const gympassKey = process.env.GYMPASS_KEY
const muralHost = process.env.MURAL_HOST

export {
  env,
  moipPublicKey,
  sentryDsnPublic,
  HotjarId,
  HotjarSv,
  mixpanelToken,
  pixelID,
  publicCryptoKey,
  apiEndpoint,
  websocketEndpoint,
  gympassKey,
  muralHost
}
