import styled from "styled-components"
import mountain from "planets/racion/racion-mountain.svg"
import sky from "planets/racion/racion-sky.svg"
import { StyledHorizon, StyledHorizonContainer, StyledHorizonTitle } from "Horizon"

const PlanetHorizon = styled(StyledHorizon)`
  background: linear-gradient(180deg, #8e3fac 0%, #ff8cba 100%);

  &::before {
    background: url(${mountain}) no-repeat;
    left: 0;
    bottom: -136px;
    width: 578px;
    height: 272px;

    @media (max-width: 768px) {
      left: -300px;
    }
  }
`

const ShortPlanetHorizon = PlanetHorizon

const PlanetHorizonContainer = styled(StyledHorizonContainer)`
  &::before {
    background: url(${sky}) no-repeat;
    height: 245px;
    bottom: 0;
    right: 70px;

    @media (max-width: 768px) {
      right: -150px;
    }
  }
`

const ShortPlanetHorizonContainer = PlanetHorizonContainer

const PlanetHorizonTitle = StyledHorizonTitle

export { PlanetHorizon, ShortPlanetHorizon, PlanetHorizonContainer, ShortPlanetHorizonContainer, PlanetHorizonTitle }
