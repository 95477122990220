import React from "react"
import { Link } from "react-router-dom"
import Modal from "components/modal"
import UserAvatar from "components/user-avatar"
import Processing from "components/processing"
import API from "api"
import { MixpanelTrack } from "tags/mixpanel"

const WithExperience = ({ experience }) => (
  <div className="earnings-container">
    <i className="earnings-brain" />

    <div className="xp-points gamification-rounded">+ {experience} xp</div>

    <Link className="modal-link" to="/jogos/estacao">
      Voltar para a Estação de Jogos
    </Link>
  </div>
)

const WithoutExperience = ({ gamesPlayed }) => (
  <div className="earnings-container">
    <UserAvatar />

    <div className="gamification-rounded">
      <p>{gamesPlayed}º partida do dia</p>
      <div className="xp-points">0 xp</div>
    </div>

    <Link className="modal-link" to="/jogos/estacao">
      Voltar para a Estação de Jogos
    </Link>
  </div>
)

class MatchFinished extends React.Component {
  constructor (props) {
    super(props)
    this.state = { fetched: false, experience: null, gamesPlayed: null }
  }

  componentDidMount () {
    const search = this.props.location.search
    const partnerMatchId = search.split("=").pop()

    API.patch(`/matches/${partnerMatchId}`, {}, response => {
      MixpanelTrack("Concluiu partida")
      const { experience_amount: experience, games_played: gamesPlayed } = response.data
      this.setState({ experience, gamesPlayed, fetched: true })
    })
  }

  getTitle (gamesPlayed) {
    if (gamesPlayed <= 3) {
      return "Você ganhou"
    } else {
      return "Apenas suas 3 primeiras partidas do dia valem xp"
    }
  }

  render () {
    const { experience, gamesPlayed } = this.state

    if (!this.state.fetched) {
      return <Processing />
    }
    return (
      <Modal fullscreen color="purple">
        <div>
          <h2>Partida concluída!</h2>
          <p>{this.getTitle(gamesPlayed)}</p>
        </div>

        {gamesPlayed <= 3 ? (
          <WithExperience experience={experience} />
        ) : (
          <WithoutExperience gamesPlayed={gamesPlayed} />
        )}
      </Modal>
    )
  }
}

export default MatchFinished
