import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import ClockCountdown from "components/clock-countdown"
import UserAvatar from "components/user-avatar"

const DailyMissionUnavailableModal = ({ opened, onClose, unblockTime }) => (
  <CloseableModal small opened={opened} onClose={onClose}>
    <h2>Missão diária indisponível!</h2>
    <p className="medium">Sua próxima missão diária será liberada em breve.</p>
    <p className="medium">Enquanto isso, se prepare para os novos desafios.</p>
    <ClockCountdown until={unblockTime} />
    <UserAvatar kind="sad" />
  </CloseableModal>
)

export default DailyMissionUnavailableModal
