import PropTypes from "prop-types"
import styled from "styled-components"
import { withPlanet } from "planet-context"

const alignments = [
  { top: -25, left: 315 },
  { top: 99, left: 144 },
  { top: 248, left: 64 },
  { top: 343, left: 275 },
  { top: 465, left: 55 },
  { top: 570, left: 320 },
  { top: 625, left: 63 }
]

const Flag = styled.i`
  position: absolute;
  top: ${props => alignments[props.position].top}px;
  left: ${props => alignments[props.position].left}px;
  background: url(${props => props.planet.trail.flag}) no-repeat;
  width: 40px;
  height: 60px;
  animation: fadeIn 500ms ease-in-out;
`

Flag.propTypes = {
  position: PropTypes.number.isRequired,
  planet: PropTypes.shape({
    trail: PropTypes.shape({
      flag: PropTypes.string.isRequired
    })
  })
}

export default withPlanet(Flag)
