import React from "react"

const QuestionsLeft = ({ questionsAnswered, totalQuestions }) => (
  <div className="exam-card-metadata">
    <i className="checkbox-icon" />
    <div>
      <div>Restam <strong>{totalQuestions - questionsAnswered}</strong> Questões</div>
      <div className="exam-card-metadata-subitem">Total: <strong>{totalQuestions} questões</strong></div>
    </div>
  </div>
)

export default QuestionsLeft
