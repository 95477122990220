import React from "react"
import { connect } from "react-redux"
import { muralHost } from "config"
import getInitialsSchoolYearWithHash from "schoolYears"

class ManualMyMural extends React.Component {

  createUrlMural () {
    const { school_year } = this.props.currentUser
    const urlMural = `${muralHost}/${getInitialsSchoolYearWithHash(school_year)}`
    return urlMural
  }

  render () {
    const { has_books } = this.props.currentUser

    if (!has_books) {
      return null
    }
    return (
      <div className="manual-my-books">
        <h3 className="purple light">Meu Mural</h3>
        <div className="manual-link">
          <a target="_blank" href={this.createUrlMural()} rel="noreferrer">
            <i className="arrow-icon arrow-icon-right manual-link-arrow" />
            <p className="pink manual-link-text">
              <strong>Acesse as atividades do Mural</strong>
            </p>
          </a>
          <hr />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  }
}

export default connect(mapStateToProps)(ManualMyMural)
