import styled from "styled-components"
import { withPlanet } from "planet-context"

const GameDetailsContainer = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  width: 90%;
  position: absolute;
  margin-top: 280px;
  padding-bottom: 130px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`

const GameCarouselContainer = styled.div`
  display: grid;
  grid-column-start: 1;
  grid-column-end: 3;
  justify-self: center;
  align-self: center;
  margin-bottom: 50px;
`

const GameImageContainer = styled.div`
  width: auto;
  justify-self:center;
  align-self:flex-start;

  & img {
    display: block;
    margin: auto;
    max-width: calc(100vw - 10%);
  }

  & a {
    display: block;
    margin: 30px auto;
  }

  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    padding: 15px;
    box-sizing: border-box;
    padding-right: 125px;
    border-top: 1px solid #ececec;
    img, span {
      display: none;
    }
    > a {
      margin: 0 !important;
    }
  }
`

const GameTitle = withPlanet(styled.span`
  text-align: center;
  color: ${props => props.planet.games.title};
  font-size: 31px;
  font-weight: 700;
  margin-bottom: 30px;
  display: block;
  @media (max-width: 768px) {
    margin-bottom: 0;
    line-height: 1.2;
  }
`)

const GameDetailSection = withPlanet(styled.span`
  display: block;
  color: ${props => props.planet.games.detailsHeader};
  font-weight: 700;
  margin: 20px 0;

  &:first-child {
    margin-top: 0;
  }
`)

const GameDescriptionContainer = styled.div`
  width: auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const InstructionTitle = styled.p`
  margin-top: 20px;
`

const InstructionDescription = styled.p`
  margin-bottom: 20px;
`

const RuleItem = styled.div`
  margin-top: 20px;
`

const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 25;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #00000066;
`

const ModalGameList = styled.div`
  position: fixed;
  background: #ffffff;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 70%;
  max-height: calc(100% - 8rem);
  overflow-y: auto;
  z-index: 1;

  @media only screen and (max-width: 768px) {
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: calc(100% - 2rem);
    min-height: calc(100vh - 5rem);
    max-height: calc(100vh - 5rem);
  }
`

const GradeLabel = styled.div`
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin: 1rem 0;
  line-height: 1;
`

const GamesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow-y: auto;
  min-height: 70vh;
`

const HEADER_HEIGHT_PX = "245px"
const GridContainer = styled.div`
  width: 100%;
  padding: 0;
  position: relative;
  overflow-y: hidden;
  margin-top: ${HEADER_HEIGHT_PX};
  @media only screen and (min-width: 768px) {
    overflow-y: auto;
    max-height: calc(100vh - ${HEADER_HEIGHT_PX});
  }
  @media (max-width: 768px) {
    margin-top: 0%;
  }
  z-index: 1;
`

const GradesGrid = styled.div`
  display: grid;
  max-width: 800px;
  margin: auto;
  padding: 2rem 0;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const GradeItem = styled.div`
  padding: 0.75rem 1rem;
  text-align: right;

  @media only screen and (max-width: 768px) {
    display: ${prop => prop.hide && "none"}
  }
`

const Image = styled.img`
  width: 90%;
  margin: 4%;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    width: 98%;
    margin: 0;
  }
`

const MadeImage = styled.div`
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100px;
  padding: 1rem;
  width: 200px;
  margin: auto;
  color: #ffffffee;
  background: ${props => props.bg};
  background-image: ${props => "linear-gradient(135deg, " + props.bg_contrast + " 12%, " + props.bg + " 12%)"};
  cursor: pointer;
  transition: 0.2s;
  border-radius: 0.375rem;
  visibility: ${props => props.hide ? "hidden" : "visible"};

  div {
    font-weight: 600;
    font-style: italic;
    font-size: 2.25rem;
  }

  span {
    font-size: 0.52rem;
    font-weight: 500;
    letter-spacing: 1px;
  }

  &:hover {
    transform: scale(1.05);
  }
`

const LabelOlympic = styled.div`
  font-size: 2.2rem;
  font-weight: 600;
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const OlympicGames = styled.div`
  display: flex;
  justify-content: center;
  zoom: 0.9;
  margin-bottom: 2rem;

  ${GamesContainer} {
    min-height: auto
  }
`

const ResponsiveLayout = styled.div`
  StyledHeader {
    @media only screen and (max-width: 768px) {
      position: relative
    }
  }

`


export {
  InstructionTitle,
  InstructionDescription,
  RuleItem,
  GameDetailsContainer,
  GameCarouselContainer,
  GameImageContainer,
  GameTitle,
  GameDescriptionContainer,
  GameDetailSection,
  GamesContainer,
  GridContainer,
  GradesGrid,
  GradeItem,
  GradeLabel,
  LabelOlympic,
  OlympicGames,
  Image,
  MadeImage,
  ModalContainer,
  ModalGameList,
  ResponsiveLayout
}
