import React from "react"
import { Link } from "react-router-dom"
import EssayTemplate from "folha_de_redacao_Mindzup.pdf"

const EssayPrintPage = () => (
  <div className="download-essay-template-container">
    <i className="print-icon"/>
    <Link to={EssayTemplate} target="_blank" download>BAIXAR FOLHA DE REDAÇÃO</Link>
  </div>
)

export default EssayPrintPage
