import styled from "styled-components"
import horizon from "planets/roya/roya-horizon.svg"

import { StyledHorizon, StyledHorizonContainer, StyledHorizonTitle } from "Horizon"

const PlanetHorizon = styled(StyledHorizon)`
  background: linear-gradient(360deg, #fcffb2 0%, #fdebc9 24%, #fecaf0 70%, #ffbdff 94%);
  overflow: hidden;
  &::before {
    background: url(${horizon}) repeat-x center top;
    background-size: auto 100%;
    width: 100%;
    margin: 0px auto;
    bottom: -1px;
    height: 95%;
    justify-content: center;
  }
`

const ShortPlanetHorizon = PlanetHorizon

const PlanetHorizonContainer = StyledHorizonContainer
const ShortPlanetHorizonContainer = StyledHorizonContainer

const PlanetHorizonTitle = styled(StyledHorizonTitle)`
  position: absolute;
  right: 250px;
  @media (max-width: 1024px) {
    right: auto;
    top: 60px;
    left: 70%;
  }
`

export { PlanetHorizon, ShortPlanetHorizon, PlanetHorizonContainer, ShortPlanetHorizonContainer, PlanetHorizonTitle }
