import React from "react"
import UserAvatar from "components/user-avatar"
import UserMessage from "components/layouts/user-message"
import { BtnPrimary } from "components/button"

const MissingOpponent = () => (
  <UserMessage backUrl="/jogos/estacao">
    <h2 className="caramel light">Nenhum oponente</h2>
    <p className="title-small">foi encontrado</p>

    <UserAvatar kind="sad" />

    <p>
      Não conseguimos encontrar alguém para jogar com você.<br />
      Você pode escolher outro jogo ou tentar novamente mais tarde.
    </p>

    <BtnPrimary to="/jogos/estacao" value="Voltar para a Estação" />
  </UserMessage>
)

export default MissingOpponent
