import React from "react"
import styled, { css } from "styled-components"

const Container = styled.div`
  border-radius: 25px;
  padding: 3px;
  border-width: 3px;
  border-style: solid;

  ${props =>
    props.theme === "purple" &&
    css`
      border-color: #8e3fac;
    `};

  ${props =>
    props.theme === "white" &&
    css`
      border-color: #ffffff;
    `};
`

const Bar = styled.div`
  border-radius: 25px;

  ${props =>
    props.fillIncomplete &&
    css`
      background: #d0021b;
    `}
`

const CompleteBar = styled.div`
  height: 10px;
  border-radius: ${props => (props.fillIncomplete ? "25px 0 0 25px" : "25px")};
  width: ${props => props.complete}%;

  ${props =>
    props.theme === "purple" &&
    css`
      background: #50e3c2;
    `};

  ${props =>
    props.theme === "white" &&
    css`
      background: linear-gradient(to right, transparent, #ffffff);
    `};
`

const PercentageBar = ({ complete, theme, fillIncomplete }) => (
  <Container theme={theme}>
    <Bar fillIncomplete={fillIncomplete}>
      <CompleteBar theme={theme} complete={complete} fillIncomplete={fillIncomplete} />
    </Bar>
  </Container>
)

PercentageBar.defaultProps = {
  complete: 0,
  theme: "purple",
  fillIncomplete: false
}

export default PercentageBar
