import styled from "styled-components"
import PlanetImage from "Galaxy/wrappers/PlanetImage"
import { StyledGameControl } from "Galaxy/elements/GameControl"
import { StyledSatellite } from "Galaxy/elements/Satellite"
import MilkyWay from "Galaxy/elements/MilkyWay"
import Axion from "Galaxy/planets/Axion"
import Groupem from "Galaxy/planets/Groupem"
import Imagite from "Galaxy/planets/Imagite"
import Logic from "Galaxy/planets/Logic"
import Natus from "Galaxy/planets/Natus"
import Onis from "Galaxy/planets/Onis"
import Pensatus from "Galaxy/planets/Pensatus"
import Racion from "Galaxy/planets/Racion"
import Union from "Galaxy/planets/Union"
import Kai from "Galaxy/planets/Kai"
import Cryo from "Galaxy/planets/Cryo"
import Roya from "Galaxy/planets/Roya"

const Space = styled.div`
  width: 80%;
  height: 100%;
  position: relative;
  margin: 0 auto;

  ${StyledGameControl} {
    position: absolute;
    left: 35%;
    top: 700px;

    @media (max-width: 375px) {
      top: 490px;
    }

    @media (min-width: 376px) and (max-width: 1024px) {
      top: 530px;
    }

    @media (max-width: 1024px) {
      left: 0;
    }
  }

  ${StyledSatellite} {
    position: absolute;
    left: 0;
    top: 230px;
  }

  ${MilkyWay} {
    position: absolute;
    top: 50px;
    left: 20%;
    z-index: 0;

    @media (max-width: 1024px) {
      left: 13%;
    }

    ${PlanetImage} {
      width: 164px;
      height: 109px;
    }
  }

  ${Pensatus}, ${Groupem}, ${Natus},  ${Cryo} {
    position: absolute;
    top: 150px;
    left: 55%;

    @media (max-width: 375px) {
      top: 180px;
    }

    @media (max-width: 1024px) {
      left: auto;
      right: 0;
    }

    ${PlanetImage} {
      width: 107px;
      height: 107px;
    }
  }

  ${Union}, ${Logic}, ${Axion}, ${Roya} {
    position: absolute;
    top: 350px;
    left: 30%;
    right: auto;
    z-index: 0;

    @media (max-width: 375px) {
      left: auto;
      right: 0;
    }

    @media (min-width: 376px) and (max-width: 1023px) {
      left: 18%;
    }

    ${PlanetImage} {
      width: 302px;
      height: 195px;

      @media (max-width: 1024px) {
        width: 200px;
        height: 130px;
      }
    }
  }

  ${Imagite}, ${Racion}, ${Onis}, ${Kai} {
    position: absolute;
    top: 400px;
    left: auto;
    right: 0;
    z-index: 0;

    @media (max-width: 375px) {
      top: 575px;
    }

    @media (min-width: 376px) and (max-width: 1024px) {
      top: 575px;
    }

    ${PlanetImage} {
      width: 328px;
      height: 331px;

      @media (max-width: 1024px) {
        width: 230px;
        height: 232px;
      }
    }
  }
`

export default Space
