import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { withPlanet } from "planet-context"

const BottomCurve = styled.div`
  background-color: #ffffff;
  width: 120%;
  height: 100%;
  min-height: 375px;
  border-radius: 50%/100px 100px 0 0;
  margin-top: 200px;
  text-align: center;
  padding-bottom: 20px;
`

const AvatarBottomCurve = styled(BottomCurve)`
  background: linear-gradient(to bottom, ${props => props.mainColor} 0%, ${props => props.secondaryColor} 100%);
  position: relative;
  margin-top: 55px;
  min-height: 230px;
`

const Stone = styled.i`
  background: url(${props => props.image}) no-repeat;
  display: block;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  position: absolute;
  top: 75px;
  left: ${props => props.left}%;

  @media (max-width: 768px) {
    left: 10%;
  }
`

const Floor = styled.div`
  background: url(${props => props.image}) no-repeat center bottom;
  width: 310px;
  height: ${props => props.height}px;
  text-align: center;
  position: absolute;
  top: 20px;
  left: 56%;
  margin-left: -154px;

  @media (max-width: 768px) {
    left: 70%;
  }
`

let UserAvatarBottomCurve = ({ planet, avatar: Avatar }) => {
  const UserAvatar = styled(Avatar)`
    display: block;
    margin: -110px auto 0;
    height: 195px;
  `

  const { mainColor, secondaryColor, stone, floor } = planet.bottomCurve

  return (
    <AvatarBottomCurve mainColor={mainColor} secondaryColor={secondaryColor}>
      <Stone image={stone.image} width={stone.width} height={stone.height} left={stone.left} />
      <Floor image={floor.image} height={floor.height}>
        <UserAvatar />
      </Floor>
    </AvatarBottomCurve>
  )
}

UserAvatarBottomCurve.propTypes = {
  planet: PropTypes.shape({
    bottomCurve: PropTypes.shape({
      mainColor: PropTypes.string.isRequired,
      secondaryColor: PropTypes.string.isRequired,
      stone: PropTypes.shape({
        image: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired
      }),
      floor: PropTypes.shape({
        image: PropTypes.string.isRequired,
        height: PropTypes.number.isRequired
      })
    })
  }),
  avatar: PropTypes.object.isRequired
}

UserAvatarBottomCurve = withPlanet(UserAvatarBottomCurve)

export { BottomCurve, UserAvatarBottomCurve }
