import React from "react"
import { push } from "connected-react-router"
import { connect } from "react-redux"
import OnboardingPaymentCreditcardWaitingNextYear from "onboarding/order/CreditcardWaitingNextYear"
import OnboardingPaymentCreditcardWaitingCurrentYear from "onboarding/order/CreditcardWaitingCurrentYear"
import API from "api"

class OnboardingPaymentCreditcardWaiting extends React.Component {
  constructor (props) {
    super(props)
    this.state = { waiting: true, isNextYear: false }
  }

  componentDidMount () {
    this.checkRenewal()
  }

  checkRenewal () {
    const { dispatch } = this.props

    API.get("/checkout/order", response => {
      const { renew } = response.data

      if (renew) {
        dispatch(push("/mochila/pagamento"))
      } else {
        API.get("/checkout/confirmation", response => {
          const { data: order } = response
          const isNextYear = order.year == new Date().getFullYear() + 1
          this.setState({ waiting: false, isNextYear })
        })
      }
    })
  }

  render () {
    const { waiting, isNextYear } = this.state

    if (waiting) {
      return null
    }

    return isNextYear ? (
      <OnboardingPaymentCreditcardWaitingNextYear />
    ) : (
      <OnboardingPaymentCreditcardWaitingCurrentYear />
    )
  }
}

export default connect()(OnboardingPaymentCreditcardWaiting)
