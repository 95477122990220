import React from "react"
import {
    CarouselContainer,
    CarouselSlides,
    CarouselLabels,
    CarouselIndicator,
    DotIndicator,
    PrevNavigator,
    NextNavigator,
} from "./styled"

const deviceSizes = {
    "mobile": {
        width: 400,
        height: 225
    },
    "tablet": {
        width: 700,
        height: 394
    },
    "desktop": {
        width: 1024,
        height: 563
    }
}

export default class Carousel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            imageIndex: 0,
            typeSize: "desktop"
        }
    }

    setImageIndex(value) {
        this.setState({ imageIndex: value })
    }

    componentDidMount() {
        this.updateDimensions()
        window.addEventListener("resize", this.updateDimensions.bind(this))
    }

    updateDimensions() {
        const { typeSize } = this.state
        if (window.innerWidth > 1024 && typeSize !== "desktop") {
            this.setState({ typeSize: "desktop" })
        } else if (window.innerWidth < 700 && typeSize !== "mobile") {
            this.setState({ typeSize: "mobile" })
        } else if (window.innerWidth <= 1024 && window.innerWidth >= 700 && typeSize !== "tablet") {
            this.setState({ typeSize: "tablet" })
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this))
    }

    render() {
        const { imageIndex, typeSize } = this.state
        const { content, labels, type, size } = this.props
        const { width, height } = deviceSizes[typeSize]
        return (
            <div>
                <CarouselContainer>
                    <CarouselSlides>
                        {
                            type === "video" && (<iframe src={content[imageIndex].url}
                                width={width} height={height} frameBorder="0" allow="autoplay; fullscreen"
                                allowFullScreen title={content[imageIndex].title}></iframe>)
                        }

                        {labels !== undefined && (
                            <CarouselLabels>
                                {labels[imageIndex]}
                            </CarouselLabels>)
                        }
                    </CarouselSlides>
                    {imageIndex !== 0 && <PrevNavigator onClick={() => this.setImageIndex(imageIndex - 1)}>&#10094;</PrevNavigator>}
                    {imageIndex !== size - 1 && <NextNavigator onClick={() => this.setImageIndex(imageIndex + 1)}>&#10095;</NextNavigator>}
                </CarouselContainer>
                <br />

                <CarouselIndicator>
                    {
                        Array.from(Array(size).keys()).map((item, index) =>
                            <DotIndicator
                                key={`carousel-dot-${String(item)}`}
                                active={item === imageIndex ? true : false}
                                onClick={() => this.setImageIndex(item)}>
                                    {index + 1}
                            </DotIndicator>)
                    }
                </CarouselIndicator>
            </div>
        )
    }

}