import bottomCurve from "planets/imagite/bottom-curve"
import trail from "planets/imagite/trail"
import {
  PlanetHorizon,
  ShortPlanetHorizon,
  PlanetHorizonContainer,
  ShortPlanetHorizonContainer,
  PlanetHorizonTitle
} from "planets/imagite/horizon"
import editProfileBackground from "planets/imagite/imagite-edit-profile-stones.svg"

export default {
  mainColor: "#6d38a7",
  secondaryColor: "#ffb3b3",
  bottomCurve: bottomCurve,
  trail: trail,
  knowledgeAreaSlide: { prev: "#b373ae", next: "#b373ae" },
  dailyMission: {
    questionSlider: { prev: "#b373ae", next: "#b373ae", arrowColor: "#fff" }
  },
  btnPrimary: { backgroundColor: "#ff9229", textColor: "#fff", shadowColor: "#ce6d10" },
  btnLink: { backgroundColor: "#b373ae", activeTextColor: "#ff9229" },
  sliderButtonArrowColor: "#fff",
  gamificationBar: {
    white: { textColor: "#6d38a7" },
    colored: { textColor: "#fff" }
  },
  header: { colored: { backgroundColor: "#8e3fac" } },
  horizon: {
    PlanetHorizon,
    ShortPlanetHorizon,
    PlanetHorizonContainer,
    ShortPlanetHorizonContainer,
    PlanetHorizonTitle
  },
  editProfileBackground: editProfileBackground,
  games: {
    title: "#6d38a7",
    border: "#6d38a7",
    detailsHeader: "#6a7dd9"
  }
}
