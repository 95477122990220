import React from "react"
import ActiveAvatar from "avatar/leri-ship.svg"
import InactiveAvatar from "avatar/leri-ship-inactive.svg"

const LeriShip = ({ inactive, className = "", ...rest }) => {
  if (inactive) {
    return <img src={InactiveAvatar} className={`inactive ${className}`} {...rest} />
  }
  return <img src={ActiveAvatar} className={className} {...rest} />
}

export default LeriShip
