import stone0Icon from "planets/union/union-stone-0.svg"
import stone1Icon from "planets/union/union-stone-1.svg"
import stone2Icon from "planets/union/union-stone-2.svg"
import stone3Icon from "planets/union/union-stone-3.svg"
import stone4Icon from "planets/union/union-stone-4.svg"
import stone5Icon from "planets/union/union-stone-5.svg"
import stone6Icon from "planets/union/union-stone-6.svg"

export const stone0 = {
  icon: stone0Icon,
  width: "30px",
  height: "41px",
  top: "0",
  left: "315px"
}

export const stone1 = {
  icon: stone1Icon,
  width: "33px",
  height: "47px",
  top: "115px",
  left: "140px"
}

export const stone2 = {
  icon: stone2Icon,
  width: "51px",
  height: "66px",
  top: "250px",
  left: "45px"
}

export const stone3 = {
  icon: stone3Icon,
  width: "48px",
  height: "66px",
  top: "345px",
  left: "260px"
}

export const stone4 = {
  icon: stone4Icon,
  width: "35px",
  height: "50px",
  top: "480px",
  left: "55px"
}

export const stone5 = {
  icon: stone5Icon,
  width: "41px",
  height: "63px",
  top: "570px",
  left: "315px"
}

export const stone6 = {
  icon: stone6Icon,
  width: "53px",
  height: "68px",
  top: "630px",
  left: "45px"
}
