import React from "react"
import { Redirect } from "react-router"
import { connect } from "react-redux"
import { Layout } from "Avatar"
import { BtnPrimary } from "components/button"
import AvatarSlide from "components/avatar-slide"
import { BottomCurve } from "components/bottom-curve"
import { validateAvatarAccess } from "onboarding/avatar/validation"

class OnboardingAvatarChoose extends React.Component {
  constructor (props) {
    super(props)
    this.state = { fireRedirect: false }

    this.handleChange = this.handleChange.bind(this)
    this.handleChoose = this.handleChoose.bind(this)
  }

  componentDidMount () {
    validateAvatarAccess(this.props.dispatch)
  }

  handleChange (avatar) {
    this.props.dispatch({ type: "SET_SELECTED_AVATAR", avatar: avatar })
  }

  handleChoose (event) {
    this.setState({ fireRedirect: true })
    event.preventDefault()
  }

  render () {
    const { fireRedirect } = this.state
    const { selectedAvatar } = this.props

    return (
      <Layout>
        <h2 className="big dotted">
          Escolha seu <strong>Avatar</strong>
        </h2>

        <BottomCurve>
          <AvatarSlide selected={selectedAvatar} onChange={this.handleChange} />

          <BtnPrimary to="/" value="Confirmar" onClick={this.handleChoose} arrow />
        </BottomCurve>

        {fireRedirect && <Redirect to="/criar-conta/avatar-escolhido" />}
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    selectedAvatar: state.selectedAvatar
  }
}

export default connect(mapStateToProps)(OnboardingAvatarChoose)
