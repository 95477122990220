import React from "react"
import Modal from "components/modal"
import { VigoSad } from "components/avatar"

const NotFound = () =>
  <Modal backUrl="/" fullscreen color="honey-flower">
    <div>
      <h2 className="caramel">Página não encontrada!</h2>
      <p>Infelizmente não conseguimos encontrar a página que você está <br />
      procurando. Você pode voltar e tentar novamente :)</p>
      <VigoSad className="avatar big" />
    </div>
  </Modal>

export default NotFound
