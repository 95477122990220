import background from "galaxies/purple-galaxy/purple-background.svg"
import satellite from "galaxies/elements/purple-satellite.svg"
import gameControl from "galaxies/elements/purple-game-control.svg"
import milkyWay from "galaxies/elements/purple-milky-way.svg"

export default {
  background: background,
  satellite: satellite,
  gameControl: gameControl,
  milkyWay: milkyWay
}
