import React from "react"

const ExamPausableRulesContent = ({ title }) => (
  <div>
    <h2 className="light">{title}</h2>

    <div className="exam-rules">
      <p>
        <strong>Regras:</strong>
      </p>
      <p>
        Cada área do conhecimento de uma avaliação possui duração pré-definida e o tempo disponível pode ser visualizado
        no cronômetro dentro do respondedor. Ao término desse tempo, a prova será encerrada e você não poderá mais
        responder às questões em branco.
      </p>
      <p>
        <strong>1.</strong> As questões não respondidas serão consideradas incorretas.
      </p>
      <p>
        <strong>2.</strong> A avaliação <strong>pode ser pausada quantas vezes você achar necessário</strong> e por
        tempo indefinido. Ao reiniciar a avaliação, o tempo volta a ser computado de onde parou.
      </p>
      <p>
        <strong>3.</strong> Em caso de queda de energia ou internet, o tempo será pausado automaticamente. Quando se
        conectar novamente, você poderá <strong>retomar a avaliação exatamente de onde parou</strong>.
      </p>
      <p>
        <strong>4.</strong> As questões podem ser respondidas na ordem que você desejar.
      </p>
      <p>
        <strong>5.</strong> Você pode revisar uma questão já respondida e alterar sua resposta quantas vezes desejar,
        até que finalize a avaliação ou o tempo total seja esgotado.
      </p>
      <p>
        <strong>6.</strong> Ao término da avaliação, ela será considerada entregue, e você poderá visualizar os detalhes
        da avaliação na aba Finalizadas.
      </p>
      <p>
        <strong>7.</strong> Para alunos de 2° ao 8° ano, a nota será disponibilizada pela escola.
      </p>
    </div>
  </div>
)

export default ExamPausableRulesContent
