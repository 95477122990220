import React from "react"
import CloseableModal from "components/modals/closeable-modal"
import { Vigo } from "components/avatar"

const VisitedPlanetModal = ({ opened, onClose }) => (
  <CloseableModal small opened={opened} onClose={onClose}>
    <h2 className="light">Planeta Visitado!</h2>

    <p>Você já recolheu todo o conhecimento que estava nesse planeta!</p>
    <p>Vá para o próximo e ajude seus companheiros!</p>

    <Vigo />
  </CloseableModal>
)

export default VisitedPlanetModal
