import ThemingRoute from "helpers/theming-route"
import SearchingOpponent from "GamesStation/SearchingOpponent"
import SingleGame from "GamesStation/SingleGame"
import MissingOpponent from "GamesStation/MissingOpponent"
import GradeList from "GamesStation/GradeList"
import GameDetails from "GamesStation/GameDetails"
import MatchFinished from "GamesStation/MatchFinished"
import MatchFinishedSinglePlayer from "GamesStation/MatchFinishedSinglePlayer"

const Routes = [
  {
    router: ThemingRoute,
    path: "/jogos/procurando/:name",
    component: SearchingOpponent
  },
  {
    router: ThemingRoute,
    path: "/jogos/singlegame/:name",
    component: SingleGame
  },
  {
    router: ThemingRoute,
    path: "/jogos/sem-oponente",
    component: MissingOpponent
  },
  {
    router: ThemingRoute,
    path: "/jogos/estacao",
    component: GradeList
  },
  {
    router: ThemingRoute,
    path: "/jogos/detalhes/:name",
    component: GameDetails
  },
  {
    router: ThemingRoute,
    path: "/matches/callback/singleplayer",
    component: MatchFinishedSinglePlayer
  },
  {
    router: ThemingRoute,
    path: "/matches/callback",
    component: MatchFinished
  }
]

export default Routes
