import React from "react"
import { Switch, Route } from "react-router"
import OnboardingActivationRoutes from "onboarding/activation/routes"
import OnboardingAvatarRoutes from "onboarding/avatar/routes"
import OnboardingOrderRoutes from "onboarding/order/routes"
import GameRoutes from "GamesStation/routes"
import TrailRoutes from "trail/routes"
import DailyMissionRoutes from "daily-mission/routes"
import BagpackRoutes from "backpack/routes"
import ExamRoutes from "exam/routes"
import AccountRoutes from "account/routes"
import GalaxyRoutes from "Galaxy/routes"
import NotFound from "components/not-found"

const Routes = [].concat(
  AccountRoutes,
  GalaxyRoutes,
  BagpackRoutes,
  ExamRoutes,
  TrailRoutes,
  DailyMissionRoutes,
  GameRoutes,
  OnboardingActivationRoutes,
  OnboardingAvatarRoutes,
  OnboardingActivationRoutes,
  OnboardingOrderRoutes
)

const RouteMapper = () => (
  <Switch>
    {Routes.map((route, i) => (
      <route.router exact key={i} path={route.path} component={route.component} />
    ))}
    <Route component={NotFound} />
  </Switch>
)

export default RouteMapper
