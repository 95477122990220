import styled from "styled-components"
import PropTypes from "prop-types"

const alignments = [
  { top: 10, left: 350 },
  { top: 140, left: 180 },
  { top: 301, left: 94 },
  { top: 399, left: 310 },
  { top: 524, left: 95 },
  { top: 633, left: 358 },
  { top: 687, left: 100 },
]

const UserCounter = styled.div`
  position: absolute;
  background-repeat: no-repeat;
  background-color: #cbcbe2;
  border: #7064a0 3px solid;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  top: ${(props) => alignments[props.position].top}px;
  left: ${(props) => alignments[props.position].left}px;
  animation: fadeIn 500ms ease-in-out;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 12px;
  line-height: 2.4em;
  text-align: center;
  z-index: 5;
`

UserCounter.propTypes = {
  position: PropTypes.number.isRequired,
}

export default UserCounter
