import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { Header } from "Header"
import { GamificationBar } from "GamificationBar"
import StudentInfo from "components/student-info"
import { ScrollUp } from "helpers/scroll"
import { Notification } from "Icons"
import API from "api"

const NotificationIcon = styled(Notification)`
  position: absolute;
  top: -5px;
  right: -20px;
`

const BagPerformanceMenu = ({ activeSection, paymentEnabled, notifyPayment }) => (
  <div className="bag-menu">
    <Link to="/mochila/conquistas" className={`menu-item ${activeSection === "badges" ? "selected" : ""}`}>
      CONQUISTAS
    </Link>
    <Link to="/mochila" className={`menu-item ${activeSection === "performance" ? "selected" : ""}`}>
      DESEMPENHO
    </Link>
    <Link to="/mochila/mural" className={`menu-item ${activeSection === "mural" ? "selected" : ""}`}>
      MURAL
    </Link>
    {paymentEnabled && (
      <Link to="/mochila/pagamento" className={`menu-item ${activeSection === "payment" ? "selected" : ""}`}>
        PAGAMENTO
        {notifyPayment && <NotificationIcon>1</NotificationIcon>}
      </Link>
    )}
  </div>
)

class BagLayout extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      renewAvailable: false,
      notifyPayment: false,
      fetched: false
    }
  }

  componentDidMount () {
    ScrollUp()

    API.get("/renew", response => {
      const { renew_available: renewAvailable, unpaid_order: unpaidOrder } = response.data

      this.setState({
        renewAvailable: renewAvailable,
        notifyPayment: unpaidOrder,
        fetched: true
      })
    })
  }

  render () {
    const { renewAvailable, notifyPayment, fetched } = this.state
    const { activeSection, children } = this.props

    return (
      <div>
        <Header backUrl="/galaxia" color="colored">
          <GamificationBar />
        </Header>

        <StudentInfo />

        {fetched && (
          <BagPerformanceMenu
            activeSection={activeSection}
            paymentEnabled={renewAvailable}
            notifyPayment={notifyPayment}
          />
        )}

        <hr />

        {children}
      </div>
    )
  }
}

export default BagLayout
