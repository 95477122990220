import {
  STORAGE_KEY,
  SET_ACTIVATION_CODE,
  SET_PARTNER_EMAIL,
  SET_DAILY_MISSION_QUESTIONS,
  SET_DAILY_MISSION_CORRECT_AMOUNT,
  SET_DAILY_MISSION_WRONG_AMOUNT,
  SET_DAILY_MISSION_EXPERIENCE,
  SET_DAILY_MISSION_RUBY,
  SET_DAILY_MISSION_TRAIL_STEP,
  SET_DAILY_MISSION_TRAIL,
  UPDATE_CURRENT_USER,
  SET_CURRENT_PLANET,
  SET_CURRENT_GALAXY,
  SYNC_GAMIFICATION,
  START_GAMIFICATION_ANIMATION,
  STOP_GAMIFICATION_ANIMATION,
} from "actions"

import { START_REQUEST, STOP_REQUEST } from "actions/http"

import { persistCombineReducers } from "redux-persist"
import { connectRouter } from "connected-react-router"
import storage from "redux-persist/es/storage"
import mySisuReducer from "MySisu/reducer"
import backpackReducer from "backpack/reducer"
import { currentEssayReducer, essaySessionReducer } from "exam/reducer"

const selectedAvatar = (state = null, action) => {
  switch (action.type) {
    case "SET_SELECTED_AVATAR":
      return action.avatar
    default:
      return state
  }
}

const authInitialState = {
  currentUser: null,
  currentPlanet: null,
  currentGalaxy: null,
}

const auth = (state = authInitialState, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_USER:
      return Object.assign({}, state, {
        currentUser: Object.assign({}, action.user, {
          relative: action.relative,
        }),
      })
    case SET_CURRENT_PLANET:
      return Object.assign({}, state, {
        currentPlanet: action.planet,
      })
    case SET_CURRENT_GALAXY:
      return Object.assign({}, state, {
        currentGalaxy: action.galaxy,
      })
    default:
      return state
  }
}

const activationInitialState = {
  activationCode: "",
  schoolName: "",
  schoolYear: {},
}

const activation = (state = activationInitialState, action) => {
  switch (action.type) {
    case SET_ACTIVATION_CODE:
      return Object.assign({}, state, {
        activationCode: action.activationCode,
      })

    case SET_PARTNER_EMAIL:
      return Object.assign({}, state, {
        partnerEmail: action.partnerEmail,
      })
    case "SET_SCHOOL":
      return Object.assign({}, state, {
        schoolName: action.schoolName,
        schoolYear: action.schoolYear,
        schoolClass: action.schoolClass,
      })

    case "CLEAR_SCHOOL":
      return Object.assign({}, state, {
        schoolName: "",
        schoolYear: {},
        schoolClass: "",
      })

    case "SET_PAYMENT":
      return Object.assign({}, state, {
        payment: action.payment,
        order: action.order,
      })

    case "CLEAR_PAYMENT":
      return Object.assign({}, state, {
        payment: "",
        order: "",
      })

    default:
      return state
  }
}

const dailyMissionInitialState = {
  questions: [],
  wrongAmount: 0,
  correctAmount: 0,
  experience: 0,
  ruby: 0,
}

const dailyMissionQuestions = (state = dailyMissionInitialState, action) => {
  switch (action.type) {
    case SET_DAILY_MISSION_QUESTIONS:
      return Object.assign({}, state, {
        questions: action.questions,
      })
    case SET_DAILY_MISSION_WRONG_AMOUNT:
      return Object.assign({}, state, {
        wrongAmount: action.wrongAmount,
      })
    case SET_DAILY_MISSION_CORRECT_AMOUNT:
      return Object.assign({}, state, {
        correctAmount: action.correctAmount,
      })
    case SET_DAILY_MISSION_EXPERIENCE:
      return Object.assign({}, state, {
        experience: action.experience,
      })
    case SET_DAILY_MISSION_RUBY:
      return Object.assign({}, state, {
        ruby: action.ruby,
      })
    default:
      return state
  }
}

const dailyMissionTrailInitialState = {
  previousPlanet: "",
  previousStep: -1,
  completedScenarios: 0,
}

const dailyMissionTrail = (state = dailyMissionTrailInitialState, action) => {
  switch (action.type) {
    case SET_DAILY_MISSION_TRAIL_STEP:
      return Object.assign({}, state, {
        previousStep: action.step,
      })
    case SET_DAILY_MISSION_TRAIL:
      return Object.assign({}, state, {
        completedScenarios: action.completedScenarios,
        previousPlanet: action.planet,
      })
    default:
      return state
  }
}

const gamificationInitialState = {
  prevXp: 0,
  prevRuby: 0,
  xp: 0,
  ruby: 0,
  countUp: false,
}

const gamification = (state = gamificationInitialState, action) => {
  switch (action.type) {
    case SYNC_GAMIFICATION:
      return Object.assign({}, state, {
        prevXp: state.xp,
        prevRuby: state.ruby,
        xp: action.xp,
        ruby: action.ruby,
      })
    case START_GAMIFICATION_ANIMATION:
      return Object.assign({}, state, {
        countUp: true,
      })
    case STOP_GAMIFICATION_ANIMATION:
      return Object.assign({}, state, {
        countUp: false,
      })
    default:
      return state
  }
}

const httpInitialState = {
  requestInProgress: false,
}

const http = (state = httpInitialState, action) => {
  switch (action.type) {
    case START_REQUEST:
      return Object.assign({}, state, {
        requestInProgress: true,
      })
    case STOP_REQUEST:
      return Object.assign({}, state, {
        requestInProgress: false,
      })
    default:
      return state
  }
}

export default (history) => {
  // `router` is in blacklist because it is redirecing to the previous persisted
  // route when you open a page.
  let config = { key: STORAGE_KEY, storage, blacklist: ["router"] }

  // if (env === "production") {
  //   const encryptor = createEncryptor({
  //     secretKey: publicCryptoKey,
  //   })
  //   config = Object.assign(config, { transforms: [encryptor] })
  // }

  return persistCombineReducers(config, {
    router: connectRouter(history),
    selectedAvatar,
    auth,
    activation,
    dailyMissionQuestions,
    dailyMissionTrail,
    currentEssayReducer,
    essaySessionReducer,
    gamification,
    http,
    mySisuReducer,
    backpackReducer,
  })
}
