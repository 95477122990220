import React from "react"
import parseDate from "date-fns/parseISO"
import formatDate from "date-fns/format"
import MiniexamDetails from "components/exams/miniexam-details"
import EssayDetails from "components/exams/essay-details"
import HoursLeft from "components/exams/hours-left"
import QuestionsLeft from "components/exams/questions-left"
import PercentageBar from "components/PercentageBar"
import { BtnLight } from "components/button"

const Miniexams = ({ miniexams, exam }) =>
  miniexams.map((miniexam, index) => (
    <MiniexamDetails
      miniexamId={miniexam.id}
      miniexamName={miniexam.name}
      knowledgeArea={miniexam.knowledge_area}
      hasLanguage={miniexam.has_language}
      timeLeft={miniexam.time_left}
      correct={miniexam.total_correct}
      incorrect={miniexam.total_wrong}
      started={miniexam.started}
      completed={miniexam.completed}
      grade={miniexam.grade}
      examName={exam.name}
      examDuration={exam.duration}
      pausable={exam.pausable}
      showRules={miniexam.show_rules}
      key={index}
    />
  ))

class ExamCalibratedStartableCard extends React.Component {
  constructor (props) {
    super(props)
    this.state = { showDetails: false }
    this.toggleDetails = this.toggleDetails.bind(this)
  }

  toggleDetails (e) {
    this.setState({ showDetails: !this.state.showDetails })
    e.preventDefault()
  }

  render () {
    const {
      exam,
      totalQuestions,
      questionsAnswered,
      duration,
      timeLeft,
      started,
      expiresAt,
      miniexams,
      essay,
      completePercentage
    } = this.props
    const { showDetails } = this.state

    return (
      <div className="exam-card">
        <div className="exam-card-title">{exam.name}</div>

        <div className="exam-stats-container">
          <QuestionsLeft questionsAnswered={questionsAnswered} totalQuestions={totalQuestions} />
          <HoursLeft timeLeft={timeLeft} duration={duration} />
        </div>

        <PercentageBar theme="white" complete={completePercentage} />
        <div className="progress-bar-text">seu progresso na prova: {completePercentage}%</div>

        <div className="expiration-start-container">
          <div>
            Expira em <strong>{formatDate(parseDate(expiresAt), "dd/MM/yyyy 'às' HH'h'mm")}</strong>
          </div>

          <BtnLight
            color="white"
            small
            to="#"
            arrow={!showDetails}
            arrowDown={showDetails}
            value={started ? "Continuar" : "Começar"}
            onClick={this.toggleDetails}
          />
        </div>

        {showDetails && (
          <div>
            <Miniexams miniexams={miniexams} exam={exam} />
            {essay && <EssayDetails essay={essay} examName={exam.name} examDuration={exam.duration} />}
          </div>
        )}
      </div>
    )
  }
}

export default ExamCalibratedStartableCard
