import React from "react"
import HoursUtilized from "components/exams/hours-utilized"
import { BtnLight } from "components/button"
import { Redirect } from "react-router"

class ExamSlimFinalizedCard extends React.Component {
  constructor (props) {
    super(props)
    this.state = { fireRedirect: false, redirectTarget: "", redirectState: {} }
    this.handleAnswerKey = this.handleAnswerKey.bind(this)
  }

  handleAnswerKey () {
    const questionSetSessionId = this.props.questionSetSessionId

    this.setState({
      fireRedirect: true,
      redirectTarget: "/simulados/gabarito",
      redirectState: { questionSetSessionId }
    })
  }

  render () {
    const { miniexamName, timeSpent, duration, completed } = this.props
    const { fireRedirect, redirectTarget, redirectState } = this.state

    if (fireRedirect) {
      return <Redirect to={{ pathname: redirectTarget, state: redirectState }} />
    }

    return (
      <div className="exam-card">
        <div className="exam-card-title">{miniexamName}</div>

        <div className="expiration-start-container">
          <HoursUtilized timeSpent={timeSpent} duration={duration} />

          {completed ? (
            <div className="exam-card-metadata">
              <BtnLight to="#" value="Gabarito" color="white" onClick={this.handleAnswerKey} small arrow />
            </div>
          ) : (
            <div className="exam-card-metadata">Expirado</div>
          )}
        </div>
      </div>
    )
  }
}

export default ExamSlimFinalizedCard
